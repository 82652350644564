import { ListingBuyerModelConverter } from '@steelbuy/ts-shared';
import axios from '../../axios';
import { getPackageDetails } from '../packages';

const modelConverter = new ListingBuyerModelConverter();

export const fetchDetails = async (id: string, isPackage?: boolean) => {
    if (isPackage) {
        const packageModel = await getPackageDetails(id);
        return packageModel;
    }
    const { data } = await axios.get(`/api/universal/search-listings/${id}`);
    const listingModel = modelConverter.toViewModel(data);
    return listingModel;
};
