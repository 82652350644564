import { Control, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup, Checkbox } from '@steelbuy/form';
import { MaterialProperties, getOrigins } from '@steelbuy/ts-shared';
import { FormItem, Icon, IconIdentifier } from '@steelbuy/ui-primitive';

interface RFQOriginSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    name: TName;
    material: MaterialProperties;
    control: Control<TFieldValues>;
    error?: string;
}

export const RFQOriginSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    control,
    name,
    material,
    error,
}: RFQOriginSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['domainModel', 'translation']);

    const availableOrigins = getOrigins(material);

    const originsOptions = availableOrigins.map((origin) => ({
        label: t(`material.origin.value.${origin}`),
        value: origin,
    }));

    if (!originsOptions.length) {
        return null;
    }

    return (
        <FormItem
            header={t('translation:application.createRFQ.materialOrigin.header')}
            description={t('translation:application.createRFQ.materialOrigin.description')}
            isMandatory
        >
            {!!error && <Icon name={IconIdentifier.ERROR} />}
            <CheckboxGroup>
                <Checkbox
                    control={control}
                    name={name}
                    options={originsOptions}
                    id="origin-select"
                    rules={{ required: t(`translation:application.createRFQ.fieldRequired`) }}
                />
            </CheckboxGroup>
            {!!error && <span className="form-item__error-msg">{error}</span>}
        </FormItem>
    );
};
