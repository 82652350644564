import { AxiosError } from 'axios';
import { lazy, Suspense, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomError } from 'ts-custom-error';
import { useAuth } from '@steelbuy/auth';
import { useAssociateSoftwareToken, useVerifySoftwareToken, useUserSelfDetails } from '@steelbuy/data-access';

import { ButtonPrimary, InputTextfield, Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { ROUTE_ACCOUNT_OVERVIEW } from '../account/Account.enum';
import { PageHeader } from '../page-header/PageHeader';
import './AccountMfaView.scss';

const QRCodeSVG = lazy(() => import('./QrCode'));

interface FormData {
    code: string;
}

export const AccountMfaView = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);

    const navigate = useNavigate();
    const auth = useAuth();
    const { data: user, refetch } = useUserSelfDetails();
    const { control, handleSubmit } = useForm<FormData>();
    const [error, setError] = useState('');
    const mutation = useVerifySoftwareToken();
    const { data } = useAssociateSoftwareToken();

    const onSubmit = handleSubmit(({ code }) => {
        if (!user) {
            throw new CustomError(t('error.noUserError'));
        }
        mutation.mutate(
            {
                accessToken: auth.getToken().getOrThrow(Error('Access Token Unavailable')).accessToken as string,
                username: user.email,
                userCode: code,
            },
            {
                onError: (e: unknown) => e instanceof AxiosError && e.response && setError(e.response.data.message),
                onSuccess: (verifySoftwareTokenData) => {
                    if (verifySoftwareTokenData.status === 'SUCCESS') {
                        refetch();
                        navigate(RoutePath.ACCOUNT);
                    }
                },
            }
        );
    });

    return (
        <>
            <PageHeader
                pageTitle={t('translation:application.accountMFA.pageTitle')}
                previousPageTitle={t('translation:application.accountMFA.previousPageTitle')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_OVERVIEW)}
            />
            <FormLayout>
                <section className="accountMfaView__section">
                    {t('translation:application.accountMFA.info')}
                    <ol>
                        <li>
                            <strong>
                                {t(`uiDomain:login.challengeRespond.mfaSetup.instructions.downloadAndInstall`)}
                            </strong>
                        </li>
                        <li>
                            <strong>{t(`uiDomain:login.challengeRespond.mfaSetup.instructions.scanQRCode`)}</strong>
                        </li>
                        <li>
                            <strong>{t(`uiDomain:login.challengeRespond.mfaSetup.instructions.verifyAuthCode`)}</strong>
                        </li>
                    </ol>
                </section>
                <Suspense fallback={<div>loading</div>}>
                    {data && (
                        <div className="mfa-setup__qr-code">
                            <QRCodeSVG value={`otpauth://totp/steelbuy.app?secret=${data.secretCode}`} />
                        </div>
                    )}
                </Suspense>
                <form className="accountMfaView__form" onSubmit={onSubmit}>
                    <div>
                        <Controller
                            render={({ field: { onChange, onBlur } }) => (
                                <InputTextfield
                                    label={t('translation:application.accountMFA.inputLabel')}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                />
                            )}
                            name="code"
                            control={control}
                        />
                    </div>
                    <ButtonPrimary label={t('translation:application.accountMFA.submit')} type="submit" />
                </form>
                {error && <Notification level={NotificationLevel.ERROR} message={error} stayOpen />}
            </FormLayout>
        </>
    );
};
