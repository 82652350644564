import { useTranslation } from 'react-i18next';
import { InputRadioButton } from '../input-radio-button/InputRadioButton';
import { InputRadioButtonGroup, InputRadioButtonGroupAlign } from '../input-radio-button-group/InputRadioButtonGroup';
import { Notification } from '../notification/Notification';
import { NotificationLevel } from '../notification/Notification.enums';

import './CardContentCheckoutPayment.scss';

export type CardContentCheckoutPaymentProps = {
    header: string;
    notificationMessage: string;
};

export const CardContentCheckoutPayment = (props: CardContentCheckoutPaymentProps) => {
    const { header, notificationMessage } = props;

    const { t } = useTranslation('uiPrimitive');

    return (
        <article className="card-content-checkout-payment">
            <article className="card-content-checkout-payment__header">
                {header}
                <span className="required-field"> *</span>
            </article>
            <article className="card-content-checkout-payment__button-group">
                <InputRadioButtonGroup align={InputRadioButtonGroupAlign.ALIGN_HORIZONTAL}>
                    <article className="card-content-checkout-payment__button-group__group">
                        <InputRadioButton
                            value={t('checkout.payment.optionCreditLabel', 'Credit')}
                            id="radio-button-credit"
                            name="radio-button-group-payment"
                            checked
                        />
                        <div className="card-content-checkout-payment__button-group__group__helper-text-credit">
                            {t('checkout.payment.optionCreditExplanation')}
                        </div>
                    </article>
                    <article className="card-content-checkout-payment__button-group__upfront-group">
                        <InputRadioButton
                            value={t('checkout.payment.optionUpfrontLabel', 'Upfront payment')}
                            id="radio-button-credit-upfront-payment"
                            name="radio-button-group-payment"
                            disabled
                        />
                        <div className="card-content-checkout-payment__button-group__group__helper-text-upfront">
                            {t('checkout.payment.optionUpfrontExplanation')}
                        </div>
                    </article>
                </InputRadioButtonGroup>
            </article>
            <Notification level={NotificationLevel.WARNING} message={notificationMessage} />
        </article>
    );
};
