import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterCriteria, MultiValueFilterComparator } from '@steelbuy/api-integration';
import { FetchStatus, useListingSellerCollectionData } from '@steelbuy/data-provider';
import { ListingSellerModel, ListingStatus, isArray } from '@steelbuy/ts-shared';

import { Dropdown, SelectOption } from '@steelbuy/ui-primitive';
import { useMyPackagesContext } from '../my-packages/MyPackagesContext';

export const FILTER_CRITERIA_ID = 'filter';
export const FILTER_PROPERTY = 'status.value';

// We do not want to show drafts in the my listings overview at the moment.
// Removing the drafts in the backend would be a pretty big change and it's
// not completely clear whether we might not need the drafts in the future.
// So we don't show "Drafts" as an option in the filter and make sure that
// by default we filter for every status expect drafts.
export const MyListingsFilter = ({ isPackage = false }: { isPackage?: boolean }) => {
    const { t } = useTranslation('translation');
    const listingSellerCollectionData = useListingSellerCollectionData();
    const { packageFilter, setPackageFilter } = useMyPackagesContext();
    const fetchStatus = listingSellerCollectionData.queryFetchStatus();

    const filterOptions: SelectOption[] = Object.values(ListingStatus)
        .filter((status) => status !== ListingStatus.DRAFT)
        .map((value) => ({
            label: t(`application.myListingsFilter.optionLabels.${value}`),
            value,
        }));
    const effectiveSortCriteria = listingSellerCollectionData.querySortCriteria();
    const statusFilter = listingSellerCollectionData
        .queryFilterCriteria()
        ?.find((filterCriteria) => filterCriteria.id === FILTER_CRITERIA_ID)
        ?.criteria.find((criteriaRule) => criteriaRule.property === FILTER_PROPERTY)?.value;

    const [effectiveStatusFilter, setEffectiveStatusFilter] = useState(
        isArray(statusFilter) && statusFilter.length === filterOptions.length ? [] : statusFilter
    );

    const handleFilterChange = (value: string[]) => {
        if (isPackage) {
            setPackageFilter(value as ListingStatus[]);
        } else {
            if (fetchStatus === FetchStatus.PENDING) {
                return;
            }

            let filterValue = value;

            if (value.length === 0) {
                filterValue = filterOptions.map((option) => option.value);
            }

            const filter: FilterCriteria<ListingSellerModel> = [
                {
                    id: FILTER_CRITERIA_ID,
                    criteria: [
                        {
                            property: FILTER_PROPERTY,
                            value: filterValue,
                            comparator: MultiValueFilterComparator.ONE_OF,
                        },
                    ],
                },
            ];

            listingSellerCollectionData.fetch(effectiveSortCriteria ?? undefined, filter);

            setEffectiveStatusFilter(value);
        }
    };

    const selectedFilterOptionKeys = isArray(effectiveStatusFilter)
        ? effectiveStatusFilter.map((value) => value as ListingStatus)
        : [];

    return (
        <Dropdown
            multiselect
            title={t('application.myListingsFilter.selectLabel')}
            options={filterOptions}
            preselection={isPackage ? packageFilter : selectedFilterOptionKeys}
            onChange={handleFilterChange}
            placeholder={t('application.myListingsFilter.selectPlaceholder')}
        />
    );
};
