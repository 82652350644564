/* eslint-disable no-param-reassign */
import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { CollectionResponse, FilterCriteria, PreparedSortCriteria, SortCriteria } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { AnyErrorObject } from '@steelbuy/error';
import { Mutable, Mutation, ViewModel, ModelPrimaryKey, Nullable, ReadonlyOptional } from '@steelbuy/ts-shared';

import { CollectionStore } from './CollectionStore';
import { ThunkApiConfig } from './Thunks';
import { ActionStatus } from '../ActionStatus';
import { FetchStatus } from '../FetchStatus';
import { ModelFilter } from '../ModelFilter';
import { ModelSort } from '../ModelSort';

// Initial store
export const createInitialCollectionStore = <Model extends ViewModel>() =>
    ({
        models: [] as Array<Model>,
        fetchStatus: FetchStatus.IDLE,
        lastFetchError: null,
        currentPage: null,
        maxPages: null,
        totalItems: null,
        sortCriteria: null,
        filterCriteria: null,
        actionStatus: ActionStatus.IDLE,
        lastActionError: null,
        createdEntity: null,
    } as CollectionStore<Model>);

// Slice creator
export const createCollectionSlice = <Model extends ViewModel>(
    storeName: string,
    fetchAll: AsyncThunk<
        CollectionResponse<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            sortCriteria?: SortCriteria<Model> | PreparedSortCriteria;
            filterCriteria?: FilterCriteria<Model>;
        },
        ThunkApiConfig
    >,
    fetchNext: AsyncThunk<
        CollectionResponse<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
        },
        ThunkApiConfig
    >,
    fetchOne: AsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
        },
        ThunkApiConfig
    >,
    mutateEntity: AsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
            modelMutation: Mutation<Model>;
        },
        ThunkApiConfig
    >,
    createEntity: AsyncThunk<
        Model,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            mutableModel: Mutable<Model>;
        },
        ThunkApiConfig
    >,
    deleteEntity: AsyncThunk<
        ModelPrimaryKey,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
        },
        ThunkApiConfig
    >,
    entityService: AsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
            modelMutation?: Mutation<Model>;
            service: string;
        },
        ThunkApiConfig
    >
) =>
    createSlice({
        name: storeName,
        initialState: createInitialCollectionStore<Model>(),
        // Regular synchronous reducers
        reducers: {
            resetStore(store) {
                Object.assign(store, createInitialCollectionStore<Model>());
            },
            resetActionStatus(store) {
                store.actionStatus = ActionStatus.IDLE;
            },
            resetFetchStatus(store) {
                store.fetchStatus = FetchStatus.IDLE;
            },
        },
        // Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
        // Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
        // handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
        // https://github.com/immerjs/immer.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        extraReducers: (builder) => {
            builder.addCase(String(fetchAll.pending), (state) => {
                state.fetchStatus = FetchStatus.PENDING;
            });
            builder.addCase(
                String(fetchAll.fulfilled),
                (state, action: PayloadAction<Draft<CollectionResponse<Model>>>) => {
                    state.models = action.payload.items;
                    state.currentPage = action.payload.currentPage;
                    state.maxPages = action.payload.maxPages;
                    state.totalItems = action.payload.totalItems;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    state.sortCriteria = action.meta.arg.sortCriteria ?? null;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    state.filterCriteria = action.meta.arg.filterCriteria ?? null;
                    state.fetchStatus = FetchStatus.SUCCESS;
                }
            );
            builder.addCase(
                String(fetchAll.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastFetchError = action.payload ?? action.error;
                    state.fetchStatus = FetchStatus.FAILED;
                }
            );
            builder.addCase(String(fetchNext.pending), (state) => {
                state.fetchStatus = FetchStatus.PAGING_PENDING;
            });
            builder.addCase(
                String(fetchNext.fulfilled),
                (state, action: PayloadAction<Draft<CollectionResponse<Model>>>) => {
                    state.models = [...state.models, ...action.payload.items];
                    state.currentPage = action.payload.currentPage;
                    state.maxPages = action.payload.maxPages;
                    state.totalItems = action.payload.totalItems;
                    state.fetchStatus = FetchStatus.SUCCESS;
                }
            );
            builder.addCase(
                String(fetchNext.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastFetchError = action.payload ?? action.error;
                    state.fetchStatus = FetchStatus.FAILED;
                }
            );
            builder.addCase(String(fetchOne.pending), (state) => {
                state.fetchStatus = FetchStatus.PENDING;
            });
            builder.addCase(String(fetchOne.fulfilled), (state, action: PayloadAction<Nullable<Draft<Model>>>) => {
                if (action.payload !== null) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    const index = state.models?.findIndex((entry) => entry.id === action.payload?.id);
                    if (index >= 0) {
                        state.models[index] = action.payload;
                    } else {
                        state.models.push(action.payload);
                    }
                }
                state.fetchStatus = FetchStatus.SUCCESS;
            });
            builder.addCase(
                String(fetchOne.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastFetchError = action.payload ?? action.error;
                    state.fetchStatus = FetchStatus.FAILED;
                }
            );
            builder.addCase(String(mutateEntity.pending), (state) => {
                state.actionStatus = ActionStatus.MUTATE_PENDING;
            });
            builder.addCase(String(mutateEntity.fulfilled), (state, action: PayloadAction<Nullable<Draft<Model>>>) => {
                if (action.payload !== null) {
                    const index = state.models.findIndex((entry): boolean => entry.id === action.payload?.id);
                    if (index >= 0) {
                        state.models[index] = action.payload;
                    }
                }
                state.actionStatus = ActionStatus.MUTATE_SUCCESS;
            });
            builder.addCase(
                String(mutateEntity.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastActionError = action.payload ?? action.error;
                    state.actionStatus = ActionStatus.FAILED;
                }
            );
            builder.addCase(String(entityService.pending), (state) => {
                state.actionStatus = ActionStatus.SERVICE_PENDING;
            });
            builder.addCase(String(entityService.fulfilled), (state, action: PayloadAction<Nullable<Draft<Model>>>) => {
                if (action.payload !== null) {
                    const index = state.models.findIndex((entry): boolean => entry.id === action.payload?.id);
                    if (index >= 0) {
                        state.models[index] = action.payload;
                    }
                }
                state.actionStatus = ActionStatus.SERVICE_SUCCESS;
            });
            builder.addCase(
                String(entityService.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastActionError = action.payload ?? action.error;
                    state.actionStatus = ActionStatus.FAILED;
                }
            );
            builder.addCase(String(createEntity.pending), (state) => {
                state.createdEntity = null;
                state.actionStatus = ActionStatus.CREATE_PENDING;
            });
            builder.addCase(String(createEntity.fulfilled), (state, action: PayloadAction<Draft<Model>>) => {
                state.models.push(action.payload);
                state.createdEntity = action.payload;
                state.actionStatus = ActionStatus.CREATE_SUCCESS;
            });
            builder.addCase(
                String(createEntity.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastActionError = action.payload ?? action.error;
                    state.actionStatus = ActionStatus.FAILED;
                }
            );
            builder.addCase(String(deleteEntity.pending), (state) => {
                state.actionStatus = ActionStatus.DELETE_PENDING;
            });
            builder.addCase(String(deleteEntity.fulfilled), (state, action: PayloadAction<Draft<ModelPrimaryKey>>) => {
                if (action.payload !== undefined) {
                    const index = state.models.findIndex((entry): boolean => entry.id === action.payload);
                    if (index >= 0) {
                        state.models.splice(index, 1);
                        if (state.totalItems) {
                            state.totalItems -= 1;
                        }
                    }
                }
                state.actionStatus = ActionStatus.DELETE_SUCCESS;
            });
            builder.addCase(
                String(deleteEntity.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastActionError = action.payload ?? action.error;
                    state.actionStatus = ActionStatus.FAILED;
                }
            );
        },
    });

// Selector creators
export const createSelectCollection =
    <Model extends ViewModel, Store extends Record<string, CollectionStore<Model>>>(storeName: string) =>
    (filter?: ModelFilter<Model>, sort?: ModelSort<Model>): ((rootStore: Store) => ReadonlyArray<Model>) =>
    (rootStore): ReadonlyArray<Model> => {
        let modelCollection = rootStore[storeName].models;
        if ((filter ?? null) !== null) {
            modelCollection = modelCollection.filter(filter as ModelFilter<Model>);
        }
        if ((sort ?? null) !== null) {
            modelCollection = [...modelCollection].sort(sort);
        }
        return modelCollection;
    };

export const createSelectCollectionEntity =
    <Model extends ViewModel, Store extends Record<string, CollectionStore<Model>>>(storeName: string) =>
    (id: ModelPrimaryKey): ((rootStore: Store) => ReadonlyOptional<Model>) =>
    (rootStore): ReadonlyOptional<Model> => {
        const modelEntity = rootStore[storeName].models.find((model): boolean => model.id === id);
        return new ReadonlyOptional(modelEntity);
    };

export const createSelectCreated =
    <Model extends ViewModel, Store extends Record<string, CollectionStore<Model>>>(storeName: string) =>
    (): ((rootStore: Store) => ReadonlyOptional<Model>) =>
    (rootStore): ReadonlyOptional<Model> =>
        new ReadonlyOptional(rootStore[storeName]?.createdEntity);
