import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlag } from '@steelbuy/data-access';
import { useListingBuyerSearchData } from '@steelbuy/data-provider';
import { Feature } from '@steelbuy/ts-shared';
import { SearchSummary } from '@steelbuy/ui-domain';
import { ButtonGhostSystemOnLightM, CardClear, IconIdentifier, SearchSummaryActions } from '@steelbuy/ui-primitive';
import { SearchSummaryAlertButton } from './SearchSummaryAlertButton';
import { RoutePath } from '../../router/Routes';
import { useSearchFormDataContext } from '../listing-search/SearchFormDataContext';

type ListingSearchSummaryProps = {
    isPackage?: boolean;
};

export const ListingSearchSummary = ({ isPackage }: ListingSearchSummaryProps) => {
    const listingSearchData = useListingBuyerSearchData();
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const searchFormData = useSearchFormDataContext().getSearchFormData();
    const { isFeatureSupported } = useFeatureFlag();
    return (
        <CardClear isClickable={false}>
            <SearchSummary
                searchFormData={searchFormData}
                showPrice={isFeatureSupported(Feature.IMR_TARGET_PRICE)}
                isPackage={isPackage}
            >
                <SearchSummaryActions>
                    <ButtonGhostSystemOnLightM
                        title={t('application.listingSearchResults.editSearchButtonTitle')}
                        icon={IconIdentifier.EDIT}
                        onClick={() => {
                            listingSearchData.resolveFetchStatus();
                            navigate(RoutePath.SEARCH_LISTINGS);
                        }}
                    />
                    {!isPackage && <SearchSummaryAlertButton searchFormData={searchFormData} />}
                </SearchSummaryActions>
            </SearchSummary>
        </CardClear>
    );
};
