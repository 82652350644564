import { SortDirection } from '@steelbuy/api-integration';
import {
    OrderBuyerModelConverter,
    OrderBuyerModel,
    CartOrderBuyerModel,
    CartOrderBuyerModelConverter,
    ApiModel,
} from '@steelbuy/ts-shared';
import cacheConfig from '../../utils/cacheOptions';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

export const MY_PURCHASE_ORDER = 'purchase-orders';

const modelConverter = new OrderBuyerModelConverter();

const cartModelConverter = new CartOrderBuyerModelConverter();

export const isCartOrder = (data: OrderBuyerModel | CartOrderBuyerModel): data is CartOrderBuyerModel =>
    (data as CartOrderBuyerModel).steelBuyReferenceNumber !== undefined;

const searchOrdersMapFn = (data: OrderBuyerModel | CartOrderBuyerModel) =>
    isCartOrder(data)
        ? cartModelConverter.toViewModel(data as unknown as ApiModel<CartOrderBuyerModel>)
        : modelConverter.toViewModel(data as unknown as ApiModel<OrderBuyerModel>);

export const useMyPurchaseOrders = ({ filterCriteria }: { filterCriteria?: Record<string, string> }) =>
    usePaginatedQuery<OrderBuyerModel | CartOrderBuyerModel>({
        url: '/api/universal/purchase-orders',
        requestOptions: {
            filter: filterCriteria,
            sort: [
                {
                    property: 'createdAt',
                    direction: SortDirection.DESCENDING,
                },
            ],
        },
        mapFn: searchOrdersMapFn,
        queryKey: [MY_PURCHASE_ORDER, filterCriteria],
        queryOptions: {
            staleTime: cacheConfig['5_MINUTES'],
        },
    });
