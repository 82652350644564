import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

export interface MyOrdersContextValue {
    filter: string[];
    setFilter: (filter: string[]) => void;
    searchValue: string;
    setSearchValue: (SearchValue: string) => void;
    reset: () => void;
    scrollPosition: number;
    setScrollPosition: (position: number) => void;
    cartOrderScrollPosition: number;
    setCartOrderScrollPosition: (position: number) => void;
}

const defaultValue: MyOrdersContextValue = {
    scrollPosition: 0,
    cartOrderScrollPosition: 0,
    setScrollPosition: () => null,
    setCartOrderScrollPosition: () => null,
    filter: [],
    searchValue: '',
    setSearchValue: () => null,
    setFilter: () => null,
    reset: () => null,
};

const MyOrdersContext = createContext(defaultValue);

export const MyOrdersContextProvider = ({ children }: PropsWithChildren) => {
    const [filter, setFilter] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [scrollPosition, setScrollPosition] = useState(defaultValue.scrollPosition);
    const [cartOrderScrollPosition, setCartOrderScrollPosition] = useState(defaultValue.scrollPosition);

    const reset = useCallback(() => {
        setFilter([]);
        setSearchValue('');
    }, [setFilter, setSearchValue]);

    const value = useMemo(
        () => ({
            filter,
            setFilter,
            searchValue,
            setSearchValue,
            reset,
            scrollPosition,
            setScrollPosition,
            cartOrderScrollPosition,
            setCartOrderScrollPosition,
        }),
        [
            filter,
            setFilter,
            searchValue,
            setSearchValue,
            reset,
            scrollPosition,
            setScrollPosition,
            cartOrderScrollPosition,
            setCartOrderScrollPosition,
        ]
    );

    return <MyOrdersContext.Provider value={value}>{children}</MyOrdersContext.Provider>;
};

export const useMyOrdersContext = () => useContext(MyOrdersContext);
