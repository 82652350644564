import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserSelfDetails } from '@steelbuy/data-access';
import { ActionStatus, useListingBuyerAlertCollectionData } from '@steelbuy/data-provider';
import { LoadingSpinner } from '@steelbuy/ui-primitive';
import { MyAlertsSettingsForm } from './MyAlertsSettingsForm';
import { RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { PageHeader } from '../page-header/PageHeader';

import './MyAlertsSettings.scss';

interface MyAlertsSettingsProps {
    alertId: string;
}

export const MyAlertsSettings = (props: MyAlertsSettingsProps) => {
    const { alertId } = props;
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const listingAlertCollectionData = useListingBuyerAlertCollectionData();
    const { data: userModel } = useUserSelfDetails();
    const listingAlert = listingAlertCollectionData.queryOne(alertId).get();

    listingAlertCollectionData.useActionStatusEffect(
        [ActionStatus.DELETE_SUCCESS],
        () => {
            navigate(RoutePath.MY_ALERTS);
        },
        false
    );

    listingAlertCollectionData.useActionStatusEffect(
        [ActionStatus.IDLE],
        () => {
            if (!listingAlert) {
                listingAlertCollectionData.fetchOne(alertId);
            }
        },
        true
    );

    if (!listingAlert || !userModel) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <PageHeader
                pageTitle={t('application.myAlertsSettings.header')}
                previousPageTitle={t('application.myAlertsSettings.headerBackLinkLabel')}
                onBackClick={() => navigate(RoutePath.MY_ALERTS)}
            />
            <div className="my-alerts-settings">
                <FormLayout>{listingAlert && <MyAlertsSettingsForm listingAlert={listingAlert} />}</FormLayout>
            </div>
        </>
    );
};
