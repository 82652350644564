/* eslint-disable react/destructuring-assignment */
import { useEffect } from 'react';
import { useAuth } from '@steelbuy/auth';

import { isAuthenticationRequiredError, restoreError } from '@steelbuy/error';
import { AddressModel } from '@steelbuy/ts-shared';
import { LoadingSpinner } from '@steelbuy/ui-primitive';

import { HistoricalAddressEntityData } from './HistoricalAddressEntityData';
import {
    HistoricalAddressEntityStore,
    historicalAddressEntityStoreAccessors,
    historicalAddressEntityStoreName,
} from './HistoricalAddressEntitySlice';
import { createDataProviderAccessTokenLoader } from '../../util/auth/AccessTokenLoader';
import { EntityDataProviderProps } from '../../util/provider/DataProviderProps';
import { createEntityDataProviderValue } from '../../util/provider/EntityDataProviderValue';
import { FetchStatus } from '../../util/store/FetchStatus';
import { getBaseUrl } from '../../util/urlUtils';

export const HistoricalAddressEntityDataProvider = (props: EntityDataProviderProps) => {
    const { lazyLoad = false, entityId, interceptRendering = !lazyLoad, pendingComponent = null } = props;

    const authConsumer = useAuth();

    const PendingComponent = pendingComponent;

    const dataProviderValue = createEntityDataProviderValue<AddressModel, HistoricalAddressEntityStore>(
        historicalAddressEntityStoreName,
        historicalAddressEntityStoreAccessors,
        entityId,
        getBaseUrl(),
        createDataProviderAccessTokenLoader(authConsumer, getBaseUrl())
    );

    const dataProviderIdle = dataProviderValue.idle();
    const fetchStatus = dataProviderValue.queryFetchStatus();
    const error = dataProviderValue.queryFetchError();

    dataProviderValue.useFetchStatusEffect([FetchStatus.FAILED], () => {
        if (error === null) {
            return;
        }
        if (isAuthenticationRequiredError(error)) {
            authConsumer.reauthenticate();
            return;
        }
        throw restoreError(error);
    });

    useEffect(() => {
        if (dataProviderIdle && !lazyLoad) {
            dataProviderValue.fetch();
        }
    }, [dataProviderIdle, lazyLoad]);

    if (interceptRendering) {
        switch (fetchStatus) {
            case FetchStatus.IDLE:
                return null;
            case FetchStatus.PENDING:
                if (PendingComponent !== null) {
                    return <PendingComponent />;
                }
                return <LoadingSpinner />;
            case FetchStatus.FAILED:
                return null;
        }
    }

    return (
        <HistoricalAddressEntityData.Provider value={dataProviderValue}>
            {props.children}
        </HistoricalAddressEntityData.Provider>
    );
};
