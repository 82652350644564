import { Trans, useTranslation } from 'react-i18next';
import { CurrencyCode, formatCurrency, CartEntryModel, getPricingUnit, isListingCartEntry } from '@steelbuy/ts-shared';
import { useMaterialProperties } from '@steelbuy/ui-domain';
import './ItemsRenewedList.scss';

interface ItemsRenewedListProps {
    cartEntries: CartEntryModel[];
}

const ListingRenewed = ({ entry }: { entry: CartEntryModel }) => {
    const { t } = useTranslation(['translation', 'domainModel']);
    const isListing = isListingCartEntry(entry);

    const listingProperties = useMaterialProperties(isListing ? entry.product.material : entry.product, [
        'materialType',
    ]);
    const property = listingProperties.map((item) => item.label).join(', ');
    const oldPrice = formatCurrency(
        navigator.language,
        entry.product.oldPricing?.priceIncludingDelivery.value ?? 0,
        entry.product.oldPricing?.currencyCode ?? CurrencyCode.GBP
    );
    const newPrice = formatCurrency(
        navigator.language,
        entry.product.pricing.priceIncludingDelivery.value,
        entry.product.pricing.currencyCode
    );
    const pricingUnit = getPricingUnit(isListing ? entry.product.material : { materialType: entry.product.material });

    const pricingUnitLabel = t('domainModel:material.perPricingUnit', {
        pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
    });

    return (
        <span className="items-renewed-notification__list-item">
            <Trans
                i18nKey="application.myCart.itemsRenewed"
                components={{
                    property: <span className="items-renewed-notification__list-item__property" />,
                }}
                t={t}
                values={{ property, oldPrice, newPrice, pricingUnit: pricingUnitLabel }}
                shouldUnescape
            />
        </span>
    );
};

export const ItemsRenewedList = ({ cartEntries }: ItemsRenewedListProps) => {
    const { t } = useTranslation('translation');

    return (
        <div className="items-renewed-notification">
            <span className="items-renewed-notification__message">
                {t('application.myCart.itemsRenewedMessage', { count: cartEntries.length })}
            </span>
            <div className="items-renewed-notification__list">
                {cartEntries.map((entry) => (
                    <ListingRenewed entry={entry} key={entry.id} />
                ))}
            </div>
        </div>
    );
};
