import { useMutation } from '@tanstack/react-query';
import { ListingMaterialOrigin, QuoteEntry, RFQBuyerModel } from '@steelbuy/ts-shared';
import axios from '../axios';

export type CreateRFQModel = {
    expiresAt: string;
    origin: ListingMaterialOrigin[];
    additionalInformation?: string;
    delivery: { month: number; year: number };
    quoteEntry: QuoteEntry[];
    draftId?: string;
};

const createRFQ = async (rfq: CreateRFQModel) => {
    const { data } = await axios.post<RFQBuyerModel>('/api/buyer/quotes', rfq);
    return data;
};

export const useCreateRFQ = () =>
    useMutation({
        mutationFn: (rfq: CreateRFQModel) => createRFQ(rfq),
    });
