import { useMutation } from '@tanstack/react-query';
import { ListingSellerModel, ListingSellerModelConverter } from '@steelbuy/ts-shared';
import axios from '../../axios';

const createListing = async (createListingRequest: ListingSellerModel) => {
    const modelConverter = new ListingSellerModelConverter();
    const { data } = await axios.post<ListingSellerModel>(
        '/api/seller/listings',
        modelConverter.toApiModel(createListingRequest)
    );
    return data;
};

export const useCreateListing = () =>
    useMutation({
        mutationFn: (createListingRequest: ListingSellerModel) => createListing(createListingRequest),
    });
