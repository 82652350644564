import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetUrgentRequests, useFeatureFlag, useUserSelfDetails, userHasRole } from '@steelbuy/data-access';
import { Feature, UserRole } from '@steelbuy/ts-shared';

import { SearchSummarySkeleton } from '@steelbuy/ui-domain';
import {
    ButtonTertiaryOnLightM,
    FormActionbar,
    MarketingBannerDashboardNavigation,
    MarketingBannerDashboardSell,
    Skeleton,
    SkeletonType,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { PageHeader } from '../page-header/PageHeader';
import { UrgentRequestsList } from '../urgent-requests/UrgentRequestsList';

import './Dashboard.scss';

const URGENT_REQUESTS_MAX_RESULTS = 2;

export const Dashboard = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const { data: userModel } = useUserSelfDetails();
    const hasUrgentRequestAccess = userHasRole(userModel || null, [UserRole.SELLER, UserRole.MANAGER]);

    const { data: urgentRequests, isLoading: isUrgentRequestsLoading } = useGetUrgentRequests(
        userModel?.roles,
        URGENT_REQUESTS_MAX_RESULTS,
        {
            enabled: hasUrgentRequestAccess,
        }
    );

    const hasBuyerRole = userHasRole(userModel || null, [UserRole.BUYER]);
    const { isFeatureSupported } = useFeatureFlag();

    const hasRfqAccess = isFeatureSupported(Feature.RFQ) && hasBuyerRole;

    const hasRequestMaterialAccess = hasBuyerRole || userHasRole(userModel || null, [UserRole.MANAGER]);

    return (
        <>
            <PageHeader pageTitle={t('application.dashboard.headerTitle', { name: userModel?.name ?? '' })} />
            <div className="dashboard">
                <DetailsLayout>
                    {hasUrgentRequestAccess &&
                        (isUrgentRequestsLoading ? (
                            <div className="urgent-requests-skeleton">
                                <Skeleton type={SkeletonType.TITLE} />
                                <Skeleton type={SkeletonType.HELPER_TEXT} />
                                <SearchSummarySkeleton count={2} />
                            </div>
                        ) : (
                            <article className="dashboard__urgent-requests">
                                <div className="dashboard__urgent-requests__header">
                                    {t('application.dashboard.urgentRequests.headerTitle')}
                                </div>
                                <div className="dashboard__urgent-requests__help-text">
                                    {t('application.dashboard.urgentRequests.helperText')}
                                </div>
                                {urgentRequests && urgentRequests?.length > 0 ? (
                                    <>
                                        <UrgentRequestsList
                                            urgentRequests={urgentRequests}
                                            showSeperators={false}
                                            showSymbols={false}
                                            origin={RoutePath.DASHBOARD}
                                        />
                                        <FormActionbar>
                                            <ButtonTertiaryOnLightM
                                                label={t('application.dashboard.urgentRequests.viewAll')}
                                                onClick={() => navigate(RoutePath.URGENT_REQUESTS)}
                                            />
                                        </FormActionbar>
                                    </>
                                ) : (
                                    <div className="dashboard__urgent-requests__no-urgent-requests">
                                        {t('application.dashboard.urgentRequests.noUrgentRequests')}
                                    </div>
                                )}
                            </article>
                        ))}

                    {hasRfqAccess && (
                        <MarketingBannerDashboardNavigation
                            header={t('application.dashboardRfq.bannerNewAtSteelBuy.header')}
                            text={t('application.dashboardRfq.bannerNewAtSteelBuy.body')}
                            buttonLabel={t('application.dashboardRfq.bannerNewAtSteelBuy.buttonLabel')}
                            onClick={() => {
                                navigate(RoutePath.CREATE_RFQ_WIZARD);
                            }}
                            imageSrc="assets/lib-ui-primitive/images/DashboardRFQ.svg"
                            reverse
                        />
                    )}

                    {hasRequestMaterialAccess && (
                        <MarketingBannerDashboardNavigation
                            header={t('application.dashboard.bannerRequest.header')}
                            text={t('application.dashboard.bannerRequest.body')}
                            buttonLabel={t('application.dashboard.bannerRequest.buttonLabel')}
                            onClick={() => {
                                navigate(RoutePath.IMMEDIATE_REQUEST);
                            }}
                            imageSrc="/assets/lib-ui-primitive/images/DashboardRequestMaterial_2.svg"
                        />
                    )}
                    <MarketingBannerDashboardNavigation
                        header={t('application.dashboard.bannerSearch.header')}
                        text={t('application.dashboard.bannerSearch.body')}
                        buttonLabel={t('application.dashboard.bannerSearch.buttonLabel')}
                        onClick={() => {
                            navigate(RoutePath.SEARCH_LISTINGS);
                        }}
                        imageSrc="/assets/lib-ui-primitive/images/DashboardSearchNew.svg"
                    />
                    <MarketingBannerDashboardSell
                        header={t('application.dashboard.bannerSell.header')}
                        text={t('application.dashboard.bannerSell.body')}
                        buttonLabel1={t('application.dashboard.bannerSell.buttonLabel1')}
                        onClick1={() => {
                            navigate(RoutePath.CREATE_LISTING_WIZARD);
                        }}
                        buttonLabel2={t('application.dashboard.bannerSell.buttonLabel2')}
                        onClick2={() => {
                            navigate(RoutePath.MY_LISTINGS);
                        }}
                    />
                </DetailsLayout>
            </div>
        </>
    );
};
