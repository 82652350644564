import { useMutation } from '@tanstack/react-query';
import { RFQDraftModel, RFQDraftModelConverter } from '@steelbuy/ts-shared';
import axios from '../axios';

type UpdateRFQDraftModel = Omit<RFQDraftModel, 'status' | 'missingFieldsCount'>;

const updateRFQDraft = async (rfq: UpdateRFQDraftModel) => {
    const modelConverter = new RFQDraftModelConverter();
    const { data } = await axios.put<RFQDraftModel>(
        `/api/buyer/quotes/draft/${rfq.id}`,
        modelConverter.toApiModel(rfq)
    );
    return data;
};

export const useUpdateRFQDraft = () =>
    useMutation({
        mutationFn: (rfq: UpdateRFQDraftModel) => updateRFQDraft(rfq),
    });
