import { useTranslation } from 'react-i18next';
import { Tolerance, Nullable } from '@steelbuy/ts-shared';
import { Card, CardContentAttributes, CardContentAttributesItem, CardLayout } from '@steelbuy/ui-primitive';

interface ListingDetailsDimensionsProps {
    thickness: Nullable<string | number>;
    tolerance: Nullable<Tolerance>;
    width: Nullable<string | number>;
    length?: Nullable<string | number>;
}

export const ListingDetailsDimensions = ({ length, thickness, tolerance, width }: ListingDetailsDimensionsProps) => {
    const { t } = useTranslation('domainModel');

    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentAttributes header={t('material.dimensions.name')}>
                    <CardContentAttributesItem
                        label={t('material.dimensions.thickness')}
                        value={
                            thickness
                                ? t('material.dimensions.units.millimetersWithValue', { value: `${thickness} ` })
                                : '-'
                        }
                    />
                    <CardContentAttributesItem
                        label={t('material.dimensions.width')}
                        value={
                            width ? t('material.dimensions.units.millimetersWithValue', { value: `${width} ` }) : '-'
                        }
                    />
                    {length !== undefined && (
                        <CardContentAttributesItem
                            label={t('material.dimensions.length')}
                            value={
                                length
                                    ? t('material.dimensions.units.millimetersWithValue', { value: `${length} ` })
                                    : '-'
                            }
                        />
                    )}
                    <CardContentAttributesItem
                        label={t('material.tolerance.name')}
                        value={tolerance ? t(`material.tolerance.value.${tolerance}`) : '-'}
                    />
                </CardContentAttributes>
            </CardLayout>
        </Card>
    );
};
