import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { PARAM_DELIMITER, SingleValueFilterComparator, MULTI_VALUE_DELIMITER } from '@steelbuy/api-integration';
import {
    CurrencyCode,
    formatCurrency,
    Coating,
    CoatingColourType,
    CoatingCoverage,
    CoatingThicknessType,
    CoatingType,
    Definition,
    Finish,
    Grade,
    MaterialType,
    MillFinish,
    Polish,
    Product,
    ProductType,
    SearchFormData,
    Shape,
    Specification,
    Surface,
    Temper,
    getPricingUnit,
    getTradeUnit,
} from '@steelbuy/ts-shared';
import { SearchSummaryProperties, SearchSummaryHeader } from '@steelbuy/ui-primitive';

import { useMaterialProperties } from '../material/Material';

import './SearchSummary.scss';

export type SearchSummaryProps = {
    searchFormData: SearchFormData;
    children: ReactNode;
    showSymbols?: boolean;
    comment?: string;
    showPrice?: boolean;
    isPackage?: boolean;
};

export const mapFromFilterString = (filterString: string) =>
    filterString
        .split(PARAM_DELIMITER)
        .map((param) => {
            // this only supports the equal comparator "=".
            const parts = param.split(SingleValueFilterComparator.EQUAL);

            if (parts.length !== 2) {
                throw new Error(`Could not parse filter param: ${param}`);
            }

            const key = parts[0];
            const value = parts[1];

            if (value.includes(MULTI_VALUE_DELIMITER)) {
                return { key, value: value.split(MULTI_VALUE_DELIMITER) };
            }
            return { key, value };
        })
        .reduce((prev, cur) => prev.set(cur.key, cur.value), new Map<string, string | string[]>());

export const toSearchFormData: (searchCriteriaString: string) => SearchFormData = (searchCriteriaString) => {
    const params = mapFromFilterString(searchCriteriaString);
    return {
        materialType: params.get('materialType') as MaterialType,
        shape: params.get('shape') as Shape,
        product: params.get('product') as Product,
        definition: params.get('definition') as Definition,
        plateType: params.get('plateType') as ProductType,
        grade: params.get('grade') as Grade,
        temper: params.get('temper') as Temper,
        specification: params.get('specification') as Specification,
        minWidth: params.get('minWidth') as string,
        maxWidth: params.get('maxWidth') as string,
        minLength: params.get('minLength') as string,
        maxLength: params.get('maxLength') as string,
        surface: params.get('surface') as Surface,
        width: params.get('width') as string,
        thickness: params.get('thickness') as string,
        length: params.get('length') as string,
        finish: params.get('finish') as Finish,
        coating: params.get('coating') as Coating,
        coatingThicknessValue: params.get('coatingThicknessValue') as CoatingThicknessType,
        coatingType: params.get('coatingType') as CoatingType,
        coatingColour: params.get('coatingColour') as CoatingColourType,
        coatingCoverage: params.get('coatingCoverage') as CoatingCoverage,
        millFinish: params.get('millFinish') as MillFinish,
        polish: params.get('polish') as Polish,
        weight: params.get('weight') as string,
        targetPrice: params.get('targetPrice') as string,
    };
};

export const SearchSummary = ({
    children,
    comment = '',
    searchFormData,
    showPrice,
    showSymbols = true,
    isPackage,
}: SearchSummaryProps) => {
    const materialProperties = useMaterialProperties(searchFormData);

    const { t } = useTranslation('domainModel');

    const product = searchFormData.product ? t(`material.product.value.${searchFormData.product}`) : '';

    const materialType = searchFormData.materialType
        ? t(`material.materialType.value.${searchFormData.materialType}`)
        : '';

    const lengthRange = [searchFormData.minLength, searchFormData.maxLength];
    const widthRange = [searchFormData.minWidth, searchFormData.maxWidth];
    const millimeterUnit = t('material.dimensions.units.millimeters');
    const dimensions = [searchFormData.thickness, searchFormData.width, widthRange, searchFormData.length, lengthRange]
        .filter((dimension) => dimension && dimension.toString() !== ',') // ['',''] in case we have no range inputs, dimension.ToString is ","
        .map((dimension) => {
            if (dimension && (dimension === widthRange || dimension === lengthRange)) {
                return `${dimension[0]}${millimeterUnit}`.concat('\u00A0-\u00A0', `${dimension[1]}${millimeterUnit}`);
            }
            return `${dimension}${millimeterUnit}`;
        })
        .join(' x ');

    const weightText = searchFormData.weight
        ? t(
              `material.tradeUnit.value.${getTradeUnit({
                  materialType: searchFormData.materialType,
              })}.quantity`,
              {
                  count: Number(searchFormData.weight),
              }
          )
        : undefined;

    const priceText =
        searchFormData.targetPrice && showPrice
            ? `${formatCurrency(navigator.language, parseFloat(searchFormData.targetPrice), CurrencyCode.GBP)} ${t(
                  `material.perPricingUnit`,
                  {
                      pricingUnit: t(`material.pricingUnit.value.${getPricingUnit(searchFormData)}.label`),
                  }
              )}`
            : '';

    const weightAndPrice = [] as string[];
    if (weightText) {
        weightAndPrice.push(weightText);
    }
    if (priceText) {
        weightAndPrice.push(priceText);
    }

    const weightAndPriceText = weightAndPrice.join(', ');
    const bottomLineText = comment && comment.length > 0 ? `${weightAndPriceText}, ${comment}` : weightAndPriceText;

    return (
        <div className={classNames('search-summary', { 'search-summary-with-symbols': showSymbols })}>
            <div className="search-summary__header-properties-wrapper">
                <SearchSummaryHeader
                    symbol={showSymbols ? searchFormData.product.toLowerCase() : undefined}
                    firstLine={isPackage ? '' : materialProperties.map((property) => property.label).join(', ')}
                    secondLine={`${product}, ${materialType}`}
                    thirdLine={dimensions}
                    fourthLine={bottomLineText}
                />
            </div>
            <div className="search-summary__dimension">
                <SearchSummaryProperties topLine={dimensions} bottomLine={bottomLineText} />
            </div>
            <div className="search-summary__children-wrapper">{children}</div>
        </div>
    );
};
