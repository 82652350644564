import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag, URGENT_REQUESTS_KEY, useUserSelfDetails } from '@steelbuy/data-access';
import { Feature, UserRole } from '@steelbuy/ts-shared';
import { IconIdentifier, Separator, SeparatorIdentifier } from '@steelbuy/ui-primitive';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { RoutePath } from '../../../router/Routes';
import { NavigationItem } from '../navigation-item/NavigationItem';
import { useNavigationContext } from '../NavigationContext';

export const NavigationItemGroup = () => {
    const navigationContext = useNavigationContext();
    const { t } = useTranslation('translation');
    const { data: userData } = useUserSelfDetails();
    const queryClient = useQueryClient();

    const navigationItemGroupClassMap = {
        'navigation-item-group': true,
        'navigation-item-group--is-collapsed': navigationContext.isCollapsed,
    };

    const setCollapsed = () => {
        if (navigationContext.collapseOnNavigation) {
            navigationContext.setIsCollapsed(true);
        }
    };

    const { isFeatureSupported } = useFeatureFlag();
    const isBidOfferEnabled = isFeatureSupported(Feature.BID_OFFER);
    const isRFQEnable = isFeatureSupported(Feature.RFQ);

    const userRoleChecker = (role: UserRole) => userData?.roles?.includes(role);

    const hasBuyerRole = userRoleChecker(UserRole.BUYER);
    const hasUrgentRequestAccess = userRoleChecker(UserRole.SELLER) || userRoleChecker(UserRole.MANAGER);
    const hasRequestMaterialAccess = hasBuyerRole || userRoleChecker(UserRole.MANAGER);
    const hasMyOffersAccess = hasBuyerRole && isBidOfferEnabled;
    const hasManageOffersAccess = userRoleChecker(UserRole.SELLER) && isBidOfferEnabled;
    const hasRFQAccess = isRFQEnable && hasBuyerRole;

    const clearUrgentRequestsCache = () => queryClient.removeQueries({ queryKey: [URGENT_REQUESTS_KEY] });

    return (
        <ul className={buildClassStringFromClassMap(navigationItemGroupClassMap)}>
            <NavigationItem
                icon={IconIdentifier.PLACEHOLDER}
                label={t('application.navigation.itemLabels.dashboard')}
                to={RoutePath.DASHBOARD}
                onClick={() => {
                    setCollapsed();
                    clearUrgentRequestsCache();
                }}
            />

            {hasRFQAccess && (
                <>
                    <Separator separatorType={SeparatorIdentifier.ON_DARK} />
                    <NavigationItem
                        icon={IconIdentifier.ADD_TO_LIST}
                        label={t('application.navigation.itemLabels.createRfq')}
                        to={RoutePath.CREATE_RFQ_WIZARD}
                        onClick={setCollapsed}
                        state={{ fromNavbar: true }}
                    />
                    <NavigationItem
                        icon={IconIdentifier.JUSTIFY}
                        label={t('application.navigation.itemLabels.myRfqs')}
                        to={RoutePath.MY_RFQS}
                        onClick={setCollapsed}
                    />
                </>
            )}

            <Separator separatorType={SeparatorIdentifier.ON_DARK} />

            <NavigationItem
                icon={IconIdentifier.ADD_TO_LIST}
                label={t('application.navigation.itemLabels.createListing')}
                to={RoutePath.CREATE_LISTING_WIZARD}
                onClick={setCollapsed}
                state={{ fromNavbar: true }}
            />
            <NavigationItem
                icon={IconIdentifier.JUSTIFY}
                label={t('application.navigation.itemLabels.myListings')}
                to={RoutePath.MY_LISTINGS}
                onClick={setCollapsed}
            />
            {hasManageOffersAccess && (
                <NavigationItem
                    icon={IconIdentifier.OFFER}
                    label={t('application.navigation.itemLabels.manageOffers')}
                    to={RoutePath.MANAGE_OFFERS}
                    onClick={setCollapsed}
                />
            )}

            {hasUrgentRequestAccess && (
                <NavigationItem
                    icon={IconIdentifier.TIMER}
                    label={t('application.navigation.itemLabels.activeRequests')}
                    to={RoutePath.URGENT_REQUESTS}
                    onClick={() => {
                        setCollapsed();
                        clearUrgentRequestsCache();
                    }}
                />
            )}

            <Separator separatorType={SeparatorIdentifier.ON_DARK} />

            <NavigationItem
                icon={IconIdentifier.SEARCH}
                label={t('application.navigation.itemLabels.searchListing')}
                to={RoutePath.SEARCH_LISTINGS}
                onClick={setCollapsed}
                state={{ fromNavbar: true }}
            />
            {hasRequestMaterialAccess && (
                <NavigationItem
                    icon={IconIdentifier.HORN}
                    label={t('application.navigation.itemLabels.requestMaterial')}
                    to={RoutePath.IMMEDIATE_REQUEST}
                    onClick={setCollapsed}
                    flipIcon
                />
            )}
            <NavigationItem
                icon={IconIdentifier.BELL}
                label={t('application.navigation.itemLabels.alerts')}
                to={RoutePath.MY_ALERTS}
                onClick={setCollapsed}
            />
            {hasMyOffersAccess && (
                <NavigationItem
                    icon={IconIdentifier.OFFER}
                    label={t('application.navigation.itemLabels.myOffers')}
                    to={RoutePath.MY_OFFERS}
                    onClick={setCollapsed}
                />
            )}
            <NavigationItem
                icon={IconIdentifier.BAG}
                label={t('application.navigation.itemLabels.myOrders')}
                to={RoutePath.MY_ORDERS}
                onClick={setCollapsed}
            />

            <Separator separatorType={SeparatorIdentifier.ON_DARK} />

            <NavigationItem
                icon={IconIdentifier.PERSON}
                label={t('application.navigation.itemLabels.account')}
                to={RoutePath.ACCOUNT}
                onClick={setCollapsed}
            />
        </ul>
    );
};
