import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressModel } from '@steelbuy/ts-shared';
import {
    InputRadioButton,
    Tag,
    TagVariant,
    ButtonTertiaryOnLightM,
    Notification,
    NotificationLevel,
    InputRadioButtonGroup,
} from '@steelbuy/ui-primitive';

import './CardContentCheckoutAddresses.scss';

export type CardContentCheckoutAddressesProps = {
    addresses: readonly AddressModel[];
    selectedAddress?: string;
    onSelectionChanged: (id: string) => void;
    forceValidation?: boolean;
    isDeliveryAddress?: boolean;
    isMandatory?: boolean;
};

export const CardContentCheckoutAddresses = (props: CardContentCheckoutAddressesProps) => {
    const {
        addresses: allAddresses,
        selectedAddress,
        onSelectionChanged,
        forceValidation,
        isDeliveryAddress,
        isMandatory = false,
    } = props;
    const isMaxAmount = allAddresses.length > 6;
    const [slicedAmount, setSlicedAmount] = useState(isMaxAmount ? 6 : allAddresses.length);
    const { t } = useTranslation('uiDomain');
    const showAllAddresses = () => {
        setSlicedAmount(allAddresses.length);
    };

    return (
        <>
            <section className="checkout-addresses">
                <div className="checkout-addresses__header">
                    {isDeliveryAddress
                        ? t('checkoutDeliveryAddress.listHeading')
                        : t('cardContentCheckoutAddresses.listHeading')}
                    {isMandatory && <span className="required-field"> *</span>}
                </div>
                <div className="checkout-addresses__header">
                    <Notification
                        level={NotificationLevel.INFO}
                        message={
                            isDeliveryAddress
                                ? t('checkoutDeliveryAddress.notificationMessage')
                                : t('cardContentCheckoutAddresses.notificationMessage')
                        }
                    />
                </div>
                <div className="checkout-addresses__selection">
                    <InputRadioButtonGroup
                        required
                        forceValidation={forceValidation}
                        requiredMessage={t('validation.requiredField')}
                        value={selectedAddress}
                    >
                        {allAddresses.slice(0, slicedAmount).map((address) => (
                            <InputRadioButton
                                key={`checkout-addresses-list_${address.id}`}
                                id={address.id}
                                name="address-selection"
                                checked={address.id === selectedAddress}
                                onChange={(checked) => checked && onSelectionChanged(address.id)}
                                required
                            >
                                {address.primary && (
                                    <Tag
                                        label={t('addressForm.primaryTagLabel', 'Choose other address')}
                                        variant={TagVariant.NEUTRAL}
                                    />
                                )}
                                <span>{address.organisation.name}</span>
                                <span>{address.name}</span>
                                <span>{address.addressLine1}</span>
                                {address.addressLine2 && <span>{address.addressLine2}</span>}
                                <span>{address.city}</span>
                                <span>{address.country}</span>
                                <span>{address.postalCode}</span>
                            </InputRadioButton>
                        ))}
                    </InputRadioButtonGroup>
                </div>
            </section>
            {isMaxAmount && slicedAmount !== allAddresses.length && (
                <div className="checkout-addresses__center">
                    <ButtonTertiaryOnLightM onClick={showAllAddresses} label={t('commonList.loadMore')} />
                </div>
            )}
        </>
    );
};
