import { useMemo } from 'react';
import { getAvailableCredit, useUserSelfDetails } from '@steelbuy/data-access';
import { CurrencyCode, OfferView, PricingUnit } from '@steelbuy/ts-shared';
import { useBlurEffect } from '@steelbuy/util';
import { OfferAcceptedModal } from './offer-modals/OfferAcceptedModal';
import { OfferCounterModal } from './offer-modals/OfferCounterModal';
import { OfferRejectedModal } from './offer-modals/OfferRejectedModal';
import { OfferWithdrawModal } from './offer-modals/OfferWithdrawModal';

export enum ModalView {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    COUNTER = 'COUNTER',
    WITHDRAW = 'WITHDRAW',
}

type OfferModalsProps = {
    activeModal: ModalView | null;
    onModalClose: () => void;
    negotiationId: string;
    onStatusChange: (message?: string, errorMessage?: string) => void;
    originalPrice: number;
    latestOfferPrice: number;
    currencyCode: CurrencyCode;
    pricingUnit: PricingUnit;
    weightOrItems: number;
    expiresAt: string;
    view: OfferView;
    taxPercent: number;
    minimumPrice: number;
};

export const OfferModals = ({
    activeModal,
    onModalClose,
    negotiationId,
    onStatusChange,
    originalPrice,
    latestOfferPrice,
    currencyCode,
    pricingUnit,
    weightOrItems,
    expiresAt,
    view,
    taxPercent,
    minimumPrice,
}: OfferModalsProps) => {
    useBlurEffect([activeModal], !activeModal);

    const commonProps = useMemo(
        () => ({
            onModalClose,
            negotiationId,
            onStatusChange,
        }),
        [onModalClose, negotiationId, onStatusChange]
    );
    const { data: userData } = useUserSelfDetails();
    return (
        <>
            <OfferAcceptedModal {...commonProps} show={activeModal === ModalView.ACCEPTED} view={view} />
            <OfferRejectedModal {...commonProps} show={activeModal === ModalView.REJECTED} view={view} />
            <OfferWithdrawModal {...commonProps} show={activeModal === ModalView.WITHDRAW} />

            <OfferCounterModal
                {...commonProps}
                originalPrice={originalPrice}
                show={activeModal === ModalView.COUNTER}
                latestOfferPrice={latestOfferPrice}
                currencyCode={currencyCode}
                pricingUnit={pricingUnit}
                weightOrItems={weightOrItems}
                expiresAt={expiresAt}
                creditLimit={getAvailableCredit(userData || null)}
                view={view}
                taxPercent={taxPercent}
                minimumPrice={minimumPrice}
            />
        </>
    );
};
