/* eslint-disable react/destructuring-assignment */
import { Component, ReactElement, ReactNode } from 'react';
import { Nullable } from '@steelbuy/ts-shared';

export interface ErrorHandlerProps {
    errorComponent: (error: Error, resetError: () => void) => Nullable<ReactElement>;
    errorReporter?: (error: Error) => void;
    transparent?: boolean;
    children?: ReactNode;
}

interface ErrorHandlerState {
    error?: Error;
}

export class ErrorHandler extends Component<ErrorHandlerProps, ErrorHandlerState> {
    constructor(props: ErrorHandlerProps) {
        super(props);
        this.state = {
            error: undefined,
        };
    }

    static getDerivedStateFromError(error: Error): ErrorHandlerState {
        return {
            error,
        };
    }

    private renderError(error: Error) {
        const errorComponent = this.props.errorComponent(error, () => this.setState({ error: undefined }));
        if (errorComponent === null) {
            throw error;
        }
        const errorReporter = this.props.errorReporter ?? null;
        if (errorReporter !== null) {
            errorReporter(error);
        }
        const children = this.props.transparent ? this.props.children : null;
        return (
            <>
                {errorComponent}
                {children}
            </>
        );
    }

    override render() {
        const { error } = this.state;
        if (error !== undefined) {
            return this.renderError(error);
        }
        return this.props.children;
    }
}
