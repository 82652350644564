import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    CartProductType,
    MY_CART_DETAILS,
    checkListingAlreadyInCart,
    handleCartError,
    updateCartCache,
    useAddItemToCart,
    useCartDetails,
    usePackageDetails,
    useFeatureFlag,
    useUserSelfDetails,
} from '@steelbuy/data-access';
import { NotFoundError } from '@steelbuy/error';
import { useModal } from '@steelbuy/modal-dialog';
import { UserRole, ListingBuyerModel, Feature, CartBuyerModel, createDateFromTimestamp } from '@steelbuy/ts-shared';
import {
    ListingDetailsExpiration,
    MarketingBannerSteelbuyPromise,
    ListingBuyerDetailsPrice,
} from '@steelbuy/ui-domain';
import {
    Card,
    CardLayout,
    CardContentAttributes,
    CardContentAttributesItem,
    ButtonCallToAction,
    Notification,
    NotificationLevel,
    LoadingSpinner,
    CardContentMaterialLocation,
    ButtonSecondary,
    LoadingStatus,
} from '@steelbuy/ui-primitive';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { AddToCartConfirmModal } from '../add-to-cart-confirm-modal/AddToCartConfirmModal';
import { useCheckoutContext } from '../checkout/CheckoutContext';
import { CommonPackageDetails } from '../common-listing-details/CommonPackageDetails';
import { MaterialPageHeader } from '../material-page-header/MaterialPageHeader';

import './PackageDetails.scss';

interface PackageDetailsProps {
    checkoutPath: string;
    goBackPath: string;
    packageListingId: string;
}

export const PackageDetails = ({ checkoutPath, goBackPath, packageListingId }: PackageDetailsProps) => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);
    const {
        data: packageModel,
        isLoading,
        error,
    } = usePackageDetails({
        packageId: packageListingId,
        queryOptions: {
            enabled: true,
        },
    });
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data: userData } = useUserSelfDetails();
    const checkoutContext = useCheckoutContext();
    const { isFeatureSupported } = useFeatureFlag();

    const hasBuyerRole = userData?.roles.includes(UserRole.BUYER) ?? false;
    const isCartSupported = isFeatureSupported(Feature.CART) && hasBuyerRole;
    const addItemToCartMutation = useAddItemToCart();
    const [cartData, setCartData] = useState<CartBuyerModel>();
    const openAddToCartModal = useModal();

    const handleCheckoutButtonClick = () => {
        if (packageModel) {
            checkoutContext.initializeWithListingId(packageModel.id);
            navigate(checkoutPath);
        }
    };
    const { refetch, data } = useCartDetails({ queryOptions: { enabled: false } });

    const handleAddToCart = (id: string): void => {
        addItemToCartMutation.mutate(
            { productId: id, productType: CartProductType.PACKAGE },
            {
                onSuccess: (response) => {
                    setCartData(response);
                    openAddToCartModal.show();
                    updateCartCache(queryClient, [MY_CART_DETAILS], response);
                },
                onError: (e: unknown) => {
                    handleCartError(e, refetch, t);
                },
            }
        );
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }
    if (error || !packageModel) {
        throw new NotFoundError();
    }

    const listingInCart = data && checkListingAlreadyInCart(data, packageModel.id);

    const renderActionButtons = () => {
        if (!hasBuyerRole) {
            return null;
        }
        return (
            <div className={classNames('listing-details__action-bar')}>
                {isCartSupported && (
                    <ButtonSecondary
                        label={
                            listingInCart ? t(`uiDomain:commonList.addedToCart`) : t(`uiDomain:commonList.addToCart`)
                        }
                        onClick={() => handleAddToCart(packageModel.id)}
                        disabled={listingInCart}
                        loadingStatus={addItemToCartMutation.isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                    />
                )}
                <ButtonCallToAction
                    label={t('translation:application.listingDetails.checkoutButtonText')}
                    onClick={handleCheckoutButtonClick}
                    disabled={!packageModel.canCheckout || addItemToCartMutation.isLoading}
                />
            </div>
        );
    };

    return (
        <>
            <MaterialPageHeader
                packageModel={packageModel}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={() => navigate(goBackPath)}
            />
            <div className="package-details">
                <DetailsLayout>
                    {renderActionButtons()}

                    {hasBuyerRole && !packageModel?.canCheckout && (
                        <Notification
                            level={NotificationLevel.ERROR}
                            message={t('translation:application.checkout.creditLimitExceeded')}
                        />
                    )}

                    <CommonPackageDetails packageModel={packageModel} />

                    <ListingBuyerDetailsPrice
                        listing={
                            {
                                ...packageModel,
                                material: { materialType: packageModel.material },
                            } as unknown as ListingBuyerModel
                        }
                    />

                    <Card isClickable={false}>
                        <CardLayout>
                            <CardContentAttributes header={t('translation:application.listingDetails.deliveryTitle')}>
                                <CardContentAttributesItem
                                    label={t('translation:application.listingDetails.deliveryText')}
                                    value={t(`domainModel:deliveryPromise.${packageModel.deliveryTimeframe}`)}
                                />
                                {packageModel.country && (
                                    <CardContentMaterialLocation countryCode={packageModel.country} />
                                )}
                            </CardContentAttributes>
                        </CardLayout>
                    </Card>
                    <ListingDetailsExpiration expiryDate={createDateFromTimestamp(packageModel.expiresAt)} />

                    <MarketingBannerSteelbuyPromise />

                    {renderActionButtons()}
                    {cartData && <AddToCartConfirmModal modalAccessor={openAddToCartModal} cartData={cartData} />}
                </DetailsLayout>
            </div>
        </>
    );
};
