import { useQuery } from '@tanstack/react-query';
import { NotificationSettings } from '@steelbuy/ts-shared';
import axios from '../../axios';

export const NOTIFICATIONS_SETTINGS_KEY = 'NotificationSettings';

const getSettings = async () => {
    const { data } = await axios.get<NotificationSettings>('/api/seller/notification/settings');
    return data;
};

export const useGetNotificationSettings = () =>
    useQuery({
        queryKey: [NOTIFICATIONS_SETTINGS_KEY],
        queryFn: () => getSettings(),
    });
