import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    useListingSellerDraftCollectionData,
    useRefreshingCollection,
    FetchStatus,
    ActionStatus,
} from '@steelbuy/data-provider';
import { useModal } from '@steelbuy/modal-dialog';
import { ModelPrimaryKey } from '@steelbuy/ts-shared';
import { DeleteDraftModal, ListingDraftTeaser } from '@steelbuy/ui-domain';
import {
    FillHeightContainer,
    LoadingSpinner,
    ButtonTertiaryOnLightM,
    Card,
    ViewHeading,
    useSuccessErrorNotification,
    LoadingStatus,
} from '@steelbuy/ui-primitive';
import { MyListingEmptyState } from './MyListingEmptyState';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { FOOTER_HEIGHT } from '../app-layout/app-footer/AppFooter';

import './MyListings.scss';

export const MyListingsDrafts = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();

    const listingDraftCollectionData = useListingSellerDraftCollectionData();
    const fetchStatus = useRefreshingCollection(listingDraftCollectionData);
    const drafts = listingDraftCollectionData.query();
    const totalListings = listingDraftCollectionData.queryTotalItems() ?? 0;
    const hasNext = listingDraftCollectionData.hasNextPage();
    const modalAccessor = useModal();
    const [selectedDraftId, setSelectedDraftId] = useState<ModelPrimaryKey>();

    const { Notification, showSuccessNotification, showErrorNotification, hideNotification } =
        useSuccessErrorNotification();

    useEffect(
        () => () => {
            hideNotification();
        },
        []
    );
    const editNavigateState = {
        goBackLink: RoutePath.MY_LISTINGS_DRAFTS,
        goBackText: t('application.myListings.drafts'),
    };

    listingDraftCollectionData.useActionStatusEffect(
        [ActionStatus.DELETE_SUCCESS],
        () => {
            showSuccessNotification(t('application.createListingOverview.notificationSuccess'));
            modalAccessor.hide();
            modalAccessor.setActionPending(false);
        },
        true
    );

    listingDraftCollectionData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            showErrorNotification(t('application.createListingOverview.notificationError'));
            modalAccessor.hide();
            modalAccessor.setActionPending(false);
        },
        true
    );

    listingDraftCollectionData.useActionStatusEffect(
        [ActionStatus.DELETE_PENDING],
        () => {
            hideNotification();
            modalAccessor.setActionPending(true);
        },
        true
    );

    const handleLoadMore = () => {
        listingDraftCollectionData.fetchNext();
    };

    const handleEditDraft = (draftId: string) => {
        navigate(
            createRouteUrl(
                RoutePath.CREATE_LISTING_WIZARD_WITH_DRAFT,
                ['draftId', draftId],
                ['listingType', 'listing']
            ),
            {
                state: editNavigateState,
            }
        );
    };

    const handleDeleteDraft = (draftId: string) => {
        modalAccessor.show();
        setSelectedDraftId(draftId);
    };

    const handleListingDraftDelete = (draftId: string) => {
        listingDraftCollectionData.delete(draftId);
    };

    return (
        <div className="my-listings-drafts">
            {fetchStatus === FetchStatus.PENDING ? (
                <FillHeightContainer extraPadding={FOOTER_HEIGHT}>
                    <LoadingSpinner fillContainerHeight />
                </FillHeightContainer>
            ) : (
                <>
                    {Notification}
                    <ViewHeading value={t('application.myListings.numberOfDrafts', { count: totalListings })} />
                    <div className="my-listings-drafts__listings">
                        {drafts.map((draft) => (
                            <Card
                                key={draft.id}
                                isClickable
                                url={createRouteUrl(
                                    RoutePath.CREATE_LISTING_WIZARD_WITH_DRAFT,
                                    ['draftId', draft.id],
                                    ['listingType', 'listing']
                                )}
                                linkState={editNavigateState}
                            >
                                <ListingDraftTeaser
                                    draft={draft}
                                    onEdit={() => handleEditDraft(draft.id)}
                                    onDelete={() => handleDeleteDraft(draft.id)}
                                />
                            </Card>
                        ))}
                    </div>
                    {hasNext && (
                        <div className="my-listings-drafts__load-more">
                            <ButtonTertiaryOnLightM
                                onClick={handleLoadMore}
                                label={t('uiDomain:commonList.loadMore')}
                                loadingStatus={
                                    fetchStatus === FetchStatus.PAGING_PENDING
                                        ? LoadingStatus.PENDING
                                        : LoadingStatus.IDLE
                                }
                            />
                        </div>
                    )}
                </>
            )}
            {totalListings === 0 && <MyListingEmptyState />}
            <DeleteDraftModal
                modalAccessor={modalAccessor}
                onConfirm={() => {
                    selectedDraftId && handleListingDraftDelete(selectedDraftId);
                }}
            />
        </div>
    );
};
