import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PackageBuyerModel, PackageBuyerModelConverter, ApiModel } from '@steelbuy/ts-shared';
import axios from '../../axios';

interface PackageViewsOptions<Model> {
    packageId: string;
    queryOptions?: Omit<UseQueryOptions<Model, unknown, Model, string[]>, 'queryFn' | 'queryKey'>;
}

const modelConverter = new PackageBuyerModelConverter();

export const getPackageDetails = async (packageId: string) => {
    const { data } = await axios.get<PackageBuyerModel>(`/api/universal/packaging/search/${packageId}`);
    const packageModel = modelConverter.toViewModel(data as unknown as ApiModel<PackageBuyerModel>);
    return packageModel;
};

export const usePackageDetails = ({ packageId, queryOptions }: PackageViewsOptions<PackageBuyerModel>) =>
    useQuery({
        queryKey: ['packaging-search', packageId],
        queryFn: () => getPackageDetails(packageId),
        ...queryOptions,
    });
