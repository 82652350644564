import { Locale, Region } from '@steelbuy/ts-shared';

const defaultRegion = {
    locale: Locale['en-GB'],
};

let region: Region = defaultRegion;

export const setRegion = (regionConfig: Region) => {
    region = regionConfig;
};

export const getRegion = () => region;
