import { ChangeEvent, InputHTMLAttributes } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { useCursorPosition } from '../../hooks/useCursorPosition';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { ValidationProps } from '../input-validation/InputValidation';
import { useValidation } from '../input-validation/InputValidationHook';

import './InputTextfieldUnit.scss';

const DEFAULT_TEST_ID = 'input-text-field-unit';

export type InputTextfieldUnitProps = {
    label?: string;
    unit: string;
    helperText?: string;
    error?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    preInput?: string;
    testId?: string;
    isMandatory?: boolean;
} & ValidationProps &
    Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

export const InputTextfieldUnit = (props: InputTextfieldUnitProps) => {
    const {
        label,
        unit,
        readOnly,
        helperText,
        error,
        placeholder,
        disabled,
        onChange,
        validators,
        forceValidation,
        value,
        preInput,
        testId,
        isMandatory = false,
        ...rest
    } = props;

    const { elementRef, validationError, validate } = useValidation<HTMLInputElement>(
        value,
        validators,
        forceValidation
    );
    const { setCursor } = useCursorPosition(elementRef);

    const combinedError = validationError ?? error;

    const inputTextUnitClassMap = {
        'input-text-unit': true,
        'input-text-unit--disabled': disabled,
    };

    const inputTextUnitFieldClassMap = {
        'input-text-unit__field': true,
        'input-text-unit__field--error': combinedError,
    };

    const inputTextUnitFieldInputClassMap = {
        'input-text-unit__field__input': true,
        'input-text-unit__field__input--disabled': disabled,
        'input-text-unit__field__input--read-only': readOnly,
        'input-text-unit__field__input--pre-input': !!preInput,
    };

    const helperTextClassMap = {
        'input-text-unit__helper-text': true,
        'input-text-unit__helper-text--error': combinedError,
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return (
            <span className="input-text-unit__label">
                {label}
                {isMandatory && <span className="required-field"> *</span>}
            </span>
        );
    };

    const renderHelperText = () => {
        const text = combinedError ?? helperText;
        if (text === undefined) {
            return null;
        }
        return <span className={buildClassStringFromClassMap(helperTextClassMap)}>{text}</span>;
    };

    const renderErrorIcon = () => {
        if (combinedError === undefined) {
            return null;
        }
        return <Icon name={IconIdentifier.ERROR} />;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        validate(event.target.value);
        if (onChange !== null) {
            if (onChange) {
                setCursor(event.target.selectionStart);
                onChange(event.target.value);
            }
        }
    };

    return (
        <label className={buildClassStringFromClassMap(inputTextUnitClassMap)}>
            {renderLabel()}
            <span className={buildClassStringFromClassMap(inputTextUnitFieldClassMap)}>
                {preInput && <span className="input-text-unit__field__pre-input">{preInput}</span>}
                <input
                    ref={elementRef}
                    className={buildClassStringFromClassMap(inputTextUnitFieldInputClassMap)}
                    type="text"
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={handleChange}
                    placeholder={placeholder}
                    value={value}
                    data-testid={testId || DEFAULT_TEST_ID}
                    {...rest}
                />
                {unit && <span className="input-text-unit__field__unit">{unit}</span>}
                {renderErrorIcon()}
            </span>
            {renderHelperText()}
        </label>
    );
};
