import { useTranslation } from 'react-i18next';
import { Country } from '@steelbuy/ts-shared';
import { CardContentAttributesItem } from '../card-content-attributes/card-content-attributes-item/CardContentAttributesItem';
import { CountryIcon } from '../country-icon/CountryIcon';

export const CardContentMaterialLocation = ({ countryCode }: { countryCode: Country }) => {
    const { t } = useTranslation(['uiPrimitive', 'domainModel']);

    return (
        <CardContentAttributesItem
            label={t('checkout.delivery.materialLocationLabel')}
            value={t(`domainModel:address.country.${countryCode}`)}
        >
            <CountryIcon code={countryCode} />
        </CardContentAttributesItem>
    );
};
