import { JsonWebToken } from '@steelbuy/auth';
import { ApiError } from '@steelbuy/error';
import { ModelConverter, AnonymousViewModel, ApiModel, ModelPrimaryKey, Nullable } from '@steelbuy/ts-shared';

import { replaceVariables } from '@steelbuy/util';
import { CollectionApiResponseBody } from './CollectionApiResponseBody';
import { CollectionResponse } from './CollectionResponse';
import { PathVariables } from './PathVariables';
import { SearchApiClient } from './SearchApiClient';
import { createFilterString, createSortString } from './UrlParams';
import { FilterCriteria } from '../collection-criteria/FilterCriteria';
import { PreparedSortCriteria, SortCriteria } from '../collection-criteria/SortCriteria';
import { JsonRestConnector } from '../network/JsonRestConnector';

export abstract class AbstractSearchApiClient<Model extends AnonymousViewModel> implements SearchApiClient<Model> {
    protected abstract collectionControllerUri: string;

    protected abstract paginationControllerUri: string;

    protected abstract entityFetchUri: string;

    protected paginationSize = 50;

    protected paginationQueryOffset = 'offset';

    protected paginationQueryLimit = 'limit';

    protected sortableProperties: Array<keyof Model> = [];

    protected sortableQuery = 'sort';

    protected filterableProperties: Array<keyof Model> = [];

    protected filterableQuery = 'filter';

    protected abstract modelConverter: ModelConverter<Model>;

    private baseUrl: Nullable<string> = null;

    private connector: Nullable<JsonRestConnector> = null;

    public init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
        this.baseUrl = baseUrl;
        this.connector = new JsonRestConnector(jsonWebToken);
        return this;
    }

    async fetchCollection(
        sort?: SortCriteria<Model> | PreparedSortCriteria,
        filter?: FilterCriteria<Model>,
        pathVariables?: PathVariables
    ): Promise<CollectionResponse<Model>> {
        if (this.connector === null) {
            throw new ApiError('Search API not initialized');
        }
        const endpoint = this.buildCollectionEndpoint(sort, filter, pathVariables);
        const parsedResponse = await this.connector.get(endpoint);
        const responseBody = parsedResponse.body as CollectionApiResponseBody<Model>;
        const items = responseBody.items.map((apiModel): Model => this.modelConverter.toViewModel(apiModel));
        const currentPage = Math.floor(responseBody.offset / responseBody.limit) + 1;
        const maxPage = Math.ceil(responseBody.total / responseBody.limit);
        return {
            items,
            currentPage,
            maxPages: maxPage,
            totalItems: responseBody.total,
        };
    }

    async fetchPage(
        page = 1,
        sort?: SortCriteria<Model> | PreparedSortCriteria,
        filter?: FilterCriteria<Model>,
        pathVariables?: PathVariables
    ): Promise<CollectionResponse<Model>> {
        if (this.connector === null) {
            throw new ApiError('Search API not initialized');
        }
        const endpoint = this.buildPaginationEndpoint(page, sort, filter, pathVariables);
        const parsedResponse = await this.connector.get(endpoint);
        const responseBody = parsedResponse.body as CollectionApiResponseBody<Model>;
        const items = responseBody.items.map((apiModel): Model => this.modelConverter.toViewModel(apiModel));
        const currentPage = Math.floor(responseBody.offset / responseBody.limit) + 1;
        const maxPage = Math.ceil(responseBody.total / responseBody.limit);
        return {
            items,
            currentPage,
            maxPages: maxPage,
            totalItems: responseBody.total,
        };
    }

    async fetch(id: ModelPrimaryKey): Promise<Nullable<Model>> {
        if (this.connector === null) {
            throw new ApiError('CRUD API not initialized');
        }
        const endpoint = this.buildEndpointWithId(this.entityFetchUri, id);
        const parsedResponse = await this.connector.get(endpoint);
        const apiModel = parsedResponse.body as ApiModel<Model>;
        return this.modelConverter.toViewModel(apiModel);
    }

    private buildCollectionEndpoint(
        sort?: SortCriteria<Model> | PreparedSortCriteria,
        filter?: FilterCriteria<Model>,
        pathVariables?: PathVariables
    ): string {
        const collectionUri = replaceVariables(this.collectionControllerUri, pathVariables);

        const endpointUrl = new URL(this.baseUrl + collectionUri);
        endpointUrl.searchParams.set(this.paginationQueryLimit, this.paginationSize.toString());
        if (sort !== undefined && sort.length > 0) {
            this.appendSortParams(endpointUrl, sort);
        }
        if (filter !== undefined && filter.length > 0) {
            this.appendFilterParams(endpointUrl, filter);
        }
        return endpointUrl.toString();
    }

    private buildPaginationEndpoint(
        page: number,
        sort?: SortCriteria<Model> | PreparedSortCriteria,
        filter?: FilterCriteria<Model>,
        pathVariables?: PathVariables
    ): string {
        const paginationUri = replaceVariables(this.paginationControllerUri, pathVariables);

        const endpointUrl = new URL(this.baseUrl + paginationUri);
        const offset = (page - 1) * this.paginationSize;
        endpointUrl.searchParams.set(this.paginationQueryOffset, offset.toString());
        endpointUrl.searchParams.set(this.paginationQueryLimit, this.paginationSize.toString());
        if (sort !== undefined && sort.length > 0) {
            this.appendSortParams(endpointUrl, sort);
        }
        if (filter !== undefined && filter.length > 0) {
            this.appendFilterParams(endpointUrl, filter);
        }
        return endpointUrl.toString();
    }

    private buildEndpointWithId(uri: string, id: string): string {
        return this.baseUrl + uri.replace('{id}', id);
    }

    private appendSortParams = (url: URL, sort: SortCriteria<Model> | PreparedSortCriteria) => {
        url.searchParams.set(this.sortableQuery, createSortString(sort));
    };

    private appendFilterParams = (url: URL, filter: FilterCriteria<Model>) => {
        url.searchParams.set(this.filterableQuery, createFilterString(filter));
    };
}
