import { Fragment, useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useIsFeatureSupported, userHasRole, useUserSelfDetails } from '@steelbuy/data-access';
import { Feature, UserRole } from '@steelbuy/ts-shared';
import { WizardStepsContextProvider } from '@steelbuy/ui-primitive';
import { useRerenderFromNavbar } from '@steelbuy/util';
import { RFQ_STEPS } from '../../components/create-rfq/createRFQUtil';
import { RfqFormProvider, useRfqFormContext } from '../../components/create-rfq/RfqFormContext';
import { RoutePath } from '../../router/Routes';

const CreateRFQViewInner = () => {
    const location = useLocation();
    const createRFQData = location.state?.createRFQData;
    const navigate = useNavigate();
    const { data: userModel } = useUserSelfDetails();
    const hasBuyerRole = userHasRole(userModel || null, [UserRole.BUYER]);
    const showRfq = useIsFeatureSupported(Feature.RFQ) && hasBuyerRole;

    if (!showRfq) {
        navigate(RoutePath.DASHBOARD);
    }
    const { updateFormData } = useRfqFormContext();
    const [isInitialDataSet, setIsInitialDataSet] = useState(!createRFQData);

    useEffect(() => {
        if (createRFQData) {
            updateFormData(createRFQData);
            setIsInitialDataSet(true);
        }
    }, [createRFQData]);

    if (!isInitialDataSet) {
        return null;
    }

    return <Outlet />;
};

export const CreateRFQView = () => {
    const rerenderKey = useRerenderFromNavbar();

    return (
        <Fragment key={rerenderKey}>
            <RfqFormProvider>
                <WizardStepsContextProvider steps={RFQ_STEPS}>
                    <CreateRFQViewInner />
                </WizardStepsContextProvider>
            </RfqFormProvider>
        </Fragment>
    );
};
