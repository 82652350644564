import { ChangeEvent, ReactNode, SelectHTMLAttributes } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { ValidationProps } from '../input-validation/InputValidation';
import { useValidation } from '../input-validation/InputValidationHook';

import './InputSelect.scss';

const DEFAULT_TEST_ID = 'input-select';

export type InputSelectOption<T> = {
    label: string;
    value: T;
};

export type InputSelectProps<T> = {
    label?: string;
    placeholder?: string;
    options?: Array<InputSelectOption<T>>;
    isReadonly?: boolean;
    helperText?: string;
    error?: string;
    onSelect?: (value?: T) => void;
    testId?: string;
} & ValidationProps &
    Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onSelect' | 'onBlur'>;

export const InputSelect = <T extends string>({
    disabled,
    error,
    forceValidation,
    helperText,
    isReadonly,
    label,
    onSelect,
    options,
    placeholder,
    testId,
    validators,
    value,
    ...rest
}: InputSelectProps<T>) => {
    const { elementRef, validationError } = useValidation<HTMLSelectElement>(value, validators, forceValidation);

    const combinedError = validationError ?? error;

    const inputSelectClassMap = {
        'input-select': true,
        'input-select--disabled': disabled,
    };

    const inputSelectFieldClassMap = {
        'input-select__field': true,
        'input-select__field--error': combinedError,
    };

    const inputSelectFieldSelectClassMap = {
        'input-select__field__select': true,
        'input-select__field__select--disabled': disabled,
        'input-select__field__select--read-only': isReadonly,
    };

    const helperTextClassMap = {
        'input-select__helper-text': true,
        'input-select__helper-text--error': combinedError,
    };

    const renderOptions = () => {
        if (!options) {
            return null;
        }

        const optionElements: Array<ReactNode> = [];
        if (placeholder !== undefined) {
            optionElements.push(
                <option value="" key="placeholder">
                    {placeholder}
                </option>
            );
        }

        options.forEach((option) => {
            optionElements.push(
                <option value={option.value} key={option.value}>
                    {option.label}
                </option>
            );
        });

        return optionElements;
    };

    const renderHelperText = () => {
        const text = helperText ?? combinedError;

        if (text === undefined) {
            return null;
        }
        return <span className={buildClassStringFromClassMap(helperTextClassMap)}>{text}</span>;
    };

    const handleSelect = (event: ChangeEvent<HTMLSelectElement>): void => {
        if (onSelect !== undefined) {
            onSelect(event.currentTarget.value?.length > 0 ? (event.currentTarget.value as T) : undefined);
        }
    };

    return (
        <label className={buildClassStringFromClassMap(inputSelectClassMap)}>
            {label && (
                <span className="input-select__label">
                    {label}
                    {validators && <span className="required-field"> *</span>}
                </span>
            )}
            <span className={buildClassStringFromClassMap(inputSelectFieldClassMap)}>
                <select
                    ref={elementRef}
                    className={buildClassStringFromClassMap(inputSelectFieldSelectClassMap)}
                    name="default"
                    id="default-input-select"
                    onChange={handleSelect}
                    data-testid={testId || DEFAULT_TEST_ID}
                    value={value === undefined && placeholder !== undefined ? '' : value}
                    {...rest}
                >
                    {renderOptions()}
                </select>
                <Icon name={IconIdentifier.CHEVRON_DOWN} />
                {combinedError && <Icon name={IconIdentifier.ERROR} />}
            </span>
            {renderHelperText()}
        </label>
    );
};
