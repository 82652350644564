import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionStatus, useWarehouseAddressCollectionData } from '@steelbuy/data-provider';
import { useCheckoutContext } from './CheckoutContext';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { PageHeader } from '../page-header/PageHeader';
import { WarehouseAddressSelection } from '../warehouse-address-selection/WarehouseAddressSelection';

export const CheckoutChooseAddress = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();
    const checkoutContext = useCheckoutContext();
    const warehouseAddressCollectionData = useWarehouseAddressCollectionData();

    const createdAddress = warehouseAddressCollectionData.queryCreated().get();

    const [selectedAddressId, setSelectedAddressId] = useState<string | undefined>(checkoutContext.deliveryAddressId);

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            if (createdAddress) {
                setSelectedAddressId(createdAddress.id);
            }
        },
        true
    );

    const goToCheckout = () => {
        navigate('./..', { replace: true });
    };

    return (
        <>
            <PageHeader
                pageTitle={t('application.checkoutChooseAddress.pageTitle')}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={goToCheckout}
                showCartIcon={false}
            />

            <DetailsLayout>
                <WarehouseAddressSelection
                    selectedAddressId={selectedAddressId}
                    isDeliveryAddress
                    onCancel={goToCheckout}
                    onSelect={(newAddressId) => {
                        checkoutContext.setDeliveryAddressId(newAddressId);
                        goToCheckout();
                    }}
                />
            </DetailsLayout>
        </>
    );
};
