import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    CollectionResponse,
    compareFilterCriteria,
    comparePathVariables,
    compareSortCriteria,
    CrudApiClient,
    FilterCriteria,
    PreparedSortCriteria,
    SearchApiClient,
    SortCriteria,
    PathVariables,
} from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { createErrorObject, AnyErrorObject, StoreError } from '@steelbuy/error';
import { Mutable, Mutation, ViewModel, ModelPrimaryKey, AnonymousViewModel, Nullable } from '@steelbuy/ts-shared';

import { CollectionStore } from './CollectionStore';
import { CreateEntityStore } from './CreateEntityStore';
import { EntityStore } from './EntityStore';
import { SearchStore } from './SearchStore';
import { FetchStatus } from '../FetchStatus';

export type ThunkApiConfig = { rejectValue: AnyErrorObject };

export const createFetchAllThunk = <
    Model extends ViewModel,
    Store extends Record<string, CollectionStore<Model>>,
    ApiClient extends CrudApiClient<Model>
>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        CollectionResponse<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            sortCriteria?: SortCriteria<Model> | PreparedSortCriteria;
            filterCriteria?: FilterCriteria<Model>;
            roles?: string[];
        },
        ThunkApiConfig
    >(
        `${storeName}/fetchAll`,
        async (params, thunkApi) => {
            try {
                return await apiClientCreator(
                    params.apiBaseUrl,
                    await params.jsonWebTokenLoader(),
                    params.roles
                ).fetchCollection(params.sortCriteria, params.filterCriteria);
            } catch (error) {
                const errorObject = createErrorObject(error as Error);
                return thunkApi.rejectWithValue(errorObject);
            }
        },
        {
            condition: (params, { getState }): boolean => {
                // Silently abort the action
                const store = (getState() as Store)[storeName];
                // Abort if already pending
                if (store.fetchStatus === FetchStatus.PENDING || store.fetchStatus === FetchStatus.PAGING_PENDING) {
                    return false;
                }
                // Proceed if sort criteria has changed
                if (!compareSortCriteria<Model>(params.sortCriteria, store.sortCriteria ?? undefined)) {
                    return true;
                }
                // Proceed if filter criteria has changed
                if (!compareFilterCriteria<Model>(params.filterCriteria, store.filterCriteria ?? undefined)) {
                    return true;
                }
                // Cancel if store is populated
                return store.fetchStatus === FetchStatus.IDLE;
            },
        }
    );

export const createSearchAllThunk = <
    Model extends AnonymousViewModel,
    Store extends Record<string, SearchStore<Model>>,
    ApiClient extends SearchApiClient<Model>
>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        CollectionResponse<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            sortCriteria?: SortCriteria<Model> | PreparedSortCriteria;
            filterCriteria?: FilterCriteria<Model>;
            pathVariables?: PathVariables;
            roles?: string[];
        },
        ThunkApiConfig
    >(
        `${storeName}/fetchAll`,
        async (params, thunkApi) => {
            try {
                return await apiClientCreator(
                    params.apiBaseUrl,
                    await params.jsonWebTokenLoader(),
                    params.roles
                ).fetchCollection(params.sortCriteria, params.filterCriteria, params.pathVariables);
            } catch (error) {
                const errorObject = createErrorObject(error as Error);
                return thunkApi.rejectWithValue(errorObject);
            }
        },
        {
            condition: (params, { getState }): boolean => {
                // Silently abort the action
                const store = (getState() as Store)[storeName];
                // Abort if already pending
                if (store.fetchStatus === FetchStatus.PENDING || store.fetchStatus === FetchStatus.PAGING_PENDING) {
                    return false;
                }
                // Proceed if sort criteria has changed
                if (!compareSortCriteria<Model>(params.sortCriteria, store.sortCriteria ?? undefined)) {
                    return true;
                }
                // Proceed if filter criteria has changed
                if (!compareFilterCriteria<Model>(params.filterCriteria, store.filterCriteria ?? undefined)) {
                    return true;
                }
                // Proceed if path variables have changed
                if (!comparePathVariables(params.pathVariables, store.pathVariables ?? undefined)) {
                    return true;
                }
                // Cancel if store is populated
                return store.fetchStatus === FetchStatus.IDLE;
            },
        }
    );

export const createFetchNextThunk = <
    Model extends ViewModel,
    Store extends Record<string, CollectionStore<Model>>,
    ApiClient extends CrudApiClient<Model>
>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        CollectionResponse<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            roles?: string[];
        },
        ThunkApiConfig
    >(
        `${storeName}/fetchNext`,
        async (params, thunkApi) => {
            try {
                const store = (thunkApi.getState() as Store)[storeName];
                const currentPage = store.currentPage ?? 1;
                const sortCriteria = store.sortCriteria ?? undefined;
                const filterCriteria = store.filterCriteria ?? undefined;
                return await apiClientCreator(
                    params.apiBaseUrl,
                    await params.jsonWebTokenLoader(),
                    params.roles
                ).fetchPage(currentPage + 1, sortCriteria, filterCriteria);
            } catch (error) {
                const errorObject = createErrorObject(error as Error);
                return thunkApi.rejectWithValue(errorObject);
            }
        },
        {
            condition: (params, { getState }): boolean => {
                // Silently abort the action
                const store = (getState() as Store)[storeName];
                const currentPage = store.currentPage ?? 1;
                const lastPage = store.maxPages ?? 1;
                return (
                    store.fetchStatus !== FetchStatus.PENDING &&
                    store.fetchStatus !== FetchStatus.PAGING_PENDING &&
                    currentPage < lastPage
                );
            },
        }
    );

export const createSearchNextThunk = <
    Model extends AnonymousViewModel,
    Store extends Record<string, SearchStore<Model>>,
    ApiClient extends SearchApiClient<Model>
>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        CollectionResponse<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            roles?: string[];
        },
        ThunkApiConfig
    >(
        `${storeName}/fetchNext`,
        async (params, thunkApi) => {
            try {
                const store = (thunkApi.getState() as Store)[storeName];
                const currentPage = store.currentPage ?? 1;
                const sortCriteria = store.sortCriteria ?? undefined;
                const filterCriteria = store.filterCriteria ?? undefined;
                const pathVariables = store.pathVariables ?? undefined;
                return await apiClientCreator(
                    params.apiBaseUrl,
                    await params.jsonWebTokenLoader(),
                    params.roles
                ).fetchPage(currentPage + 1, sortCriteria, filterCriteria, pathVariables);
            } catch (error) {
                const errorObject = createErrorObject(error as Error);
                return thunkApi.rejectWithValue(errorObject);
            }
        },
        {
            condition: (params, { getState }): boolean => {
                // Silently abort the action
                const store = (getState() as Store)[storeName];
                const currentPage = store.currentPage ?? 1;
                const lastPage = store.maxPages ?? 1;
                return (
                    store.fetchStatus !== FetchStatus.PENDING &&
                    store.fetchStatus !== FetchStatus.PAGING_PENDING &&
                    currentPage < lastPage
                );
            },
        }
    );

export const createFetchOneThunk = <
    Model extends ViewModel,
    Store extends Record<string, CollectionStore<Model>>,
    ApiClient extends CrudApiClient<Model>
>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
            roles?: string[];
        },
        ThunkApiConfig
    >(
        `${storeName}/fetchOne`,
        async (params, thunkApi) => {
            try {
                return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader(), params.roles).fetch(
                    params.id
                );
            } catch (error) {
                const errorObject = createErrorObject(error as Error);
                return thunkApi.rejectWithValue(errorObject);
            }
        },
        {
            condition: (params, { getState }): boolean => {
                // Silently abort the action
                const store = (getState() as Store)[storeName];
                return store.fetchStatus !== FetchStatus.PENDING && store.fetchStatus !== FetchStatus.PAGING_PENDING;
            },
        }
    );

export const createFetchEntityThunk = <
    Model extends ViewModel,
    Store extends Record<string, EntityStore<Model>>,
    ApiClient extends CrudApiClient<Model>
>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
            roles?: string[];
        },
        ThunkApiConfig
    >(
        `${storeName}/fetchEntity`,
        async (params, thunkApi) => {
            try {
                return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader(), params.roles).fetch(
                    params.id
                );
            } catch (error) {
                const errorObject = createErrorObject(error as Error);
                return thunkApi.rejectWithValue(errorObject);
            }
        },
        {
            condition: (params, { getState }): boolean => {
                // Silently abort the action
                const store = (getState() as Store)[storeName];
                return store.scopes?.[params.id]?.fetchStatus !== FetchStatus.PENDING;
            },
        }
    );

export const createMutateThunk = <Model extends ViewModel, ApiClient extends CrudApiClient<Model>>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
            modelMutation: Mutation<Model>;
            roles?: string[];
        },
        ThunkApiConfig
    >(`${storeName}/mutateEntity`, async (params, thunkApi) => {
        try {
            return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader(), params.roles).mutate(
                params.id,
                params.modelMutation
            );
        } catch (error) {
            const errorObject = createErrorObject(error as Error);
            return thunkApi.rejectWithValue(errorObject);
        }
    });

export const createMutateCreatedThunk = <
    Model extends ViewModel,
    Store extends Record<string, CreateEntityStore<Model>>,
    ApiClient extends CrudApiClient<Model>
>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            modelMutation: Mutation<Model>;
            roles?: string[];
        },
        ThunkApiConfig
    >(`${storeName}/mutateCreatedEntity`, async (params, thunkApi) => {
        try {
            const store = (thunkApi.getState() as Store)[storeName];
            if (store.model === null) {
                throw new StoreError('No created entity available');
            }
            return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader(), params.roles).mutate(
                store.model.id,
                params.modelMutation
            );
        } catch (error) {
            const errorObject = createErrorObject(error as Error);
            return thunkApi.rejectWithValue(errorObject);
        }
    });

export const createCreateThunk = <Model extends ViewModel, ApiClient extends CrudApiClient<Model>>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        Readonly<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            mutableModel: Mutable<Model>;
            roles?: string[];
        },
        ThunkApiConfig
    >(`${storeName}/createEntity`, async (params, thunkApi) => {
        try {
            return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader(), params.roles).create(
                params.mutableModel
            );
        } catch (error) {
            const errorObject = createErrorObject(error as Error);
            return thunkApi.rejectWithValue(errorObject);
        }
    });

export const createDeleteThunk = <Model extends ViewModel, ApiClient extends CrudApiClient<Model>>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        ModelPrimaryKey,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
            roles?: string[];
        },
        ThunkApiConfig
    >(`${storeName}/deleteEntity`, async (params, thunkApi) => {
        try {
            return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader(), params.roles).delete(
                params.id
            );
        } catch (error) {
            const errorObject = createErrorObject(error as Error);
            return thunkApi.rejectWithValue(errorObject);
        }
    });

export const createDeleteCreatedThunk = <
    Model extends ViewModel,
    Store extends Record<string, CreateEntityStore<Model>>,
    ApiClient extends CrudApiClient<Model>
>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        ModelPrimaryKey,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            roles?: string[];
        },
        ThunkApiConfig
    >(`${storeName}/deleteCreatedEntity`, async (params, thunkApi) => {
        try {
            const store = (thunkApi.getState() as Store)[storeName];
            if (store.model === null) {
                throw new StoreError('No created entity available');
            }
            return await apiClientCreator(params.apiBaseUrl, await params.jsonWebTokenLoader(), params.roles).delete(
                store.model.id
            );
        } catch (error) {
            const errorObject = createErrorObject(error as Error);
            return thunkApi.rejectWithValue(errorObject);
        }
    });

export const createEntityServiceThunk = <Model extends ViewModel, ApiClient extends CrudApiClient<Model>>(
    storeName: string,
    apiClientCreator: (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => ApiClient
) =>
    createAsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
            service: string;
            modelMutation?: Mutation<Model>;
            roles?: string[];
        },
        ThunkApiConfig
    >(`${storeName}/serviceCall`, async (params, thunkApi) => {
        try {
            return await apiClientCreator(
                params.apiBaseUrl,
                await params.jsonWebTokenLoader(),
                params.roles
            ).serviceCall(params.id, params.service, params.modelMutation);
        } catch (error) {
            const errorObject = createErrorObject(error as Error);
            return thunkApi.rejectWithValue(errorObject);
        }
    });
