import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TradeUnit, TradeUnitCalculation } from '@steelbuy/ts-shared';
import { FormItem, InputTextfieldUnit } from '@steelbuy/ui-primitive';

import { RequiredFieldValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator, useNumberValidator } from '../validation/ValidationHooks';

type MaterialWeightInputProps = {
    initialWeight?: number;
    setWeight: (newWeight?: number) => void;
    tradeUnit?: TradeUnit;
    label?: string;
    tradeUnitCalculation?: TradeUnitCalculation;
} & RequiredFieldValidationProps;

export const MaterialWeightInput = ({
    initialWeight,
    setWeight,
    tradeUnit = TradeUnit.TON,
    forceValidation,
    label,
    tradeUnitCalculation,
}: MaterialWeightInputProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();
    const numberValidator = useNumberValidator();
    const isTheoreticalWeight = tradeUnitCalculation === TradeUnitCalculation.BY_ITEM;
    const [weight, setWeightState] = useState<string | undefined>(initialWeight?.toString());

    useEffect(() => {
        setWeightState(initialWeight?.toString());
    }, [initialWeight]);

    const handleWeightChange = (newWeight: string) => {
        setWeightState(newWeight);
        const weightAsFloat = Number.parseFloat(newWeight);
        if (newWeight && !Number.isNaN(weightAsFloat)) {
            setWeight(weightAsFloat);
        } else {
            setWeight(undefined);
        }
    };

    return (
        <FormItem
            header={t(
                `uiDomain:materialWeightInput.${isTheoreticalWeight ? 'formItemHeaderTheoretical' : 'formItemHeader'}`
            )}
        >
            <InputTextfieldUnit
                unit={t(`domainModel:material.tradeUnit.value.${tradeUnit}.label`)}
                label={
                    label ||
                    t(
                        `uiDomain:materialWeightInput.${
                            isTheoreticalWeight ? 'weightInputLabelTheoretical' : 'weightInputLabel'
                        }`
                    )
                }
                pattern="[0-9]*\.?[0-9]*"
                name="weight"
                value={weight ?? ''}
                onChange={handleWeightChange}
                validators={isTheoreticalWeight ? [] : [requiredFieldValidator, numberValidator]}
                forceValidation={forceValidation}
                disabled={isTheoreticalWeight}
                readOnly={isTheoreticalWeight}
                isMandatory={!isTheoreticalWeight}
            />
        </FormItem>
    );
};
