import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@steelbuy/ts-shared';
import {
    FormItem,
    InputTextfieldUnit,
    InputTextfieldCombination,
    ButtonGhostSystemOnLightM,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { useNumberValidator, useRequiredFieldValidator } from '../validation/ValidationHooks';
import './MaterialDimensionSearchSelection.scss';

const ProductsWithLength = [Product.PLATE, Product.SHEET, Product.TREAD];

export type MaterialDimensionSearchSpecification = {
    thickness?: string;
    width?: string;
    minWidth?: string;
    maxWidth?: string;
    length?: string;
    minLength?: string;
    maxLength?: string;
};

interface MaterialDimensionSearchSelectionProps {
    product: Product;
    dimensions: MaterialDimensionSearchSpecification;
    setDimensions: (dimensions: MaterialDimensionSearchSpecification) => void;
    widthRequired?: boolean;
    thicknessRequired?: boolean;
    forceValidation?: boolean;
    lengthRequired?: boolean;
    setIsWidthRange?: (isRange: boolean) => void;
    setIsLengthRange?: (isRange: boolean) => void;
}

function clearValue(value: string): string | undefined {
    return value.length === 0 ? undefined : value;
}

export const MaterialDimensionSearchSelection = (props: MaterialDimensionSearchSelectionProps) => {
    const { t } = useTranslation(['domainModel']);
    const {
        thicknessRequired,
        widthRequired,
        lengthRequired,
        forceValidation,
        dimensions,
        setDimensions,
        product,
        setIsWidthRange,
        setIsLengthRange,
    } = props;
    const { thickness, width, minWidth, maxWidth, length, minLength, maxLength } = dimensions;
    const [showWidthRanges, setShowWidthRanges] = useState<boolean>(dimensions.minWidth !== undefined);
    const [showLengthRanges, setShowLengthRanges] = useState<boolean>(dimensions.minLength !== undefined);
    const requiredFieldValidator = useRequiredFieldValidator();
    const numberValidator = useNumberValidator();
    const isLengthProduct = ProductsWithLength.includes(product);

    const updateDimensions = (newDimensions: MaterialDimensionSearchSpecification) => {
        setDimensions({
            ...dimensions,
            ...newDimensions,
        });
    };

    useEffect(() => {
        if (length !== undefined && !isLengthProduct) {
            updateDimensions({ length: undefined });
        }
    }, [length, isLengthProduct]);

    const updateWidthRange = (isRange: boolean) => {
        setShowWidthRanges(isRange);
        setIsWidthRange?.(isRange);
    };

    const updateLengthRange = (isRange: boolean) => {
        setShowLengthRanges(isRange);
        setIsLengthRange?.(isRange);
    };

    return (
        <FormItem header={t('domainModel:material.dimensions.name')}>
            <Notification
                level={NotificationLevel.INFO}
                message={t('domainModel:material.dimensions.dimensionsNotification')}
            />
            <InputTextfieldUnit
                unit={t('domainModel:material.dimensions.units.millimeters')}
                label={t('domainModel:material.dimensions.thickness')}
                name="thickness"
                value={thickness ?? ''}
                onChange={(value) => updateDimensions({ thickness: clearValue(value) })}
                validators={thicknessRequired ? [requiredFieldValidator, numberValidator] : [numberValidator]}
                forceValidation={forceValidation}
                testId="thickness-input"
                isMandatory={thicknessRequired}
            />
            <InputTextfieldCombination label={t('domainModel:material.dimensions.width')} isMandatory={widthRequired}>
                {!showWidthRanges && (
                    <>
                        <InputTextfieldUnit
                            unit={t('domainModel:material.dimensions.units.millimeters')}
                            name="width"
                            value={width ?? ''}
                            onChange={(value) => updateDimensions({ width: clearValue(value) })}
                            validators={widthRequired ? [requiredFieldValidator, numberValidator] : [numberValidator]}
                            forceValidation={forceValidation}
                            testId="width-input"
                        />
                        <div>
                            <ButtonGhostSystemOnLightM
                                label={t('domainModel:material.dimensions.addRange')}
                                onClick={() => {
                                    updateDimensions({ width: undefined });
                                    updateWidthRange(true);
                                }}
                            />
                        </div>
                    </>
                )}
                {showWidthRanges && (
                    <>
                        <InputTextfieldUnit
                            unit={t('domainModel:material.dimensions.units.millimeters')}
                            label={t('domainModel:material.dimensions.min')}
                            name="minWidth"
                            value={minWidth ?? ''}
                            onChange={(value) =>
                                updateDimensions({
                                    minWidth: clearValue(value),
                                })
                            }
                            validators={[requiredFieldValidator, numberValidator]}
                            forceValidation
                            testId="min-width-input"
                        />

                        <InputTextfieldUnit
                            unit={t('domainModel:material.dimensions.units.millimeters')}
                            label={t('domainModel:material.dimensions.max')}
                            name="maxWidth"
                            value={maxWidth ?? ''}
                            onChange={(value) =>
                                updateDimensions({
                                    maxWidth: clearValue(value),
                                })
                            }
                            validators={[requiredFieldValidator, numberValidator]}
                            forceValidation
                            required={widthRequired}
                            testId="max-width-input"
                        />
                        <ButtonGhostSystemOnLightM
                            label={t('domainModel:material.dimensions.deleteRange')}
                            onClick={() => {
                                updateWidthRange(false);
                                updateDimensions({
                                    minWidth: undefined,
                                    maxWidth: undefined,
                                });
                            }}
                            className="delete-range-button"
                        />
                    </>
                )}
            </InputTextfieldCombination>
            {showWidthRanges && (
                <Notification
                    level={NotificationLevel.WARNING}
                    message={t('domainModel:material.dimensions.rangeNotification')}
                />
            )}
            {isLengthProduct ? (
                <>
                    <InputTextfieldCombination
                        label={t('domainModel:material.dimensions.length')}
                        isMandatory={lengthRequired}
                    >
                        {!showLengthRanges && (
                            <>
                                <InputTextfieldUnit
                                    unit={t('domainModel:material.dimensions.units.millimeters')}
                                    name="length"
                                    value={length ?? ''}
                                    onChange={(value) =>
                                        updateDimensions({
                                            length: clearValue(value),
                                        })
                                    }
                                    validators={
                                        lengthRequired ? [requiredFieldValidator, numberValidator] : [numberValidator]
                                    }
                                    forceValidation={forceValidation}
                                    testId="length-input"
                                />
                                <div>
                                    <ButtonGhostSystemOnLightM
                                        label={t('domainModel:material.dimensions.addRange')}
                                        onClick={() => {
                                            updateDimensions({ length: undefined });
                                            updateLengthRange(true);
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        {showLengthRanges && (
                            <>
                                <InputTextfieldUnit
                                    unit={t('domainModel:material.dimensions.units.millimeters')}
                                    label={t('domainModel:material.dimensions.min')}
                                    name="minLength"
                                    value={minLength ?? ''}
                                    onChange={(value) =>
                                        updateDimensions({
                                            minLength: clearValue(value),
                                        })
                                    }
                                    validators={[requiredFieldValidator, numberValidator]}
                                    forceValidation
                                    testId="min-length-input"
                                />
                                <InputTextfieldUnit
                                    unit={t('domainModel:material.dimensions.units.millimeters')}
                                    label={t('domainModel:material.dimensions.max')}
                                    name="maxLength"
                                    value={maxLength ?? ''}
                                    onChange={(value) =>
                                        updateDimensions({
                                            maxLength: clearValue(value),
                                        })
                                    }
                                    validators={[requiredFieldValidator, numberValidator]}
                                    forceValidation
                                    testId="max-length-input"
                                />
                                <ButtonGhostSystemOnLightM
                                    label={t('domainModel:material.dimensions.deleteRange')}
                                    onClick={() => {
                                        updateDimensions({
                                            minLength: undefined,
                                            maxLength: undefined,
                                        });
                                        updateLengthRange(false);
                                    }}
                                    className="delete-range-button"
                                />
                            </>
                        )}
                    </InputTextfieldCombination>
                    {showLengthRanges && (
                        <Notification
                            level={NotificationLevel.WARNING}
                            message={t('domainModel:material.dimensions.rangeNotification')}
                        />
                    )}
                </>
            ) : null}
        </FormItem>
    );
};
