import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { TrackingEvent, useAnalytics } from '@steelbuy/analytics';
import { restartDraftListing, useListingViews, useUpdateListing, useUserSelfDetails } from '@steelbuy/data-access';
import { ActionStatus, useListingSellerEntityData } from '@steelbuy/data-provider';
import { NotFoundError } from '@steelbuy/error';
import {
    getPricingUnit,
    getTradeUnitCalculation,
    isStackableProduct,
    ListingSellerDraftModel,
    CurrencyCode,
    formatCurrency,
    ListingSellerModel,
    ListingStatus,
    NegotiationModel,
    OfferListingStatus,
    OfferView,
    Product,
    TradeUnitCalculation,
    UserRole,
    createDateFromTimestamp,
    createTimestampFromDate,
    MonetaryAmount,
} from '@steelbuy/ts-shared';

import {
    CustomPrompt,
    ListingDetailsExpiration,
    ListingDetailsExpirationView,
    ListingDetailsStatus,
    ListingDetailsViews,
    MarketingBannerSteelbuyPromise,
    MonetaryAmountInput,
    MaterialPriceInput,
    useNumberOfItemsLabel,
} from '@steelbuy/ui-domain';

import {
    AddressIdentifier,
    Card,
    CardLayout,
    CardSellerSummaryWeightPrice,
    LoadingSpinnerOverlay,
    LoadingStatus,
    Notification,
    NotificationLevel,
    useSuccessErrorNotification,
} from '@steelbuy/ui-primitive';
import { ListingsRejectionCard } from './ListingsRejectionCard';
import { RenewListingSuccess } from './RenewListingSuccess';
import { SellerActionCta } from './SellerActionCta';
import { UpdateListingCheckboxes } from './UpdateListingCheckboxes';
import { createRouteUrl, RoutePath } from '../../router/Routes';

import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { scrollMainLayout } from '../app-layout/app-main-layout/AppMainLayout';
import { CommonListingDetails } from '../common-listing-details/CommonListingDetails';
import { AUTO_RENEW_WINDOW_MS, getMonetaryAmount } from '../common-utils/AutoRenewUtils';
import { useCreateListingContext } from '../create-listing/CreateListingContext';
import { HistoricalAddressDetails } from '../historical-address-details/HistoricalAddressDetails';
import { MaterialPageHeader } from '../material-page-header/MaterialPageHeader';
import { Negotiations, hasActiveOffer } from '../offers/Negotiations';

import './SellerListingDetails.scss';

interface SellerListingDetailsProps {
    listingId: string;
    goBackPath: string;
}

export const SellerListingDetails = ({ listingId, goBackPath }: SellerListingDetailsProps) => {
    const { t } = useTranslation(['translation', 'uiDomain', 'domainModel']);
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const { data: userData } = useUserSelfDetails();
    const listingEntityData = useListingSellerEntityData();
    const createListingContext = useCreateListingContext();
    const materialData = createListingContext.materialStepData;
    const isPackageListing = materialData.product === Product.PACKAGE;

    const { updateListing } = useUpdateListing();
    const { data: listingViews, isLoading: isListingViewsLoading } = useListingViews({
        listingId,
        queryOptions: {
            enabled: true,
        },
    });
    const [loading, setLoading] = useState(false);
    const {
        Notification: UpdateNotification,
        showSuccessNotification,
        showErrorNotification,
    } = useSuccessErrorNotification();
    const [clearAutoRenewFields, setClearAutoRenewFields] = useState(0); // hack to force remount of MaterialPriceInput

    const hasSellerRole = userData?.roles.includes(UserRole.SELLER) ?? false;

    const listingModel = listingEntityData
        .query()
        .getOrThrow(new NotFoundError(t('translation:application.listingNotFound.notFoundMessage')));
    const numberOfItemsLabel = useNumberOfItemsLabel(listingModel.material.product);
    const serviceListing = listingEntityData.queryServiceEntity().getOrUndefined() as
        | ListingSellerDraftModel
        | ListingSellerModel
        | undefined;

    const isExpired = listingModel.status.value === ListingStatus.EXPIRED;
    const isRenewable = isExpired && hasSellerRole;
    const isRejected = listingModel.status.value === ListingStatus.REVIEW_REJECTED;
    const isPurchased = listingModel.status.value === ListingStatus.PURCHASED;
    const isWithdrawn = listingModel.status.value === ListingStatus.WITHDRAWN;
    const { pathname } = useLocation();
    const isOffer = pathname.includes('manage-offers');

    const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false);

    const [sellingPrice, setSellingPrice] = useState<MonetaryAmount | undefined>(listingModel.pricing.netPricePerUnit);
    const [autoRenewPrice, setAutoRenewPrice] = useState<MonetaryAmount | undefined>(
        getMonetaryAmount(listingModel.autoRenew?.price)
    );

    const [expiryDate, setExpiryDate] = useState<Date | undefined>(createDateFromTimestamp(listingModel.expiresAt));
    const [autoRenewExpiryDate, setAutoRenewExpiryDate] = useState<Date | undefined>(
        listingModel.autoRenew.expiresAt ? createDateFromTimestamp(listingModel.autoRenew.expiresAt) : undefined
    );
    const [autoRenew, setAutoRenew] = useState<boolean>(listingModel.autoRenew.enabled);

    const actionStatus =
        listingEntityData.queryActionStatus() === ActionStatus.SERVICE_PENDING
            ? LoadingStatus.PENDING
            : LoadingStatus.IDLE;

    listingEntityData.useActionStatusEffect(
        [ActionStatus.SERVICE_SUCCESS],
        () => {
            if (!serviceListing) {
                return;
            }

            if ('status' in serviceListing && serviceListing.status.value === ListingStatus.PUBLISHED) {
                setShowSuccessPage(true);
                setLoading(false);
            }
        },
        true
    );
    listingEntityData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            showErrorNotification(t('translation:application.sellerListingDetails.updateError'));
            setLoading(false);
        },
        false
    );

    const createSameListing = () => {
        if (isOffer) {
            navigate(RoutePath.CREATE_LISTING_WIZARD, { state: { createListingData: listingModel } });
        } else {
            createListingContext.copyFromListing(listingModel);
            navigate(RoutePath.CREATE_LISTING_WIZARD);
        }
    };

    const editRejectedListing = async () => {
        if (!isRejected) {
            return;
        }

        const draftListing = await restartDraftListing(listingModel.id);
        createListingContext.loadFromRejectedListing(draftListing, listingModel.id);
        navigate(
            createRouteUrl(
                RoutePath.CREATE_LISTING_WIZARD_WITH_DRAFT,
                ['draftId', draftListing.id],
                ['listingType', isPackageListing ? 'package' : 'listing']
            )
        );
    };

    const renewExpiredListing = () => {
        if (!isExpired || !expiryDate) {
            return;
        }
        setLoading(true);
        listingEntityData.entityService(listingModel.id, 'renewal', {
            pricing: {
                netPricePerUnit: sellingPrice,
                currencyCode: listingModel.pricing.currencyCode,
            },
            expiresAt: createTimestampFromDate(expiryDate),
            autoRenew: {
                enabled: autoRenew,
            },
        });
    };

    const [agreedToTAndC, setAgreedToTAndC] = useState<boolean>(false);
    const [agreedAuthorised, setAgreedAuthorised] = useState<boolean>(false);
    const autoRenewTimestamp = autoRenewExpiryDate ? createTimestampFromDate(autoRenewExpiryDate) : undefined;
    const autoRenewTimestampChanged =
        (autoRenewTimestamp && !listingModel.autoRenew.expiresAt) ||
        (autoRenewTimestamp &&
            listingModel.autoRenew.expiresAt &&
            new Date(autoRenewTimestamp.value).getTime() !==
                new Date(listingModel.autoRenew.expiresAt.value).getTime());
    const isDirtyFields = !!(
        autoRenewPrice?.value !== listingModel.autoRenew.price ||
        autoRenew !== listingModel.autoRenew.enabled ||
        autoRenewTimestampChanged
    );
    const checkBoxChecked = agreedToTAndC && agreedAuthorised;

    const isActiveOffer = hasActiveOffer(listingModel.negotiations);

    const isPublishDisabled = () => {
        if (!checkBoxChecked) {
            return true;
        }
        if (sellingPrice === undefined) {
            return true;
        }
        return expiryDate === undefined;
    };

    let listingDetailsExpirationViewMode: ListingDetailsExpirationView;
    if (!hasSellerRole || isPurchased || isRejected || isWithdrawn) {
        listingDetailsExpirationViewMode = ListingDetailsExpirationView.READ;
    } else if (isExpired) {
        listingDetailsExpirationViewMode = ListingDetailsExpirationView.EXPIRED;
    } else if (new Date(listingModel.expiresAt.value).getTime() - new Date().getTime() < AUTO_RENEW_WINDOW_MS) {
        listingDetailsExpirationViewMode = ListingDetailsExpirationView.LIVE_EDITABLE;
    } else {
        listingDetailsExpirationViewMode = ListingDetailsExpirationView.READ;
    }

    const pricingUnit = getPricingUnit(listingModel.material);

    const resetFields = () => {
        setSellingPrice(listingModel.pricing.netPricePerUnit);
        setAutoRenewPrice(getMonetaryAmount(listingModel.autoRenew?.price));
        setAutoRenewExpiryDate(
            listingModel.autoRenew.expiresAt ? createDateFromTimestamp(listingModel.autoRenew.expiresAt) : undefined
        );
        setAutoRenew(listingModel.autoRenew.enabled);
        setClearAutoRenewFields(clearAutoRenewFields + 1);
    };

    const sendAnalytics = () => {
        if (autoRenew !== listingModel.autoRenew.enabled) {
            analytics.sendEvent(TrackingEvent.LISTING_AUTORENEW_TOGGLED, {
                ListingId: listingModel.id,
                enabled: autoRenew,
                toggledAt: new Date().toISOString(),
                expiresAt: listingModel.expiresAt.value,
            });
        }
        if (autoRenewExpiryDate && autoRenewTimestampChanged) {
            analytics.sendEvent(TrackingEvent.LISTING_EXPIRY_UPDATE, {
                ListingId: listingModel.id,
                oldExpiry: listingModel.expiresAt.value,
                newExpiry: autoRenewExpiryDate.toISOString(),
            });
        }
        if (autoRenewPrice?.value && autoRenewPrice?.value !== listingModel.autoRenew.price) {
            analytics.sendEvent(TrackingEvent.LISTING_PRICE_UPDATE, {
                ListingId: listingModel.id,
                oldPrice: listingModel.pricing.netPricePerUnit.value,
                newPrice: autoRenewPrice.value,
            });
        }
    };

    const updateAutoRenewFields = async () => {
        if (autoRenewExpiryDate || autoRenewPrice || autoRenew !== listingModel.autoRenew.enabled) {
            try {
                setLoading(true);
                await updateListing(listingModel.id, {
                    autoRenew: {
                        enabled: autoRenew,
                        ...(autoRenewPrice?.value && { price: autoRenewPrice.value }),
                        ...(autoRenewExpiryDate && { expiresAt: autoRenewExpiryDate }),
                    },
                });
                showSuccessNotification(t('translation:application.sellerListingDetails.updateSuccess'));
                listingEntityData.resolveFetchStatus();
                sendAnalytics();
                setLoading(false);
                scrollMainLayout(0, 0);
            } catch (e) {
                showErrorNotification(t('translation:application.sellerListingDetails.updateError'));
                setLoading(false);
            }
        }
    };

    const renderButtons = () => (
        <SellerActionCta
            actionStatus={actionStatus}
            checkBoxChecked={checkBoxChecked}
            createSameListing={createSameListing}
            detailsExpirationViewMode={listingDetailsExpirationViewMode}
            editRejectedListing={editRejectedListing}
            hasSellerRole={hasSellerRole}
            isDirtyFields={isDirtyFields}
            isExpired={isExpired}
            isPublishDisabled={isPublishDisabled}
            isRejected={isRejected}
            isRenewable={isRenewable}
            renewExpiredListing={renewExpiredListing}
            resetFields={resetFields}
            updateAutoRenewFields={updateAutoRenewFields}
        />
    );

    const refetchListingDetails = (
        notificationMessage?: string,
        errorNotificationMessage?: string,
        forceRefetch?: boolean
    ) => {
        if (notificationMessage) {
            showSuccessNotification(notificationMessage);
        } else if (errorNotificationMessage) {
            showErrorNotification(errorNotificationMessage);
        }
        if (!errorNotificationMessage || forceRefetch) {
            listingEntityData.resolveFetchStatus();
        }
    };

    const getNumberActiveOffers = (negotiations: NegotiationModel[] | undefined) =>
        negotiations?.filter(
            ({ status }) =>
                status === OfferListingStatus.AWAITING_BUYER || status === OfferListingStatus.AWAITING_SELLER
        ).length || 0;

    const weightOrItems =
        getTradeUnitCalculation(listingModel.material) === TradeUnitCalculation.BY_WEIGHT
            ? listingModel.weight
            : listingModel.numberOfItems || 0;
    return (
        <>
            {(loading || isListingViewsLoading) && <LoadingSpinnerOverlay />}
            <MaterialPageHeader
                material={listingModel.material}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={() => navigate(goBackPath)}
            />

            <div className="seller-listing-details">
                {showSuccessPage ? (
                    <RenewListingSuccess />
                ) : (
                    <DetailsLayout>
                        {UpdateNotification}

                        <Negotiations
                            negotiations={listingModel.negotiations}
                            view={OfferView.SELLER}
                            onStatusChange={refetchListingDetails}
                            pricingUnit={getPricingUnit(listingModel.material)}
                            weightOrItems={weightOrItems}
                            numberOffers={listingModel.negotiations?.length || 0}
                            numberActiveOffers={getNumberActiveOffers(listingModel?.negotiations)}
                            minimumPrice={0.01}
                        />

                        <ListingDetailsStatus status={listingModel.status}>
                            {isRenewable && (
                                <Notification
                                    level={NotificationLevel.INFO}
                                    message={t('uiDomain:listingDetailsStatus.expiredInfo')}
                                />
                            )}
                        </ListingDetailsStatus>

                        {isRejected && (
                            <ListingsRejectionCard
                                internalSellerSku={listingModel.internalSellerSku}
                                reason={listingModel.status.reason ?? ''}
                            />
                        )}

                        {listingViews && listingViews.numberOfViews > 0 && (
                            <ListingDetailsViews views={listingViews.numberOfViews} />
                        )}

                        <CommonListingDetails
                            listingModel={listingModel}
                            internalSellerSku={listingModel.internalSellerSku}
                        />
                        <CardSellerSummaryWeightPrice
                            header={t(
                                `translation:application.sellerListingDetails.weightAndSellingPrice.${
                                    isActiveOffer ? 'offerTitle' : 'title'
                                }`
                            )}
                            renderPrice={(isEditable) => {
                                if (isEditable) {
                                    return (
                                        <MonetaryAmountInput
                                            initialMonetaryAmount={sellingPrice}
                                            currencyCode={listingModel.pricing.currencyCode}
                                            updateMonetaryAmount={setSellingPrice}
                                            label=""
                                            name="sellingPrice"
                                            required
                                            pricingUnit={getPricingUnit(listingModel.material)}
                                        />
                                    );
                                }
                                if (listingModel.pricing.netPricePerUnit) {
                                    return `${formatCurrency(
                                        navigator.language,
                                        listingModel.pricing.netPricePerUnit.value,
                                        listingModel.pricing.currencyCode
                                    )} ${t('domainModel:material.perPricingUnit', {
                                        pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
                                    })}`;
                                }

                                return null;
                            }}
                            weightLabel={t('translation:application.sellerListingDetails.weightAndSellingPrice.weight')}
                            weight={t(`domainModel:material.tradeUnit.value.${listingModel.tradeUnit}.quantity`, {
                                count: listingModel.weight,
                            })}
                            numberOfItems={
                                isStackableProduct(listingModel.material.product) && listingModel.numberOfItems
                                    ? listingModel.numberOfItems.toString()
                                    : undefined
                            }
                            itemsLabel={numberOfItemsLabel}
                            isEditable={isRenewable}
                            isAutoRenewPriceEditable={
                                autoRenew &&
                                listingDetailsExpirationViewMode === ListingDetailsExpirationView.LIVE_EDITABLE
                            }
                        >
                            {listingDetailsExpirationViewMode === ListingDetailsExpirationView.LIVE_EDITABLE &&
                                autoRenew && (
                                    <MaterialPriceInput
                                        header={t(
                                            'translation:application.sellerListingDetails.weightAndSellingPrice.newSellingPriceHeader',
                                            {
                                                tradeUnit: t(
                                                    `domainModel:material.pricingUnit.value.${pricingUnit}.label`
                                                ),
                                            }
                                        )}
                                        priceCurrencyCode={CurrencyCode.GBP}
                                        initialPrice={autoRenewPrice}
                                        setPrice={setAutoRenewPrice}
                                        required
                                        pricingUnit={getPricingUnit(listingModel.material)}
                                        key={clearAutoRenewFields}
                                        name="sellingPrice"
                                    />
                                )}
                            {!isPurchased && (
                                <Notification
                                    level={NotificationLevel.INFO}
                                    message={t(
                                        `translation:application.sellerListingDetails.weightAndSellingPrice.${
                                            isActiveOffer ? 'offerNotification' : 'notification'
                                        }`
                                    )}
                                />
                            )}
                        </CardSellerSummaryWeightPrice>

                        {!isPurchased && (
                            <ListingDetailsExpiration
                                expiryDate={expiryDate}
                                onExpiryDateChange={setExpiryDate}
                                autoRenew={autoRenew}
                                showAutoRenew={!isWithdrawn && !isRejected}
                                onAutoRenewChange={setAutoRenew}
                                autoRenewExpiryDate={autoRenewExpiryDate}
                                onAutoRenewExpiryChange={setAutoRenewExpiryDate}
                                viewMode={listingDetailsExpirationViewMode}
                                key={clearAutoRenewFields}
                            >
                                {(isRenewable ||
                                    listingDetailsExpirationViewMode ===
                                        ListingDetailsExpirationView.LIVE_EDITABLE) && (
                                    <Notification
                                        level={NotificationLevel.INFO}
                                        message={t(
                                            'translation:application.createListingSummary.expirationNotificationMessage'
                                        )}
                                    />
                                )}
                            </ListingDetailsExpiration>
                        )}

                        <Card isClickable={false}>
                            <CardLayout>
                                <HistoricalAddressDetails
                                    addressId={listingModel.pickupAddress.id}
                                    type={AddressIdentifier.PICKUP_ADDRESS}
                                    additionalText={listingModel.additionalInformation}
                                    labelText={t('application.addressDetails.collectionInformationLabel')}
                                />
                            </CardLayout>
                        </Card>

                        <MarketingBannerSteelbuyPromise />

                        <UpdateListingCheckboxes
                            isRenewable={isRenewable}
                            DetailsExpirationViewMode={listingDetailsExpirationViewMode}
                            agreedAuthorised={agreedAuthorised}
                            agreedToTAndC={agreedToTAndC}
                            setAgreedAuthorised={setAgreedAuthorised}
                            setAgreedToTAndC={setAgreedToTAndC}
                        />

                        {renderButtons()}
                    </DetailsLayout>
                )}
            </div>
            <CustomPrompt
                message={t('translation:application.sellerListingDetails.listingHasUnsavedChanges')}
                shouldPrompt={
                    isDirtyFields && listingDetailsExpirationViewMode === ListingDetailsExpirationView.LIVE_EDITABLE
                }
                onDiscard={(done) => {
                    resetFields();
                    done();
                }}
            />
        </>
    );
};
