import { HistoricalAddressCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { AddressModel, Nullable } from '@steelbuy/ts-shared';
import {
    createCrudEntitySlice,
    createSelectCreated,
    createSelectEntity,
    createSelectServiceEntity,
} from '../../util/store/crud/EntitySlice';
import { EntityStore } from '../../util/store/crud/EntityStore';
import { EntityStoreAccessor } from '../../util/store/crud/EntityStoreAccessor';

import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchEntityThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const historicalAddressEntityStoreName = 'historical-address-entity';
export type HistoricalAddressEntityStore = {
    [historicalAddressEntityStoreName]: EntityStore<AddressModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new HistoricalAddressCrudApiClient().init(apiBaseUrl, jsonWebToken);
const fetch = createFetchEntityThunk<AddressModel, HistoricalAddressEntityStore, HistoricalAddressCrudApiClient>(
    historicalAddressEntityStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<AddressModel, HistoricalAddressCrudApiClient>(
    historicalAddressEntityStoreName,
    createApiClient
);
const createEntity = createCreateThunk<AddressModel, HistoricalAddressCrudApiClient>(
    historicalAddressEntityStoreName,
    createApiClient
);
const deleteEntity = createDeleteThunk<AddressModel, HistoricalAddressCrudApiClient>(
    historicalAddressEntityStoreName,
    createApiClient
);
const entityService = createEntityServiceThunk<AddressModel, HistoricalAddressCrudApiClient>(
    historicalAddressEntityStoreName,
    createApiClient
);

// Slice definition
export const historicalAddressEntitySlice = createCrudEntitySlice<AddressModel>(
    historicalAddressEntityStoreName,
    fetch,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetActionStatus, resetFetchStatus } = historicalAddressEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<AddressModel, HistoricalAddressEntityStore>(historicalAddressEntityStoreName);
const selectCreated = createSelectCreated<AddressModel, HistoricalAddressEntityStore>(historicalAddressEntityStoreName);
const selectServiceEntity = createSelectServiceEntity<AddressModel, HistoricalAddressEntityStore>(
    historicalAddressEntityStoreName
);

// Export the store accessors to be used by the data provider value
export const historicalAddressEntityStoreAccessors = {
    fetch,
    resetFetchStatus,
    mutateEntity,
    createEntity,
    deleteEntity,
    resetActionStatus,
    select,
    selectCreated,
    entityService,
    selectServiceEntity,
} as EntityStoreAccessor<AddressModel, HistoricalAddressEntityStore>;
