import { useTranslation } from 'react-i18next';
import { Country } from '@steelbuy/ts-shared';
import { CountryIcon } from '@steelbuy/ui-primitive';

type ListingTeaserCartLocationsProps = {
    materialLocations: Country[];
};

export const ListingTeaserCartLocations = ({ materialLocations }: ListingTeaserCartLocationsProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);

    return (
        <div className="material-location">
            <span className="material-location__label"> {t('listingTeaserContainer.materialLocation')}</span>
            <div className="material-location__wrapper">
                {materialLocations.map((location) => (
                    <div className="material-location__value" key={location}>
                        <span className="material-location__value-country">
                            {t(`domainModel:address.country.${location}`)}
                        </span>
                        <CountryIcon code={location} />
                    </div>
                ))}
            </div>
        </div>
    );
};
