import { useMemo } from 'react';
import { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Select, InputType } from '@steelbuy/form';
import { FormItem } from '@steelbuy/ui-primitive';
import { formatDate, formatDayname } from '@steelbuy/util';

interface RFQDeadlineSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    name: TName;
    control: Control<TFieldValues>;
    error?: FieldError;
}

const SATURDAY = 0;
const SUNDAY = 6;
const NO_OF_DAYS = 10;
const END_OF_DAY = {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 999,
};

const getEndOfDay = (currentDate: Date) => {
    currentDate.setHours(END_OF_DAY.hours);
    currentDate.setMinutes(END_OF_DAY.minutes);
    currentDate.setSeconds(END_OF_DAY.seconds);
    currentDate.setMilliseconds(END_OF_DAY.milliseconds);
    return currentDate.getTime();
};

const calculateNextWorkingDays = (currentDate: Date) => {
    const nextWorkingDays = [];

    while (nextWorkingDays.length < NO_OF_DAYS) {
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day

        // Check if it's a weekend (Saturday or Sunday)
        if (currentDate.getDay() !== SATURDAY && currentDate.getDay() !== SUNDAY) {
            const optionValue = getEndOfDay(currentDate);
            const optionLabel = `${formatDayname(navigator.language, currentDate)}, ${formatDate(
                navigator.language,
                currentDate
            )}`;
            nextWorkingDays.push({ value: optionValue, label: optionLabel });
        }
    }

    return nextWorkingDays;
};

export const RFQDeadlineSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    name,
    control,
    error,
}: RFQDeadlineSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['domainModel', 'uiDomain', 'translation']);
    const currentDate = new Date();

    const options = useMemo(() => calculateNextWorkingDays(currentDate), [currentDate.getDay()]);

    return (
        <FormItem
            header={t('translation:application.createRFQ.quoteDeadline.header')}
            description={t('translation:application.createRFQ.quoteDeadline.description')}
            isMandatory
        >
            <Select
                options={options}
                placeholder={t(`translation:application.createRFQ.selectPlaceholder`)}
                name={name}
                control={control}
                rules={{ required: t(`translation:application.createRFQ.fieldRequired`) }}
                error={error?.message}
                type={InputType.BLOCK}
            />
        </FormItem>
    );
};
