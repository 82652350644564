import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SellerListingOfferStatus, OfferView, Country, Product, TradeUnit } from '@steelbuy/ts-shared';
import {
    BadgeSecondary,
    BadgeState,
    BadgeVariant,
    Icon,
    IconIdentifier,
    IdentificationAttribute,
    IdentificationNumber,
    MaterialLocation,
    Tag,
    TagVariant,
} from '@steelbuy/ui-primitive';
import { useWindowSize } from '@steelbuy/util';
import { ListingTeaserOfferBadges } from './ListingTeaserOfferBadges';

type CartValues = {
    weight: { value: number; tradeUnit: TradeUnit };
    origin: string;
    age?: string;
    noOfItems?: { label: Product.PLATE | Product.SHEET | Product.TREAD; value: number };
};

export type ListingTeaserContainerProps = {
    isPrime?: boolean;
    expiryTime?: string;
    statusBadgeVariant?: BadgeVariant;
    statusLabel?: string;
    deliveryTime?: string;
    steelBuyPurchaseOrderNumber?: string;
    buyerPurchaseOrderNumber?: string;
    sellerMaterialId?: string;
    lastModified?: string;
    hideStatusNonMaxSize?: boolean;
    offerStatus?: SellerListingOfferStatus;
    numberOffers?: number;
    numberActiveOffers?: number;
    referenceId?: string;
    offerView?: OfferView;
    minActiveOffersForBadge?: number;
    countryCode?: Country;
    cartValues?: CartValues;
};

export const ListingTeaserContainer = ({
    buyerPurchaseOrderNumber,
    deliveryTime,
    expiryTime,
    isPrime,
    sellerMaterialId,
    statusBadgeVariant,
    statusLabel,
    steelBuyPurchaseOrderNumber,
    lastModified,
    hideStatusNonMaxSize = false,
    offerStatus,
    numberOffers,
    numberActiveOffers,
    referenceId,
    offerView,
    minActiveOffersForBadge,
    countryCode,
    cartValues,
}: ListingTeaserContainerProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel', 'translation']);
    const { width } = useWindowSize();
    const SHOW_SMALL_LABEL_SCREEN_SIZE = 800;

    return (
        <div className="listing-teaser__container">
            <div className="listing-teaser__container__prime-and-identification">
                {isPrime === false && <Tag label="Non-Prime" variant={TagVariant.NON_PRIME} />}
                {isPrime && <Tag label="Prime" icon={IconIdentifier.PRIME} variant={TagVariant.PRIME} />}
                {!offerStatus && steelBuyPurchaseOrderNumber !== undefined && steelBuyPurchaseOrderNumber !== '' && (
                    <IdentificationNumber
                        identificationNumber={steelBuyPurchaseOrderNumber}
                        label={t('listingTeaserContainer.steelBuyPurchaseOrderNumber')}
                    />
                )}
                {!offerStatus && buyerPurchaseOrderNumber !== undefined && buyerPurchaseOrderNumber !== '' && (
                    <IdentificationNumber
                        identificationNumber={buyerPurchaseOrderNumber}
                        label={t('listingTeaserContainer.buyerPurchaseOrderNumber')}
                    />
                )}
                {!offerStatus && sellerMaterialId !== undefined && sellerMaterialId !== '' && (
                    <IdentificationNumber
                        identificationNumber={sellerMaterialId}
                        label={t('listingTeaserContainer.sellerMaterialId')}
                    />
                )}
                {lastModified && (
                    <>
                        <div className="listing-teaser__container__break" />
                        <div className="listing-teaser__container__last-modified">
                            <span className="listing-teaser__container__last-modified__label">
                                <BadgeState label={lastModified} variant={BadgeVariant.NEUTRAL} />
                            </span>
                        </div>
                    </>
                )}
                {width < SHOW_SMALL_LABEL_SCREEN_SIZE && referenceId && (
                    <span className="listing-teaser__container__reference">
                        {t('listingTeaserContainer.negotiationReference')}
                        <span>{referenceId}</span>
                    </span>
                )}
                {cartValues && (
                    <div className="listing-teaser__container__identification-values">
                        <IdentificationAttribute
                            value={t(`domainModel:material.tradeUnit.value.${cartValues.weight.tradeUnit}.quantity`, {
                                count: cartValues.weight.value,
                            })}
                            label={t('uiDomain:listingTeaserContainer.weight')}
                        />
                        {cartValues.noOfItems && (
                            <IdentificationAttribute
                                value={cartValues.noOfItems.value}
                                label={t(`uiDomain:numberOfItemsLabel.labelShort.${cartValues.noOfItems.label}`)}
                            />
                        )}

                        <IdentificationAttribute
                            value={cartValues.origin}
                            label={t('uiDomain:listingTeaserContainer.origin')}
                        />
                        {cartValues.age && (
                            <IdentificationAttribute
                                value={cartValues.age}
                                label={t('uiDomain:listingTeaserContainer.age')}
                            />
                        )}
                    </div>
                )}
            </div>
            <div className="listing-teaser__container__expiry-state-and-delivery">
                {deliveryTime !== undefined && (
                    <span className="listing-teaser__container__expiry-state-and-delivery__delivery-time">
                        <Icon name={IconIdentifier.DELIVERY} />
                        {deliveryTime}
                    </span>
                )}
                {countryCode && (
                    <MaterialLocation
                        flag={countryCode}
                        value={t(`domainModel:address.country.${countryCode}`)}
                        label={t('listingTeaserContainer.materialLocation')}
                    />
                )}
                {expiryTime !== undefined && !offerStatus && (
                    <BadgeSecondary icon={IconIdentifier.TIMER} label={expiryTime} />
                )}
                <ListingTeaserOfferBadges
                    status={offerStatus}
                    numberOffers={numberOffers}
                    numberActiveOffers={numberActiveOffers}
                    offerView={offerView}
                    minActiveOffersForBadge={minActiveOffersForBadge}
                />
                {!offerStatus && statusLabel !== undefined && statusLabel !== '' && (
                    <span
                        className={classnames('listing-teaser__container__expiry-state-and-delivery__status', {
                            'listing-teaser__container__expiry-state-and-delivery__status--hide': hideStatusNonMaxSize,
                        })}
                    >
                        <BadgeState label={statusLabel} variant={statusBadgeVariant ?? BadgeVariant.NEUTRAL} />
                    </span>
                )}
            </div>
        </div>
    );
};
