import {
    getCoatingColours,
    ListingBuyerModel,
    ListingSellerModel,
    DownloadFile,
    UploadFile,
} from '@steelbuy/ts-shared';
import { ListingDetailsMaterial, ListingDetailsQuality, ListingDetailsDimensions } from '@steelbuy/ui-domain';
import { constants } from '../../constants';

export const CommonListingDetails = ({
    listingModel,
    internalSellerSku,
}: {
    listingModel: ListingBuyerModel | ListingSellerModel;
    internalSellerSku?: string;
}) => (
    <>
        <ListingDetailsMaterial
            {...listingModel.material}
            age={listingModel.age}
            origin={[listingModel.origin]}
            prime={listingModel.prime}
            sku={internalSellerSku}
            coatingColours={getCoatingColours(listingModel.material)}
            countryCode={(listingModel as ListingBuyerModel).country}
        />
        {listingModel.prime ? (
            <ListingDetailsQuality
                prime={listingModel.prime}
                testCertificate={[listingModel.testCertificate]}
                description={listingModel.description}
                apiBaseUrl={constants.apiBaseUrl}
            />
        ) : (
            <ListingDetailsQuality
                prime={listingModel.prime}
                picture={listingModel.picture ? [listingModel.picture] : null}
                testCertificate={
                    listingModel.testCertificate
                        ? [(listingModel as ListingSellerModel).testCertificate as DownloadFile | UploadFile]
                        : null
                }
                description={listingModel.description}
                apiBaseUrl={constants.apiBaseUrl}
            />
        )}
        <ListingDetailsDimensions tolerance={listingModel.material.tolerance} {...listingModel.materialDimensions} />
    </>
);
