import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ListingBuyerModel,
    ListingSellerDraftModel,
    ListingSellerModel,
    PackageBuyerModel,
    PackageSellerDraftModel,
    PackageSellerModel,
    Product,
} from '@steelbuy/ts-shared';
import { Tag, TagVariant } from '@steelbuy/ui-primitive';
import { MaterialProperty } from '../material/Material';

const ListingProperty = ({
    propertyDisplayValue,
    highlighted,
}: {
    propertyDisplayValue: string | number;
    highlighted?: boolean;
}) => {
    const className = highlighted ? 'listing-teaser__header__highlighted' : '';
    return <span className={className}>{propertyDisplayValue}</span>;
};

type TeaserHeaderProps = {
    isNew?: boolean;
    packages?: PackageBuyerModel | PackageSellerModel | PackageSellerDraftModel;
    listing?: ListingBuyerModel | ListingSellerModel | ListingSellerDraftModel;
    highlights?: Record<string, boolean>;
    children?: ReactNode;
    headerProperties: MaterialProperty[];
};

export const TeaserHeader = ({
    isNew,
    packages,
    listing,
    highlights,
    children,
    headerProperties,
}: TeaserHeaderProps) => {
    const { t } = useTranslation('domainModel');
    const renderNewTag = () => {
        if (isNew) {
            return <Tag label="New" variant={TagVariant.NEGATIVE} isVertical />;
        }
        return null;
    };
    return (
        <div className="listing-teaser__header">
            <div
                className={classNames('listing-teaser__header__text', {
                    'listing-teaser__header__text-package': !!packages,
                })}
            >
                <div className="listing-teaser__header__text__head">
                    {headerProperties.map((property, index) => (
                        <Fragment key={`key-attributes-${property.name}`}>
                            {index > 0 && ', '}

                            <ListingProperty
                                propertyDisplayValue={property.label}
                                highlighted={highlights?.[property.name]}
                            />
                        </Fragment>
                    ))}
                </div>

                <div className="listing-teaser__header__text__attributes__wrapper">
                    <div className="listing-teaser__header__text__attributes">
                        {packages && (
                            <ListingProperty propertyDisplayValue={t(`material.product.value.${Product.PACKAGE}`)} />
                        )}
                        {packages?.material && ', '}
                        {packages?.material && (
                            <ListingProperty
                                propertyDisplayValue={t(`material.materialType.value.${packages.material}`)}
                            />
                        )}
                    </div>
                    {listing && (
                        <div className="listing-teaser__header__text__attributes">
                            {listing.material?.product && (
                                <ListingProperty
                                    propertyDisplayValue={t(`material.product.value.${listing.material.product}`)}
                                    highlighted={highlights && highlights['product'] === true}
                                />
                            )}
                            {listing.material?.product && listing.material.materialType && ', '}
                            {listing.material?.materialType && (
                                <ListingProperty
                                    propertyDisplayValue={t(
                                        `material.materialType.value.${listing.material.materialType}`
                                    )}
                                    highlighted={highlights && highlights['materialType'] === true}
                                />
                            )}
                        </div>
                    )}
                    {children}
                </div>
            </div>
            {renderNewTag()}
        </div>
    );
};
