import { RFQDraftModel, RFQDraftModelConverter, ApiModel } from '@steelbuy/ts-shared';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

const modelConverter = new RFQDraftModelConverter();
const draftRFQMapFn = (data: RFQDraftModel) => modelConverter.toViewModel(data as unknown as ApiModel<RFQDraftModel>);

export const useMyRFQsDrafts = () =>
    usePaginatedQuery<RFQDraftModel>({
        url: '/api/buyer/quotes/drafts',
        requestOptions: {},
        mapFn: draftRFQMapFn,
        queryKey: [],
        queryOptions: {
            cacheTime: 0,
        },
    });
