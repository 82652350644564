import { useTranslation } from 'react-i18next';
import { SellerListingOfferStatus, OfferView, OfferListingStatus, CustomOfferListingStatus } from '@steelbuy/ts-shared';
import { BadgeSecondary, BadgeVariant, IconIdentifier, Tag, TagVariant } from '@steelbuy/ui-primitive';

export type ListingTeaserOfferBadgesProps = {
    status?: SellerListingOfferStatus;
    numberOffers?: number;
    numberActiveOffers?: number;
    offerView?: OfferView;
    minActiveOffersForBadge?: number;
};

export const ListingTeaserOfferBadges = ({
    status,
    numberOffers,
    numberActiveOffers,
    offerView,
    minActiveOffersForBadge = 2,
}: ListingTeaserOfferBadgesProps) => {
    const { t } = useTranslation('uiDomain');

    if (!status && !numberOffers) {
        return null;
    }

    const isActiveOffers =
        status === OfferListingStatus.AWAITING_BUYER || status === OfferListingStatus.AWAITING_SELLER;

    const icon = isActiveOffers ? IconIdentifier.TIMER : undefined;

    let statusLabel = '';

    if (status) {
        statusLabel = t(`listingTeaserOfferBadge.status.${status}`);
    }

    const isAwaitingYourResponse =
        (status === OfferListingStatus.AWAITING_BUYER && offerView === OfferView.BUYER) ||
        (status === OfferListingStatus.AWAITING_SELLER && offerView === OfferView.SELLER);

    if (isAwaitingYourResponse) {
        statusLabel = t('listingTeaserOfferBadge.awaitingYourResponse');
    }

    const isBuyerOutbid = status === OfferListingStatus.CANCELLED && offerView === OfferView.BUYER;

    if (isBuyerOutbid) {
        statusLabel = t('listingTeaserOfferBadge.outbid');
    }

    let numberOffersLabel = '';

    if (
        numberOffers &&
        numberOffers >= minActiveOffersForBadge &&
        (!numberActiveOffers || numberActiveOffers >= minActiveOffersForBadge)
    ) {
        if (numberActiveOffers) {
            numberOffersLabel =
                minActiveOffersForBadge === 1 && numberActiveOffers === 1
                    ? t('listingTeaserOfferBadge.numberOffersHasOneLabel', { numberOffers: numberActiveOffers })
                    : t('listingTeaserOfferBadge.numberOffersHasLabel', { numberOffers: numberActiveOffers });
        } else {
            numberOffersLabel = t('listingTeaserOfferBadge.numberOffersHadLabel', { numberOffers });
        }
    }

    const getStatusVariant = () => {
        if (isAwaitingYourResponse) {
            return BadgeVariant.WARNING;
        }
        switch (status) {
            case OfferListingStatus.ACCEPTED:
                return BadgeVariant.POSITIVE;
            case OfferListingStatus.EXPIRED:
                return BadgeVariant.WARNING;
            case OfferListingStatus.AWAITING_BUYER:
            case OfferListingStatus.AWAITING_SELLER:
            case OfferListingStatus.WITHDRAWN:
                return BadgeVariant.NEUTRAL;
            case OfferListingStatus.REJECTED:
            case OfferListingStatus.CANCELLED:
            case CustomOfferListingStatus.NO_SUCCESSFUL_OFFERS:
                return BadgeVariant.NEGATIVE;
            default:
                return BadgeVariant.NEUTRAL;
        }
    };

    return (
        <>
            {status !== undefined && <BadgeSecondary icon={icon} label={statusLabel} variant={getStatusVariant()} />}
            {numberOffersLabel && (
                <Tag icon={IconIdentifier.BELL} label={numberOffersLabel} variant={TagVariant.PRIME} />
            )}
        </>
    );
};
