import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingSellerStatusModel, createDateFromTimestamp } from '@steelbuy/ts-shared';
import { Card, CardContentListingViewState, CardLayout } from '@steelbuy/ui-primitive';
import { formatDate, formatTime } from '@steelbuy/util';
import { getBadgeVariant } from '../status-badge-variant/StatusBadgeVariant';

type ListingDetailsStatusProps = {
    status: ListingSellerStatusModel;
    children?: ReactNode;
};

export const ListingDetailsStatus = ({ children, status: { modifiedAt, value } }: ListingDetailsStatusProps) => {
    const { t } = useTranslation('uiDomain');

    const modifiedDate = createDateFromTimestamp(modifiedAt);

    const badgeVariant = getBadgeVariant(value);
    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentListingViewState
                    header={t(`listingDetailsStatus.header.${value}`)}
                    date={`${formatDate(navigator.language, modifiedDate)}, ${formatTime(
                        navigator.language,
                        modifiedDate
                    )}`}
                    badgeVariant={badgeVariant}
                    badgeLabel={t(`listingSellerStatus.${value}`)}
                />
                {children}
            </CardLayout>
        </Card>
    );
};
