/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FilterCriteria, PreparedSortCriteria, SortCriteria } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { AnyErrorObject } from '@steelbuy/error';
import { Mutable, Mutation, ViewModel, ModelPrimaryKey, Nullable, ReadonlyOptional } from '@steelbuy/ts-shared';

import { ActionStatus } from '../store/ActionStatus';
import { CollectionStore } from '../store/crud/CollectionStore';
import { CollectionStoreAccessors } from '../store/crud/CollectionStoreAccessor';
import { FetchStatus } from '../store/FetchStatus';
import { ModelFilter } from '../store/ModelFilter';
import { ModelSort } from '../store/ModelSort';

export type CollectionDataProviderValue<Model extends ViewModel> = {
    fetch: (sortCriteria?: SortCriteria<Model> | PreparedSortCriteria, filterCriteria?: FilterCriteria<Model>) => void;
    fetchNext: () => void;
    fetchOne: (id: ModelPrimaryKey) => void;
    query: (sort?: ModelSort<Model>, filter?: ModelFilter<Model>) => ReadonlyArray<Model>;
    queryOne: (id: ModelPrimaryKey) => ReadonlyOptional<Model>;
    queryCreated: () => ReadonlyOptional<Model>;
    mutate: (id: ModelPrimaryKey, mutation: Mutation<Model>) => void;
    create: (mutatable: Mutable<Model>) => void;
    delete: (id: ModelPrimaryKey) => void;
    entityService: (id: ModelPrimaryKey, service: string, modelMutation?: Mutation<Model>) => void;
    idle: () => boolean;
    pending: () => boolean;
    hasNextPage: () => boolean;
    isPaginated: () => boolean;
    queryCurrentPage: () => Nullable<number>;
    queryMaxPages: () => Nullable<number>;
    queryTotalItems: () => Nullable<number>;
    isSorted: () => boolean;
    querySortCriteria: () => Nullable<SortCriteria<Model> | PreparedSortCriteria>;
    isFiltered: () => boolean;
    queryFilterCriteria: () => Nullable<FilterCriteria<Model>>;
    queryFetchStatus: () => FetchStatus;
    queryFetchError: () => Nullable<AnyErrorObject>;
    queryActionStatus: () => ActionStatus;
    queryActionError: () => Nullable<AnyErrorObject>;
    resolveFetchStatus: () => void;
    resolveActionStatus: () => void;
    useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void) => void;
    useActionStatusEffect: (status: Array<ActionStatus>, effect: () => void, resolve: boolean) => void;
};

// noinspection JSUnusedLocalSymbols
export const defaultCollectionDataValue: CollectionDataProviderValue<any> = {
    idle: (): boolean => true,
    pending: (): boolean => false,
    hasNextPage: (): boolean => false,
    isPaginated: (): boolean => false,
    queryCurrentPage: (): Nullable<number> => null,
    queryMaxPages: (): Nullable<number> => null,
    queryTotalItems: (): Nullable<number> => null,
    isSorted: (): boolean => false,
    querySortCriteria: (): Nullable<SortCriteria<any> | PreparedSortCriteria> => null,
    isFiltered: (): boolean => false,
    queryFilterCriteria: (): Nullable<FilterCriteria<any>> => null,
    queryFetchStatus: (): FetchStatus => FetchStatus.IDLE,
    queryFetchError: (): Nullable<AnyErrorObject> => null,
    queryActionStatus: (): ActionStatus => ActionStatus.IDLE,
    queryActionError: (): Nullable<AnyErrorObject> => null,
    fetch: (sortCriteria?: SortCriteria<any> | PreparedSortCriteria, filterCriteria?: FilterCriteria<any>): void => {
        throw new Error('Data provider missing');
    },
    fetchNext: (): void => {
        throw new Error('Data provider missing');
    },
    fetchOne: (id: ModelPrimaryKey): void => {
        throw new Error('Data provider missing');
    },
    query: (sort?: ModelSort<never>, filter?: ModelFilter<any>): ReadonlyArray<any> => {
        throw new Error('Data provider missing');
    },
    queryOne: (id: ModelPrimaryKey): ReadonlyOptional<any> => {
        throw new Error('Data provider missing');
    },
    queryCreated: (): ReadonlyOptional<any> => {
        throw new Error('Data provider missing');
    },
    resolveFetchStatus: (): void => {
        throw new Error('Data provider missing');
    },
    resolveActionStatus: (): void => {
        throw new Error('Data provider missing');
    },
    mutate: (id: ModelPrimaryKey, mutation: Mutation<any>): void => {
        throw new Error('Data provider missing');
    },
    create: (mutatable: Mutable<any>): void => {
        throw new Error('Data provider missing');
    },
    delete: (id: ModelPrimaryKey): void => {
        throw new Error('Data provider missing');
    },
    entityService: (id: ModelPrimaryKey, service: string, modelMutation?: Mutation<any>): void => {
        throw new Error('Data provider missing');
    },
    useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void): void => {
        throw new Error('Data provider missing');
    },
    useActionStatusEffect: (status: Array<ActionStatus>, effect: () => void, resolve = false): void => {
        throw new Error('Data provider missing');
    },
};

export const createCollectionDataProviderValue = <
    Model extends ViewModel,
    RootStore extends Record<string, CollectionStore<Model>>
>(
    storeKey: string,
    storeAccessors: CollectionStoreAccessors<Model, RootStore>,
    apiBaseUrl: string,
    jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
    roles?: string[]
): CollectionDataProviderValue<Model> => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();

    const dataProviderValue = {
        idle: (): boolean => dataProviderValue.queryFetchStatus() === FetchStatus.IDLE,
        pending: (): boolean => {
            const fetchStatus = dataProviderValue.queryFetchStatus();
            return fetchStatus === FetchStatus.PENDING || fetchStatus === FetchStatus.PAGING_PENDING;
        },
        hasNextPage: (): boolean => {
            const currentPage = dataProviderValue.queryCurrentPage();
            const maxPages = dataProviderValue.queryMaxPages();
            return (currentPage ?? 1) < (maxPages ?? 1);
        },
        isPaginated: (): boolean => {
            const maxPages = dataProviderValue.queryMaxPages();
            return (maxPages ?? 1) > 1;
        },
        queryCurrentPage: (): Nullable<number> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<number>>((state) => state[storeKey].currentPage),
        queryMaxPages: (): Nullable<number> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<number>>((state) => state[storeKey].maxPages),
        queryTotalItems: (): Nullable<number> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<number>>((state) => state[storeKey].totalItems),
        isSorted: (): boolean => {
            const sortCriteria = dataProviderValue.querySortCriteria();
            return sortCriteria !== null;
        },
        querySortCriteria: (): Nullable<SortCriteria<Model> | PreparedSortCriteria> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<SortCriteria<Model>>>((state) => state[storeKey].sortCriteria ?? null),
        isFiltered: (): boolean => {
            const filterCriteria = dataProviderValue.queryFilterCriteria();
            return filterCriteria !== null;
        },
        queryFilterCriteria: (): Nullable<FilterCriteria<Model>> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<FilterCriteria<Model>>>((state) => state[storeKey].filterCriteria ?? null),
        queryFetchStatus: (): FetchStatus =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, FetchStatus>((state) => state[storeKey].fetchStatus),
        queryFetchError: (): Nullable<AnyErrorObject> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<AnyErrorObject>>((state) => state[storeKey].lastFetchError),
        queryActionStatus: (): ActionStatus =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ActionStatus>((state) => state[storeKey].actionStatus),
        queryActionError: (): Nullable<AnyErrorObject> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<AnyErrorObject>>((state) => state[storeKey].lastActionError),
        fetch: (
            sortCriteria?: SortCriteria<Model> | PreparedSortCriteria,
            filterCriteria?: FilterCriteria<Model>
        ): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.fetchAll({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    sortCriteria,
                    filterCriteria,
                    roles,
                })
            );
        },
        fetchNext: (): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.fetchNext({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    roles,
                })
            );
        },
        fetchOne: (id: ModelPrimaryKey): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.fetchOne({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    id,
                    roles,
                })
            );
        },
        query: (sort?: ModelSort<Model>, filter?: ModelFilter<Model>): ReadonlyArray<Model> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ReadonlyArray<Model>>(storeAccessors.selectCollection(filter, sort)),
        queryOne: (id: ModelPrimaryKey): ReadonlyOptional<Model> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ReadonlyOptional<Model>>(storeAccessors.selectEntity(id)),
        queryCreated: (): ReadonlyOptional<Model> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ReadonlyOptional<Model>>(storeAccessors.selectCreated()),
        resolveFetchStatus: (): void => {
            dispatch(storeAccessors.resetFetchStatus());
        },
        resolveActionStatus: (): void => {
            dispatch(storeAccessors.resetActionStatus());
        },
        mutate: (id: ModelPrimaryKey, modelMutation: Mutation<Model>): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.mutateEntity({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    id,
                    modelMutation,
                    roles,
                })
            );
        },
        create: (mutableModel: Mutable<Model>): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.createEntity({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    mutableModel,
                    roles,
                })
            );
        },
        delete: (id: ModelPrimaryKey): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.deleteEntity({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    id,
                    roles,
                })
            );
        },
        entityService: (id: ModelPrimaryKey, service: string, modelMutation?: Mutation<Model>): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.entityService({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    id,
                    service,
                    modelMutation,
                    roles,
                })
            );
        },
        useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void): void => {
            const fetchStatus = dataProviderValue.queryFetchStatus();
            useEffect(() => {
                if (status.includes(fetchStatus)) {
                    effect();
                }
            }, [fetchStatus]);
        },
        useActionStatusEffect: (status: Array<ActionStatus>, effect: () => void, resolve = false): void => {
            const actionStatus = dataProviderValue.queryActionStatus();
            useEffect(() => {
                if (status.includes(actionStatus)) {
                    try {
                        effect();
                    } finally {
                        if (resolve) {
                            dataProviderValue.resolveActionStatus();
                        }
                    }
                }
            }, [actionStatus]);
        },
    };

    return dataProviderValue;
};
