import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCreatePackageOrder, usePackageDetails, useUserSelfDetails } from '@steelbuy/data-access';
import { useAddressCollectionData } from '@steelbuy/data-provider';
import { useModal } from '@steelbuy/modal-dialog';
import { ModelPrimaryKey, formatCurrency, AddressModel, getPricingUnit } from '@steelbuy/ts-shared';
import { ListingBuyerTeaser } from '@steelbuy/ui-domain';
import {
    AddressIdentifier,
    ButtonCallToAction,
    ButtonSecondary,
    Card,
    CardContentCheckoutDelivery,
    CardContentCheckoutPayment,
    CardContentProductViewPrice,
    CardLayout,
    FormActionbar,
    LoadingSpinner,
    LoadingStatus,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { CheckoutCancelModal } from './CheckoutCancelModal';
import CheckoutCheckboxes from './CheckoutCheckboxes';
import { useCheckoutContext } from './CheckoutContext';
import { CheckoutErrorNotification } from './CheckoutErrorNotification';
import CheckoutPurchaseOrderNumber from './CheckoutPurchaseOrderNumber';
import { CHOOSE_ADDRESS_RELATIVE_PATH } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { AddressDetails } from '../address-details/AddressDetails';
import { CheckoutPackageError } from '../checkout-error/CheckoutPackageError';
import { CheckoutSuccessPackage } from '../checkout-success/CheckoutSuccessPackage';
import { PageHeader } from '../page-header/PageHeader';

interface CheckoutPackageFormProps {
    packageDetailPath: string;
    packageListingId: ModelPrimaryKey;
}

const CheckoutPackageForm = ({ packageDetailPath, packageListingId }: CheckoutPackageFormProps) => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);
    const navigate = useNavigate();

    const checkoutContext = useCheckoutContext();
    const { mutate, isError, isLoading, reset } = useCreatePackageOrder();
    const { invoiceAddressCollectionData, warehouseAddressCollectionData } = useAddressCollectionData();
    const { data: packageModel, refetch, isRefetching } = usePackageDetails({ packageId: packageListingId });
    const { data: userData } = useUserSelfDetails();
    const cancelModal = useModal();
    const [showSuccess, setShowSuccess] = useState(false);
    const [orderPlaced, setOrderPlaced] = useState<string | null>(null);
    const [retryCount, setRetryCount] = useState(0);
    const [showPriceUpdateError, setShowPriceUpdateError] = useState(false);
    const invoiceAddresses = invoiceAddressCollectionData.query();
    const warehouseAddresses = warehouseAddressCollectionData.query();

    const defaultInvoiceAddressId = invoiceAddresses[0]?.id ?? '';
    const defaultWarehouseAddressId =
        (warehouseAddresses.find((address: AddressModel) => address.primary) ?? warehouseAddresses[0])?.id ?? '';

    const checkoutPrice = useRef<number | null>(null);
    const {
        formState: { isValid },
        control,
        trigger,
    } = useForm({ mode: 'all' });

    const onRetry = () => {
        setTimeout(() => {
            setRetryCount((prev) => prev + 1);
        }, 0);
    };

    useEffect(() => {
        if (!checkoutContext.deliveryAddressId) {
            checkoutContext.setDeliveryAddressId(defaultWarehouseAddressId);
        }
    }, [defaultWarehouseAddressId]);

    const createPackagePurchaseOrder = () => {
        if (checkoutPrice.current === packageModel?.pricing.priceIncludingDelivery.value) {
            mutate(
                {
                    packagingId: packageListingId,
                    invoiceAddressId: defaultInvoiceAddressId,
                    deliveryAddressId: checkoutContext.deliveryAddressId,
                    additionalDeliveryInformation: checkoutContext.additionalDeliveryInformation,
                    buyerOrderNumber: checkoutContext.buyerOrderNumber?.trim(),
                    haulierRequired: checkoutContext.haulierRequired,
                },
                {
                    onSuccess: ({ orderNumber }) => {
                        setShowSuccess(true);
                        setOrderPlaced(orderNumber);
                    },
                }
            );
        } else {
            checkoutContext.setAgreedAuthorised(false);
            checkoutContext.setAgreedTermsAndConditions(false);
            setShowPriceUpdateError(true);
        }
    };

    useEffect(() => {
        if (checkoutPrice.current !== null && !isRefetching && packageModel) {
            createPackagePurchaseOrder();
        }
    }, [isRefetching]);

    useEffect(() => {
        if (!isValid && (checkoutContext.agreedTermsAndConditions || checkoutContext.agreedAuthorised)) {
            trigger();
        }
    }, [checkoutContext.agreedTermsAndConditions, checkoutContext.agreedAuthorised]);

    if (!packageModel) {
        return <LoadingSpinner />;
    }
    let errorMessage = '';
    if (!checkoutContext.agreedTermsAndConditions || !checkoutContext.agreedAuthorised || !isValid) {
        errorMessage = t('translation:application.checkout.incompleteInformationNotification');
    }
    const buyIsDisabled = () => {
        if (!checkoutContext.agreedTermsAndConditions || !isValid) {
            return true;
        }
        return !checkoutContext.agreedAuthorised;
    };
    const handleCheckout = () => {
        if (!checkoutContext.deliveryAddressId) {
            return;
        }
        checkoutPrice.current = packageModel.pricing.priceIncludingDelivery.value;
        refetch();
    };

    if (showSuccess && orderPlaced) return <CheckoutSuccessPackage orderModel={{ orderNumber: orderPlaced }} />;

    if (isError)
        return (
            <CheckoutPackageError
                packageModel={packageModel}
                onRetry={onRetry}
                retryCount={retryCount}
                onBack={() => {
                    setRetryCount(0);
                    reset();
                }}
            />
        );

    return (
        <>
            <PageHeader
                pageTitle={t('translation:application.checkout.header.title')}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={() => navigate(packageDetailPath)}
                showCartIcon={false}
            />
            <div className="checkout-view">
                <TableLayout>
                    <Card isClickable={false}>
                        <ListingBuyerTeaser listing={packageModel} isPackage />
                    </Card>
                    {showPriceUpdateError && (
                        <Notification
                            level={NotificationLevel.WARNING}
                            message={t('translation:application.checkout.priceUpdatedError')}
                            className="price-updated-error"
                            disableScroll={false}
                        />
                    )}
                </TableLayout>
                <DetailsLayout>
                    <Card isClickable={false}>
                        <CardLayout>
                            <AddressDetails
                                addressId={defaultInvoiceAddressId}
                                type={AddressIdentifier.BILLING_ADDRESS}
                                additionalInfoLabel={t(
                                    'translation:application.addressDetails.additionalInformationLabel'
                                )}
                            />
                        </CardLayout>
                    </Card>

                    <CheckoutPurchaseOrderNumber
                        setBuyerOrderNumber={checkoutContext.setBuyerOrderNumber}
                        control={control}
                    />

                    {/* checkout package payment  */}
                    <Card isClickable={false}>
                        <CardLayout>
                            <CardContentCheckoutPayment
                                header={t('translation:application.checkout.payment.sectionHeading')}
                                notificationMessage={t(
                                    'translation:application.checkout.payment.creditInfoNotification'
                                )}
                            />
                        </CardLayout>
                    </Card>

                    <Card isClickable={false}>
                        <CardLayout>
                            <AddressDetails
                                addressId={checkoutContext.deliveryAddressId}
                                type={AddressIdentifier.DELIVERY_ADDRESS}
                                onButtonClick={() => navigate(CHOOSE_ADDRESS_RELATIVE_PATH)}
                                additionalText={checkoutContext.additionalDeliveryInformation ?? ''}
                                onChangeAdditionalText={(value) =>
                                    checkoutContext.setAdditionalDeliveryInformation(value)
                                }
                                additionalInfoLabel={t(
                                    'translation:application.addressDetails.deliveryInformationLabel'
                                )}
                                additionalInfoPlaceholder={t(
                                    'translation:application.pickupAddressStep.additionalInfoPlaceholder'
                                )}
                                isMandatory
                            />
                        </CardLayout>
                    </Card>

                    {/* delivery info */}
                    <CardContentCheckoutDelivery
                        deliveryPromise={packageModel.deliveryTimeframe}
                        onHaulierRequiredChanged={(value) => {
                            checkoutContext.setHaulierRequired(value);
                        }}
                        haulierRequired={checkoutContext.haulierRequired}
                        countryCode={packageModel.country}
                    />

                    {/* weight & price */}
                    <Card isClickable={false}>
                        <CardLayout>
                            <CardContentProductViewPrice
                                header={t('translation:application.checkout.totalPriceSectionHeading')}
                                pricePerUnit={formatCurrency(
                                    navigator.language,
                                    packageModel.pricing.priceIncludingDelivery.value,
                                    packageModel.pricing.currencyCode
                                )}
                                pricePerUnitLabel={t('translation:application.checkout.pricePerUnitInclDelivery', {
                                    tradeUnit: t(
                                        `domainModel:material.pricingUnit.value.${getPricingUnit({
                                            materialType: packageModel.material,
                                        })}.label`
                                    ),
                                })}
                                weight={t(`domainModel:material.tradeUnit.value.${packageModel.tradeUnit}.quantity`, {
                                    count: packageModel.weight,
                                })}
                                totalExVat={formatCurrency(
                                    navigator.language,
                                    packageModel.pricing.totalExcludingVat.value,
                                    packageModel.pricing.currencyCode
                                )}
                                vatPercent={(packageModel.pricing.taxPercent / 100).toString()}
                                vat={formatCurrency(
                                    navigator.language,
                                    packageModel.pricing.vat.value,
                                    packageModel.pricing.currencyCode
                                )}
                                totalPrice={formatCurrency(
                                    navigator.language,
                                    packageModel.pricing.totalIncludingVat.value,
                                    packageModel.pricing.currencyCode
                                )}
                            />
                        </CardLayout>
                    </Card>
                    {/* Check boxes */}
                    {userData ? <CheckoutCheckboxes /> : null}
                    {errorMessage && <CheckoutErrorNotification errorMessage={errorMessage} />}
                    <FormActionbar>
                        <ButtonSecondary label={t('uiDomain:common.cancel')} onClick={cancelModal.show} />
                        <ButtonCallToAction
                            label={t('translation:application.checkout.submitCheckoutButtonLabel')}
                            disabled={buyIsDisabled() || isLoading}
                            onClick={() => handleCheckout()}
                            loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                        />
                    </FormActionbar>

                    <CheckoutCancelModal
                        modalAccessor={cancelModal}
                        action={() => {
                            checkoutContext.reset();
                            navigate(packageDetailPath);
                        }}
                    />
                </DetailsLayout>
            </div>
        </>
    );
};

export default CheckoutPackageForm;
