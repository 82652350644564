import { MyOffersModelConverter, MyOffersModel, OfferListingStatus } from '@steelbuy/ts-shared';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

export const MY_OFFERS_LISTING_KEY = 'my-offers-listings';

const modelConverter = new MyOffersModelConverter();
const offerListingMapFn = (data: MyOffersModel) => modelConverter.toViewModel(data);

export const useMyOfferListings = ({ filter }: { filter: OfferListingStatus[] }) =>
    usePaginatedQuery<MyOffersModel>({
        url: '/api/buyer/negotiations',
        requestOptions: {
            status: filter?.length ? filter.join(',') : undefined,
        },
        mapFn: offerListingMapFn,
        queryKey: [MY_OFFERS_LISTING_KEY, filter],
        queryOptions: {
            cacheTime: 0,
        },
    });
