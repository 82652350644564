import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { createFilterString } from '@steelbuy/api-integration';
import { ActionStatus, useListingBuyerAlertCollectionData } from '@steelbuy/data-provider';
import { AlertType, getTradeUnit, getUrgentRequestMandatoryFields, SearchFormData } from '@steelbuy/ts-shared';
import { NotificationLevel } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import { composeFilterCriteria } from '../listing-search/FilterCriteriaHelper';
import { ListingSearchForm } from '../listing-search/ListingSearchForm';
import { useSearchFormDataContext } from '../listing-search/SearchFormDataContext';

export const UrgentRequestForm = () => {
    const { t } = useTranslation('translation');
    const location = useLocation();
    const navigate = useNavigate();
    const listingAlertCollection = useListingBuyerAlertCollectionData();
    const searchFormDataContext = useSearchFormDataContext();
    const { searchFormData: initialFormData, forceValidation = false } = location.state || {};
    const [isFormDataSet, setIsFormDataSet] = useState(!initialFormData);
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState(
        forceValidation
            ? {
                  level: NotificationLevel.WARNING,
                  message: t('application.urgentRequestForm.warningMessage'),
              }
            : {
                  level: NotificationLevel.INFO,
                  message: t('application.urgentRequestForm.infoMessage'),
              }
    );
    const listingAlert = listingAlertCollection.queryCreated().getOrUndefined();
    useEffect(() => {
        if (initialFormData) {
            searchFormDataContext.setSearchFormData(initialFormData);
            setIsFormDataSet(true);
        }
    }, []);

    const onSubmit = ({ comment, ...formData }: SearchFormData) => {
        setIsLoading(true);
        const tradeUnit = getTradeUnit(formData);
        listingAlertCollection.create({
            enabled: true,
            filterQuery: createFilterString(composeFilterCriteria({ ...formData, tradeUnit })),
            type: AlertType.URGENT,
            comment,
        });
    };
    listingAlertCollection.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            setIsLoading(false);
            if (listingAlert && listingAlert.numberOfListings > 0) {
                navigate(
                    generatePath(RoutePath.MY_ALERTS_SEARCH, {
                        alertId: listingAlert.id,
                    }),
                    {
                        state: { successNotification: t('application.urgentRequestForm.createdAlertMessageWithMatch') },
                    }
                );
            } else {
                navigate(RoutePath.MY_ALERTS, {
                    state: { successNotification: t('application.urgentRequestForm.createdAlertMessageNoMatch') },
                });
            }
        },
        true
    );

    listingAlertCollection.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            // scroll to top
            document.getElementsByClassName('page-header')?.[0]?.scrollIntoView();
            setNotification({
                level: NotificationLevel.ERROR,
                message: t('application.urgentRequestForm.failedToCreate'),
            });
            setIsLoading(false);
        },
        false
    );

    if (!isFormDataSet) {
        return null;
    }

    return (
        <ListingSearchForm
            heading={t('application.urgentRequestForm.pageHeader')}
            warningMessage={notification.message}
            notificationLevel={notification.level}
            submitButtonLabel={t('application.urgentRequestForm.submitButtonLabel')}
            onSubmit={onSubmit}
            getMandatoryFields={getUrgentRequestMandatoryFields}
            forceValidation={forceValidation}
            isLoading={isLoading}
            showSubmitIcon={false}
            isIMR
        />
    );
};
