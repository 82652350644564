import { AddressCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { AddressModel, UserRole, Nullable } from '@steelbuy/ts-shared';
import {
    createCrudEntitySlice,
    createSelectCreated,
    createSelectEntity,
    createSelectServiceEntity,
} from '../../util/store/crud/EntitySlice';
import { EntityStore } from '../../util/store/crud/EntityStore';
import { EntityStoreAccessor } from '../../util/store/crud/EntityStoreAccessor';

import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchEntityThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const addressEntityStoreName = 'address-entity';
export type AddressEntityStore = {
    [addressEntityStoreName]: EntityStore<AddressModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>, roles?: string[]) => {
    if (roles?.includes(UserRole.MANAGER)) {
        return new AddressCrudApiClient('manager').init(apiBaseUrl, jsonWebToken);
    }

    if (roles?.includes(UserRole.BUYER)) {
        return new AddressCrudApiClient('buyer').init(apiBaseUrl, jsonWebToken);
    }

    if (roles?.includes(UserRole.SELLER)) {
        return new AddressCrudApiClient('seller').init(apiBaseUrl, jsonWebToken);
    }

    return new AddressCrudApiClient('universal').init(apiBaseUrl, jsonWebToken);
};
const fetch = createFetchEntityThunk<AddressModel, AddressEntityStore, AddressCrudApiClient>(
    addressEntityStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<AddressModel, AddressCrudApiClient>(addressEntityStoreName, createApiClient);
const createEntity = createCreateThunk<AddressModel, AddressCrudApiClient>(addressEntityStoreName, createApiClient);
const deleteEntity = createDeleteThunk<AddressModel, AddressCrudApiClient>(addressEntityStoreName, createApiClient);
const entityService = createEntityServiceThunk<AddressModel, AddressCrudApiClient>(
    addressEntityStoreName,
    createApiClient
);

// Slice definition
export const addressEntitySlice = createCrudEntitySlice<AddressModel>(
    addressEntityStoreName,
    fetch,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetActionStatus, resetFetchStatus } = addressEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<AddressModel, AddressEntityStore>(addressEntityStoreName);
const selectCreated = createSelectCreated<AddressModel, AddressEntityStore>(addressEntityStoreName);
const selectServiceEntity = createSelectServiceEntity<AddressModel, AddressEntityStore>(addressEntityStoreName);

// Export the store accessors to be used by the data provider value
export const addressEntityStoreAccessors = {
    fetch,
    resetFetchStatus,
    mutateEntity,
    createEntity,
    deleteEntity,
    resetActionStatus,
    select,
    selectCreated,
    entityService,
    selectServiceEntity,
} as EntityStoreAccessor<AddressModel, AddressEntityStore>;
