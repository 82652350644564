import { ReactNode, useEffect, useState } from 'react';
import { exchangeDocumentIdForAccessibleLink } from '@steelbuy/api-integration';
import { resolveProtectedUrl } from '@steelbuy/api-integration-util';
import { JsonWebToken, useAuth } from '@steelbuy/auth';
import { getToken } from '@steelbuy/data-access';
import { DownloadFile, isDownloadFile, ProtectedUrl, UploadFile } from '@steelbuy/ts-shared';

import { startDownload } from '@steelbuy/util';

export type FileDownloadClickableProps = {
    children: ReactNode;
    apiBaseUrl: string;
    file?: DownloadFile | UploadFile;
};

export const FileDownloadClickable = ({ children, apiBaseUrl, file }: FileDownloadClickableProps) => {
    const auth = useAuth();

    const [protectedUrl, setProtectedUrl] = useState<ProtectedUrl | null>(null);

    useEffect(() => {
        if (protectedUrl === null) {
            return;
        }

        const download = async () => {
            const accessableUrl = (await resolveProtectedUrl(protectedUrl, auth.getToken().get())).getOrUndefined();
            if (accessableUrl !== undefined) {
                startDownload(accessableUrl.value, file?.meta.fileName);
            }

            setProtectedUrl(null);
        };

        download();
    }, [auth, protectedUrl, setProtectedUrl, file?.meta.fileName]);

    if (file === undefined) {
        return null;
    }

    const onClick = async () => {
        if (isDownloadFile(file)) {
            if (file.url.accessible) {
                startDownload(file.url.value, file?.meta.fileName);
            } else {
                setProtectedUrl(file.url);
            }
        } else {
            const token = await getToken();
            if (!token) {
                return;
            }
            const fileDownload = await exchangeDocumentIdForAccessibleLink(apiBaseUrl, file?.documentId, {
                accessToken: token,
            } as JsonWebToken);
            if (fileDownload.url.accessible) {
                startDownload(fileDownload.url.value, file?.meta.fileName);
            } else {
                setProtectedUrl(fileDownload.url);
            }
        }
    };

    return (
        <div className="file-download-clickable" onClick={onClick} aria-hidden="true">
            {children}
        </div>
    );
};
