import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { PreparedSortCriteria, SortDirection } from '@steelbuy/api-integration';
import { ListingBuyerSearchSortCriteria } from '@steelbuy/ts-shared';

export interface SearchResultsContextValue {
    sortCriteria: PreparedSortCriteria<ListingBuyerSearchSortCriteria>;
    setSortCriteria: (sort: PreparedSortCriteria<ListingBuyerSearchSortCriteria>) => void;
    scrollPosition: number;
    setScrollPosition: (position: number) => void;
    reset: () => void;
}

const defaultValue: SearchResultsContextValue = {
    sortCriteria: [
        {
            property: ListingBuyerSearchSortCriteria.RELEVANCE,
            direction: SortDirection.DESCENDING,
        },
    ],
    setSortCriteria: () => null,
    scrollPosition: 0,
    reset: () => null,
    setScrollPosition: () => null,
};

const SearchResultsContext = createContext(defaultValue);

export const SearchResultsContextProvider = ({ children }: { children: ReactNode }) => {
    const [sortCriteria, setSortCriteria] = useState(defaultValue.sortCriteria);
    const [scrollPosition, setScrollPosition] = useState(defaultValue.scrollPosition);
    const reset = useCallback(() => {
        setSortCriteria(defaultValue.sortCriteria);
        setScrollPosition(defaultValue.scrollPosition);
    }, []);

    const value = useMemo(
        () => ({
            sortCriteria,
            setSortCriteria,
            scrollPosition,
            setScrollPosition,
            reset,
        }),
        [sortCriteria, scrollPosition, setSortCriteria, setScrollPosition, reset]
    );

    return <SearchResultsContext.Provider value={value}>{children}</SearchResultsContext.Provider>;
};

export const useSearchResultsContext = () => useContext(SearchResultsContext);
