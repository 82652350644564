import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useUserSelfDetails } from '@steelbuy/data-access';
import { UserRole } from '@steelbuy/ts-shared';
import { RoutePath } from '../../router/Routes';

export const UrgentRequestsView = () => {
    const navigate = useNavigate();
    const { data: userData } = useUserSelfDetails();
    const userRoleChecker = (role: UserRole) => userData?.roles?.includes(role);

    const hasUrgentRequestAccess =
        userRoleChecker(UserRole.SELLER) || userRoleChecker(UserRole.MANAGER) || userRoleChecker(UserRole.ADMIN);

    useEffect(() => {
        if (!hasUrgentRequestAccess) {
            navigate(RoutePath.DASHBOARD);
        }
    }, []);

    return <Outlet />;
};
