import { useMutation } from '@tanstack/react-query';
import { RFQDraftModel } from '@steelbuy/ts-shared';
import axios from '../../axios';

const restartRFQ = async (rfqId: string) => {
    const { data } = await axios.put<RFQDraftModel>(`/api/buyer/quotes/${rfqId}/restart`);
    return data;
};

export const useRestartRFQ = () =>
    useMutation({
        mutationFn: ({ rfqId }: { rfqId: string }) => restartRFQ(rfqId),
    });
