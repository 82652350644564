import {
    Definition,
    MaterialType,
    Product,
    ProductType,
    Shape,
    MaterialWithDimensions,
    ListingMaterialOrigin,
} from '@steelbuy/ts-shared';

interface BaseStep1Schema {
    materialType: MaterialType;
    shape: Shape;
    product: Product;
}

export interface Step1SchemaWithDefinition extends BaseStep1Schema {
    definition: Definition[];
}

export interface Step1SchemaWithPlateType extends BaseStep1Schema {
    plateType: ProductType[];
}

export type Step1Type = Step1SchemaWithDefinition | Step1SchemaWithPlateType;

export type Step3Type = {
    origin: ListingMaterialOrigin[];
    delivery: string;
    deadline: string;
    comments: string;
};

export const isStep1WithDefinition = (data?: Step1Type | Partial<RFQType>): data is Step1SchemaWithDefinition =>
    (data as Step1SchemaWithDefinition)?.definition?.length > 0;

export const isStep1WithPlateType = (data?: Step1Type | Partial<RFQType>): data is Step1SchemaWithPlateType =>
    (data as Step1SchemaWithPlateType)?.plateType?.length > 0;

export const isPlateProductSelected = (data?: Step1Type | Partial<RFQType>): data is Step1SchemaWithPlateType =>
    (data as Step1SchemaWithPlateType).product === Product.PLATE;

export const isStep2 = (data?: Partial<RFQType>): data is Step2Type => (data as Step2Type).materials !== undefined;

export const isStep3 = (data?: Partial<RFQType>): data is Step3Type => (data as Step3Type).origin !== undefined;

export type Step2Materials = Record<Definition | ProductType, Array<MaterialWithDimensions>>;

export interface Step2Type {
    materials: Step2Materials;
}

export type PartialRFQTypeWithDefinition = Partial<Step1SchemaWithDefinition & Step2Type & Step3Type>;

export type PartialRFQTypeWithPlateType = Partial<Step1SchemaWithPlateType & Step2Type & Step3Type>;

export type RFQType = Step1Type | (Step1Type & Step2Type) | (Step1Type & Step2Type & Step3Type);
