import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MaterialWithDimensions, Literal } from '@steelbuy/ts-shared';
import {
    ButtonSize,
    ButtonTertiary,
    Card,
    IconIdentifier,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { useWindowSize } from '@steelbuy/util';
import { RFQInputs, isUniqueEntry, removeNullFields, updateNonApplicableFields } from '../../createRFQUtil';
import { useRfqFormContext } from '../../RfqFormContext';
import { Row } from '../rfq-table/RFQTable';

import './AddRFQItem.scss';

interface AddRFQItemProps {
    append: (data: MaterialWithDimensions) => void;
    headings: RFQInputs;
    material: MaterialWithDimensions;
    fields: MaterialWithDimensions[];
    hasAddedRows: boolean;
    draftId: string | null;
    stepVisited: boolean;
    saveDraftCounter: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RFQNumber = any;

export const AddRFQItem = ({
    append,
    material,
    headings,
    fields,
    hasAddedRows,
    draftId,
    stepVisited,
    saveDraftCounter,
}: AddRFQItemProps) => {
    const { t } = useTranslation('translation');
    const [error, setError] = useState('');
    const triggerOnChange = useRef(false);
    const { width } = useWindowSize();
    const [isAddButtonWrapped, setIsAddButtonWrapped] = useState(false);
    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { isValid, errors, isDirty },
        clearErrors,
        setValue,
        trigger,
    } = useForm<MaterialWithDimensions>({
        defaultValues: {
            grade: undefined,
            specification: undefined,
            finish: undefined,
            width: '' as RFQNumber,
            weight: '' as RFQNumber,
            thickness: '' as RFQNumber,
            length: '' as RFQNumber,
            tolerance: undefined,
            surface: undefined,
            coatingThicknessValue: '' as RFQNumber,
        },
        mode: 'all',
    });

    const { cancelEditRow } = useRfqFormContext();

    useEffect(() => {
        updateNonApplicableFields(headings, { ...material, ...watch() }, clearErrors, watch, setValue, errors);
        if (triggerOnChange.current && draftId) {
            triggerOnChange.current = false;
            trigger();
        }
    }, [watch(), draftId]);

    useEffect(() => {
        if ((stepVisited || draftId) && !hasAddedRows) {
            trigger();
        }
    }, [draftId, hasAddedRows, stepVisited, isDirty, trigger]);

    useEffect(() => {
        reset();
        setError('');
        if (!hasAddedRows) {
            triggerOnChange.current = true;
        }
    }, [saveDraftCounter, reset]);

    const tbodyRef = useRef<HTMLTableSectionElement>(null);
    const addButtonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (tbodyRef.current && addButtonRef.current) {
            setIsAddButtonWrapped(
                addButtonRef.current?.getBoundingClientRect().bottom > tbodyRef.current.getBoundingClientRect().bottom
            );
        }
    }, [width]);

    return (
        <section className="add-rfq-item">
            <Card isClickable={false}>
                <div className="add-rfq-item__container">
                    <p className="add-rfq-item__title">{t('application.addRfq.addTitle')}</p>
                    <p className="add-rfq-item__description">{t('application.addRfq.addDescription')}</p>
                </div>
                <div className="add-rfq-item__content">
                    <table>
                        <thead>
                            <tr className="add-rfq-item__label">
                                {Object.entries(headings).map(([key, { disabledFn, measurementLiteral }]) => {
                                    let measurementText = '';
                                    if (measurementLiteral) {
                                        const literalText = t(`domainModel:${measurementLiteral}` as Literal);
                                        measurementText = ` (${literalText})`;
                                    }
                                    return (
                                        <td
                                            key={key}
                                            className={classNames({
                                                'add-rfq-item__label--disabled': disabledFn?.({
                                                    ...material,
                                                    ...watch(),
                                                }),
                                            })}
                                        >
                                            <div className="add-rfq-item__label__container">
                                                <span>{t(`application.rfqHeadingLabels.${key}` as Literal)}</span>
                                                {measurementText ? <span>{measurementText}</span> : null}
                                                {!disabledFn?.({
                                                    ...material,
                                                    ...watch(),
                                                }) && <span className="required-field"> *</span>}
                                            </div>
                                        </td>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody ref={tbodyRef}>
                            <Row
                                headings={headings}
                                material={{
                                    ...material,
                                    ...watch(),
                                }}
                                control={control}
                                isEdit
                                errors={errors}
                            />
                        </tbody>
                    </table>
                    <div
                        className={classNames('add-rfq-item__content__add-button', {
                            'add-rfq-item__content__add-button--wrapped': isAddButtonWrapped,
                        })}
                        ref={addButtonRef}
                    >
                        <ButtonTertiary
                            label={t('application.createRFQ.addToRFQ')}
                            size={ButtonSize.S}
                            icon={IconIdentifier.PLUS}
                            type="submit"
                            onClick={handleSubmit(
                                (data) => {
                                    if (isUniqueEntry(data, fields)) {
                                        cancelEditRow();
                                        setError('');
                                        append(removeNullFields(data));
                                        reset();
                                    } else {
                                        setError(t('application.addRfq.itemExists'));
                                    }
                                },
                                () => setError(t('application.addRfq.genericError'))
                            )}
                            disabled={!isValid}
                        />
                    </div>
                </div>
                {!!error && <Notification level={NotificationLevel.ERROR} message={error} stayOpen disableScroll />}
            </Card>
        </section>
    );
};
