import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ActionStatus, useWarehouseAddressCollectionData } from '@steelbuy/data-provider';
import { AddressModel, Mutable } from '@steelbuy/ts-shared';
import { AddressForm } from '@steelbuy/ui-domain';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { ROUTE_ACCOUNT_ORGANISATION } from '../account/Account.enum';
import { PageHeader } from '../page-header/PageHeader';

export const AccountAddAddress = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const warehouseAddressCollectionData = useWarehouseAddressCollectionData();

    const [error, setError] = useState<string>();

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setError(t('application.accountAddAddress.errorMessage'));
        },
        true
    );

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS],
        () => {
            // Refresh all addresses, because the primary address could have changed.
            warehouseAddressCollectionData.resolveFetchStatus();
            navigate(ROUTE_ACCOUNT_ORGANISATION);
        },
        false
    );

    const handleSave = (address: Mutable<AddressModel>) => {
        warehouseAddressCollectionData.create(address);
    };

    return (
        <>
            <PageHeader
                pageTitle={t('application.accountAddAddress.pageTitle')}
                previousPageTitle={t('application.accountAddAddress.previousPageTitle')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_ORGANISATION)}
            />

            <FormLayout>
                <AddressForm
                    error={error}
                    onCancel={() => navigate(ROUTE_ACCOUNT_ORGANISATION)}
                    onPrimary={handleSave}
                    isReadOnly={false}
                />
            </FormLayout>
        </>
    );
};
