import { OrderBuyerModel } from '@steelbuy/ts-shared';
import { CheckoutIllustratedSuccess } from '@steelbuy/ui-domain';
import { CheckoutSuccessWrapper } from './CheckoutSuccessWrapper';
import { RoutePath } from '../../router/Routes';

export const CheckoutSuccessPackage = ({ orderModel }: { orderModel: Partial<OrderBuyerModel> }) => {
    if (orderModel === null) {
        return null;
    }

    return (
        <CheckoutSuccessWrapper>
            <CheckoutIllustratedSuccess orderModel={orderModel} myOrdersUrl={RoutePath.MY_ORDERS} />
        </CheckoutSuccessWrapper>
    );
};
