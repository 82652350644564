import { AddressModel, AddressModelConverter } from '@steelbuy/ts-shared';

import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class HistoricalAddressCrudApiClient extends AbstractCrudApiClient<AddressModel> {
    protected collectionControllerUri = 'api/universal/addresses-history/';

    protected paginationControllerUri = 'api/universal/addresses-history/';

    protected entityCreateUri = 'api/universal/addresses-history/';

    protected entityFetchUri = 'api/universal/addresses-history/{id}/';

    protected entityMutateUri = 'api/universal/addresses-history/{id}/';

    protected entityDeleteUri = 'api/universal/addresses-history/{id}/';

    protected entityServiceCallUri = 'api/universal/addresses-history/{id}/';

    protected modelConverter = new AddressModelConverter();
}
