import { useTranslation } from 'react-i18next';
import { Nullable } from '@steelbuy/ts-shared';
import { Card, CardContentAttributes, CardContentAttributesItem, CardLayout } from '@steelbuy/ui-primitive';
import './ListingDetailsPackage.scss';

interface ListingDetailsPackageProps {
    packageTitle: Nullable<string>;
    packageDescription?: Nullable<string>;
}

export const ListingDetailsPackage = ({ packageTitle, packageDescription }: ListingDetailsPackageProps) => {
    const { t } = useTranslation('domainModel');

    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentAttributes header={t('material.packageDetails.name')} hasGrid={false}>
                    <CardContentAttributesItem
                        label={t('material.packageDetails.packageTitle')}
                        value={packageTitle ?? '-'}
                    />
                    {packageDescription && (
                        <div className="list-package-description">
                            <CardContentAttributesItem
                                label={t('material.packageDetails.packageDescription')}
                                value={packageDescription}
                            />
                        </div>
                    )}
                </CardContentAttributes>
            </CardLayout>
        </Card>
    );
};
