import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSellerRejectOfferMutation } from '@steelbuy/data-access';
import { useModal } from '@steelbuy/modal-dialog';
import { OfferView } from '@steelbuy/ts-shared';
import { ConfirmCancelModel } from '@steelbuy/ui-primitive';

export const OfferRejectedModal = ({
    show,
    negotiationId,
    onModalClose,
    onStatusChange,
    view,
}: {
    show: boolean;
    negotiationId: string;
    onModalClose: () => void;
    onStatusChange: (message?: string, errorMessage?: string, forceRefetch?: boolean) => void;
    view: OfferView;
}) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const offerRejectedModal = useModal(onModalClose);
    const { isLoading, mutate } = useSellerRejectOfferMutation();
    const handleRejectOffer = () => {
        mutate(
            { negotiationId },
            {
                onSuccess: () => {
                    offerRejectedModal.hide();
                    onStatusChange(
                        undefined,
                        view === OfferView.SELLER
                            ? t('application.offers.rejectMsgSeller')
                            : t('application.offers.rejectMsgBuyer'),
                        true
                    );
                },
                onError: () => {
                    offerRejectedModal.hide();
                    onStatusChange(undefined, t('application.offers.rejectErrorMsg'));
                },
            }
        );
    };

    useEffect(() => {
        if (show) {
            offerRejectedModal.show();
        } else {
            offerRejectedModal.hide();
        }
    }, [show]);
    return (
        <ConfirmCancelModel
            modalAccessor={offerRejectedModal}
            header={t('application.offers.rejectModelHeader')}
            bodyImage="assets/lib-ui-primitive/images/ListingDraft-Reject.svg"
            cancelLabel={t('uiDomain:common.cancel')}
            submitLabel={t('application.offers.reject')}
            onSubmit={handleRejectOffer}
            isSubmitting={isLoading}
            isDanger
            onClose={onModalClose}
        />
    );
};
