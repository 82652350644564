import { useEffect } from 'react';

import { useAuth } from '@steelbuy/auth';

import { useUserSelfDetails } from '@steelbuy/data-access';
import { isAuthenticationRequiredError, restoreError } from '@steelbuy/error';
import { AddressModel } from '@steelbuy/ts-shared';
import { LoadingSpinner } from '@steelbuy/ui-primitive';

import { AddressEntityData } from './AddressEntityData';
import { AddressEntityStore, addressEntityStoreAccessors, addressEntityStoreName } from './AddressEntitySlice';
import { createDataProviderAccessTokenLoader } from '../../util/auth/AccessTokenLoader';
import { EntityDataProviderProps } from '../../util/provider/DataProviderProps';
import { createEntityDataProviderValue } from '../../util/provider/EntityDataProviderValue';
import { FetchStatus } from '../../util/store/FetchStatus';
import { getBaseUrl } from '../../util/urlUtils';

export const AddressEntityDataProvider = ({
    lazyLoad = false,
    alwaysFetchOnMount = false,
    children,
    entityId,
    interceptRendering = !lazyLoad,
    pendingComponent,
}: EntityDataProviderProps) => {
    const authConsumer = useAuth();
    const { data: userModel } = useUserSelfDetails();

    const PendingComponent = pendingComponent;

    const dataProviderValue = createEntityDataProviderValue<AddressModel, AddressEntityStore>(
        addressEntityStoreName,
        addressEntityStoreAccessors,
        entityId,
        getBaseUrl(),
        createDataProviderAccessTokenLoader(authConsumer, getBaseUrl()),
        userModel?.roles
    );

    const dataProviderIdle = dataProviderValue.idle();
    const fetchStatus = dataProviderValue.queryFetchStatus();
    const error = dataProviderValue.queryFetchError();

    dataProviderValue.useFetchStatusEffect([FetchStatus.FAILED], () => {
        if (error === null) {
            return;
        }
        if (isAuthenticationRequiredError(error)) {
            authConsumer.reauthenticate();
            return;
        }
        throw restoreError(error);
    });

    useEffect(() => {
        if (!alwaysFetchOnMount && dataProviderIdle && !lazyLoad) {
            dataProviderValue.fetch();
        }
    }, [dataProviderIdle, lazyLoad]);

    useEffect(() => {
        if (alwaysFetchOnMount) {
            dataProviderValue.fetch();
        }
    }, []);

    if (interceptRendering) {
        switch (fetchStatus) {
            case FetchStatus.IDLE:
                return null;
            case FetchStatus.PENDING:
                if (PendingComponent) {
                    return <PendingComponent />;
                }
                return <LoadingSpinner />;
            case FetchStatus.FAILED:
                return null;
        }
    }

    return <AddressEntityData.Provider value={dataProviderValue}>{children}</AddressEntityData.Provider>;
};
