import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { buildClassStringFromClassMap } from '@steelbuy/util';

import { CardContentAttributesItem } from '../card-content-attributes/card-content-attributes-item/CardContentAttributesItem';

import './CardSellerSummaryWeightPrice.scss';

export type CardSellerSummaryWeightPriceProps = {
    isEditable?: boolean;
    header: string;
    numberOfItems?: string;
    itemsLabel?: string;
    weightLabel?: string;
    weight: string;
    renderPrice: (isEditable?: boolean) => ReactNode;
    children?: ReactNode;
    isAutoRenewPriceEditable?: boolean;
    isTheoretical?: boolean;
};

export const CardSellerSummaryWeightPrice = ({
    children,
    header,
    isAutoRenewPriceEditable,
    isEditable,
    itemsLabel,
    numberOfItems,
    renderPrice,
    weight,
    weightLabel,
    isTheoretical = false,
}: CardSellerSummaryWeightPriceProps) => {
    const { t } = useTranslation('uiPrimitive');
    const sellerSummaryClassMap = {
        'card-seller-summary-weight-price': true,
        'card-seller-summary-weight-price--editable': isEditable || isAutoRenewPriceEditable,
    };

    const renderNumberOfItems = () => {
        if (numberOfItems && itemsLabel) {
            return <CardContentAttributesItem label={itemsLabel} value={numberOfItems} />;
        }
        return null;
    };

    const renderChildren = () => {
        if (!children || (Array.isArray(children) && (!children.length || children.every((child) => !child)))) {
            return null;
        }
        return <article className="card-seller-summary-weight-price__children">{children}</article>;
    };

    return (
        <article className={buildClassStringFromClassMap(sellerSummaryClassMap)}>
            <article className="card-seller-summary-weight-price__header">{header}</article>
            <article className="card-seller-summary-weight-price__content">
                <article className="card-seller-summary-weight-price__content__attributes">
                    <CardContentAttributesItem
                        label={weightLabel ?? t(`checkout.${isTheoretical ? 'theoreticalWeight' : 'weight'}`)}
                        value={weight}
                    />
                    {renderNumberOfItems()}
                </article>
                <article className="card-seller-summary-weight-price__content__price">
                    {renderPrice(isEditable)}
                </article>
            </article>
            {renderChildren()}
        </article>
    );
};
