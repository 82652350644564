/* eslint-disable react/destructuring-assignment */
import { useEffect } from 'react';

import { useAuth } from '@steelbuy/auth';

import { isAuthenticationRequiredError, restoreError } from '@steelbuy/error';
import { ListingBuyerSearchResultModel } from '@steelbuy/ts-shared';

import { ListingBuyerAlertSearchData } from './ListingBuyerAlertSearchData';
import {
    listingBuyerAlertSearchStoreAccessors,
    ListingBuyerAlertSearchStore,
    listingBuyerAlertSearchStoreName,
} from './ListingBuyerAlertSearchSlice';
import { createDataProviderAccessTokenLoader } from '../../util/auth/AccessTokenLoader';
import { SearchDataProviderProps } from '../../util/provider/DataProviderProps';
import { createSearchDataProviderValue } from '../../util/provider/SearchDataProviderValue';
import { FetchStatus } from '../../util/store/FetchStatus';
import { getBaseUrl } from '../../util/urlUtils';

export const ListingBuyerAlertSearchDataProvider = (props: SearchDataProviderProps<ListingBuyerSearchResultModel>) => {
    const { pathVariables } = props;

    const authConsumer = useAuth();

    const dataProviderValue = createSearchDataProviderValue<
        ListingBuyerSearchResultModel,
        ListingBuyerAlertSearchStore
    >(
        listingBuyerAlertSearchStoreName,
        listingBuyerAlertSearchStoreAccessors,
        getBaseUrl(),
        createDataProviderAccessTokenLoader(authConsumer, getBaseUrl())
    );

    const dataProviderIdle = dataProviderValue.idle();
    const error = dataProviderValue.queryFetchError();

    dataProviderValue.useFetchStatusEffect([FetchStatus.FAILED], () => {
        if (error === null) {
            return;
        }
        if (isAuthenticationRequiredError(error)) {
            authConsumer.reauthenticate();
            return;
        }
        throw restoreError(error);
    });

    useEffect(() => {
        if (dataProviderIdle && !props.lazyLoad) {
            dataProviderValue.fetch(props.sortCriteria, props.filterCriteria, pathVariables);
        }
    }, [dataProviderIdle, props.lazyLoad]);

    return (
        <ListingBuyerAlertSearchData.Provider value={dataProviderValue}>
            {props.children}
        </ListingBuyerAlertSearchData.Provider>
    );
};
