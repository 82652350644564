import { useTranslation } from 'react-i18next';
import {
    FilterCriteria,
    MultiValueFilterComparator,
    PreparedSortCriteria,
    SortDirection,
} from '@steelbuy/api-integration';
import { FetchStatus, useListingSellerCollectionData, useRefreshingCollection } from '@steelbuy/data-provider';
import { ListingSellerModel, ListingStatus } from '@steelbuy/ts-shared';

import { ListingSellerTeaser } from '@steelbuy/ui-domain';
import {
    ButtonTertiaryOnLightM,
    Card,
    DataHeader,
    FillHeightContainer,
    LoadingSpinner,
    LoadingStatus,
} from '@steelbuy/ui-primitive';
import { MyListingEmptyState } from './MyListingEmptyState';
import { FILTER_CRITERIA_ID, FILTER_PROPERTY, MyListingsFilter } from './MyListingsFilter';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { FOOTER_HEIGHT } from '../app-layout/app-footer/AppFooter';

import './MyListings.scss';

const defaultSortCriteria: PreparedSortCriteria = [
    {
        property: 'status.modifiedAt',
        direction: SortDirection.DESCENDING,
    },
];

const defaultFilterCriteria: FilterCriteria<ListingSellerModel> = [
    {
        id: FILTER_CRITERIA_ID,
        criteria: [
            {
                property: FILTER_PROPERTY,
                value: Object.values(ListingStatus).filter((status) => status !== ListingStatus.DRAFT),
                comparator: MultiValueFilterComparator.ONE_OF,
            },
        ],
    },
];

export const MyListingsList = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const listingSellerCollectionData = useListingSellerCollectionData();
    const fetchStatus = useRefreshingCollection(
        listingSellerCollectionData,
        defaultSortCriteria,
        defaultFilterCriteria
    );

    const listings = listingSellerCollectionData.query();
    const totalListings = listingSellerCollectionData.queryTotalItems() ?? 0;
    const hasNext = listingSellerCollectionData.hasNextPage();

    const handleLoadMore = () => {
        listingSellerCollectionData.fetchNext();
    };

    return (
        <div className="my-listings-list">
            {fetchStatus === FetchStatus.PENDING ? (
                <FillHeightContainer extraPadding={FOOTER_HEIGHT}>
                    <LoadingSpinner fillContainerHeight />
                </FillHeightContainer>
            ) : (
                <>
                    <DataHeader headingValue={t('application.myListings.numberOfListings', { count: totalListings })}>
                        <MyListingsFilter />
                    </DataHeader>
                    <div className="my-listings-list__listings">
                        {listings.map((listing) => (
                            <Card
                                key={listing.id}
                                isClickable
                                url={createRouteUrl(RoutePath.MY_LISTINGS_DETAILS, ['listingId', listing.id])}
                            >
                                <ListingSellerTeaser listing={listing} />
                            </Card>
                        ))}
                    </div>
                    {hasNext && (
                        <div className="my-listings-list__load-more">
                            <ButtonTertiaryOnLightM
                                onClick={handleLoadMore}
                                label={t('uiDomain:commonList.loadMore')}
                                loadingStatus={
                                    fetchStatus === FetchStatus.PAGING_PENDING
                                        ? LoadingStatus.PENDING
                                        : LoadingStatus.IDLE
                                }
                            />
                        </div>
                    )}
                </>
            )}
            {totalListings === 0 && <MyListingEmptyState />}
        </div>
    );
};
