import { useTranslation } from 'react-i18next';
import { useUserSelfDetails } from '@steelbuy/data-access';
import {
    AddressCollectionDataProvider,
    OrganisationEntityDataProvider,
    UserCollectionDataProvider,
} from '@steelbuy/data-provider';
import { CustomError } from '@steelbuy/error';
import { Account } from '../../components/account/Account';

export const AccountView = () => {
    const { t } = useTranslation('translation');
    const { data: userModel } = useUserSelfDetails();
    if (!userModel) {
        throw new CustomError(t('error.noUserError'));
    }

    return (
        <OrganisationEntityDataProvider entityId={userModel.organisation.id}>
            <AddressCollectionDataProvider>
                <UserCollectionDataProvider lazyLoad>
                    <Account />
                </UserCollectionDataProvider>
            </AddressCollectionDataProvider>
        </OrganisationEntityDataProvider>
    );
};
