import { PreparedSortCriteria, SortDirection } from '@steelbuy/api-integration';
import { ListingBuyerSearchSortCriteria } from '@steelbuy/ts-shared';

export enum SortCriteriaOption {
    RELEVANCE = 'RELEVANCE',
    EXPIRES_AT_DESCENDING = 'EXPIRES_AT_DESCENDING',
    EXPIRES_AT_ASCENDING = 'EXPIRES_AT_ASCENDING',
    WEIGHT_DESCENDING = 'WEIGHT_DESCENDING',
    WEIGHT_ASCENDING = 'WEIGHT_ASCENDING',
    PRICE_DESCENDING = 'PRICE_DESCENDING',
    PRICE_ASCENDING = 'PRICE_ASCENDING',
}

export const sortCriteriaOptions = new Map<SortCriteriaOption, PreparedSortCriteria<ListingBuyerSearchSortCriteria>>([
    [
        SortCriteriaOption.RELEVANCE,
        [
            {
                property: ListingBuyerSearchSortCriteria.RELEVANCE,
                direction: SortDirection.DESCENDING,
            },
        ],
    ],
    [
        SortCriteriaOption.EXPIRES_AT_DESCENDING,
        [
            {
                property: ListingBuyerSearchSortCriteria.EXPIRES_AT,
                direction: SortDirection.DESCENDING,
            },
        ],
    ],
    [
        SortCriteriaOption.EXPIRES_AT_ASCENDING,
        [
            {
                property: ListingBuyerSearchSortCriteria.EXPIRES_AT,
                direction: SortDirection.ASCENDING,
            },
        ],
    ],
    [
        SortCriteriaOption.WEIGHT_DESCENDING,
        [
            {
                property: ListingBuyerSearchSortCriteria.WEIGHT,
                direction: SortDirection.DESCENDING,
            },
        ],
    ],
    [
        SortCriteriaOption.WEIGHT_ASCENDING,
        [
            {
                property: ListingBuyerSearchSortCriteria.WEIGHT,
                direction: SortDirection.ASCENDING,
            },
        ],
    ],
    [
        SortCriteriaOption.PRICE_ASCENDING,
        [
            {
                property: ListingBuyerSearchSortCriteria.PRICE,
                direction: SortDirection.ASCENDING,
            },
        ],
    ],
    [
        SortCriteriaOption.PRICE_DESCENDING,
        [
            {
                property: ListingBuyerSearchSortCriteria.PRICE,
                direction: SortDirection.DESCENDING,
            },
        ],
    ],
]);
