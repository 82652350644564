import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { exchangeDocumentIdForAccessibleLink } from '@steelbuy/api-integration';
import { useAuth } from '@steelbuy/auth';
import { useUploadDocument } from '@steelbuy/data-access';
import { DocumentType, createUploadFileFromFile } from '@steelbuy/ts-shared';
import {
    FILE_SIZE_LIMIT_IN_MEGABYTES,
    InputTextFieldCharacterCount,
    exceedsMaxFileSize,
    getSelectedFiles,
    isValidFileType,
    useRequiredFieldValidator,
} from '@steelbuy/ui-domain';
import { FormItem, InputFile, FileUploadStatus, ValidatableElement, InputTextarea } from '@steelbuy/ui-primitive';
import { StepProps } from './StepProps';
import { constants } from '../../../constants';
import { FormLayout } from '../../../views/layouts/form-layout/FormLayout';
import { useCreateListingContext } from '../CreateListingContext';
import { StepVisitation, useCreateListingStep } from '../CreateListingHooks';
import './ListUploadStep.scss';

export const ListUploadStep = ({ stepId }: StepProps) => {
    const auth = useAuth();
    const { stepVisitation, refreshStepStatus, formRef } = useCreateListingStep(stepId);
    const { listUploadStepData, updateListUploadStepData, updateFileStatus, packageStatus, addFile } =
        useCreateListingContext();
    const { t } = useTranslation(['translation', 'uiDomain']);
    const requiredFieldValidator = useRequiredFieldValidator();
    const stepVisited = stepVisitation === StepVisitation.VISITED;
    useEffect(() => {
        refreshStepStatus();
    }, [stepVisitation]);

    const uploadDocument = useUploadDocument({
        onSuccess: (data, { file }) => {
            const documentType = DocumentType.PACKAGE;
            updateFileStatus(documentType, data.fileName, FileUploadStatus.Success);
            addFile(documentType, createUploadFileFromFile(data.id, file), false);
        },
        onError: (_, { file }) => {
            const documentType = DocumentType.PACKAGE;
            updateFileStatus(documentType, file.name, FileUploadStatus.Error);
        },
    });

    const handleFileSelection = (type: DocumentType, files?: File[]) => {
        if (files) {
            files.forEach((file) => {
                if (exceedsMaxFileSize(file.size) || !isValidFileType(type, file.name)) {
                    updateFileStatus(type, file.name, FileUploadStatus.Error);
                } else {
                    updateFileStatus(type, file.name, FileUploadStatus.Uploading, true);
                    uploadDocument.mutateAsync({ file, type });
                }
            });
        }
    };

    const getInvalidMessage = (type: DocumentType) => (file: File) => {
        if (!isValidFileType(type, file.name)) {
            return t('uiDomain:validation.unsupporttedFileType');
        }
        if (exceedsMaxFileSize(file.size)) {
            return t('uiDomain:validation.fileItemSizeError', {
                sizeLimitInMegabytes: FILE_SIZE_LIMIT_IN_MEGABYTES.toString(),
            });
        }
        return undefined;
    };
    const validate = (type: DocumentType, input?: ValidatableElement) => {
        if (input && input instanceof HTMLInputElement && input.files) {
            return !Array.from(input.files).some((file) => file && getInvalidMessage(type)(file));
        }
        return true;
    };

    const packageValidator = {
        validate: (value: unknown, input?: ValidatableElement) => validate(DocumentType.PACKAGE, input),
        message: ' ', // error is displayed below the file item
    };

    const setTitle = (packageTitle: string) => {
        updateListUploadStepData({ packageTitle });
    };
    const setDescription = (packageDescription: string) => {
        updateListUploadStepData({ packageDescription });
    };

    return (
        <div className="list-upload-step">
            <FormLayout>
                <form ref={formRef}>
                    <InputTextFieldCharacterCount
                        name="packageTitle"
                        headerText={t('application.createListingListUpload.packageTitleHeader')}
                        label={t('application.createListingListUpload.packageTitleLabel')}
                        placeholder={t('application.createListingListUpload.packageTitlePlaceholder')}
                        maxLength={80}
                        value={listUploadStepData.packageTitle ?? ''}
                        onChange={setTitle}
                        validators={[requiredFieldValidator]}
                        forceValidation={stepVisited}
                    />
                    <div className="list-upload-description">
                        <FormItem header={t('application.createListingListUpload.packageDescriptionHeader')}>
                            <InputTextarea
                                name="packageDescription"
                                label={t('application.createListingListUpload.packageDescriptionLabel')}
                                placeholder={t('application.createListingListUpload.packageDescriptionPlaceholder')}
                                onChange={setDescription}
                                value={listUploadStepData.packageDescription}
                                validators={[requiredFieldValidator]}
                                forceValidation={stepVisited}
                            />
                        </FormItem>
                    </div>
                    <div className="list-upload-file">
                        <FormItem header={t('application.createListingListUpload.packageListUploadHeader')}>
                            <InputFile
                                label={t('application.createListingListUpload.packageListUploadLabel')}
                                data-testid="package-file-input"
                                fileType={['.xls', '.xlsx']}
                                onChange={(files) => {
                                    handleFileSelection(DocumentType.PACKAGE, files);
                                }}
                                fileTypeHelperText={t(
                                    'application.createListingListUpload.packageFileInputHelperText',
                                    { fileType: 'xls, xlsx' }
                                )}
                                validators={[requiredFieldValidator, packageValidator]}
                                forceValidation={stepVisited}
                                fileStatus={packageStatus}
                                initialSelectedFiles={getSelectedFiles(listUploadStepData.packageFile)}
                                onRemoveFile={(filename: string) => {
                                    updateFileStatus(DocumentType.PACKAGE, filename);
                                }}
                                getInvalidMessage={getInvalidMessage(DocumentType.PACKAGE)}
                                getDownloadFile={async (documentId: string) => {
                                    const fileDownload = await exchangeDocumentIdForAccessibleLink(
                                        constants.apiBaseUrl,
                                        documentId,
                                        auth.getToken().get()
                                    );
                                    return fileDownload;
                                }}
                            />
                        </FormItem>
                    </div>
                </form>
            </FormLayout>
        </div>
    );
};
