import { useCallback } from 'react';
import { Feature } from '@steelbuy/ts-shared';
import { useUserSelfDetails } from './getUserSelf';

export const useFeatureFlag = () => {
    const { data } = useUserSelfDetails();
    const isFeatureSupported = useCallback((feature: Feature) => data?.features?.includes(feature) || false, [data]);
    const areFeaturesSupported = useCallback(
        (features: Feature[]) => features.every((feature) => data?.features?.includes(feature) || false),
        [data]
    );
    return { isFeatureSupported, areFeaturesSupported };
};

export const useIsFeatureSupported = (feature: Feature) => {
    const { isFeatureSupported } = useFeatureFlag();
    return isFeatureSupported(feature);
};
