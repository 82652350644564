import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { JsonWebToken } from '@steelbuy/auth';
import { AnyErrorObject } from '@steelbuy/error';
import { Mutable, Mutation, ViewModel, ModelPrimaryKey, Nullable, ReadonlyOptional } from '@steelbuy/ts-shared';

import { ActionStatus } from '../store/ActionStatus';
import { EntityStore } from '../store/crud/EntityStore';
import { EntityStoreAccessor } from '../store/crud/EntityStoreAccessor';
import { FetchStatus } from '../store/FetchStatus';

export type EntityDataProviderValue<Model extends ViewModel> = {
    fetch: () => void;
    query: () => ReadonlyOptional<Model>;
    queryCreated: () => ReadonlyOptional<Model>;
    queryServiceEntity: () => ReadonlyOptional<Model>;
    mutate: (mutation: Mutation<Model>) => void;
    create: (mutatable: Mutable<Model>) => void;
    delete: () => void;
    entityService: (id: ModelPrimaryKey, service: string, modelMutation?: Mutation<Model>) => void;
    idle: () => boolean;
    queryFetchStatus: () => FetchStatus;
    queryFetchError: () => Nullable<AnyErrorObject>;
    queryActionStatus: () => ActionStatus;
    queryActionError: () => Nullable<AnyErrorObject>;
    resolveFetchStatus: () => void;
    resolveActionStatus: () => void;
    useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void) => void;
    useActionStatusEffect: (status: Array<ActionStatus>, effect: () => void, resolve: boolean) => void;
};

// noinspection JSUnusedLocalSymbols
export const defaultEntityDataValue: EntityDataProviderValue<never> = {
    idle: (): boolean => true,
    queryFetchStatus: (): FetchStatus => FetchStatus.IDLE,
    queryFetchError: (): Nullable<AnyErrorObject> => null,
    queryActionStatus: (): ActionStatus => ActionStatus.IDLE,
    queryActionError: (): Nullable<AnyErrorObject> => null,
    fetch: (): void => {
        throw new Error('Data provider missing');
    },
    query: (): ReadonlyOptional<never> => {
        throw new Error('Data provider missing');
    },
    queryCreated: (): ReadonlyOptional<never> => {
        throw new Error('Data provider missing');
    },
    queryServiceEntity: (): ReadonlyOptional<never> => {
        throw new Error('Data provider missing');
    },
    resolveFetchStatus: (): void => {
        throw new Error('Data provider missing');
    },
    resolveActionStatus: (): void => {
        throw new Error('Data provider missing');
    },
    mutate: (mutation: Mutation<never>): void => {
        throw new Error('Data provider missing');
    },
    create: (mutatable: Mutable<never>): void => {
        throw new Error('Data provider missing');
    },
    delete: (): void => {
        throw new Error('Data provider missing');
    },
    entityService: (id: ModelPrimaryKey, service: string, modelMutation?: Mutation<never>): void => {
        throw new Error('Data provider missing');
    },
    useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void): void => {
        throw new Error('Data provider missing');
    },
    useActionStatusEffect: (status: Array<ActionStatus>, effect: () => void, resolve = false): void => {
        throw new Error('Data provider missing');
    },
};

export const createEntityDataProviderValue = <
    Model extends ViewModel,
    RootStore extends Record<string, EntityStore<Model>>
>(
    storeKey: string,
    storeAccessors: EntityStoreAccessor<Model, RootStore>,
    scope: ModelPrimaryKey,
    apiBaseUrl: string,
    jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>,
    roles?: string[]
): EntityDataProviderValue<Model> => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useDispatch();

    const dataProviderValue = {
        idle: (): boolean => dataProviderValue.queryFetchStatus() === FetchStatus.IDLE,
        queryFetchStatus: (): FetchStatus =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, FetchStatus>(
                (state) => state[storeKey].scopes?.[scope]?.fetchStatus ?? FetchStatus.IDLE
            ),
        queryFetchError: (): Nullable<AnyErrorObject> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<AnyErrorObject>>(
                (state) => state[storeKey].scopes?.[scope]?.lastFetchError ?? null
            ),
        queryActionStatus: (): ActionStatus =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ActionStatus>(
                (state) => state[storeKey].scopes?.[scope]?.actionStatus ?? ActionStatus.IDLE
            ),
        queryActionError: (): Nullable<AnyErrorObject> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, Nullable<AnyErrorObject>>(
                (state) => state[storeKey].scopes?.[scope]?.lastActionError ?? null
            ),
        fetch: (): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.fetch({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    id: scope,
                    roles,
                })
            );
        },
        query: (): ReadonlyOptional<Model> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ReadonlyOptional<Model>>(storeAccessors.select(scope)),
        queryCreated: (): ReadonlyOptional<Model> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ReadonlyOptional<Model>>(storeAccessors.selectCreated()),
        queryServiceEntity: (): ReadonlyOptional<Model> =>
            // eslint-disable-next-line react-hooks/rules-of-hooks,implicit-arrow-linebreak
            useSelector<RootStore, ReadonlyOptional<Model>>(storeAccessors.selectServiceEntity()),
        resolveFetchStatus: (): void => {
            dispatch(storeAccessors.resetFetchStatus(scope));
        },
        resolveActionStatus: (): void => {
            dispatch(storeAccessors.resetActionStatus(scope));
        },
        mutate: (modelMutation: Mutation<Model>): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.mutateEntity({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    id: scope,
                    modelMutation,
                    roles,
                })
            );
        },
        create: (mutableModel: Mutable<Model>): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.createEntity({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    mutableModel,
                    roles,
                })
            );
        },
        delete: (): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.deleteEntity({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    id: scope,
                    roles,
                })
            );
        },
        entityService: (id: ModelPrimaryKey, service: string, modelMutation?: Mutation<Model>): void => {
            // TODO: Remove ts-ignore after updating react-redux and / or @reduxjs/toolkit to a more compatible set
            dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                storeAccessors.entityService({
                    apiBaseUrl,
                    jsonWebTokenLoader,
                    id,
                    service,
                    modelMutation,
                    roles,
                })
            );
        },
        useFetchStatusEffect: (status: Array<FetchStatus>, effect: () => void): void => {
            const fetchStatus = dataProviderValue.queryFetchStatus();
            useEffect(() => {
                if (status.includes(fetchStatus)) {
                    effect();
                }
            }, [fetchStatus]);
        },
        useActionStatusEffect: (status: Array<ActionStatus>, effect: () => void, resolve = false): void => {
            const actionStatus = dataProviderValue.queryActionStatus();
            useEffect(() => {
                if (status.includes(actionStatus)) {
                    try {
                        effect();
                    } finally {
                        if (resolve) {
                            dataProviderValue.resolveActionStatus();
                        }
                    }
                }
            }, [actionStatus]);
        },
    };

    return dataProviderValue;
};
