import { useState } from 'react';
import { useCreatePackageOrder } from '@steelbuy/data-access';
import { useAddressCollectionData } from '@steelbuy/data-provider';
import { PackageBuyerModel } from '@steelbuy/ts-shared';
import { LoadingSpinner } from '@steelbuy/ui-primitive';
import { CheckoutErrorLayout } from './CheckoutErrorLayout';
import { RoutePath } from '../../router/Routes';
import { useCheckoutContext } from '../checkout/CheckoutContext';
import { CheckoutSuccessPackage } from '../checkout-success/CheckoutSuccessPackage';

type CheckoutPackageErrorProps = {
    onBack: () => void;
    onRetry: () => void;
    retryCount: number;
    packageModel: PackageBuyerModel;
};

export const CheckoutPackageError = ({ onBack, onRetry, retryCount, packageModel }: CheckoutPackageErrorProps) => {
    const { invoiceAddressCollectionData } = useAddressCollectionData();
    const checkoutContext = useCheckoutContext();
    const invoiceAddresses = invoiceAddressCollectionData.query();
    const { mutate, isLoading } = useCreatePackageOrder();
    const [orderPlaced, setOrderPlaced] = useState<string | null>(null);

    const handleRetry = () => {
        if (!packageModel || !checkoutContext.deliveryAddressId) {
            return;
        }
        onRetry();
        mutate(
            {
                packagingId: packageModel.id,
                invoiceAddressId: invoiceAddresses[0]?.id ?? '',
                deliveryAddressId: checkoutContext.deliveryAddressId,
                additionalDeliveryInformation: checkoutContext.additionalDeliveryInformation,
                buyerOrderNumber: checkoutContext.buyerOrderNumber,
                haulierRequired: checkoutContext.haulierRequired,
            },
            {
                onSuccess: ({ orderNumber }) => {
                    setOrderPlaced(orderNumber);
                },
            }
        );
    };

    if (isLoading) return <LoadingSpinner />;

    if (orderPlaced) return <CheckoutSuccessPackage orderModel={{ orderNumber: orderPlaced }} />;

    return (
        <CheckoutErrorLayout
            checkoutPath={RoutePath.PACKAGE_SEARCH_CHECKOUT}
            handleRetry={handleRetry}
            onBack={onBack}
            retryCount={retryCount}
        />
    );
};
