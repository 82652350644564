import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserSelfDetails } from '@steelbuy/data-access';
import { BASIC_USER_ROLE, UserRole } from '@steelbuy/ts-shared';

import { PermissionInformation } from '@steelbuy/ui-domain';
import {
    Accordion,
    AccordionGroup,
    Card,
    CardContentAccountAttribute,
    CardContentAttributesItem,
    CardLayout,
    NotificationLevel,
    Notification,
} from '@steelbuy/ui-primitive';

import { RoutePath } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';

export const MyAccount = () => {
    const { t } = useTranslation(['translation', 'domainModel']);
    const { data: userModel } = useUserSelfDetails();
    const navigate = useNavigate();
    const location = useLocation();
    const { successNotification = '', errorNotification = '' } = location.state || {};
    const permissions = [BASIC_USER_ROLE].concat(userModel?.roles ?? []);
    const editEmail = () => {
        navigate(RoutePath.ACCOUNT_EDIT_EMAIL, {
            state: {
                email: userModel?.email,
            },
        });
    };

    const editMfa = () => {
        navigate(RoutePath.ACCOUNT_EDIT_MFA, {
            state: {
                email: userModel?.email,
            },
        });
    };

    const updatePassword = () => {
        navigate(RoutePath.ACCOUNT_CHANGE_PASSWORD, {
            state: {
                email: userModel?.email,
            },
        });
    };

    return (
        <DetailsLayout>
            {successNotification && (
                <Notification level={NotificationLevel.SUCCESS} message={successNotification} stayOpen />
            )}
            {errorNotification && <Notification level={NotificationLevel.ERROR} message={errorNotification} stayOpen />}
            <Card isClickable={false}>
                <CardLayout>
                    <CardContentAccountAttribute
                        buttonLabel={t('translation:application.myAccount.editEmailAddress')}
                        onButtonClick={editEmail}
                        header={t('translation:application.myAccount.myAccountCardHeader')}
                        isLargeButton
                    >
                        <CardContentAttributesItem
                            label={t('translation:application.myAccount.myAccountCardNameLabel')}
                            value={userModel?.name ?? ''}
                        />
                        <CardContentAttributesItem
                            label={t('translation:application.myAccount.myAccountCardEmailLabel')}
                            value={userModel?.email ?? ''}
                        />
                    </CardContentAccountAttribute>
                </CardLayout>
            </Card>

            <Card isClickable={false}>
                <CardLayout>
                    <CardContentAccountAttribute
                        buttonLabel={t('translation:application.myAccount.passwordCardButtonLabel')}
                        header={t('translation:application.myAccount.passwordCardHeader')}
                        notificationLevel={NotificationLevel.INFO}
                        onButtonClick={updatePassword}
                        isLargeButton
                    >
                        <CardContentAttributesItem value="***************" />
                    </CardContentAccountAttribute>
                </CardLayout>
            </Card>
            <Card isClickable={false}>
                <CardLayout>
                    <CardContentAccountAttribute
                        buttonLabel={t('translation:application.myAccount.mfa.button')}
                        onButtonClick={editMfa}
                        header={t('translation:application.myAccount.mfa.header')}
                        isLargeButton
                    >
                        <CardContentAttributesItem
                            value={t(
                                `translation:application.myAccount.mfa.${
                                    userModel?.mfaPreferences === 'Enabled' ? 'enabled' : 'disabled'
                                }`
                            )}
                        />
                    </CardContentAccountAttribute>
                </CardLayout>
            </Card>
            <Card isClickable={false}>
                <CardLayout>
                    <CardContentAccountAttribute header={t('translation:application.myAccount.permissionCardHeader')}>
                        <CardContentAttributesItem
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            value={permissions.map((p) => t(`domainModel:user.permissions.${p}`)).join(', ')}
                        />
                        <AccordionGroup>
                            <Accordion key="BASIC" collapsedLabel={t('domainModel:user.permissions.BASIC')}>
                                <PermissionInformation />
                            </Accordion>
                            {permissions.includes(UserRole.BUYER) && (
                                <Accordion
                                    key={UserRole.BUYER}
                                    collapsedLabel={t('domainModel:user.permissions.BUYER')}
                                >
                                    <PermissionInformation role={UserRole.BUYER} />
                                </Accordion>
                            )}
                            {permissions.includes(UserRole.SELLER) && (
                                <Accordion
                                    key={UserRole.SELLER}
                                    collapsedLabel={t('domainModel:user.permissions.SELLER')}
                                >
                                    <PermissionInformation role={UserRole.SELLER} />
                                </Accordion>
                            )}
                            {permissions.includes(UserRole.MANAGER) && (
                                <Accordion
                                    key={UserRole.MANAGER}
                                    collapsedLabel={t('domainModel:user.permissions.MANAGER')}
                                >
                                    <PermissionInformation role={UserRole.MANAGER} />
                                </Accordion>
                            )}
                        </AccordionGroup>
                    </CardContentAccountAttribute>
                </CardLayout>
            </Card>
        </DetailsLayout>
    );
};
