import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getProductTypes, MaterialProperties, Product, ProductType } from '@steelbuy/ts-shared';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

export const MaterialProductTypeSelection = ({
    material,
    typeSelected,
    isPrime,
}: {
    material: MaterialProperties;
    typeSelected: (type?: ProductType) => void;
    isPrime: boolean;
}) => {
    const { t } = useTranslation('domainModel');
    const availableProductTypes = getProductTypes(material, isPrime)?.slice()?.sort();
    const materialPlateType = material.plateType;

    useEffect(() => {
        if (materialPlateType && availableProductTypes.length === 0) {
            typeSelected(undefined);
        } else if (materialPlateType && !availableProductTypes.includes(materialPlateType)) {
            switch (material.product) {
                case Product.COIL:
                case Product.SHEET:
                    typeSelected(ProductType.STRUCTURAL_STEEL);
                    break;
                default:
                    typeSelected(availableProductTypes[0]);
                    break;
            }
        } else if (!materialPlateType && availableProductTypes.length > 0) {
            typeSelected(ProductType.STRUCTURAL_STEEL);
        }
    }, [material.product, typeSelected, materialPlateType, availableProductTypes]);

    useEffect(() => {
        if (isPrime && materialPlateType === ProductType.ROAD_PLATE) {
            typeSelected(ProductType.STRUCTURAL_STEEL);
        }
    }, [isPrime]);

    if (!availableProductTypes.length) {
        return null;
    }

    const productTypeOptions = availableProductTypes.map((type) => ({
        label: t(`material.plateType.value.${type}`),
        value: type,
    }));

    return (
        <FormItem header={t('material.plateType.name')} isMandatory>
            <InputSelect
                options={productTypeOptions}
                value={materialPlateType}
                onSelect={(value) => typeSelected(value)}
            />
        </FormItem>
    );
};
