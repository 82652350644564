/* eslint-disable react/destructuring-assignment */
import { useEffect, useRef, useState } from 'react';
import { isArray, isDefined } from '@steelbuy/ts-shared';
import { buildClassStringFromClassMap } from '@steelbuy/util';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { InputCheckbox } from '../input-checkbox/InputCheckbox';

import './Dropdown.scss';

export type SelectOption = {
    label: string;
    value: string;
    iconName?: IconIdentifier;
};

type SingleSelectProps = {
    multiselect?: false;
    preselection?: string;
    onChange: (selectedValue?: string) => void;
};

type MultiSelectProps = {
    multiselect: true;
    preselection?: string[];
    onChange: (selectedValues: string[]) => void;
};

export type DropdownProps = {
    title?: string;
    options: SelectOption[];
    disabled?: boolean;
    className?: string;
    placeholder?: string;
} & (SingleSelectProps | MultiSelectProps);

export const Dropdown = (props: DropdownProps) => {
    const { title, options, className, placeholder, disabled = false, multiselect = false, preselection = [] } = props;

    const [open, setOpen] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                setOpen(false);
            }
        };
        if (open) {
            document.addEventListener('click', handleClick);
            return () => {
                document.removeEventListener('click', handleClick);
            };
        }
        return undefined;
    }, [ref, open]);

    function getPreselectedOptions() {
        const preselectedOptions = isArray(preselection) ? preselection : [preselection];
        return preselectedOptions.map((value) => options.find((option) => option.value === value)).filter(isDefined);
    }

    const selection = getPreselectedOptions();
    const toggle = () => setOpen(!open);

    const dropdownClassMap = {
        dropdown: true,
        'dropdown--multiselect': multiselect,
        'dropdown--disabled': disabled,
        [className ?? '']: true,
    };

    function isOptionInSelection(option: SelectOption) {
        return selection.some((selected) => selected.value === option.value);
    }

    function handleOnClick(option: SelectOption) {
        let newSelection: SelectOption[];

        if (!isOptionInSelection(option)) {
            if (!multiselect) {
                newSelection = [option];
                setOpen(false);
            } else {
                newSelection = [...selection, option];
            }
        } else {
            newSelection = selection.filter((current) => current.value !== option.value);
        }

        // need to access via `props.` here to ensure TypeScript is getting the types right
        if (props.multiselect) {
            props.onChange(newSelection.map((e) => e.value));
        } else {
            props.onChange(newSelection[0]?.value);
        }
    }

    const renderIcon = () => {
        if (open) {
            return <Icon name={IconIdentifier.CHEVRON_UP} />;
        }
        return <Icon name={IconIdentifier.CHEVRON_DOWN} />;
    };

    const renderTitle = () => {
        if (selection.length === 0) {
            return <span className="dropdown__wrapper__header__title--empty">{placeholder}</span>;
        }
        return (
            <span className="dropdown__wrapper__header__title">
                {selection.map((selectedItem) => selectedItem.label).join(', ')}
            </span>
        );
    };

    function renderDropdownList() {
        return (
            <ul className="dropdown__list">
                {options.map((option) => (
                    <li className="dropdown__list__item" key={option.value}>
                        <button type="button" onClick={() => handleOnClick(option)}>
                            {option.iconName ? <Icon name={option.iconName} /> : null}
                            {multiselect ? <InputCheckbox checked={isOptionInSelection(option)} /> : null}
                            <span className="item__label">{option.label}</span>
                            {!multiselect ? (
                                <span className="item--is-selected">
                                    {isOptionInSelection(option) && <Icon name={IconIdentifier.OK} />}
                                </span>
                            ) : null}
                        </button>
                    </li>
                ))}
            </ul>
        );
    }

    return (
        <div className={buildClassStringFromClassMap(dropdownClassMap)} ref={ref}>
            <label className="dropdown__label">{title}</label>
            <div className="dropdown__wrapper">
                <div
                    className="dropdown__wrapper__header"
                    tabIndex={0}
                    role="button"
                    onKeyPress={() => toggle()}
                    onClick={() => toggle()}
                >
                    {renderTitle()}
                    {renderIcon()}
                </div>
                {open ? renderDropdownList() : null}
            </div>
        </div>
    );
};
