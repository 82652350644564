import { OrganisationModel, OrganisationModelConverter } from '@steelbuy/ts-shared';

import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class OrganisationSelfCrudApiClient extends AbstractCrudApiClient<OrganisationModel> {
    protected collectionControllerUri = 'api/universal/organisations/self/';

    protected paginationControllerUri = 'api/universal/organisations/self/';

    protected entityCreateUri = 'api/manager/organisations/self/';

    protected entityFetchUri;

    protected entityMutateUri = 'api/manager/organisations/self/';

    protected entityDeleteUri = 'api/manager/organisations/self/';

    protected entityServiceCallUri = 'api/manager/organisations/self/';

    protected override paginationSize = 10;

    protected modelConverter = new OrganisationModelConverter();

    constructor(uriInfix = 'universal') {
        super();

        this.entityFetchUri = `api/${uriInfix}/organisations/self/`;
    }
}
