import { useQuery } from '@tanstack/react-query';
import { ApiModel, UserModel, UserModelConverter } from '@steelbuy/ts-shared';
import axios from '../../axios';

export const USER_SELF_CACHE_KEY = 'user-self';

const modelConverter = new UserModelConverter();

const getUserSelf = async () => {
    const { data } = await axios.get<UserModel>(`/api/universal/users/self`);
    const userModel = modelConverter.toViewModel(data as unknown as ApiModel<UserModel>);
    return userModel;
};

export const useUserSelfDetails = ({ queryOptions } = { queryOptions: { enabled: false } }) =>
    useQuery({
        queryKey: [USER_SELF_CACHE_KEY],
        queryFn: () => getUserSelf(),
        ...queryOptions,
    });
