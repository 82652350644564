import { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useUserSelfDetails } from '@steelbuy/data-access';
import { InputCheckbox, CustomLink } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import './LegalCheckboxes.scss';

const LegalCheckboxes = ({ setValidity }: { setValidity: (value: boolean) => void }) => {
    const { t } = useTranslation('translation');
    const [agreedToTAndC, setAgreedToTAndC] = useState<boolean>(false);
    const [agreedAuthorised, setAgreedAuthorised] = useState<boolean>(false);
    const { data: userData } = useUserSelfDetails();

    useEffect(() => {
        if (agreedToTAndC && agreedAuthorised) {
            setValidity(true);
        } else {
            setValidity(false);
        }
    }, [agreedToTAndC, agreedAuthorised]);

    return (
        <div className="legal-checkboxes">
            <InputCheckbox
                label={
                    <>
                        {t('application.legalCheckboxes.checkbox1Label', {
                            organisationName: userData?.organisation?.name || 'my organisation',
                        })}
                    </>
                }
                checked={agreedAuthorised}
                onChange={(checked) => setAgreedAuthorised(checked)}
                required
            />

            <InputCheckbox
                label={
                    <Trans
                        i18nKey="application.legalCheckboxes.checkbox2Label"
                        components={{
                            websitetncs: <CustomLink path={RoutePath.TERMS_WEBSITE} isInlineLink openInNewTab />,
                            sellertncs: <CustomLink path={RoutePath.TERMS_SELLER} isInlineLink openInNewTab />,
                        }}
                        t={t}
                        values={{ organisationName: userData?.organisation?.name }}
                    />
                }
                onChange={() => setAgreedToTAndC(!agreedToTAndC)}
                required
            />
        </div>
    );
};

export default LegalCheckboxes;
