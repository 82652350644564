import { useTranslation } from 'react-i18next';
import {
    Coating,
    CoatingThicknessType,
    Finish,
    Grade,
    Definition,
    ProductType,
    Specification,
    Surface,
    Temper,
    CoatingCoverage,
    CoatingType,
    CoatingColourType,
    MillFinish,
    Polish,
    MaterialType,
    getCoatingThicknessText,
} from '@steelbuy/ts-shared';

type MaterialProperties = {
    materialType: MaterialType;
    grade: Grade;
    specification: Specification;
    definition: Definition;
    plateType: ProductType;
    coating: Coating;
    coatingThicknessValue: CoatingThicknessType;
    surface: Surface;
    finish: Finish;
    temper: Temper;
    coatingColour: CoatingColourType;
    coatingType: CoatingType;
    coatingCoverage: CoatingCoverage;
    millFinish: MillFinish;
    polish: Polish;
    packageTitle: string;
};

export type MaterialProperty = {
    name: keyof MaterialProperties;
    label: string;
};

/**
 * Returns an array of material properties in the specific order they should be displayed.
 */
export const useMaterialProperties = (
    data: Partial<MaterialProperties> | undefined,
    ignoreFields: (keyof MaterialProperties)[] = []
) => {
    const { t } = useTranslation('domainModel');

    const properties: Array<MaterialProperty> = [];

    const addProperty = (property: keyof MaterialProperties) => {
        const value = data?.[property];

        if (!value || ignoreFields.includes(property)) {
            return;
        }

        if (property === 'coatingColour' || property === 'packageTitle') {
            properties.push({
                name: property,
                label: value,
            });
            return;
        }

        if (data && property === 'coatingThicknessValue') {
            const thicknessValue = getCoatingThicknessText(data) || '';
            properties.push({
                name: property,
                label: thicknessValue,
            });
            return;
        }
        properties.push({
            name: property,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label: t(`material.${property}.value.${value}`),
        });
    };

    addProperty('materialType');
    addProperty('grade');
    addProperty('specification');
    addProperty('definition');
    addProperty('plateType');
    addProperty('temper');
    addProperty('coating');
    addProperty('coatingThicknessValue');
    addProperty('surface');
    addProperty('finish');
    addProperty('millFinish');
    addProperty('polish');
    addProperty('coatingColour');
    addProperty('coatingType');
    addProperty('coatingCoverage');
    addProperty('packageTitle');

    return properties;
};
