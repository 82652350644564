import { UserCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { UserModel, Nullable } from '@steelbuy/ts-shared';
import {
    createCollectionSlice,
    createSelectCollection,
    createSelectCollectionEntity,
    createSelectCreated,
} from '../../util/store/crud/CollectionSlice';
import { CollectionStore } from '../../util/store/crud/CollectionStore';
import { CollectionStoreAccessors } from '../../util/store/crud/CollectionStoreAccessor';
import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchAllThunk,
    createFetchNextThunk,
    createFetchOneThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const userCollectionStoreName = 'user-collection-crud';
export type UserCollectionStore = {
    [userCollectionStoreName]: CollectionStore<UserModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new UserCrudApiClient().init(apiBaseUrl, jsonWebToken);
const fetchAll = createFetchAllThunk<UserModel, UserCollectionStore, UserCrudApiClient>(
    userCollectionStoreName,
    createApiClient
);
const fetchNext = createFetchNextThunk<UserModel, UserCollectionStore, UserCrudApiClient>(
    userCollectionStoreName,
    createApiClient
);
const fetchOne = createFetchOneThunk<UserModel, UserCollectionStore, UserCrudApiClient>(
    userCollectionStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<UserModel, UserCrudApiClient>(userCollectionStoreName, createApiClient);
const createEntity = createCreateThunk<UserModel, UserCrudApiClient>(userCollectionStoreName, createApiClient);
const deleteEntity = createDeleteThunk<UserModel, UserCrudApiClient>(userCollectionStoreName, createApiClient);
const entityService = createEntityServiceThunk<UserModel, UserCrudApiClient>(userCollectionStoreName, createApiClient);

// Slice definition
export const userCollectionSlice = createCollectionSlice<UserModel>(
    userCollectionStoreName,
    fetchAll,
    fetchNext,
    fetchOne,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetFetchStatus, resetActionStatus } = userCollectionSlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const selectCollection = createSelectCollection<UserModel, UserCollectionStore>(userCollectionStoreName);
const selectEntity = createSelectCollectionEntity<UserModel, UserCollectionStore>(userCollectionStoreName);
const selectCreated = createSelectCreated<UserModel, UserCollectionStore>(userCollectionStoreName);

// Export the store accessors to be used by the data provider value
export const userCollectionStoreAccessors = {
    fetchAll,
    fetchNext,
    fetchOne,
    createEntity,
    mutateEntity,
    deleteEntity,
    resetFetchStatus,
    resetActionStatus,
    selectCollection,
    selectEntity,
    selectCreated,
    entityService,
} as CollectionStoreAccessors<UserModel, UserCollectionStore>;
