import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { QuoteEntryDetail, RFQDetailsHeading, RFQQuoteEntryMaterial, RFQStatus, Literal } from '@steelbuy/ts-shared';
import { DownloadButton } from '@steelbuy/ui-domain';
import { Card } from '@steelbuy/ui-primitive';
import { entries } from '@steelbuy/util';
import { RFQTeaser } from '../../create-rfq/rfq-teaser/RFQTeaser';

import '../RFQCommonStyles.scss';
import './CardContentRFQDetails.scss';

export const TableRow = ({ data, row }: { data: RFQQuoteEntryMaterial; row: RFQDetailsHeading }) => {
    const { t } = useTranslation('domainModel');
    return (
        <tr className={classNames('rfq-row')}>
            {entries(row).map(([key, { literalPath, valueMapper }]) => {
                let value: string | number | undefined = '';
                if (data && data[key]) {
                    if (literalPath) {
                        value = t(`domainModel:${String(literalPath)}.${data[key]}` as Literal);
                    } else if (valueMapper) {
                        value = valueMapper(data);
                    } else {
                        value = data[key];
                    }
                }
                return (
                    <td key={key}>
                        <span>{value}</span>
                    </td>
                );
            })}
            <td className="empty-cell">
                <div className="empty-cell__content" />
            </td>
        </tr>
    );
};

type CardContentRFQDetailsProps = {
    quoteEntries: QuoteEntryDetail[];
    refNumber?: string;
    status?: RFQStatus;
    id?: string;
};

export const CardContentRFQDetails = ({ quoteEntries, refNumber, status, id }: CardContentRFQDetailsProps) => {
    const { t } = useTranslation(['translation', 'domainModel']);
    return (
        <div className="card-content-rfq-details">
            <Card isClickable={false}>
                <div className="card-content-rfq-details__title">
                    {t('translation:application.createRFQ.rfqDetails')}
                    {refNumber && (
                        <DownloadButton
                            downloadUrl={`/api/universal/documents/quote/${id}/download`}
                            label={t('translation:application.createRFQ.downloadRfq')}
                            disabled={status === RFQStatus.WITHDRAWN || status === RFQStatus.EXPIRED}
                            data-testid="download-rfq-button"
                            fileName={`RFQ-${id}.xls`}
                        />
                    )}
                </div>
                {refNumber && (
                    <div className="card-content-rfq-details__reference">
                        <div className="card-content-rfq-details__reference__label">
                            {t('translation:application.createRFQ.rfqReferenceNumber')}
                        </div>
                        {refNumber}
                    </div>
                )}
                {quoteEntries.map(({ materialType, product, fieldHeadings, materials, group: { label, value } }) => (
                    <div className="card-content-rfq-details__content" key={`rfq-details-group-${value}`}>
                        <RFQTeaser material={{ materialType, product, [label]: value }} />
                        <div className="rfq-table">
                            <table className="rfq-table__table">
                                <thead>
                                    <tr className="rfq-table__label">
                                        {entries(fieldHeadings).map(([key, { measurementLiteral }]) => {
                                            let measurementText = '';
                                            if (measurementLiteral) {
                                                const literalText = t(`domainModel:${measurementLiteral}` as Literal);
                                                measurementText = ` (${literalText})`;
                                            }
                                            return (
                                                <th key={key}>
                                                    {t(`translation:application.rfqHeadingLabels.${key}` as Literal)}
                                                    {measurementText ? <span>{measurementText}</span> : null}
                                                </th>
                                            );
                                        })}
                                        <th className="empty-cell">
                                            <div className="empty--cell__content" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {materials.map((data, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <TableRow
                                            data={data}
                                            row={fieldHeadings}
                                            key={data.id ? `${data.id}` : `rfq-details-group-${value}-${index}`}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </Card>
        </div>
    );
};
