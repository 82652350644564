import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSellerAcceptOfferMutation } from '@steelbuy/data-access';
import { useModal } from '@steelbuy/modal-dialog';
import { OfferView } from '@steelbuy/ts-shared';
import { ConfirmCancelModel } from '@steelbuy/ui-primitive';

export const OfferAcceptedModal = ({
    show,
    negotiationId,
    onModalClose,
    onStatusChange,
    view,
}: {
    show: boolean;
    negotiationId: string;
    onModalClose: () => void;
    onStatusChange: (message?: string, errorMessage?: string) => void;
    view: OfferView;
}) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const offerAcceptedModal = useModal(onModalClose);
    const { isLoading, mutate } = useSellerAcceptOfferMutation();
    const handleAcceptOffer = () => {
        mutate(
            { negotiationId },
            {
                onSuccess: () => {
                    offerAcceptedModal.hide();
                    onStatusChange(
                        view === OfferView.SELLER
                            ? t('application.offers.acceptMsgSeller')
                            : t('application.offers.acceptMsgBuyer')
                    );
                },
                onError: () => {
                    offerAcceptedModal.hide();
                    onStatusChange(undefined, t('application.offers.acceptErrorMsg'));
                },
            }
        );
    };

    useEffect(() => {
        if (show) {
            offerAcceptedModal.show();
        } else {
            offerAcceptedModal.hide();
        }
    }, [show]);
    return (
        <ConfirmCancelModel
            modalAccessor={offerAcceptedModal}
            header={t('application.offers.acceptModelHeader')}
            bodyImage="assets/lib-ui-primitive/images/ListingDraft-Success.svg"
            cancelLabel={t('uiDomain:common.cancel')}
            submitLabel={t('application.offers.accept')}
            onSubmit={handleAcceptOffer}
            isSubmitting={isLoading}
            isDanger={false}
            onClose={onModalClose}
        />
    );
};
