import { AddressType } from '@steelbuy/ts-shared';
import { useGetAddress } from './getAddress';
import { FilterComparator, SortDirection } from '../../utils/types';

export const useGetInvoiceAddress = () =>
    useGetAddress({
        limit: 100,
        filter: [
            {
                property: 'addressType',
                comparator: FilterComparator.EQUAL,
                value: AddressType.INVOICE,
            },
        ],
        sort: [
            {
                property: 'postalCode',
                direction: SortDirection.ASCENDING,
            },
        ],
    });
