import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { TrackingEvent, useAnalytics } from '@steelbuy/analytics';
import {
    useCreateDraftListing,
    useCreateListing,
    useCreatePackageListing,
    useUserSelfDetails,
} from '@steelbuy/data-access';
import {
    getCoatingColours,
    formatCurrency,
    getPricingUnit,
    getTradeUnit,
    getTradeUnitCalculation,
    Product,
    TradeUnitCalculation,
    UserRole,
} from '@steelbuy/ts-shared';

import {
    ListingDetailsDimensions,
    ListingDetailsExpiration,
    ListingDetailsMaterial,
    ListingDetailsPackage,
    ListingDetailsPackageSheet,
    ListingDetailsQuality,
    MarketingBannerSteelbuyPromise,
    useNumberOfItemsLabel,
} from '@steelbuy/ui-domain';
import {
    AddressIdentifier,
    ButtonCallToAction,
    ButtonPrimary,
    ButtonTertiaryOnLightM,
    Card,
    CardContentAttributesItem,
    CardLayout,
    CardSellerSummaryWeightPrice,
    CustomLink,
    FormActionbar,
    InputCheckbox,
    LoadingSpinnerOverlay,
    Notification,
    NotificationLevel,
    WizardBar,
    WizardBarItemStatus,
} from '@steelbuy/ui-primitive';
import { constants } from '../../constants';

import { createRouteUrl, RoutePath } from '../../router/Routes';
import { DetailsLayout } from '../../views/layouts/details-layout/DetailsLayout';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { AddressDetails } from '../address-details/AddressDetails';
import { useCreateListingContext } from '../create-listing/CreateListingContext';
import {
    contextToDraft,
    contextToListing,
    contextToPackage,
    contextToPackageDraft,
} from '../create-listing/CreateListingContextMapper';
import { getCurrentStepIndex, getSteps, Steps } from '../create-listing/CreateListingContextUtil';
import { CreateListingSaveDraftButton } from '../create-listing/CreateListingSaveDraftButton';
import { PACKAGE_STEPS, STEPS } from '../create-listing/CreateListingSteps';
import { CreateListingSuccess } from '../create-listing-success/CreateListingSuccess';
import { CreateListingUnsavedChangesModal } from '../create-listing-unsaved-changes-modal/CreateListingUnsavedChangesModal';
import { PageHeader } from '../page-header/PageHeader';

import './CreateListingSummary.scss';

export const CreateListingSummary = () => {
    const { t } = useTranslation(['translation', 'domainModel', 'uiDomain']);
    const navigate = useNavigate();
    const location = useLocation();
    const analytics = useAnalytics();
    const createListingContext = useCreateListingContext();
    const createListingMutation = useCreateListing();
    const createPackageMutation = useCreatePackageListing();
    const createDraftListingMutation = useCreateDraftListing();
    const { data: userData } = useUserSelfDetails();
    const hasSellerRole = userData?.roles.includes(UserRole.SELLER) ?? false;

    const materialData = createListingContext.materialStepData;
    const dimensionStepData = createListingContext.dimensionsStepData;
    const { weightPriceStepData, listUploadStepData } = createListingContext;
    const { pickupAddressStepData } = createListingContext;
    const coatingColours = getCoatingColours(materialData);

    const numberOfItemsLabel = useNumberOfItemsLabel(materialData.product);

    const [agreedToTAndC, setAgreedToTAndC] = useState<boolean>(false);
    const [agreedAuthorised, setAgreedAuthorised] = useState<boolean>(false);
    const [showSuccess, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const isPackageListing = materialData.product === Product.PACKAGE;
    const steps = isPackageListing ? PACKAGE_STEPS : STEPS;
    const [showSaveSuccess, setShowSaveSuccess] = useState<boolean>(false);
    const [showSaveFail, setShowSaveFail] = useState<boolean>(false);
    const tradeUnit = getTradeUnit(materialData);
    const pricingUnit = getPricingUnit(materialData);

    const sendAnalytics = (ListingId: string, enabled: boolean, expiresAt: string) => {
        analytics.sendEvent(TrackingEvent.LISTING_AUTORENEW_TOGGLED, {
            ListingId,
            enabled,
            toggledAt: new Date().toISOString(),
            expiresAt,
        });
        if (location.state?.origin && location.state?.alertId) {
            analytics.sendEvent(TrackingEvent.SUBMIT_IMR_LISTING, {
                alertId: location.state.alertId,
                created: new Date().toISOString(),
                origin: location.state.origin,
                material: materialData.materialType,
            });
        }
    };

    const handleSubmit = () => {
        if (createListingContext.draftId) {
            createDraftListingMutation.mutateAsync(
                {
                    operation: 'UPDATE',
                    draftId: createListingContext.draftId,
                    isPackage: isPackageListing,
                    listingDraft: isPackageListing
                        ? contextToPackageDraft(createListingContext)
                        : contextToDraft(createListingContext),
                },
                {
                    onSuccess: () => {
                        if (createListingContext.draftId) {
                            createDraftListingMutation.mutateAsync(
                                {
                                    operation: 'SUBMIT',
                                    draftId: createListingContext.draftId,
                                    isPackage: isPackageListing,
                                },
                                {
                                    onSuccess: ({ autoRenew, expiresAt, id }) => {
                                        // Type guard as create listing submit and save have different return models
                                        if (
                                            typeof autoRenew !== 'boolean' &&
                                            autoRenew?.enabled &&
                                            expiresAt &&
                                            !isPackageListing
                                        ) {
                                            sendAnalytics(id, autoRenew.enabled, expiresAt.value);
                                        }
                                        setSuccess(true);
                                        setError(false);
                                    },
                                    onError: () => setError(true),
                                }
                            );
                        } else {
                            setSuccess(true);
                        }
                    },
                }
            );
        } else if (materialData.product === Product.PACKAGE) {
            createPackageMutation.mutateAsync(contextToPackage(createListingContext), {
                onSuccess: () => {
                    setSuccess(true);
                    setError(false);
                },
                onError: () => setError(true),
            });
        } else {
            createListingMutation.mutateAsync(contextToListing(createListingContext), {
                onSuccess: ({ autoRenew, expiresAt, id }) => {
                    sendAnalytics(id, autoRenew.enabled, expiresAt.value);
                    setSuccess(true);
                    setError(false);
                },
                onError: () => setError(true),
            });
        }
    };

    const navigateToWizard = () => {
        const locationState = { state: location.state || {} };

        if (createListingContext.draftId) {
            navigate(
                createRouteUrl(
                    RoutePath.CREATE_LISTING_WIZARD_WITH_DRAFT,
                    ['draftId', createListingContext.draftId],
                    ['listingType', isPackageListing ? 'package' : 'listing']
                )
            );
        } else {
            navigate(RoutePath.CREATE_LISTING_WIZARD, locationState);
        }
    };

    const handleStepNavigation = (stepId: Steps) => {
        createListingContext.setCurrentStepId(stepId);
        navigateToWizard();
    };

    const renderPrice = () => {
        if (weightPriceStepData.sellingPriceAmount && weightPriceStepData.sellingPriceCurrencyCode) {
            return `${formatCurrency(
                navigator.language,
                weightPriceStepData.sellingPriceAmount.value,
                weightPriceStepData.sellingPriceCurrencyCode
            )} ${t('domainModel:material.perPricingUnit', {
                pricingUnit: t(`domainModel:material.pricingUnit.value.${pricingUnit}.label`),
            })}`;
        }
        return null;
    };

    const submitIsDisabled = () => {
        if (!agreedToTAndC) {
            return true;
        }
        return !agreedAuthorised;
    };

    if (showSuccess) {
        return <CreateListingSuccess />;
    }

    const isTheoreticalWeight = getTradeUnitCalculation(materialData) === TradeUnitCalculation.BY_ITEM;

    return (
        <>
            <PageHeader pageTitle={t('translation:application.createListingSummary.header')} />
            <div className="create-listing-summary">
                {(createDraftListingMutation.isLoading ||
                    createListingMutation.isLoading ||
                    createPackageMutation.isLoading) && <LoadingSpinnerOverlay />}
                <TableLayout>
                    {showSaveSuccess && (
                        <Notification
                            level={NotificationLevel.SUCCESS}
                            message={t('translation:application.createListing.draftSuccessfulNotification')}
                            className="create-listing__save-draft-notification"
                            onClose={() => setShowSaveSuccess(false)}
                            closeButton
                        />
                    )}
                    {showSaveFail && (
                        <Notification
                            level={NotificationLevel.ERROR}
                            message={
                                isPackageListing
                                    ? t('application.createListing.packageDraftFailedNotification')
                                    : t('application.createListing.draftFailedNotification')
                            }
                            className="create-listing__save-draft-notification"
                            onClose={() => setShowSaveFail(false)}
                            closeButton
                        />
                    )}
                    <WizardBar
                        items={steps.map(({ id }) => ({
                            label: t(`translation:application.createListingSteps.labels.${id}`),
                            status: WizardBarItemStatus.SUCCESS,
                            onClick: () => handleStepNavigation(id),
                            selected: id === createListingContext.currentStepId,
                        }))}
                        currentStepIndex={getCurrentStepIndex(materialData, createListingContext.currentStepId)}
                    />
                </TableLayout>
                <DetailsLayout>
                    <ListingDetailsMaterial
                        {...materialData}
                        age={materialData.age ?? null}
                        origin={materialData.origins ?? null}
                        coatingColours={coatingColours}
                    />
                    {isPackageListing && (
                        <>
                            <ListingDetailsPackage
                                packageTitle={listUploadStepData.packageTitle ?? null}
                                packageDescription={listUploadStepData.packageDescription ?? null}
                            />
                            <ListingDetailsPackageSheet
                                files={listUploadStepData.packageFile ?? []}
                                apiBaseUrl={constants.apiBaseUrl}
                            />
                        </>
                    )}

                    {materialData.prime && (
                        <ListingDetailsQuality
                            prime={materialData.prime}
                            testCertificate={materialData.testCertificate ?? null}
                            description={materialData.description}
                            apiBaseUrl={constants.apiBaseUrl}
                        />
                    )}
                    {!materialData.prime && (
                        <ListingDetailsQuality
                            prime={materialData.prime}
                            description={materialData.description ?? null}
                            testCertificate={materialData.testCertificate ?? null}
                            picture={materialData.picture ?? null}
                            apiBaseUrl={constants.apiBaseUrl}
                        />
                    )}

                    {!isPackageListing && (
                        <ListingDetailsDimensions
                            thickness={dimensionStepData.thickness ?? null}
                            tolerance={dimensionStepData.tolerance ?? null}
                            width={dimensionStepData.width ?? null}
                            length={dimensionStepData.length}
                        />
                    )}

                    {/* weight & selling price CARD */}
                    <CardSellerSummaryWeightPrice
                        header={t('translation:application.createListingSummary.weightAndPriceHeader')}
                        numberOfItems={weightPriceStepData.numberOfItems?.toString()}
                        itemsLabel={numberOfItemsLabel}
                        weight={
                            weightPriceStepData.weight
                                ? t(`domainModel:material.tradeUnit.value.${tradeUnit}.quantity`, {
                                      count: weightPriceStepData.weight,
                                  })
                                : ''
                        }
                        renderPrice={renderPrice}
                        isTheoretical={isTheoreticalWeight}
                    >
                        <Notification
                            level={NotificationLevel.INFO}
                            message={t(
                                `translation:application.createListingSummary.weightAndPriceNotification${
                                    isTheoreticalWeight ? 'Theoretical' : ''
                                }`
                            )}
                        />
                    </CardSellerSummaryWeightPrice>

                    <ListingDetailsExpiration
                        expiryDate={weightPriceStepData.expirationDate}
                        autoRenew={weightPriceStepData.autoRenew}
                        showAutoRenew
                    >
                        <Notification
                            level={NotificationLevel.INFO}
                            message={t('translation:application.createListingSummary.expirationNotificationMessage')}
                        />
                    </ListingDetailsExpiration>

                    <Card isClickable={false}>
                        <CardLayout>
                            <AddressDetails
                                addressId={pickupAddressStepData.pickupAddressId ?? ''}
                                type={
                                    isPackageListing
                                        ? AddressIdentifier.DELIVERY_INFO
                                        : AddressIdentifier.PICKUP_ADDRESS
                                }
                                attributeLabel={
                                    isPackageListing
                                        ? t('translation:application.addressDetails.deliveryInfoCollectionLabel')
                                        : undefined
                                }
                                readonly
                                additionalText={pickupAddressStepData.additionalInformation}
                                additionalInfoLabel={t(
                                    'translation:application.addressDetails.collectionInformationLabel'
                                )}
                            />
                            {isPackageListing && pickupAddressStepData.deliveryTimeframe && (
                                <div className="delivery-time-frame">
                                    <CardContentAttributesItem
                                        label={t('translation:application.addressDetails.deliveryTimeframeLabel')}
                                        value={t(
                                            `domainModel:deliveryTimeframe.${pickupAddressStepData.deliveryTimeframe}`
                                        )}
                                    />
                                </div>
                            )}
                        </CardLayout>
                    </Card>

                    <MarketingBannerSteelbuyPromise />

                    {userData && hasSellerRole ? (
                        <>
                            <InputCheckbox
                                label={
                                    <>
                                        {' '}
                                        {t('translation:application.checkout.checkbox1Label', {
                                            organisationName: userData?.organisation?.name || 'my organisation',
                                        })}
                                    </>
                                }
                                checked={agreedAuthorised}
                                onChange={(checked) => setAgreedAuthorised(checked)}
                            />

                            <InputCheckbox
                                label={
                                    <>
                                        {' '}
                                        <Trans
                                            i18nKey="translation:application.createListingSummary.checkboxLabel"
                                            components={{
                                                websitetncs: (
                                                    <CustomLink
                                                        path={RoutePath.TERMS_WEBSITE}
                                                        isInlineLink
                                                        openInNewTab
                                                    />
                                                ),
                                                sellertncs: (
                                                    <CustomLink
                                                        path={RoutePath.TERMS_SELLER}
                                                        isInlineLink
                                                        openInNewTab
                                                    />
                                                ),
                                            }}
                                            t={t}
                                            values={{ organisationName: userData?.organisation?.name }}
                                        />
                                    </>
                                }
                                onChange={() => setAgreedToTAndC(!agreedToTAndC)}
                            />
                        </>
                    ) : null}

                    <FormActionbar>
                        <ButtonTertiaryOnLightM
                            label={t('uiDomain:common.edit')}
                            onClick={() => handleStepNavigation(getSteps(materialData)[0])}
                        />

                        {hasSellerRole ? (
                            <ButtonCallToAction
                                disabled={submitIsDisabled()}
                                label={t('translation:application.createListingSummary.submitButtonLabel')}
                                onClick={handleSubmit}
                            />
                        ) : (
                            <CreateListingSaveDraftButton
                                buttonComponent={ButtonPrimary}
                                onSaveFail={() => setShowSaveFail(true)}
                                isPackage={isPackageListing}
                                onSaveSuccess={() => {
                                    setShowSaveSuccess(true);
                                }}
                            />
                        )}
                    </FormActionbar>
                    {error && (
                        <Notification
                            level={NotificationLevel.ERROR}
                            message={t('translation:application.createListingSummary.errorNotificationMessage')}
                            onClose={() => setError(false)}
                            closeButton
                        />
                    )}
                </DetailsLayout>
                <CreateListingUnsavedChangesModal />
            </div>
        </>
    );
};
