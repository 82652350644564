import { ChangeEvent, InputHTMLAttributes } from 'react';
import { buildClassStringFromClassMap } from '@steelbuy/util';
import { useCursorPosition } from '../../hooks/useCursorPosition';

import { Icon } from '../icon/Icon';
import { IconIdentifier } from '../icon/Icon.enums';
import { ValidationProps } from '../input-validation/InputValidation';
import { useValidation } from '../input-validation/InputValidationHook';

import './InputTextarea.scss';

export type InputTextareaProps = {
    label?: string;
    helperText?: string;
    error?: string;
    onChange?: (value: string) => void;
} & ValidationProps &
    Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'onChange' | 'onBlur'>;

export const InputTextarea = ({
    disabled,
    error,
    forceValidation,
    helperText,
    label,
    onChange,
    readOnly,
    validators,
    value,
    ...rest
}: InputTextareaProps) => {
    const { elementRef, validationError } = useValidation<HTMLTextAreaElement>(value, validators, forceValidation);
    const combinedError = validationError ?? error;
    const { setCursor } = useCursorPosition(elementRef);
    const inputTextareaClassMap = {
        'input-textarea': true,
        'input-textarea--disabled': disabled,
    };

    const inputTextareaFieldClassMap = {
        'input-textarea__field': true,
        'input-textarea__field--error': combinedError,
    };

    const inputTextareaFieldInputClassMap = {
        'input-textarea__field__input': true,
        'input-textarea__field__input--disabled': disabled,
        'input-textarea__field__input--read-only': readOnly,
    };

    const helperTextClassMap = {
        'input-textarea__helper-text': true,
        'input-textarea__helper-text--error': combinedError,
    };

    const renderErrorIcon = () => {
        if (combinedError === undefined) {
            return null;
        }
        return <Icon name={IconIdentifier.ERROR} />;
    };

    const renderLabel = () => {
        if (label === undefined) {
            return null;
        }
        return (
            <div className="input-textarea__label">
                <span className="input-textarea__label--text">{label}</span>
                {validators && <span className="required-field"> *</span>}
                {renderErrorIcon()}
            </div>
        );
    };

    const renderHelperText = () => {
        const text = combinedError ?? helperText;
        if (text === undefined) {
            return null;
        }
        return <span className={buildClassStringFromClassMap(helperTextClassMap)}>{text}</span>;
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange !== null) {
            if (onChange) {
                setCursor(event.target.selectionStart);
                onChange(event.target.value);
            }
        }
    };

    return (
        <label className={buildClassStringFromClassMap(inputTextareaClassMap)}>
            {renderLabel()}
            <span className={buildClassStringFromClassMap(inputTextareaFieldClassMap)}>
                <textarea
                    ref={elementRef}
                    className={buildClassStringFromClassMap(inputTextareaFieldInputClassMap)}
                    disabled={disabled}
                    readOnly={readOnly}
                    onChange={handleChange}
                    value={value}
                    {...rest}
                />
            </span>
            {renderHelperText()}
        </label>
    );
};
