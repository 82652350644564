import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMyPurchaseCartOrders } from '@steelbuy/data-access';
import { NotFoundError } from '@steelbuy/error';
import { formatCurrency, isPackageOrderModel } from '@steelbuy/ts-shared';
import { ListingBuyerTeaser } from '@steelbuy/ui-domain';
import {
    Card,
    CardContentAttributes,
    CardContentAttributesItem,
    CardContentProductViewPrice,
    CardLayout,
    LoadingSpinner,
} from '@steelbuy/ui-primitive';
import { createRouteUrl, RoutePath } from '../../router/Routes';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { getScrollTopMainLayout, scrollMainLayout } from '../app-layout/app-main-layout/AppMainLayout';
import { MyCartEmptyState } from '../my-cart/MyCartEmptyState';
import { useMyOrdersContext } from '../my-orders/MyOrdersContext';
import { PageHeader } from '../page-header/PageHeader';

import './MyOrderCartList.scss';

interface MyOrderCartListProps {
    cartRefNo: string;
}

export const MyOrderCartList = ({ cartRefNo }: MyOrderCartListProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();
    const { cartOrderScrollPosition, setCartOrderScrollPosition } = useMyOrdersContext();

    const { data, isLoading, error } = useMyPurchaseCartOrders({
        cartRefNo,
    });

    useEffect(() => {
        scrollMainLayout(0, cartOrderScrollPosition);
        setCartOrderScrollPosition(0);
    }, []);

    if (isLoading) {
        return <LoadingSpinner />;
    }
    if (error || !data) {
        throw new NotFoundError();
    }

    return (
        <>
            <PageHeader
                pageTitle={data.buyerOrderNumber}
                previousPageTitle={t('uiDomain:common.back')}
                onBackClick={() => navigate(RoutePath.MY_ORDERS)}
            />
            <TableLayout>
                <div className="my-order-cart-list">
                    {data?.cartOrders?.length ? (
                        <>
                            <div className="my-order-cart-list__container">
                                <div className="my-order-cart-list__container__listing-teaser">
                                    {data.cartOrders.map((entry) => {
                                        const isPackage = isPackageOrderModel(entry);
                                        return (
                                            <Card
                                                key={entry.id}
                                                isClickable
                                                url={createRouteUrl(
                                                    RoutePath.MY_ORDERS_CART_DETAILS,
                                                    ['orderId', entry.id],
                                                    ['cartRefNo', cartRefNo]
                                                )}
                                                linkState={{
                                                    backLink: createRouteUrl(RoutePath.MY_ORDERS_CART, [
                                                        'cartRefNo',
                                                        cartRefNo,
                                                    ]),
                                                }}
                                                onClick={() => {
                                                    setCartOrderScrollPosition(getScrollTopMainLayout());
                                                }}
                                            >
                                                {isPackage ? (
                                                    <ListingBuyerTeaser
                                                        listing={entry.packaging}
                                                        isCart
                                                        isPackage
                                                        orderStatus={entry.status}
                                                    />
                                                ) : (
                                                    <ListingBuyerTeaser
                                                        listing={entry.listing}
                                                        isCart
                                                        orderStatus={entry.status}
                                                    />
                                                )}
                                            </Card>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className="my-order-cart-list__subdetails">
                                <div className="my-order-cart-list__subdetails__summary-price">
                                    <Card isClickable={false}>
                                        <CardLayout>
                                            <CardContentProductViewPrice
                                                header={t(
                                                    'translation:application.checkoutDeliveryOption.orderSummary'
                                                )}
                                                isCartCheckout
                                                totalExVat={
                                                    data?.totalValueVAT
                                                        ? formatCurrency(
                                                              navigator.language,
                                                              data.totalValue.value,
                                                              data?.currencyCode
                                                          )
                                                        : ''
                                                }
                                                vatPercent={data?.taxPercent ? (data.taxPercent / 100).toString() : '0'}
                                                totalPrice={
                                                    data?.totalValueVAT
                                                        ? formatCurrency(
                                                              navigator.language,
                                                              data.totalValueVAT.value,
                                                              data?.currencyCode
                                                          )
                                                        : ''
                                                }
                                                vat={
                                                    data?.totalValue
                                                        ? formatCurrency(
                                                              navigator.language,
                                                              data.taxValue,
                                                              data?.currencyCode
                                                          )
                                                        : ''
                                                }
                                                pricePerUnit=""
                                                weight=""
                                            />
                                        </CardLayout>
                                    </Card>
                                </div>
                                <div className="my-order-cart-list__subdetails__purchase-order">
                                    <Card isClickable={false}>
                                        <CardLayout>
                                            <CardContentAttributes
                                                header={t(
                                                    'translation:application.myOrderDetails.purchaseOrderNumberHeader'
                                                )}
                                            >
                                                <CardContentAttributesItem
                                                    label={t(
                                                        'translation:application.myOrderDetails.buyerPurchaseOrderNumberLabel'
                                                    )}
                                                    value={data.buyerOrderNumber}
                                                />
                                                <CardContentAttributesItem
                                                    label={t(
                                                        'translation:application.myOrderDetails.steelBuyReferenceNoLabel'
                                                    )}
                                                    value={data.steelBuyReferenceNumber}
                                                />
                                            </CardContentAttributes>
                                        </CardLayout>
                                    </Card>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="my-cart-list__empty-state-container">
                            <MyCartEmptyState />
                        </div>
                    )}
                </div>
            </TableLayout>
        </>
    );
};
