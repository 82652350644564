import { useTranslation } from 'react-i18next';
import { UserRole } from '@steelbuy/ts-shared';
import { buildClassStringFromClassMap } from '@steelbuy/util';

type PermissionInformationProps = {
    disabled?: boolean;
    role?: UserRole;
};

export const PermissionInformation = (props: PermissionInformationProps) => {
    const { t } = useTranslation('uiDomain');

    const { role, disabled } = props;

    const permissionDescriptionsClassMap = {
        'disabled-text': disabled,
    };

    const permissionDescriptions = {
        basic: [
            t('permissionInformation.basic.searchListing'),
            t('permissionInformation.basic.alerts'),
            t('permissionInformation.basic.drafts'),
        ],
        seller: [
            t('permissionInformation.seller.submitListing'),
            t('permissionInformation.seller.editRejectedListing'),
            t('permissionInformation.seller.renewListing'),
        ],
        buyer: [t('permissionInformation.buyer.purchaseProduct')],
        manager: [
            t('permissionInformation.manager.editOrganisation'),
            t('permissionInformation.manager.user'),
            t('permissionInformation.manager.creditLineInformation'),
        ],
    };

    const renderDescription = () => {
        switch (role) {
            case UserRole.SELLER:
                return permissionDescriptions.seller.map((description: string) => (
                    <li className={buildClassStringFromClassMap(permissionDescriptionsClassMap)} key={description}>
                        {description}
                    </li>
                ));
            case UserRole.BUYER:
                return permissionDescriptions.buyer.map((description: string) => (
                    <li className={buildClassStringFromClassMap(permissionDescriptionsClassMap)} key={description}>
                        {description}
                    </li>
                ));
            case UserRole.MANAGER:
                return permissionDescriptions.manager.map((description: string) => (
                    <li className={buildClassStringFromClassMap(permissionDescriptionsClassMap)} key={description}>
                        {description}
                    </li>
                ));
            default:
                return permissionDescriptions.basic.map((description: string) => (
                    <li className={buildClassStringFromClassMap(permissionDescriptionsClassMap)} key={description}>
                        {description}
                    </li>
                ));
        }
    };

    return <ul className="permission-information-list">{renderDescription()}</ul>;
};
