/* eslint-disable no-param-reassign */
import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import {
    CollectionResponse,
    FilterCriteria,
    PathVariables,
    PreparedSortCriteria,
    SortCriteria,
} from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { AnyErrorObject } from '@steelbuy/error';
import { AnonymousViewModel, Nullable } from '@steelbuy/ts-shared';

import { SearchStore } from './SearchStore';
import { ThunkApiConfig } from './Thunks';
import { FetchStatus } from '../FetchStatus';
import { ModelFilter } from '../ModelFilter';
import { ModelSort } from '../ModelSort';

// Initial store
export const createInitialSearchStore = <Model extends AnonymousViewModel>(): SearchStore<Model> => ({
    models: [] as Array<Model>,
    fetchStatus: FetchStatus.IDLE,
    lastFetchError: null,
    currentPage: null,
    maxPages: null,
    sortCriteria: null,
    filterCriteria: null,
    totalItems: null,
    pathVariables: null,
});

// Slice creator
export const createSearchSlice = <Model extends AnonymousViewModel>(
    storeName: string,
    fetchAll: AsyncThunk<
        CollectionResponse<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            sortCriteria?: SortCriteria<Model> | PreparedSortCriteria;
            filterCriteria?: FilterCriteria<Model>;
            pathVariables?: PathVariables;
        },
        ThunkApiConfig
    >,
    fetchNext: AsyncThunk<
        CollectionResponse<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
        },
        ThunkApiConfig
    >
) =>
    createSlice({
        name: storeName,
        initialState: createInitialSearchStore<Model>(),
        // Regular synchronous reducers
        reducers: {
            resetStore(store) {
                Object.assign(store, createInitialSearchStore<Model>());
            },
            resetFetchStatus(store) {
                store.fetchStatus = FetchStatus.IDLE;
            },
        },
        // Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
        // Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
        // handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
        // https://github.com/immerjs/immer.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        extraReducers: (builder) => {
            builder.addCase(String(fetchAll.pending), (state) => {
                state.fetchStatus = FetchStatus.PENDING;
            });
            builder.addCase(
                String(fetchAll.fulfilled),
                (state, action: PayloadAction<Draft<CollectionResponse<Model>>>) => {
                    state.models = action.payload.items;
                    state.currentPage = action.payload.currentPage;
                    state.maxPages = action.payload.maxPages;
                    state.totalItems = action.payload.totalItems;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    state.sortCriteria = action.meta.arg.sortCriteria ?? null;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    state.filterCriteria = action.meta.arg.filterCriteria ?? null;
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    state.pathVariables = action.meta.arg.pathVariables ?? null;
                    state.fetchStatus = FetchStatus.SUCCESS;
                }
            );
            builder.addCase(
                String(fetchAll.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastFetchError = action.payload ?? action.error;
                    state.fetchStatus = FetchStatus.FAILED;
                }
            );
            builder.addCase(String(fetchNext.pending), (state) => {
                state.fetchStatus = FetchStatus.PAGING_PENDING;
            });
            builder.addCase(
                String(fetchNext.fulfilled),
                (state, action: PayloadAction<Draft<CollectionResponse<Model>>>) => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    state.models = [...state.models, ...action.payload.items];
                    state.currentPage = action.payload.currentPage;
                    state.maxPages = action.payload.maxPages;
                    state.totalItems = action.payload.totalItems;
                    state.fetchStatus = FetchStatus.SUCCESS;
                }
            );
            builder.addCase(
                String(fetchNext.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, void, AnyErrorObject>) => {
                    state.lastFetchError = action.payload ?? action.error;
                    state.fetchStatus = FetchStatus.FAILED;
                }
            );
        },
    });

// Selector creators
export const createSelectCollection =
    <Model extends AnonymousViewModel, Store extends Record<string, SearchStore<Model>>>(storeName: string) =>
    (filter?: ModelFilter<Model>, sort?: ModelSort<Model>): ((rootStore: Store) => ReadonlyArray<Model>) =>
    (rootStore): ReadonlyArray<Model> => {
        let modelCollection = rootStore[storeName].models;
        if ((filter ?? null) !== null) {
            modelCollection = modelCollection.filter(filter as ModelFilter<Model>);
        }
        if ((sort ?? null) !== null) {
            modelCollection = [...modelCollection].sort(sort);
        }
        return modelCollection;
    };
