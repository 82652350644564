import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Coating,
    CoatingColourType,
    CoatingCoverage,
    CoatingThicknessType,
    CoatingType,
    getCoatings,
    MaterialProperties,
    SearchFields,
    SearchFormData,
} from '@steelbuy/ts-shared';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

import { MaterialCoatingColourSelection } from './MaterialCoatingColourSelection';
import { MaterialCoatingCoverageSelection } from './MaterialCoatingCoverageSelection';
import { MaterialCoatingThicknessSelection } from './MaterialCoatingThicknessSelection';
import { MaterialCoatingTypeSelection } from './MaterialCoatingTypeSelection';
import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

export const MaterialCoatingSelection = (props: {
    material: MaterialProperties;
    coatingSelected: (coating?: Coating) => void;
    coatingThicknessSelected: (thickness?: CoatingThicknessType) => void;
    coatingColourSelected: (color?: CoatingColourType) => void;
    coatingTypeSelected: (coatingType?: CoatingType) => void;
    coatingCoverageSelected: (coverage?: CoatingCoverage) => void;
    isSearch?: boolean;
    requiredFields?: SearchFields;
    required?: boolean;
    forceValidation?: boolean;
}) => {
    const {
        material,
        coatingSelected,
        coatingThicknessSelected,
        coatingColourSelected,
        coatingTypeSelected,
        coatingCoverageSelected,
        required,
        forceValidation,
        isSearch = false,
        requiredFields = [],
    } = props;
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();

    const coatings = getCoatings(material)?.slice().sort();

    const { coating } = material;

    useEffect(() => {
        if (coating !== undefined && !coatings.includes(coating)) {
            coatingSelected(undefined);
            coatingThicknessSelected(undefined);
            coatingColourSelected(undefined);
            coatingTypeSelected(undefined);
            coatingCoverageSelected(undefined);
        }
    }, [
        coating,
        coatingSelected,
        coatingThicknessSelected,
        coatingColourSelected,
        coatingTypeSelected,
        coatingCoverageSelected,
        coatings,
    ]);

    if (coatings.length === 0) {
        return null;
    }

    const materialCoatingOptions = coatings.map((coatingOption) => ({
        label: t(`domainModel:material.coating.value.${coatingOption}`),
        value: coatingOption,
    }));

    const getRequiredProps = (field: keyof SearchFormData): AnyValidationProps =>
        required || requiredFields.includes(field) ? { required: true, forceValidation } : {};

    return (
        <>
            <FormItem
                header={t('domainModel:material.coating.name')}
                isMandatory={required || requiredFields.includes('coating')}
            >
                <InputSelect
                    options={materialCoatingOptions}
                    testId="coating-select"
                    placeholder={t('uiDomain:materialSelection.placeholder')}
                    value={coating}
                    onSelect={(value) => coatingSelected(value)}
                    validators={required || requiredFields.includes('coating') ? [requiredFieldValidator] : undefined}
                    forceValidation={forceValidation}
                />
            </FormItem>
            {coating && (
                <>
                    <MaterialCoatingThicknessSelection
                        material={material}
                        coatingThicknessSelected={coatingThicknessSelected}
                        allowOtherInput={!isSearch}
                        {...getRequiredProps('coatingThicknessValue')}
                    />
                    <MaterialCoatingColourSelection
                        material={material}
                        coatingColourSelected={coatingColourSelected}
                        allowOtherInput={!isSearch}
                        {...getRequiredProps('coatingColour')}
                    />
                    <MaterialCoatingTypeSelection
                        material={material}
                        coatingTypeSelected={coatingTypeSelected}
                        {...getRequiredProps('coatingType')}
                    />
                    <MaterialCoatingCoverageSelection
                        material={material}
                        coatingCoverageSelected={coatingCoverageSelected}
                        {...getRequiredProps('coatingCoverage')}
                    />
                </>
            )}
        </>
    );
};
