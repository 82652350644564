import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Definition, getProducts, MaterialProperties, Product, ProductType } from '@steelbuy/ts-shared';
import {
    FormItem,
    IconIdentifier,
    InputRadioButtonGroupIllustration,
    InputRadioButtonGroupIllustrationAlign,
    InputRadioButtonIllustration,
} from '@steelbuy/ui-primitive';

interface MaterialProductSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    name: TName;
    control: Control<TFieldValues>;
    material: MaterialProperties;
    isPackagesSupported: boolean;
    disabledItems?: Product[];
    showConfirmSelection: (onConfirm: () => void) => void;
    addedIndexes: (Definition | ProductType)[];
}

export const MaterialProductSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    name,
    control,
    material,
    isPackagesSupported,
    disabledItems,
    showConfirmSelection,
    addedIndexes,
}: MaterialProductSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation('domainModel');
    const availableProducts = getProducts(material, isPackagesSupported);

    const {
        field: { onChange, value },
    } = useController({
        control,
        name,
    });

    return (
        <FormItem header={t('material.product.name')} isMandatory>
            <InputRadioButtonGroupIllustration align={InputRadioButtonGroupIllustrationAlign.ALIGN_HORIZONTAL}>
                {availableProducts.map((product) => (
                    <InputRadioButtonIllustration
                        icon={IconIdentifier[product]}
                        key={product}
                        name="materialProduct"
                        value={t(`material.product.value.${product}`)}
                        onChange={() => {
                            if (addedIndexes.length) {
                                showConfirmSelection(() => onChange(product));
                                return;
                            }
                            onChange(product);
                        }}
                        checked={product === value}
                        disabled={disabledItems?.includes(product)}
                    />
                ))}
            </InputRadioButtonGroupIllustration>
        </FormItem>
    );
};
