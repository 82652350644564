import { ListingBuyerAlertModel, ListingBuyerAlertModelConverter } from '@steelbuy/ts-shared';

import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class ListingBuyerAlertCrudApiClient extends AbstractCrudApiClient<ListingBuyerAlertModel> {
    protected collectionControllerUri = 'api/universal/listing-alerts/';

    protected paginationControllerUri = 'api/universal/listing-alerts/';

    protected entityCreateUri = 'api/universal/listing-alerts/';

    protected entityFetchUri = 'api/universal/listing-alerts/{id}/';

    protected entityMutateUri = 'api/universal/listing-alerts/{id}/';

    protected entityDeleteUri = 'api/universal/listing-alerts/{id}/';

    protected entityServiceCallUri = 'api/universal/listing-alerts/{id}/';

    protected modelConverter = new ListingBuyerAlertModelConverter();
}
