import {
    getTradeUnit,
    getTradeUnitCalculation,
    isStackableProduct,
    ListingSellerDraftModel,
    ListingSellerModel,
    PackageSellerCreateDraftModel,
    PackageSellerCreateModel,
    PackageSellerDraftModel,
    PackageSellerModel,
    ApiUploadFile,
    createDateFromMaybeTimestamp,
    createTimestampFromApiTimestamp,
    createTimestampFromMaybeDate,
    isApiUploadFile,
    Mutable,
    UploadFile,
} from '@steelbuy/ts-shared';
import { getEarliestExpirationDate } from '@steelbuy/ui-domain';
import {
    CreateListingContextValue,
    DEFAULT_MATERIAL_TYPE,
    DEFAULT_SHAPE,
    DEFAULT_PRODUCT,
    DEFAULT_PRIME,
    DimensionsStepData,
    MaterialStepData,
    WeightPriceStepData,
    PickupAddressStepData,
    DEFAULT_PACKAGE_PRODUCT,
    ListUploadStepData,
} from './CreateListingContextUtil';

export const contextToDraft = ({
    dimensionsStepData: { length, thickness, tolerance, width },
    materialStepData,
    pickupAddressStepData: { additionalInformation, invoiceAddressId, pickupAddressId },
    weightPriceStepData: {
        autoRenew,
        expirationDate,
        numberOfItems,
        sellingPriceAmount,
        sellingPriceCurrencyCode,
        weight,
    },
}: CreateListingContextValue): Mutable<ListingSellerDraftModel> => ({
    expiresAt: createTimestampFromMaybeDate(expirationDate).getOrUndefined(),
    invoiceAddressId,
    weight,
    age: materialStepData.age,
    internalSellerSku: materialStepData.sku,
    pricing: {
        netPricePerUnit: sellingPriceAmount,
        currencyCode: sellingPriceCurrencyCode,
    },
    picture: materialStepData.picture?.[0],
    pickupAddressId,
    additionalInformation,
    materialDimensions: {
        product: materialStepData.product,
        width,
        length,
        thickness,
    },
    origin: materialStepData.origins?.[0],
    material: {
        materialType: materialStepData.materialType,
        shape: materialStepData.shape,
        product: materialStepData.product,
        tolerance,
        grade: materialStepData.grade,
        definition: materialStepData.definition,
        specification: materialStepData.specification,
        finish: materialStepData.finish,
        plateType: materialStepData.plateType,
        coating: materialStepData.coating,
        coatingThicknessValue: materialStepData.coatingThicknessValue,
        surface: materialStepData.surface,
        coatingColour: materialStepData.coatingColour,
        coatingType: materialStepData.coatingType,
        coatingCoverage: materialStepData.coatingCoverage,
        temper: materialStepData.temper,
        millFinish: materialStepData.millFinish,
        polish: materialStepData.polish,
    },
    tradeUnit: getTradeUnit(materialStepData),
    description: materialStepData.description,
    prime: materialStepData.prime,
    testCertificate: materialStepData.testCertificate?.[0],
    numberOfItems,
    autoRenew,
    tradeUnitCalculation: getTradeUnitCalculation(materialStepData),
});

export const contextToListing = (createListingContext: CreateListingContextValue): ListingSellerModel =>
    ({
        ...contextToDraft(createListingContext),
        autoRenew: {
            enabled: createListingContext.weightPriceStepData.autoRenew,
        },
    } as ListingSellerModel);

export const contextToPackageDraft = ({
    materialStepData,
    pickupAddressStepData: { additionalInformation, invoiceAddressId, pickupAddressId, deliveryTimeframe },
    weightPriceStepData: { autoRenew, expirationDate, sellingPriceAmount, sellingPriceCurrencyCode, weight },
    listUploadStepData,
}: CreateListingContextValue): PackageSellerCreateDraftModel => ({
    invoiceAddressId,
    pickupAddressId,
    additionalInformation,
    internalSellerSku: materialStepData.sku,
    pricing: {
        netPricePerUnit: sellingPriceAmount,
        currencyCode: sellingPriceCurrencyCode,
    },
    origin: materialStepData.origins,
    expiresAt: createTimestampFromMaybeDate(expirationDate).getOrUndefined(),
    weight,
    material: materialStepData.materialType,
    tradeUnit: getTradeUnit(materialStepData),
    autoRenew,
    prime: materialStepData.prime,
    description: materialStepData.description,
    shape: materialStepData.shape,
    pictures: materialStepData.picture,
    certificates: materialStepData.testCertificate,
    packageTitle: listUploadStepData.packageTitle,
    packageDescription: listUploadStepData.packageDescription,
    packageDocument: listUploadStepData.packageFile?.[0],
    deliveryTimeframe,
});

export const contextToPackage = (createListingContext: CreateListingContextValue): PackageSellerCreateModel =>
    ({
        ...contextToPackageDraft(createListingContext),
        autoRenew: {
            enabled: createListingContext.weightPriceStepData.autoRenew,
        },
    } as Mutable<Partial<PackageSellerCreateModel>> as PackageSellerCreateModel);

const draftOrListingToMaterialStepData = (source: ListingSellerDraftModel | ListingSellerModel): MaterialStepData => {
    let materialStepData: MaterialStepData = {
        materialType: source.material?.materialType ?? DEFAULT_MATERIAL_TYPE,
        shape: source.material?.shape ?? DEFAULT_SHAPE,
        product: source.material?.product ?? DEFAULT_PRODUCT,
        age: source.age,
        origins: source.origin ? [source.origin] : undefined,
        description: source.description,
        sku: source.internalSellerSku,
        prime: source.prime ?? DEFAULT_PRIME,
    };

    if (source.material) {
        materialStepData = {
            ...materialStepData,
            definition: 'definition' in source.material ? source.material.definition : undefined,
            plateType: 'plateType' in source.material ? source.material.plateType : undefined,
            grade: 'grade' in source.material ? source.material.grade : undefined,
            temper: 'temper' in source.material ? source.material.temper : undefined,
            specification: 'specification' in source.material ? source.material.specification : undefined,
            surface: 'surface' in source.material ? source.material.surface : undefined,
            finish: 'finish' in source.material ? source.material.finish : undefined,
            millFinish: 'millFinish' in source.material ? source.material.millFinish : undefined,
            polish: 'polish' in source.material ? source.material.polish : undefined,
            coating: 'coating' in source.material ? source.material.coating : undefined,
            coatingThicknessValue:
                'coatingThicknessValue' in source.material ? source.material.coatingThicknessValue : undefined,
            coatingColour: 'coatingColour' in source.material ? source.material.coatingColour : undefined,
            coatingType: 'coatingType' in source.material ? source.material.coatingType : undefined,
            coatingCoverage: 'coatingCoverage' in source.material ? source.material.coatingCoverage : undefined,
        };
    }

    return materialStepData;
};

const draftOrPackageToMaterialStepData = (source: PackageSellerModel | PackageSellerDraftModel) => {
    const materialStepData: MaterialStepData = {
        materialType: source.material ?? DEFAULT_MATERIAL_TYPE,
        shape: source?.shape ?? DEFAULT_SHAPE,
        product: DEFAULT_PACKAGE_PRODUCT,
        origins: source.origin,
        description: source.description,
        sku: source.internalSellerSku,
        prime: source.prime ?? DEFAULT_PRIME,
    };

    return materialStepData;
};

const draftOrPackageToListUploadStepData = (source: PackageSellerDraftModel) => {
    const listUploadStepData: ListUploadStepData = {
        packageTitle: source.packageTitle,
        packageDescription: source.packageDescription,
    };

    return listUploadStepData;
};

const draftOrListingToDimensionsStepData = (
    source: ListingSellerDraftModel | ListingSellerModel
): DimensionsStepData => {
    const { materialDimensions } = source;
    return {
        thickness: materialDimensions?.thickness,
        tolerance: source.material?.tolerance,
        length: materialDimensions && 'length' in materialDimensions ? materialDimensions.length : undefined,
        width: materialDimensions?.width,
    };
};

const draftOrListingToWeightPriceStepData = (
    source: ListingSellerDraftModel | ListingSellerModel | PackageSellerModel | PackageSellerDraftModel
): WeightPriceStepData => {
    let expirationDate = createDateFromMaybeTimestamp(source.expiresAt).getOrUndefined();

    if (expirationDate && getEarliestExpirationDate(new Date()) > expirationDate) {
        expirationDate = undefined;
    }

    return {
        numberOfItems: isStackableProduct((source as ListingSellerModel).material?.product)
            ? (source as ListingSellerModel).numberOfItems
            : undefined,
        weight: source.weight,
        sellingPriceCurrencyCode: source.pricing?.currencyCode,
        sellingPriceAmount: source.pricing?.netPricePerUnit,
        expirationDate,
        autoRenew: typeof source.autoRenew === 'boolean' ? source.autoRenew : source.autoRenew?.enabled,
    };
};

export const draftToMaterialStepData = (draft: ListingSellerDraftModel): MaterialStepData => {
    const materialStepData = draftOrListingToMaterialStepData(draft);
    if (isApiUploadFile(draft.testCertificate)) {
        materialStepData.testCertificate = [
            {
                discriminator: 'UploadFile',
                documentId: draft.testCertificate.documentId,
                meta: {
                    ...draft.testCertificate.meta,
                    lastModifiedDate: createTimestampFromApiTimestamp(
                        draft.testCertificate.meta.lastModifiedDate
                    ).getOrThrow(Error('Timestamp Cannot be created')),
                },
            },
        ];
    }

    if (isApiUploadFile(draft.picture)) {
        materialStepData.picture = [
            {
                discriminator: 'UploadFile',
                documentId: draft.picture.documentId,
                meta: {
                    ...draft.picture.meta,
                    lastModifiedDate: createTimestampFromApiTimestamp(draft.picture.meta.lastModifiedDate).getOrThrow(
                        Error('Timestamp Cannot be created')
                    ),
                },
            },
        ];
    }

    return materialStepData;
};

export const draftToDimensionsStepData = (draft: ListingSellerDraftModel): DimensionsStepData =>
    draftOrListingToDimensionsStepData(draft);

export const draftToWeightPriceStepData = (
    draft: ListingSellerDraftModel | PackageSellerDraftModel
): WeightPriceStepData => draftOrListingToWeightPriceStepData(draft);

export const draftToPickupAddressStepData = (draft: ListingSellerDraftModel): PickupAddressStepData => ({
    invoiceAddressId: draft.invoiceAddressId,
    pickupAddressId: draft.pickupAddressId,
    additionalInformation: draft.additionalInformation,
});

export const packageDraftToPickupAddressStepData = (draft: PackageSellerDraftModel): PickupAddressStepData => ({
    invoiceAddressId: draft.invoiceAddressId,
    pickupAddressId: draft.pickupAddressId,
    additionalInformation: draft.additionalInformation,
    deliveryTimeframe: draft.deliveryTimeframe,
});

export const listingToMaterialStepData = (listing: ListingSellerModel): MaterialStepData =>
    draftOrListingToMaterialStepData(listing);

export const listingToDimensionsStepData = (listing: ListingSellerModel): DimensionsStepData =>
    draftOrListingToDimensionsStepData(listing);

export const listingToWeightPriceStepData = (
    listing: ListingSellerModel | PackageSellerModel | PackageSellerDraftModel
): WeightPriceStepData => draftOrListingToWeightPriceStepData(listing);

export const listingToPickupAddressStepData = (
    listing: ListingSellerModel | PackageSellerModel
): PickupAddressStepData => ({
    invoiceAddressId: listing.invoiceAddress.original,
    pickupAddressId: listing.pickupAddress.original,
    additionalInformation: listing.additionalInformation,
});

const fileToUploadFile = (file: ApiUploadFile): UploadFile => ({
    discriminator: 'UploadFile',
    documentId: file.documentId,
    meta: {
        ...file.meta,
        lastModifiedDate: createTimestampFromApiTimestamp(file.meta.lastModifiedDate).getOrThrow(
            Error('Timestamp Cannot be created')
        ),
    },
});

const getFilesInfo = (files: UploadFile[]) =>
    files.reduce((acc, file) => {
        if (isApiUploadFile(file)) {
            acc.push(fileToUploadFile(file));
        }
        return acc;
    }, [] as UploadFile[]);

export const packageToMaterialStepData = (
    packageListing: PackageSellerModel | PackageSellerDraftModel
): MaterialStepData => {
    const materialStepData = draftOrPackageToMaterialStepData(packageListing);

    if (packageListing?.certificates?.length) {
        materialStepData.testCertificate = getFilesInfo(packageListing?.certificates as UploadFile[]);
    }

    if (packageListing?.pictures?.length) {
        materialStepData.picture = getFilesInfo(packageListing?.pictures as UploadFile[]);
    }
    return materialStepData;
};

export const packageToListUploadStepData = (packageListing: PackageSellerDraftModel): ListUploadStepData => {
    const listUploadStepData = draftOrPackageToListUploadStepData(packageListing);

    if (isApiUploadFile(packageListing.packageDocument)) {
        listUploadStepData.packageFile = [
            {
                discriminator: 'UploadFile',
                documentId: packageListing.packageDocument.documentId,
                meta: {
                    ...packageListing.packageDocument.meta,
                    lastModifiedDate: createTimestampFromApiTimestamp(
                        packageListing.packageDocument.meta.lastModifiedDate
                    ).getOrThrow(Error('Timestamp Cannot be created')),
                },
            },
        ];
    }
    return listUploadStepData;
};
