import classNames from 'classnames';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrencyCode, formatCurrency, PricingUnit, MonetaryAmount } from '@steelbuy/ts-shared';
import { Tag, TagVariant } from '@steelbuy/ui-primitive';

export type ListingTeaserPriceProps = {
    value: MonetaryAmount | MonetaryAmount[];
    currencyCode: CurrencyCode;
    unit?: PricingUnit;
    showIncludingDelivery?: boolean;
    isMyOffer?: boolean;
    children?: ReactNode;
    offerValue?: number;
    showTotal?: MonetaryAmount;
    showTotalExcludeVat?: boolean;
    oldPricingTotal?: MonetaryAmount;
    isCart?: boolean;
};

const ListingTeaserPriceWrapper = ({
    currencyCode,
    unit,
    value,
    showIncludingDelivery = true,
    isMyOffer = false,
    children,
    offerValue,
    showTotal,
    showTotalExcludeVat,
    oldPricingTotal,
    isCart,
}: ListingTeaserPriceProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel', 'translation']);

    const hasValue = (Array.isArray(value) && value.length) || (!Array.isArray(value) && value?.value);

    const uniqueValues = Array.isArray(value) ? [...new Set(value.map((item) => item.value))] : [value.value];

    const shouldRenderValue = hasValue && currencyCode;
    const shouldRenderUnit = hasValue && unit && unit;

    let priceLabel = '';
    if (unit) {
        priceLabel =
            uniqueValues.length > 1
                ? t(`domainModel:material.multipleOfferPricing`, {
                      pricingUnit: t(`domainModel:material.pricingUnit.value.${unit}.label`),
                  })
                : t(`domainModel:material.offerPricing`, {
                      pricingUnit: t(`domainModel:material.pricingUnit.value.${unit}.label`),
                  });
    }

    return (
        <div className="listing-teaser__price__wrapper">
            <div className="listing-teaser__price__wrapper__values">
                <div className="listing-teaser__price__wrapper--container">
                    {shouldRenderValue && (
                        <>
                            {!!offerValue && (
                                <>
                                    <div className="listing-teaser__price__wrapper__value__invalid">
                                        {formatCurrency(navigator.language, uniqueValues[0], currencyCode)}
                                    </div>
                                    <div className="listing-teaser__price__wrapper__value">
                                        <div
                                            className={classNames('listing-teaser__price__wrapper__value--new-price', {
                                                'listing-teaser__price__wrapper__value--new-price--renewed':
                                                    oldPricingTotal,
                                            })}
                                        >
                                            {formatCurrency(navigator.language, offerValue, currencyCode)}
                                        </div>
                                    </div>
                                </>
                            )}
                            {uniqueValues.length === 1 && !offerValue && (
                                <div className="listing-teaser__price__wrapper__value">
                                    {formatCurrency(navigator.language, uniqueValues[0], currencyCode)}
                                </div>
                            )}
                            <div>
                                {uniqueValues.length === 2 && (
                                    <span className="listing-teaser__price__wrapper__value__two-prices">
                                        {[
                                            formatCurrency(navigator.language, uniqueValues[0], currencyCode),
                                            formatCurrency(navigator.language, uniqueValues[1], currencyCode),
                                        ].join(', ')}
                                    </span>
                                )}
                                {uniqueValues.length > 2 && (
                                    <>
                                        <span className="listing-teaser__price__wrapper__value__range-label">
                                            {t('uiDomain:listingTeaserPrice.offerRange')}
                                        </span>
                                        <span className="listing-teaser__price__wrapper__value__range-value">
                                            {[
                                                formatCurrency(
                                                    navigator.language,
                                                    Math.min(...uniqueValues),
                                                    currencyCode
                                                ),
                                                formatCurrency(
                                                    navigator.language,
                                                    Math.max(...uniqueValues),
                                                    currencyCode
                                                ),
                                            ].join(' - ')}
                                        </span>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                    {shouldRenderUnit && (
                        <div className="listing-teaser__price__wrapper__units">
                            {unit && (
                                <span>
                                    {isMyOffer ? (
                                        <span>{priceLabel}</span>
                                    ) : (
                                        <span>
                                            {t(
                                                `domainModel:material.${
                                                    showIncludingDelivery
                                                        ? 'perPricingUnitIncludingDelivery'
                                                        : 'perPricingUnit'
                                                }`,
                                                {
                                                    pricingUnit: t(
                                                        `domainModel:material.pricingUnit.value.${unit}.label`
                                                    ),
                                                }
                                            )}
                                        </span>
                                    )}
                                </span>
                            )}
                        </div>
                    )}
                    {showTotalExcludeVat && (
                        <div className="listing-teaser__price__wrapper__units">
                            {t('translation:application.checkout.itemsTotalExclVat')}
                        </div>
                    )}
                </div>

                {showTotal && (
                    <div className="listing-teaser__price__wrapper__total-value">
                        {`${t('uiDomain:listingBuyerDetailsPrice.totalExclVat')}: `}
                        {oldPricingTotal && isCart ? (
                            <span className="listing-teaser__price__wrapper__total-value__renewed">
                                <span className="listing-teaser__price__wrapper__total-value__renewed--old">
                                    {formatCurrency(navigator.language, oldPricingTotal.value, currencyCode)}
                                </span>
                                <span className="listing-teaser__price__wrapper__total-value__renewed--new">
                                    {formatCurrency(navigator.language, showTotal.value, currencyCode)}
                                </span>
                            </span>
                        ) : (
                            formatCurrency(navigator.language, showTotal.value, currencyCode)
                        )}
                    </div>
                )}
            </div>

            {children}
        </div>
    );
};

export const ListingTeaserPrice = ({
    isNew,
    isDraft,
    ...rest
}: ListingTeaserPriceProps & { isNew?: boolean; isDraft?: boolean }) => (
    <div className="listing-teaser__price">
        <ListingTeaserPriceWrapper {...rest} />
        {!isDraft && isNew && <Tag label="New" variant={TagVariant.NEGATIVE} isVertical />}
    </div>
);
