import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormItem, InputSelect, InputSelectOption } from '@steelbuy/ui-primitive';
import { formatDate, formatDayname } from '@steelbuy/util';
import {
    fromMinutes,
    getEarliestExpirationDate,
    getSelectableDates,
    SELECTABLE_TIMES,
    SelectableTime,
    toMinutes,
} from './ExpirationTimeHelper';
import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

import './ExpiryTimeSelection.scss';

type ExpiryTimeSelectionProps = {
    header?: string;
    label?: string;
    expiryTime?: Date;
    expiryTimeChanged: (expiryTime?: Date) => void;
    hideTextWrapper?: boolean;
    earliestExpiryDate?: Date;
    testId?: string;
    includeWeekends?: boolean;
} & AnyValidationProps;

export const ExpiryTimeSelection = ({
    earliestExpiryDate,
    expiryTime,
    expiryTimeChanged,
    forceValidation,
    header,
    hideTextWrapper,
    label,
    required,
    testId = '',
    includeWeekends,
}: ExpiryTimeSelectionProps) => {
    const { t } = useTranslation('uiDomain');
    const requiredFieldValidator = useRequiredFieldValidator();

    const [selectedDate, setSelectedDate] = useState<Date | undefined>(
        // create new date and drop time information for easier comparison
        expiryTime ? new Date(expiryTime.getFullYear(), expiryTime.getMonth(), expiryTime.getDate()) : undefined
    );
    const [selectedTime, setSelectedTime] = useState<SelectableTime | undefined>(
        expiryTime ? { hour: expiryTime.getHours(), minute: expiryTime.getMinutes() } : undefined
    );

    const earliestDate = getEarliestExpirationDate(earliestExpiryDate || new Date(), includeWeekends);
    const dates: Date[] = getSelectableDates(earliestDate);

    const dateOptions = dates.map((date) => ({
        value: `${date.getTime()}`,
        label: `${formatDayname(navigator.language, date)}, ${formatDate(navigator.language, date)}`,
    }));

    const isFirstDateSelected = dates.findIndex((value) => value.getTime() === selectedDate?.getTime()) === 0;
    const earliestHourForFirstDate = earliestDate.getHours();

    const times: SelectableTime[] = SELECTABLE_TIMES.filter(
        (time) => !isFirstDateSelected || time.hour >= earliestHourForFirstDate
    );

    const timeOptions: InputSelectOption<string>[] = times.map((value) => ({
        label: `${value.hour}:${`${value.minute}`.padStart(2, '0')}`,
        value: `${toMinutes(value)}`,
    }));

    useEffect(() => {
        if (selectedDate && selectedTime) {
            const newExpiryDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate(),
                selectedTime.hour,
                selectedTime.minute
            );

            const validHourSelected = times.findIndex((time) => time.hour === selectedTime.hour) !== -1;
            const validDateSelected = dates.findIndex((date) => date.getTime() === selectedDate.getTime()) !== -1;

            if (validHourSelected && validDateSelected) {
                if (newExpiryDate.getTime() !== expiryTime?.getTime()) {
                    expiryTimeChanged(newExpiryDate);
                }
            } else if (validDateSelected && !validHourSelected) {
                setSelectedTime(undefined);
                expiryTimeChanged(undefined);
            } else {
                expiryTimeChanged(undefined);
                setSelectedTime(undefined);
                setSelectedDate(undefined);
            }
        } else if (expiryTime !== undefined) {
            expiryTimeChanged(undefined);
        }
    }, [selectedDate, selectedTime, expiryTimeChanged, times, dates]);

    return (
        <FormItem header={header} hideTextWrapper={hideTextWrapper} isMandatory={required}>
            <div className="expiry-time-selection">
                <div className="expiry-time-selection__label">{label ?? label}</div>
                <div className="expiry-time-selection__input-fields">
                    <InputSelect
                        options={dateOptions}
                        onSelect={(value) => setSelectedDate(value ? new Date(Number.parseInt(value, 10)) : undefined)}
                        placeholder={t('expiryTimeSelection.selectDatePlaceholder')}
                        value={selectedDate ? `${selectedDate?.getTime()}` : ''}
                        validators={required ? [requiredFieldValidator] : undefined}
                        forceValidation={forceValidation}
                        data-testid={`${testId}-day-selection`}
                    />

                    <InputSelect
                        options={timeOptions}
                        onSelect={(value) => {
                            setSelectedTime(value ? fromMinutes(Number.parseInt(value, 10)) : undefined);
                        }}
                        placeholder={t('expiryTimeSelection.selectTimePlaceholder')}
                        value={selectedTime ? `${toMinutes(selectedTime)}` : ''}
                        validators={required ? [requiredFieldValidator] : undefined}
                        forceValidation={forceValidation}
                        data-testid={`${testId}-time-selection`}
                    />
                </div>
            </div>
        </FormItem>
    );
};
