import { PropsWithChildren, useEffect } from 'react';
// import { useUserSelfDetails } from '@steelbuy/data-access';

export const LanguageSelection = ({ children }: PropsWithChildren) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // const {data: userModel} = useUserSelfDetails();
    useEffect(() => {
        // wire up userModel region or country to language selection
    }, []);
    return children;
};
