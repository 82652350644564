import { UserModel, UserModelConverter } from '@steelbuy/ts-shared';

import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class UserCrudApiClient extends AbstractCrudApiClient<UserModel> {
    protected collectionControllerUri = 'api/manager/users/';

    protected paginationControllerUri = 'api/manager/users/';

    protected entityCreateUri = 'api/manager/users/';

    protected entityFetchUri = 'api/manager/users/{id}/';

    protected entityMutateUri = 'api/manager/users/{id}/';

    protected entityDeleteUri = 'api/manager/users/{id}/';

    protected entityServiceCallUri = 'api/manager/users/{id}/';

    protected override paginationSize = 10;

    protected modelConverter = new UserModelConverter();
}
