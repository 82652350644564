import { ListingStatus, RFQStatus } from '@steelbuy/ts-shared';
import { BadgeVariant } from '@steelbuy/ui-primitive';

export function getBadgeVariant(status: ListingStatus | RFQStatus) {
    switch (status) {
        case ListingStatus.EXPIRED:
            return BadgeVariant.WARNING;

        case ListingStatus.PUBLISHED:
        case RFQStatus.LIVE:
            return BadgeVariant.POSITIVE;

        case ListingStatus.REVIEW_REJECTED:
        case RFQStatus.REJECTED:
            return BadgeVariant.NEGATIVE;

        default:
            return BadgeVariant.NEUTRAL;
    }
}
