import { useTranslation } from 'react-i18next';
import { PreparedSortCriteria, SortCriteria } from '@steelbuy/api-integration';
import { ListingBuyerSearchResultModel, ListingBuyerSearchSortCriteria, Nullable } from '@steelbuy/ts-shared';
import { Dropdown, IconIdentifier, SelectOption } from '@steelbuy/ui-primitive';

import { SortCriteriaOption, sortCriteriaOptions } from './SearchResults.enums';

const icons = {
    [SortCriteriaOption.RELEVANCE]: IconIdentifier.STAR,
    [SortCriteriaOption.EXPIRES_AT_ASCENDING]: IconIdentifier.ARROW_DOWN,
    [SortCriteriaOption.EXPIRES_AT_DESCENDING]: IconIdentifier.ARROW_UP,
    [SortCriteriaOption.WEIGHT_ASCENDING]: IconIdentifier.ARROW_DOWN,
    [SortCriteriaOption.WEIGHT_DESCENDING]: IconIdentifier.ARROW_UP,
    [SortCriteriaOption.PRICE_ASCENDING]: IconIdentifier.ARROW_DOWN,
    [SortCriteriaOption.PRICE_DESCENDING]: IconIdentifier.ARROW_UP,
};

interface SearchResultsSortingProps {
    sortCriteria: Nullable<SortCriteria<ListingBuyerSearchResultModel> | PreparedSortCriteria>;
    handleSortChange: (sortCriteria: PreparedSortCriteria<ListingBuyerSearchSortCriteria>) => void;
    isDisabled?: boolean;
}

export const SearchResultsSorting = ({ handleSortChange, isDisabled, sortCriteria }: SearchResultsSortingProps) => {
    const { t } = useTranslation('uiDomain');

    const dropdownOptions = Object.values(SortCriteriaOption).map<SelectOption>((sortCriteriaOptionKey) => ({
        label: t(`searchResultsSorting.options.${sortCriteriaOptionKey}`),
        value: sortCriteriaOptionKey,
        iconName: icons[sortCriteriaOptionKey],
    }));

    const selectedSortCriteriaOptionKey = Object.values(SortCriteriaOption).find((key) => {
        const sortCriteriaOption = sortCriteriaOptions.get(key as SortCriteriaOption);

        return (
            sortCriteriaOption?.[0].property === sortCriteria?.[0].property &&
            sortCriteriaOption?.[0].direction === sortCriteria?.[0].direction
        );
    });

    const handleSortSelection = (value?: string): void => {
        const sortCriteriaOption = sortCriteriaOptions.get(value as SortCriteriaOption) ?? null;
        if (sortCriteriaOption === null) {
            return;
        }
        handleSortChange(sortCriteriaOption);
    };

    return (
        <div className="search-results__header__sort-selection">
            <Dropdown
                title={t('searchResultsSorting.inputTitle')}
                options={dropdownOptions}
                onChange={handleSortSelection}
                preselection={selectedSortCriteriaOptionKey}
                placeholder={t('searchResultsSorting.placeholderText')}
                disabled={isDisabled}
            />
        </div>
    );
};
