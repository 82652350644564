import { JsonWebToken } from '@steelbuy/auth';
import { ApiError } from '@steelbuy/error';
import { ModelConverter, Mutable, Mutation, ApiModel, ViewModel, ModelPrimaryKey, Nullable } from '@steelbuy/ts-shared';

import { CollectionApiResponseBody } from './CollectionApiResponseBody';
import { CollectionResponse } from './CollectionResponse';
import { CrudApiClient } from './CrudApiClient';
import { createFilterString, createSortString } from './UrlParams';
import { FilterCriteria } from '../collection-criteria/FilterCriteria';
import { PreparedSortCriteria, SortCriteria } from '../collection-criteria/SortCriteria';
import { JsonRestConnector } from '../network/JsonRestConnector';

export abstract class AbstractCrudApiClient<Model extends ViewModel> implements CrudApiClient<Model> {
    protected abstract collectionControllerUri: string;

    protected abstract paginationControllerUri: string;

    protected abstract entityCreateUri: string;

    protected abstract entityFetchUri: string;

    protected abstract entityMutateUri: string;

    protected abstract entityDeleteUri: string;

    protected abstract entityServiceCallUri: string;

    protected paginationSize = 50;

    protected paginationQueryOffset = 'offset';

    protected paginationQueryLimit = 'limit';

    protected sortableProperties: Array<keyof Model> = [];

    protected sortableQuery = 'sort';

    protected filterableProperties: Array<keyof Model> = [];

    protected filterableQuery = 'filter';

    protected abstract modelConverter: ModelConverter<Model>;

    private baseUrl: Nullable<string> = null;

    private connector: Nullable<JsonRestConnector> = null;

    public init(baseUrl: string, jsonWebToken: Nullable<JsonWebToken> = null): this {
        this.baseUrl = baseUrl;
        this.connector = new JsonRestConnector(jsonWebToken);
        return this;
    }

    async fetchCollection(
        sort?: SortCriteria<Model> | PreparedSortCriteria,
        filter?: FilterCriteria<Model>
    ): Promise<CollectionResponse<Model>> {
        if (this.connector === null) {
            throw new ApiError('CRUD API not initialized');
        }
        const endpoint = this.buildCollectionEndpoint(sort, filter);
        const parsedResponse = await this.connector.get(endpoint);
        const responseBody = parsedResponse.body as CollectionApiResponseBody<Model>;
        const items = responseBody.items.map((apiModel): Model => this.modelConverter.toViewModel(apiModel));
        const currentPage = Math.floor(responseBody.offset / responseBody.limit) + 1;
        const maxPage = Math.ceil(responseBody.total / responseBody.limit);
        return {
            items,
            currentPage,
            maxPages: maxPage,
            totalItems: responseBody.total,
        };
    }

    async fetchPage(
        page = 1,
        sort?: SortCriteria<Model> | PreparedSortCriteria,
        filter?: FilterCriteria<Model>
    ): Promise<CollectionResponse<Model>> {
        if (this.connector === null) {
            throw new ApiError('CRUD API not initialized');
        }
        const endpoint = this.buildPaginationEndpoint(page, sort, filter);
        const parsedResponse = await this.connector.get(endpoint);
        const responseBody = parsedResponse.body as CollectionApiResponseBody<Model>;
        const items = responseBody.items.map((apiModel): Model => this.modelConverter.toViewModel(apiModel));
        const currentPage = Math.floor(responseBody.offset / responseBody.limit) + 1;
        const maxPage = Math.ceil(responseBody.total / responseBody.limit);
        return {
            items,
            currentPage,
            maxPages: maxPage,
            totalItems: responseBody.total,
        };
    }

    async fetch(id: ModelPrimaryKey): Promise<Nullable<Model>> {
        if (this.connector === null) {
            throw new ApiError('CRUD API not initialized');
        }
        const endpoint = this.buildEndpointWithId(this.entityFetchUri, id);
        const parsedResponse = await this.connector.get(endpoint);
        const apiModel = parsedResponse.body as ApiModel<Model>;
        return this.modelConverter.toViewModel(apiModel);
    }

    async mutate(id: ModelPrimaryKey, mutation: Mutation<Model>): Promise<Nullable<Model>> {
        if (this.connector === null) {
            throw new ApiError('CRUD API not initialized');
        }
        const endpoint = this.buildEndpointWithId(this.entityMutateUri, id);
        const apiMutation = this.modelConverter.toApiModel(mutation);
        const parsedResponse = await this.connector.put(endpoint, apiMutation);
        const apiModel = parsedResponse.body as Nullable<ApiModel<Model>>;
        return apiModel === null ? null : this.modelConverter.toViewModel(apiModel);
    }

    async create(model: Mutable<Model>): Promise<Model> {
        if (this.connector === null) {
            throw new ApiError('CRUD API not initialized');
        }
        const endpoint = this.baseUrl + this.entityCreateUri;
        const apiMutableModel = this.modelConverter.toApiModel(model);
        const parsedResponse = await this.connector.post(endpoint, apiMutableModel);
        const apiModel = parsedResponse.body as ApiModel<Model>;
        return this.modelConverter.toViewModel(apiModel);
    }

    async delete(id: ModelPrimaryKey): Promise<ModelPrimaryKey> {
        if (this.connector === null) {
            throw new ApiError('CRUD API not initialized');
        }
        const endpoint = this.buildEndpointWithId(this.entityDeleteUri, id);
        await this.connector.delete(endpoint);
        return id;
    }

    async serviceCall(id: ModelPrimaryKey, service: string, mutation?: Mutation<Model>): Promise<Nullable<Model>> {
        if (this.connector === null) {
            throw new ApiError('CRUD API not initialized');
        }

        const endpoint = this.buildEndpointWithId(this.entityServiceCallUri, id) + service;
        const apiMutation = mutation ? this.modelConverter.toApiModel(mutation) : undefined;
        const parsedResponse = await this.connector.put(endpoint, apiMutation ?? null);
        const apiModel = parsedResponse.body as ApiModel<Model>;
        return this.modelConverter.toViewModel(apiModel);
    }

    private buildCollectionEndpoint(
        sort?: SortCriteria<Model> | PreparedSortCriteria,
        filter?: FilterCriteria<Model>
    ): string {
        const endpointUrl = new URL(this.baseUrl + this.collectionControllerUri);
        endpointUrl.searchParams.set(this.paginationQueryLimit, this.paginationSize.toString());
        if (sort !== undefined && sort.length > 0) {
            this.appendSortParams(endpointUrl, sort);
        }
        if (filter !== undefined && filter.length > 0) {
            this.appendFilterParams(endpointUrl, filter);
        }
        return endpointUrl.toString();
    }

    private buildPaginationEndpoint(
        page: number,
        sort?: SortCriteria<Model> | PreparedSortCriteria,
        filter?: FilterCriteria<Model>
    ): string {
        const endpointUrl = new URL(this.baseUrl + this.paginationControllerUri);
        const offset = (page - 1) * this.paginationSize;
        endpointUrl.searchParams.set(this.paginationQueryOffset, offset.toString());
        endpointUrl.searchParams.set(this.paginationQueryLimit, this.paginationSize.toString());
        if (sort !== undefined && sort.length > 0) {
            this.appendSortParams(endpointUrl, sort);
        }
        if (filter !== undefined && filter.length > 0) {
            this.appendFilterParams(endpointUrl, filter);
        }
        return endpointUrl.toString();
    }

    private buildEndpointWithId(uri: string, id: string): string {
        return this.baseUrl + uri.replace('{id}', id);
    }

    private appendSortParams = (url: URL, sort: SortCriteria<Model> | PreparedSortCriteria) => {
        url.searchParams.set(this.sortableQuery, createSortString(sort));
    };

    private appendFilterParams = (url: URL, filter: FilterCriteria<Model>) => {
        url.searchParams.set(this.filterableQuery, createFilterString(filter));
    };
}
