import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CartBuyerModel } from '@steelbuy/ts-shared';
import { ButtonTertiaryOnLightM } from '@steelbuy/ui-primitive';
import { RoutePath } from '../../router/Routes';
import './CartReminderNotification.scss';

const CartReminderNotification = ({ time, cart }: { time: number; cart?: CartBuyerModel }) => {
    const navigate = useNavigate();

    const { t } = useTranslation('translation');
    return (
        <div className="toast-cart-reminder">
            <div>
                <Trans
                    i18nKey="application.cartReminderNotification.message"
                    components={{
                        time: <span className="message-bold" />,
                    }}
                    values={{ time }}
                    t={t}
                />
            </div>
            <ButtonTertiaryOnLightM
                label={t('application.cartReminderNotification.checkoutButtonLabel', {
                    count: cart ? cart.cartEntries.length : 0,
                })}
                onClick={() => navigate(RoutePath.MY_CART_CHECKOUT)}
                disabled={!cart?.canCheckout}
            />
        </div>
    );
};

export default CartReminderNotification;
