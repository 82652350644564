import { useTranslation } from 'react-i18next';
import { DownloadFile, UploadFile } from '@steelbuy/ts-shared';
import { Card, CardLayout, CardContentTestCertificate } from '@steelbuy/ui-primitive';
import { FileMultiUploadItemView } from '../file-multi-upload-item-view/FileMultiUploadItemView';

type ListingDetailsPackageSheetProps = {
    apiBaseUrl: string;
    files: Array<DownloadFile | UploadFile>;
};

export const ListingDetailsPackageSheet = ({ apiBaseUrl, files }: ListingDetailsPackageSheetProps) => {
    const { t } = useTranslation('translation');

    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentTestCertificate header={t('application.listingDetails.packageListSheetTitle')}>
                    <FileMultiUploadItemView files={files ?? []} apiBaseUrl={apiBaseUrl} />
                </CardContentTestCertificate>
            </CardLayout>
        </Card>
    );
};
