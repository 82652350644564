import { useTranslation } from 'react-i18next';
import { FormItem, InputTextfield } from '@steelbuy/ui-primitive';
import { RequiredFieldValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

export const ListingSkuInput = ({
    forceValidation,
    sku: sku1,
    skuSelected,
}: {
    sku?: string;
    skuSelected: (sku: string) => void;
} & RequiredFieldValidationProps) => {
    const { t } = useTranslation('uiDomain');
    const requiredFieldValidator = useRequiredFieldValidator();

    return (
        <FormItem header={t('listingSkuInput.inputHeader')} isMandatory>
            <InputTextfield
                name="sku"
                value={sku1 ?? ''}
                onChange={skuSelected}
                autoComplete="off"
                autoCorrect="off"
                validators={[requiredFieldValidator]}
                forceValidation={forceValidation}
            />
        </FormItem>
    );
};
