import { useTranslation } from 'react-i18next';
import { RFQStatus } from '@steelbuy/ts-shared';
import { getBadgeVariant } from '@steelbuy/ui-domain';
import { Card, CardContentListingViewState, CardLayout, Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { formatDate, formatTime } from '@steelbuy/util';

export const CardContentStatus = ({ status, expiresAt }: { status: RFQStatus; expiresAt: string }) => {
    const { t } = useTranslation('translation');

    return (
        <Card isClickable={false}>
            <CardLayout>
                <CardContentListingViewState
                    header={t(`application.myRFQ.statusText.header.${status}`)}
                    date={`${formatDate(navigator.language, new Date(expiresAt))}, ${formatTime(
                        navigator.language,
                        new Date(expiresAt)
                    )}`}
                    badgeVariant={getBadgeVariant(status)}
                    badgeLabel={t(`application.myRFQFilter.optionLabels.${status}`)}
                />
                {status === RFQStatus.EXPIRED && (
                    <Notification
                        level={NotificationLevel.INFO}
                        message={t('application.myRFQDetails.notificationMsg')}
                    />
                )}
            </CardLayout>
        </Card>
    );
};
