import { ListingBuyerSearchResultModel, ListingBuyerSearchResultModelConverter } from '@steelbuy/ts-shared';

import { AbstractSearchApiClient } from './api-client/AbstractSearchApiClient';

export class ListingBuyerAlertSearchApiClient extends AbstractSearchApiClient<ListingBuyerSearchResultModel> {
    protected collectionControllerUri = 'api/universal/search-listings/by-alert/{alertId}';

    protected paginationControllerUri = 'api/universal/search-listings/by-alert/{alertId}';

    protected entityFetchUri = 'api/universal/search-listings/{id}/';

    protected override paginationSize = 10;

    protected modelConverter = new ListingBuyerSearchResultModelConverter();
}
