import { UseInfiniteQueryOptions } from '@tanstack/react-query';
import { PackageSellerModel, PackageSellerModelConverter, ApiModel } from '@steelbuy/ts-shared';
import { ResponseModel } from '../../utils/types';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

export const MY_PACKAGE_KEY = 'packages';

const modelConverter = new PackageSellerModelConverter();
const searchListingMapFn = (data: PackageSellerModel) =>
    modelConverter.toViewModel(data as unknown as ApiModel<PackageSellerModel>);

export const useMyPackages = ({
    filterCriteria,
    queryOptions,
}: {
    filterCriteria?: Record<string, string>;
    queryOptions?: Omit<
        UseInfiniteQueryOptions<ResponseModel<PackageSellerModel[]>>,
        'queryKey' | 'queryFn' | 'getNextPageParam'
    >;
}) =>
    usePaginatedQuery<PackageSellerModel>({
        url: '/api/universal/packaging/packages',
        requestOptions: {
            filter: filterCriteria,
        },
        mapFn: searchListingMapFn,
        queryKey: [MY_PACKAGE_KEY, filterCriteria],
        queryOptions: {
            cacheTime: 0,
            ...queryOptions,
        },
    });
