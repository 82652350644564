import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingBuyerAlertModel, AlertType, ModelPrimaryKey } from '@steelbuy/ts-shared';
import {
    ButtonGhostSystemOnLightM,
    CardClear,
    IconIdentifier,
    SearchSummaryActions,
    SearchSummaryResults,
    Separator,
    SeparatorIdentifier,
    Tag,
    TagVariant,
} from '@steelbuy/ui-primitive';
import { SearchSummary, toSearchFormData } from '../search-summary/SearchSummary';

import './MyAlertsList.scss';

interface MyAlertsListProps {
    alerts: Readonly<ListingBuyerAlertModel[]>;
    navigateToAlertSettings: (alertId: ModelPrimaryKey) => void;
    pathToAlertSearchResults: (alertId: ModelPrimaryKey) => string;
    showPrice: boolean;
}

export const MyAlertsList = ({
    alerts,
    navigateToAlertSettings,
    pathToAlertSearchResults,
    showPrice,
}: MyAlertsListProps) => {
    const { t } = useTranslation('uiDomain');

    return (
        <div className="my-alerts__list">
            <Separator separatorType={SeparatorIdentifier.GROUP_ON_LIGHT} />
            {alerts.map(({ filterQuery, comment, id, type, numberOfNewListings, enabled }) => (
                <Fragment key={`alert-${id}`}>
                    <CardClear isClickable url={pathToAlertSearchResults(id)}>
                        <SearchSummary
                            searchFormData={toSearchFormData(filterQuery)}
                            comment={comment}
                            showPrice={showPrice}
                        >
                            <div className="my-alerts__list__tag-container">
                                {type === AlertType.URGENT && (
                                    <SearchSummaryResults results={t('myAlertsList.urgent')} />
                                )}
                                {numberOfNewListings > 0 && (
                                    <SearchSummaryResults
                                        results={t('myAlertsList.searchSummaryNewResults', {
                                            value: numberOfNewListings.toString(),
                                        })}
                                    />
                                )}
                            </div>
                            <SearchSummaryActions>
                                {!enabled && <Tag icon={IconIdentifier.MUTE} variant={TagVariant.NEUTRAL} />}
                                <ButtonGhostSystemOnLightM
                                    icon={IconIdentifier.SETTINGS}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        navigateToAlertSettings(id);
                                    }}
                                    title={t('myAlertsList.alertSettingsButtonLabel')}
                                />
                            </SearchSummaryActions>
                        </SearchSummary>
                    </CardClear>
                    <Separator separatorType={SeparatorIdentifier.GROUP_ON_LIGHT} />
                </Fragment>
            ))}
        </div>
    );
};
