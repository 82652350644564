import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ActionStatus,
    useListingSellerDraftCollectionData,
    useListingSellerDraftEntityData,
} from '@steelbuy/data-provider';
import { ListingSellerDraftModel } from '@steelbuy/ts-shared';
import { ButtonCallToAction, ButtonPrimary, ButtonTertiaryOnLightM, LoadingStatus } from '@steelbuy/ui-primitive';
import { useCreateListingContext } from './CreateListingContext';
import { contextToDraft } from './CreateListingContextMapper';

type SaveListingDraftButtonProps = {
    onSaveSuccess: (draft: ListingSellerDraftModel, newEntityCreated: boolean, isPackage: boolean) => void;
    onSaveFail?: () => void;
    ButtonComponent: typeof ButtonTertiaryOnLightM | typeof ButtonPrimary | typeof ButtonCallToAction;
};

export const SaveListingDraftButton = ({ ButtonComponent, onSaveFail, onSaveSuccess }: SaveListingDraftButtonProps) => {
    const { t } = useTranslation('translation');
    const createListingContext = useCreateListingContext();
    const listingSellerDraftCollectionData = useListingSellerDraftCollectionData();
    const listingSellerDraftEntityData = useListingSellerDraftEntityData();
    const actionPending = [ActionStatus.MUTATE_PENDING, ActionStatus.CREATE_PENDING].includes(
        listingSellerDraftCollectionData.queryActionStatus()
    );

    const [savingDraft, setSavingDraft] = useState<boolean>(false);

    const createdDraft = listingSellerDraftCollectionData.queryCreated().get();
    const queriedDraft = listingSellerDraftCollectionData.queryOne(createListingContext.draftId ?? '').get();
    const draftEntity = listingSellerDraftEntityData.query().get();
    listingSellerDraftCollectionData.useActionStatusEffect(
        [ActionStatus.CREATE_SUCCESS, ActionStatus.MUTATE_SUCCESS],
        () => {
            const saveDraft = (draft: Readonly<ListingSellerDraftModel>) => {
                createListingContext.savedDraft(draft);
                onSaveSuccess(draft, false, false);
                listingSellerDraftEntityData.resolveFetchStatus();
            };
            // If the status change didn't originate from this component instance, ignore it.
            if (!savingDraft) {
                return;
            }

            if (createListingContext.draftId) {
                if (queriedDraft) {
                    saveDraft(queriedDraft);
                } else if (draftEntity) {
                    saveDraft(draftEntity);
                }
            } else if (createdDraft) {
                createListingContext.savedDraft(createdDraft);
                onSaveSuccess(createdDraft, true, false);
            }

            setSavingDraft(false);
        },
        true
    );

    listingSellerDraftCollectionData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setSavingDraft(false);
            onSaveFail?.();
        },
        true
    );

    const handleSaveDraft = () => {
        setSavingDraft(true);
        const draft = contextToDraft(createListingContext);

        if (createListingContext.draftId) {
            listingSellerDraftCollectionData.mutate(createListingContext.draftId, draft);
        } else {
            listingSellerDraftCollectionData.create(draft);
        }
    };

    return (
        <ButtonComponent
            label={t('application.createListingSaveDraftButton.label')}
            onClick={handleSaveDraft}
            loadingStatus={actionPending ? LoadingStatus.PENDING : LoadingStatus.IDLE}
        />
    );
};
