/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from 'lodash';
import { FC } from 'react';
import { FieldErrors, FieldValues, UseFormClearErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { CreateRFQDraftModel, CreateRFQModel } from '@steelbuy/data-access';
import {
    MaterialCoatingSelection,
    MaterialCoatingThicknessSelection,
    MaterialFinishSelection,
    MaterialGradeSelection,
    MaterialSpecificationSelection,
    MaterialSurfaceSelection,
    MaterialToleranceSelection,
    RFQNumberInput,
} from '@steelbuy/form';
import {
    AnyMaterialDimensionModel,
    AnyMaterialModel,
    Definition,
    DraftQuoteEntry,
    MaterialProperties,
    MaterialWithDimensions,
    Product,
    ProductType,
    QuoteEntry,
    RFQBuyerDetailsModel,
    RFQDetailsHeadingConfig,
    RFQDraftModel,
    Shape,
    getCoatingThickness,
    getCoatingThicknessValue,
    getCoatings,
    getFinishes,
    getGrades,
    getSpecifications,
    getSurfaces,
    isMaterialWithCoating,
    isMaterialWithCoatingThickness,
    isMaterialWithDefinition,
    isMaterialWithGrade,
    isMaterialWithPlateType,
    isMaterialWithSpecification,
    isMaterialWithSurface,
    rfqHeadings,
    createDateFromTimestamp,
    createTimestampFromDate,
} from '@steelbuy/ts-shared';
import { entries } from '@steelbuy/util';
import {
    PartialRFQTypeWithDefinition,
    PartialRFQTypeWithPlateType,
    RFQType,
    Step2Materials,
    Step2Type,
    Step3Type,
    isStep1WithDefinition,
    isStep1WithPlateType,
    isStep2,
    isStep3,
} from './Schema';
import { RoutePath } from '../../router/Routes';

export enum RFQStep {
    CHOOSE_MATERIALS = 'CHOOSE_MATERIALS',
    ADD_ITEMS = 'ADD_ITEMS',
    SHIPPING_DETAILS = 'SHIPPING_DETAILS',
}

export const RFQ_STEPS = Object.values(RFQStep);

export const isStep1Valid = (formData?: Partial<RFQType>) =>
    isStep1WithDefinition(formData) || isStep1WithPlateType(formData);

export const isStep2Valid = (formData?: Partial<RFQType>) => {
    let materialIndexes: (ProductType | Definition)[] = [];
    if (isStep1WithPlateType(formData)) {
        materialIndexes = formData.plateType;
    } else if (isStep1WithDefinition(formData)) {
        materialIndexes = formData.definition;
    }

    return (
        (formData as Step2Type)?.materials &&
        materialIndexes.length > 0 &&
        materialIndexes.length === Object.values((formData as Step2Type).materials).length &&
        Object.values((formData as Step2Type)?.materials).every((field) => field.length > 0)
    );
};
export const isStep3Valid = (formData?: Partial<RFQType>) => {
    const step3FormData = formData as Step3Type;
    if (step3FormData?.origin?.length > 0 && step3FormData?.delivery && step3FormData?.deadline) {
        return true;
    }
    return false;
};

export const getAddedItemsIndexes = (formData?: RFQType) => {
    if (!formData || !isStep2(formData)) {
        return [];
    }
    return entries((formData as Step2Type)?.materials)
        .filter(([_index, fields]) => fields.length > 0)
        .map(([index, _fields]) => index);
};

export const rfqValidationLookup = {
    [RFQStep.CHOOSE_MATERIALS]: isStep1Valid,
    [RFQStep.ADD_ITEMS]: isStep2Valid,
    [RFQStep.SHIPPING_DETAILS]: isStep3Valid,
};

export type RFQInputs = Record<
    keyof MaterialWithDimensions,
    {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Component: FC<any>;
        checkFn: (material: MaterialProperties) => boolean;
        disabledFn?: (material: MaterialProperties) => boolean;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        rules?: Record<string, any>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getOptions?: (material: MaterialProperties) => any;
        valueMapper?: (material: MaterialProperties) => string;
    } & RFQDetailsHeadingConfig
>;

export const rfqInputs: RFQInputs = {
    grade: {
        ...rfqHeadings.grade,
        Component: MaterialGradeSelection,
        checkFn: (material) => isMaterialWithGrade(material),
        getOptions: (material: MaterialProperties) => getGrades(material),
    },
    specification: {
        ...rfqHeadings.specification,
        Component: MaterialSpecificationSelection,
        checkFn: (material) => isMaterialWithSpecification(material),
        disabledFn: (material) => getSpecifications(material).length === 0,
        getOptions: (material: MaterialProperties) => getSpecifications(material),
    },
    finish: {
        ...rfqHeadings.finish,
        Component: MaterialFinishSelection,
        disabledFn: (material) => getFinishes(material).length === 0,
        getOptions: (material: MaterialProperties) => getFinishes(material),
    },
    width: {
        ...rfqHeadings.width,
        Component: RFQNumberInput,
    },
    thickness: {
        ...rfqHeadings.thickness,
        Component: RFQNumberInput,
    },
    weight: {
        ...rfqHeadings.weight,
        Component: RFQNumberInput,
    },
    tolerance: {
        ...rfqHeadings.tolerance,
        Component: MaterialToleranceSelection,
    },
    length: {
        ...rfqHeadings.length,
        Component: RFQNumberInput,
    },
    surface: {
        ...rfqHeadings.surface,
        Component: MaterialSurfaceSelection,
        checkFn: (material) => isMaterialWithSurface(material),
        disabledFn: (material) => getSurfaces(material).length === 0,
        getOptions: (material: MaterialProperties) => getSurfaces(material),
    },
    coating: {
        ...rfqHeadings.coating,
        Component: MaterialCoatingSelection,
        checkFn: (material) => isMaterialWithCoating(material),
        disabledFn: (material) => getCoatings(material).length === 0,
        getOptions: (material: MaterialProperties) => getCoatings(material),
    },
    coatingThicknessValue: {
        ...rfqHeadings.coatingThicknessValue,
        Component: MaterialCoatingThicknessSelection,
        checkFn: (material) => isMaterialWithCoatingThickness(material),
        disabledFn: (material) => getCoatingThickness(material).length === 0,
        getOptions: (material: MaterialProperties) => getCoatingThickness(material),
        valueMapper: getCoatingThicknessValue,
    },
} as RFQInputs;

const numberFields = ['weight', 'length', 'width', 'thickness'];

export const isUniqueEntry = (
    formData: MaterialWithDimensions & { id?: string },
    fields: MaterialWithDimensions[],
    ignoreIndex?: number
) => {
    let isUnique = true;
    fields.forEach((field, index) => {
        if (isUnique && ignoreIndex !== index) {
            isUnique = !entries(formData).every(([key, value]) => {
                const fieldValue = field[key as NonNullable<keyof MaterialWithDimensions>];
                const isNumberField = numberFields.includes(key);
                const formattedValue = isNumberField && value !== undefined ? parseFloat(`${value}`) : value;
                const formattedFieldValue = isNumberField ? parseFloat(`${fieldValue}`) : fieldValue;
                return key === 'id' || key === 'weight' || !formattedValue || formattedFieldValue === formattedValue;
            });
        }
    });
    return isUnique;
};

export const updateNonApplicableFields = (
    formHeadings: RFQInputs,
    material: MaterialWithDimensions,
    clearErrors: UseFormClearErrors<FieldValues>,
    watch: UseFormWatch<FieldValues>,
    setValue: UseFormSetValue<FieldValues>,
    errors: FieldErrors<FieldValues>
) => {
    entries(formHeadings).forEach(([key, value]) => {
        const options = value.getOptions?.({ ...material, ...watch() });
        if (options && options.length === 0 && errors[key]) {
            clearErrors(key);
        }
        if (options && watch(key) && (options?.length === 0 || !options?.includes(watch(key)))) {
            setValue(key, undefined);
        }
    });
};

export const removeNullFields = (data: MaterialWithDimensions) =>
    entries(data).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null && value !== '') {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            acc[key] = value as any;
        }
        return acc;
    }, {} as MaterialWithDimensions);

export const hasFormData = (formData?: RFQType) => formData && Object.keys(formData).length;

const DIMENSION_FIELDS = ['width', 'length', 'thickness'];
const MATERIAL_OMIT_FIELDS = [...DIMENSION_FIELDS, 'weight', 'plateType', 'definition'];

const getDimensions = (materials: MaterialWithDimensions, product: Product) =>
    DIMENSION_FIELDS.reduce(
        (acc, field: string) => {
            if (materials[field as keyof AnyMaterialDimensionModel] !== undefined) {
                const value = materials[field as keyof MaterialWithDimensions];
                acc[field as keyof AnyMaterialDimensionModel] = Number.isNaN(value) ? value : parseFloat(`${value}`);
            }
            return acc;
        },
        { product } as AnyMaterialDimensionModel
    );

const getMaterials = (materials: MaterialWithDimensions) =>
    entries(materials).reduce((acc, [field, value]) => {
        if (field !== undefined && value !== undefined && !MATERIAL_OMIT_FIELDS.includes(field)) {
            // have to use any as typescript isn't clever enough to realise that the field cannot be one of the MATERIAL_OMIT_FIELDS
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (acc as any)[field] = value;
        }
        return acc as MaterialProperties;
    }, {} as MaterialProperties);

export const getCreateRFQModel = (formData: RFQType): CreateRFQModel | null => {
    if (isStep3(formData)) {
        const monthAndYear = formData.delivery.split('_');
        let materialIndexes: (ProductType | Definition)[] = [];
        let materialIndexer: 'plateType' | 'definition' | undefined;
        if (isStep1WithPlateType(formData)) {
            materialIndexer = 'plateType';
            materialIndexes = formData.plateType;
        } else if (isStep1WithDefinition(formData)) {
            materialIndexer = 'definition';
            materialIndexes = formData.definition;
        }

        const baseMaterial = {
            materialType: formData.materialType,
            shape: formData.shape,
            product: formData.product,
        };

        if (monthAndYear)
            return {
                expiresAt: new Date(parseInt(formData.deadline, 10)).toISOString(),
                origin: formData.origin,
                additionalInformation: formData.comments,
                delivery: { month: parseInt(monthAndYear[0], 10), year: parseInt(monthAndYear[1], 10) },
                quoteEntry: materialIndexes.reduce((acc, index) => {
                    const quoteEntries = formData.materials[index].map((materials) => ({
                        weight: parseFloat(`${materials.weight}`),
                        materialDimensions: getDimensions(materials, formData.product),
                        material: {
                            ...(materialIndexer ? { [materialIndexer]: index } : {}),
                            ...baseMaterial,
                            ...getMaterials(materials),
                        },
                    }));
                    acc.push(...(quoteEntries as QuoteEntry[]));
                    return acc;
                }, [] as QuoteEntry[]),
            };
    }
    return null;
};

const DRAFT_RFQ_DEFAULTS = {
    origin: [],
    delivery: {},
    quoteEntry: [],
};

export const getCreateRFQDraftModel = (formData: Partial<RFQType>): CreateRFQDraftModel | null => {
    let payload: CreateRFQDraftModel = { ...DRAFT_RFQ_DEFAULTS };
    if (isStep3(formData)) {
        const monthAndYear = formData?.delivery?.split('_');
        payload = {
            ...payload,
            origin: formData?.origin || DRAFT_RFQ_DEFAULTS.origin,
            delivery: monthAndYear
                ? { month: parseInt(monthAndYear[0], 10), year: parseInt(monthAndYear[1], 10) }
                : DRAFT_RFQ_DEFAULTS.delivery,
        };
        if (formData?.deadline) {
            payload.expiresAt = createTimestampFromDate(new Date(parseInt(formData.deadline, 10)));
        }
        if (formData?.comments) {
            payload.additionalInformation = formData.comments;
        }
    }
    let materialIndexes: (ProductType | Definition)[] = [];
    let materialIndexer: 'plateType' | 'definition' | undefined;
    if (isStep1WithPlateType(formData)) {
        materialIndexer = 'plateType';
        materialIndexes = formData.plateType;
    } else if (isStep1WithDefinition(formData)) {
        materialIndexer = 'definition';
        materialIndexes = formData.definition;
    }

    const baseMaterial = {
        materialType: formData.materialType,
        shape: formData.shape,
        product: formData.product,
    } as Partial<AnyMaterialModel>;

    let quoteEntry: DraftQuoteEntry[] = [];
    if (materialIndexes.length) {
        quoteEntry = materialIndexes.reduce((acc, index) => {
            const indexer = { ...(materialIndexer ? { [materialIndexer]: index } : {}) };

            const quoteEntries =
                isStep2(formData) && formData?.materials && formData?.materials[index]?.length > 0
                    ? formData.materials[index].map((materials) => ({
                          weight: parseFloat(`${materials.weight}`),
                          materialDimensions: getDimensions(materials, baseMaterial.product || Product.COIL),
                          material: {
                              ...(materialIndexer ? { [materialIndexer]: index } : {}),
                              ...baseMaterial,
                              ...getMaterials(materials),
                          },
                      }))
                    : [
                          {
                              material: { ...baseMaterial, ...indexer },
                              materialDimensions: {},
                              weight: undefined,
                          },
                      ];
            acc.push(...(quoteEntries as DraftQuoteEntry[]));
            return acc;
        }, [] as DraftQuoteEntry[]);
    } else {
        quoteEntry = [
            {
                material: baseMaterial,
                materialDimensions: {},
            },
        ];
    }

    return {
        ...payload,
        quoteEntry,
    };
};

// filter out empty array, undefined, and empty objects
const filterInvalidKeys = (obj: Partial<RFQType>) =>
    Object.entries(obj)
        .filter(
            ([_, v]) =>
                v !== undefined &&
                (!Array.isArray(v) || v.length > 0) &&
                (!(v instanceof Object) || Object.keys(v).length > 0)
        )
        .reduce((r, [k, v]) => {
            const filteredValue = v instanceof Object ? filterInvalidKeys(v) : v;
            if (!(filteredValue instanceof Object && Object.keys(filteredValue).length === 0)) {
                // eslint-disable-next-line no-param-reassign
                r[k] = filteredValue;
            }
            return r;
        }, {} as Record<string, unknown>);

export const hasDraftChanged = (prevDraft: Partial<RFQType>, currentDraft: Partial<RFQType>) => {
    const previousDraft = filterInvalidKeys(prevDraft);
    const updatedDraft = filterInvalidKeys(currentDraft);
    const hasChanges = !isEqual(previousDraft, updatedDraft);
    return hasChanges;
};

const step1Fields = ['materialType', 'shape', 'product', 'definition', 'plateType'];

const GUARDS = {
    definition: isMaterialWithDefinition,
    plateType: isMaterialWithPlateType,
};

const getIndexerAndMaterials = (draftRFQ: RFQDraftModel, indexer: 'definition' | 'plateType') => {
    const guard = GUARDS[indexer];
    return draftRFQ.quoteEntry.reduce(
        (acc, quoteEntry) => {
            const { material } = quoteEntry;
            if (guard(material) && material[indexer]) {
                const indexerValue = material[indexer] as Definition | ProductType;
                if (!acc.indexer.includes(indexerValue)) {
                    acc.indexer.push(indexerValue);
                }
                const rfqMaterials = Object.keys(quoteEntry.material)
                    .filter((key) => !step1Fields.includes(key))
                    .reduce((mappedObj, key: string) => {
                        // eslint-disable-next-line no-param-reassign
                        (mappedObj as any)[key] = quoteEntry.material[key as keyof MaterialProperties];
                        return mappedObj;
                    }, {} as MaterialProperties);
                const materialsForDefinition: Omit<MaterialWithDimensions, 'plateType' | 'definition'> = {
                    ...rfqMaterials,
                    ...(quoteEntry.materialDimensions || {}),
                    ...(quoteEntry.weight ? { weight: quoteEntry.weight } : {}),
                };
                if (Object.keys(materialsForDefinition).length) {
                    if (!acc.materials[indexerValue]) {
                        acc.materials[indexerValue] = [];
                    }
                    acc.materials[indexerValue].push(materialsForDefinition);
                }
            }
            return acc;
        },
        {
            indexer: [] as any[],
            materials: {} as any,
        }
    );
};

export const convertToRFQType = (draftRFQ: RFQDraftModel): Partial<RFQType> | null => {
    if (!draftRFQ.quoteEntry.length) {
        return null;
    }

    const { materialType, product, shape } = draftRFQ.quoteEntry[0].material;
    const rfqType: Partial<RFQType> = {
        materialType,
        shape,
        product,
        origin: draftRFQ.origin,
    };

    if (draftRFQ.delivery.month && draftRFQ.delivery.year) {
        rfqType.delivery = `${draftRFQ.delivery.month}_${draftRFQ.delivery.year}`;
    }

    if (draftRFQ.expiresAt) {
        rfqType.deadline = `${createDateFromTimestamp(draftRFQ.expiresAt).getTime()}`;
    }

    if (draftRFQ.additionalInformation) {
        rfqType.comments = draftRFQ.additionalInformation;
    }

    if (isMaterialWithDefinition(draftRFQ.quoteEntry[0].material)) {
        const { indexer, materials } = getIndexerAndMaterials(draftRFQ, 'definition');
        (rfqType as PartialRFQTypeWithDefinition).definition = indexer;
        rfqType.materials = materials;
    } else if (isMaterialWithPlateType(draftRFQ.quoteEntry[0].material)) {
        const { indexer, materials } = getIndexerAndMaterials(draftRFQ, 'plateType');
        (rfqType as PartialRFQTypeWithPlateType).plateType = indexer;
        rfqType.materials = materials;
    }
    if (rfqType.materials && Object.keys(rfqType.materials).length === 0) {
        rfqType.materials = undefined;
    }
    return rfqType;
};

const getIndexerAndMaterialsForRFQ = (
    rfqData: RFQBuyerDetailsModel
): {
    materials: Step2Materials;
    definition: Definition[];
    plateType: ProductType[];
} => {
    const result = {
        materials: {} as Step2Materials,
        definition: [] as Definition[],
        plateType: [] as ProductType[],
    };

    const processMaterial = (material: MaterialProperties, key: 'definition' | 'plateType') => {
        const value = material[key];
        if (value) {
            if (key === 'definition') {
                if (!result.definition.includes(value as Definition)) {
                    result.definition.push(value as Definition);
                }
            } else if (!result.plateType.includes(value as ProductType)) {
                result.plateType.push(value as ProductType);
            }

            if (!result.materials[value]) {
                result.materials[value] = [];
            }

            const materialWithoutKey = { ...material } as Omit<MaterialWithDimensions, 'plateType' | 'definition'>;
            result.materials[value].push(materialWithoutKey);
        }
    };

    rfqData.quoteEntry.forEach((quoteEntry) => {
        quoteEntry.materials.forEach((material) => {
            if (isMaterialWithDefinition(material)) {
                processMaterial(material, 'definition');
            }
            if (isMaterialWithPlateType(material)) {
                processMaterial(material, 'plateType');
            }
        });
    });

    return { materials: result.materials, definition: result.definition, plateType: result.plateType };
};

export const getSimilarRFQ = (rfqData: RFQBuyerDetailsModel): RFQType => {
    const { definition, plateType, materials } = getIndexerAndMaterialsForRFQ(rfqData);

    const rfqType: RFQType = {
        origin: rfqData.origin,
        materialType: rfqData.quoteEntry[0].materialType,
        shape: rfqData.quoteEntry[0].materials[0].shape as Shape,
        product: rfqData.quoteEntry[0].product,
        definition,
        plateType,
        delivery: '',
        deadline: '',
        materials,
        comments: rfqData.additionalInformation || '',
    };

    return rfqType;
};

export const getStepRoute = (step: number, draftId?: string | null) => {
    let stepPath = '';
    if (step > 1) {
        stepPath = `/step${step}`;
    }
    if (draftId) {
        return `${RoutePath.CREATE_RFQ_WIZARD}/${draftId}${stepPath}`;
    }
    return `${RoutePath.CREATE_RFQ_WIZARD}${stepPath}`;
};
