import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getSurfaces, MaterialProperties, Surface } from '@steelbuy/ts-shared';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

export const MaterialSurfaceSelection = ({
    forceValidation,
    material,
    required,
    surfaceSelected,
}: {
    material: MaterialProperties;
    surfaceSelected: (surface?: Surface) => void;
} & AnyValidationProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();

    const surfaces = getSurfaces(material).slice().sort();

    const { surface } = material;

    useEffect(() => {
        if (surface !== undefined && !surfaces.includes(surface)) {
            surfaceSelected(undefined);
        }
    }, [surface, surfaceSelected, surfaces]);

    if (surfaces.length === 0) {
        return null;
    }

    const materialSurfaceOptions = surfaces.map((materialSurface) => ({
        label: t(`domainModel:material.surface.value.${materialSurface}`),
        value: materialSurface,
    }));

    return (
        <FormItem header={t('domainModel:material.surface.name')} isMandatory={required}>
            <InputSelect
                options={materialSurfaceOptions}
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={surface}
                onSelect={(value) => surfaceSelected(value)}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={forceValidation}
            />
        </FormItem>
    );
};
