import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CartBuyerModel, ModelPrimaryKey } from '@steelbuy/ts-shared';
import axios from '../../axios';
import { MY_CART_DETAILS } from '../../query/cart/cartDetails';

type UpdateCartNotificationParams = {
    cartId: ModelPrimaryKey;
    entryIds: ModelPrimaryKey[];
};

export const useUpdateCartNotification = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationKey: ['UpdateCartNotification'],
        mutationFn: async (params: UpdateCartNotificationParams) => {
            await axios.patch('/api/buyer/cart/update-notification', { ...params });
        },
        onSuccess: () => {
            queryClient.setQueryData<CartBuyerModel>([MY_CART_DETAILS], (cache) => {
                if (!cache) {
                    return cache;
                }
                return { ...cache, errors: undefined };
            });
        },
    });
};
