import { useSearchListings } from '@steelbuy/data-access';
import { ListingBuyerSearchResultModel } from '@steelbuy/ts-shared';
import { SearchResultsLayout } from './SearchResultsLayout';
import { useSearchFormDataContext } from '../listing-search/SearchFormDataContext';
import { useSearchResultsContext } from '../listing-search/SearchResultsContext';

import './ListingSearchResults.scss';

export const ListingSearchResults = () => {
    const searchFormData = useSearchFormDataContext().getSearchFormData();
    const { sortCriteria } = useSearchResultsContext();
    const { data, hasNextPage, total, fetchNextPage, isFetchingNextPage, isLoading } = useSearchListings({
        searchData: searchFormData,
        sortCriteria,
    });

    return (
        <SearchResultsLayout<ListingBuyerSearchResultModel>
            data={data}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            total={total}
            isFetchingNextPage={isFetchingNextPage}
        />
    );
};
