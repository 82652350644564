import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MY_CART_DETAILS, useCartDetails, useUserSelfDetails } from '@steelbuy/data-access';
import { UserRole, CartBuyerModel } from '@steelbuy/ts-shared';
import { toaster } from '@steelbuy/ui-primitive';
import CartReminderNotification from '../components/cart-reminder-notification/CartReminderNotification';
import { RoutePath } from '../router/Routes';

const useCartCacheUpdater = () => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const { data: userData } = useUserSelfDetails();
    const hasBuyerRole = userData?.roles.includes(UserRole.BUYER) ?? false;
    const showCartReminderToast = (time: number) => {
        if (location.pathname !== RoutePath.MY_CART_CHECKOUT) {
            toaster.warn(
                <CartReminderNotification
                    time={time}
                    cart={queryClient.getQueryData<CartBuyerModel>([MY_CART_DETAILS])}
                />,
                { autoClose: 5000 }
            );
        }
    };
    const { refetch } = useCartDetails({ queryOptions: { enabled: hasBuyerRole } }, showCartReminderToast);

    const pathToExclude = [RoutePath.MY_CART, RoutePath.CREATE_RFQ_STEP2, RoutePath.CREATE_RFQ_STEP3];
    useEffect(() => {
        const shouldRefetch = !pathToExclude.some((path) => location.pathname?.includes(path));

        if (shouldRefetch && hasBuyerRole) {
            refetch();
        }
    }, [location.pathname, refetch]);
};

export default useCartCacheUpdater;
