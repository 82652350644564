import { useTranslation } from 'react-i18next';
import { TradeUnit } from '@steelbuy/ts-shared';

export type ListingTeaserWeightAndItemsProps = {
    weight: number;
    unit: TradeUnit;
    numberOfItems?: string;
};

export const ListingTeaserWeightAndItems = (props: ListingTeaserWeightAndItemsProps) => {
    const { weight, unit, numberOfItems } = props;

    const { t } = useTranslation('domainModel');
    return (
        <div className="listing-teaser__weight">
            <div className="listing-teaser__weight__value">
                {t(`material.tradeUnit.value.${unit}.quantity`, {
                    count: weight,
                })}
                {numberOfItems && ','}
            </div>
            <div className="listing-teaser__weight__items">{numberOfItems}</div>
        </div>
    );
};
