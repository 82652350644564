import { useEffect, useState } from 'react';

export const useInitialRefetch = (isRefetching: boolean) => {
    const [isInitialRefetch, setIsInitialRefetch] = useState(true);

    useEffect(() => {
        if (!isRefetching) {
            setIsInitialRefetch(false);
        }
    }, [isRefetching]);
    return isRefetching && isInitialRefetch;
};
