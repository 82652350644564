import { ListingBuyerAlertModel, UserRole } from '@steelbuy/ts-shared';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

export const URGENT_REQUESTS_KEY = 'UrgentRequests';

type QueryOptions = {
    enabled?: boolean;
};

export const useGetUrgentRequests = (roles: UserRole[] | undefined, pageSize: number, queryOptions?: QueryOptions) => {
    let roleEndpoint;
    if (roles) {
        if (roles.includes(UserRole.MANAGER)) {
            roleEndpoint = 'manager';
        } else if (roles.includes(UserRole.SELLER)) {
            roleEndpoint = 'seller';
        }
    }
    const enabled = !!(roleEndpoint && (queryOptions?.enabled === undefined || queryOptions.enabled === true));

    return usePaginatedQuery<ListingBuyerAlertModel>({
        queryKey: [URGENT_REQUESTS_KEY],
        url: `/api/${roleEndpoint}/listing-alerts/urgent-from-buyers`,
        requestOptions: { pageSize },
        queryOptions: {
            ...(queryOptions || {}),
            enabled,
        },
    });
};
