import { useFeatureFlag } from '@steelbuy/data-access';
import { Feature, ListingSellerDraftModel, PackageSellerDraftModel } from '@steelbuy/ts-shared';
import { ButtonCallToAction, ButtonPrimary, ButtonTertiaryOnLightM } from '@steelbuy/ui-primitive';
import { SaveListingDraftButton } from './SaveListingDraftButton';
import { SavePackageDraftButton } from './SavePackageDraftButton';

type CreateListingSaveDraftButtonProps = {
    onSaveSuccess: (
        draft: ListingSellerDraftModel | PackageSellerDraftModel,
        newEntityCreated: boolean,
        isPackage?: boolean
    ) => void;
    onSaveFail?: () => void;
    buttonComponent?: typeof ButtonTertiaryOnLightM | typeof ButtonPrimary | typeof ButtonCallToAction;
    isPackage?: boolean;
};

export const CreateListingSaveDraftButton = ({
    buttonComponent,
    onSaveFail,
    onSaveSuccess,
    isPackage = false,
}: CreateListingSaveDraftButtonProps) => {
    const { areFeaturesSupported } = useFeatureFlag();

    const hasPackageFeatureEnabled = areFeaturesSupported([Feature.PACKAGES, Feature.PACKAGES_DRAFT]);

    const ButtonComponent = buttonComponent ?? ButtonTertiaryOnLightM;

    if (isPackage && !hasPackageFeatureEnabled) {
        return null;
    }

    const commonProps = {
        ButtonComponent,
        onSaveFail,
        onSaveSuccess,
    };

    return isPackage ? <SavePackageDraftButton {...commonProps} /> : <SaveListingDraftButton {...commonProps} />;
};
