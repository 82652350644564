import { useEffect, useState } from 'react';
import { useGetWarehouseAddress } from '@steelbuy/data-access';
import { CardContentCheckoutAddresses } from '@steelbuy/ui-domain';
import { CardLayout } from '@steelbuy/ui-primitive';
import { useCreateListingContext } from '../CreateListingContext';

type CollectionAddressesProps = {
    pickupAddressId?: string;
    forceValidation: boolean;
    isMandatory?: boolean;
};

const CollectionAddresses = ({ pickupAddressId, forceValidation, isMandatory = false }: CollectionAddressesProps) => {
    const { data: addressCollectionItems } = useGetWarehouseAddress();
    const [address, setAddress] = useState(pickupAddressId ?? '');
    const { updatePickupAddressStepData } = useCreateListingContext();

    useEffect(() => {
        if (pickupAddressId) {
            setAddress(pickupAddressId);
        }
    }, [pickupAddressId]);

    return (
        <section>
            <CardLayout>
                {addressCollectionItems && (
                    <CardContentCheckoutAddresses
                        addresses={addressCollectionItems}
                        selectedAddress={address}
                        forceValidation={forceValidation}
                        onSelectionChanged={(newAddressId) => {
                            setAddress(newAddressId);
                            updatePickupAddressStepData({
                                pickupAddressId: newAddressId,
                            });
                        }}
                        isMandatory={isMandatory}
                    />
                )}
            </CardLayout>
        </section>
    );
};

export default CollectionAddresses;
