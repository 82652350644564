import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CoatingCoverage, getCoatingCoverages, MaterialProperties } from '@steelbuy/ts-shared';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

export const MaterialCoatingCoverageSelection = ({
    material,
    coatingCoverageSelected,
    required,
    forceValidation,
}: {
    material: MaterialProperties;
    coatingCoverageSelected: (coverage?: CoatingCoverage) => void;
} & AnyValidationProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();

    const coverages = getCoatingCoverages(material);

    const { coatingCoverage } = material;

    useEffect(() => {
        if (coatingCoverage !== undefined && !coverages.includes(coatingCoverage)) {
            coatingCoverageSelected(undefined);
        }
    }, [coatingCoverage, coatingCoverageSelected, coverages]);

    if (coverages.length === 0) {
        return null;
    }

    const coatingCoverageOptions = coverages.map((coverage) => ({
        label: t(`domainModel:material.coatingCoverage.value.${coverage}`),
        value: coverage,
    }));

    return (
        <FormItem header={t('domainModel:material.coatingCoverage.name')} isMandatory={required}>
            <InputSelect
                options={coatingCoverageOptions}
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={coatingCoverage}
                onSelect={(value) => coatingCoverageSelected(value)}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={forceValidation}
            />
        </FormItem>
    );
};
