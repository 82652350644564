import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { domainModelLiteralsDe, domainModelLiteralsEn } from '@steelbuy/ts-shared';
import { uiDomainLiteralsDe, uiDomainLiteralsEn } from '@steelbuy/ui-domain';
import { uiPrimitiveLiteralsDe, uiPrimitiveLiteralsEn } from '@steelbuy/ui-primitive';
import applicationLiteralsDe from '../../l10n/literals-de';
import applicationLiteralsEn from '../../l10n/literals-en';

export const defaultNS = 'translation';

export const resources = {
    en: {
        translation: applicationLiteralsEn,
        domainModel: domainModelLiteralsEn,
        uiDomain: uiDomainLiteralsEn,
        uiPrimitive: uiPrimitiveLiteralsEn,
    },
    de: {
        translation: applicationLiteralsDe,
        domainModel: domainModelLiteralsDe,
        uiDomain: uiDomainLiteralsDe,
        uiPrimitive: uiPrimitiveLiteralsDe,
    },
} as const;

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    defaultNS,
    debug: true,
    ns: ['translation', 'domainModel', 'uiDomain', 'uiPrimitive'],
});
