import { useTranslation } from 'react-i18next';
import { EmptyState, FillHeightContainer } from '@steelbuy/ui-primitive';
import { useMyOrdersContext } from './MyOrdersContext';
import { FOOTER_HEIGHT } from '../app-layout/app-footer/AppFooter';

export const MyOrdersEmptyState = ({ setSearchTerm }: { setSearchTerm: (value: string) => void }) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { reset, filter, searchValue } = useMyOrdersContext();
    const getLabel = () => {
        let text = t('application.myOrders.noOrdersCallToAction');
        if (filter.length > 0 && searchValue) {
            text = t('application.myOrders.noOrdersCallToActionClearBoth');
        } else if (searchValue) {
            text = t('application.myOrders.noOrdersCallToActionClearSearch');
        }
        return text;
    };

    const getDescription = () => {
        let text = t('application.myOrders.noResultsDescription');
        if (filter.length > 0 && searchValue) {
            text = t('application.myOrders.noResultsDescriptionForBoth', { number: searchValue });
        } else if (searchValue) {
            text = t('application.myOrders.noResultsDescriptionForSearch', { number: searchValue });
        }
        return text;
    };

    return (
        <FillHeightContainer className="my-orders__empty-state-container" extraPadding={FOOTER_HEIGHT} minHeight={220}>
            <EmptyState
                caption={t('uiDomain:commonList.noListCaption')}
                description={getDescription()}
                imgSrc="/assets/lib-ui-primitive/images/EmptyStateResult.svg"
                button={{
                    label: getLabel(),
                    onClick: () => {
                        reset();
                        setSearchTerm('');
                    },
                }}
            />
        </FillHeightContainer>
    );
};
