/* eslint-disable no-param-reassign */
import { AsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { JsonWebToken } from '@steelbuy/auth';
import { AnyErrorObject, StoreError } from '@steelbuy/error';
import { Mutable, Mutation, ViewModel, ModelPrimaryKey, Nullable, ReadonlyOptional } from '@steelbuy/ts-shared';

import { EntityStore } from './EntityStore';
import { ThunkApiConfig } from './Thunks';
import { ActionStatus } from '../ActionStatus';
import { FetchStatus } from '../FetchStatus';

type EntityMeta = {
    arg: {
        id: string;
    };
};

// Initial store
export const createInitialEntityStore = <Model extends ViewModel>() =>
    ({
        scopes: {},
        createdEntityId: null,
        resolvedServiceEntityId: null,
    } as EntityStore<Model>);

// Scope loader
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createInitialEntityStoreEntry = (store: any, id: ModelPrimaryKey): void => {
    if (id === undefined) {
        throw new StoreError('Scope undefined');
    }
    if (store.scopes?.[id] === undefined) {
        store.scopes[id] = {
            model: null,
            fetchStatus: FetchStatus.IDLE,
            lastFetchError: null,
            actionStatus: ActionStatus.IDLE,
            lastActionError: null,
        };
    }
};

// Slice creator
export const createCrudEntitySlice = <Model extends ViewModel>(
    storeName: string,
    fetch: AsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
        },
        ThunkApiConfig
    >,
    mutateEntity: AsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
            modelMutation: Mutation<Model>;
        },
        ThunkApiConfig
    >,
    createEntity: AsyncThunk<
        Model,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            mutableModel: Mutable<Model>;
        },
        ThunkApiConfig
    >,
    deleteEntity: AsyncThunk<
        ModelPrimaryKey,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
        },
        ThunkApiConfig
    >,
    entityService: AsyncThunk<
        Nullable<Model>,
        {
            apiBaseUrl: string;
            jsonWebTokenLoader: () => Promise<Nullable<JsonWebToken>>;
            id: ModelPrimaryKey;
            modelMutation?: Mutation<Model>;
            service: string;
        },
        ThunkApiConfig
    >
) =>
    createSlice({
        name: storeName,
        initialState: createInitialEntityStore<Model>(),
        // Regular synchronous reducers
        reducers: {
            resetStore(store) {
                Object.assign(store, createInitialEntityStore<Model>());
            },
            resetActionStatus(store, action: PayloadAction<ModelPrimaryKey>) {
                createInitialEntityStoreEntry(store, action.payload);
                store.scopes[action.payload].actionStatus = ActionStatus.IDLE;
            },
            resetFetchStatus(store, action: PayloadAction<ModelPrimaryKey>) {
                createInitialEntityStoreEntry(store, action.payload);
                store.scopes[action.payload].fetchStatus = FetchStatus.IDLE;
            },
        },
        // Extra reducers required to handle async actions; the returning promise is resolved to the according reducer
        // Attention: Because we use Redux Toolkit´s creation slice utility we can also mtutate the state directly. It is internally
        // handled by Immer. See https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns and
        // https://github.com/immerjs/immer.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        extraReducers: (builder) => {
            builder.addCase(String(fetch.pending), (state, action: PayloadAction<void, string, EntityMeta, void>) => {
                createInitialEntityStoreEntry(state, action.meta.arg.id);
                state.scopes[action.meta.arg.id].fetchStatus = FetchStatus.PENDING;
            });
            builder.addCase(String(fetch.fulfilled), (state, action: PayloadAction<Nullable<Draft<Model>>>) => {
                if (action.payload !== null) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    createInitialEntityStoreEntry(state, action.payload.id);
                    state.scopes[action.payload.id].model = action.payload;
                    state.scopes[action.payload.id].fetchStatus = FetchStatus.SUCCESS;
                }
            });
            builder.addCase(
                String(fetch.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, EntityMeta, AnyErrorObject>) => {
                    createInitialEntityStoreEntry(state, action.meta.arg.id);
                    state.scopes[action.meta.arg.id].lastFetchError = action.payload ?? action.error;
                    state.scopes[action.meta.arg.id].fetchStatus = FetchStatus.FAILED;
                }
            );
            builder.addCase(
                String(mutateEntity.pending),
                (state, action: PayloadAction<void, string, EntityMeta, void>) => {
                    createInitialEntityStoreEntry(state, action.meta.arg.id);
                    state.scopes[action.meta.arg.id].actionStatus = ActionStatus.MUTATE_PENDING;
                }
            );
            builder.addCase(String(mutateEntity.fulfilled), (state, action: PayloadAction<Nullable<Draft<Model>>>) => {
                if (action.payload !== null) {
                    createInitialEntityStoreEntry(state, action.payload.id);
                    state.scopes[action.payload.id].model = action.payload;
                    state.scopes[action.payload.id].actionStatus = ActionStatus.MUTATE_SUCCESS;
                }
            });
            builder.addCase(
                String(mutateEntity.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, EntityMeta, AnyErrorObject>) => {
                    createInitialEntityStoreEntry(state, action.meta.arg.id);
                    state.scopes[action.meta.arg.id].lastActionError = action.payload ?? action.error;
                    state.scopes[action.meta.arg.id].actionStatus = ActionStatus.FAILED;
                }
            );
            builder.addCase(
                String(entityService.pending),
                (state, action: PayloadAction<void, string, EntityMeta, void>) => {
                    state.scopes[action.meta.arg.id].actionStatus = ActionStatus.SERVICE_PENDING;
                }
            );
            builder.addCase(
                String(entityService.fulfilled),
                (state, action: PayloadAction<Nullable<Draft<Model>>, string, EntityMeta, AnyErrorObject>) => {
                    if (action.payload !== null) {
                        createInitialEntityStoreEntry(state, action.payload.id);
                        state.scopes[action.payload.id].model = action.payload;
                        state.resolvedServiceEntityId = action.payload.id;
                    }
                    state.scopes[action.meta.arg.id].actionStatus = ActionStatus.SERVICE_SUCCESS;
                }
            );
            builder.addCase(
                String(entityService.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, EntityMeta, AnyErrorObject>) => {
                    createInitialEntityStoreEntry(state, action.meta.arg.id);
                    state.scopes[action.meta.arg.id].lastActionError = action.payload ?? action.error;
                    state.scopes[action.meta.arg.id].actionStatus = ActionStatus.FAILED;
                }
            );
            builder.addCase(
                String(createEntity.pending),
                (state, action: PayloadAction<void, string, EntityMeta, void>) => {
                    createInitialEntityStoreEntry(state, action.meta.arg.id);
                    state.scopes[action.meta.arg.id].actionStatus = ActionStatus.CREATE_PENDING;
                }
            );
            builder.addCase(String(createEntity.fulfilled), (state, action: PayloadAction<Draft<Model>>) => {
                createInitialEntityStoreEntry(state, action.payload.id);
                state.scopes[action.payload.id].model = action.payload;
                state.scopes[action.payload.id].actionStatus = ActionStatus.CREATE_SUCCESS;
                state.createdEntityId = action.payload.id;
            });
            builder.addCase(
                String(createEntity.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, EntityMeta, AnyErrorObject>) => {
                    createInitialEntityStoreEntry(state, action.meta.arg.id);
                    state.scopes[action.meta.arg.id].lastActionError = action.payload ?? action.error;
                    state.scopes[action.meta.arg.id].actionStatus = ActionStatus.FAILED;
                }
            );
            builder.addCase(
                String(deleteEntity.pending),
                (state, action: PayloadAction<void, string, EntityMeta, void>) => {
                    createInitialEntityStoreEntry(state, action.meta.arg.id);
                    state.scopes[action.meta.arg.id].actionStatus = ActionStatus.DELETE_PENDING;
                }
            );
            builder.addCase(String(deleteEntity.fulfilled), (state, action: PayloadAction<Draft<ModelPrimaryKey>>) => {
                if (action.payload !== undefined) {
                    createInitialEntityStoreEntry(state, action.payload);
                    state.scopes[action.payload].model = null;
                    state.scopes[action.payload].actionStatus = ActionStatus.DELETE_SUCCESS;
                }
            });
            builder.addCase(
                String(deleteEntity.rejected),
                (state, action: PayloadAction<AnyErrorObject, string, EntityMeta, AnyErrorObject>) => {
                    createInitialEntityStoreEntry(state, action.meta.arg.id);
                    state.scopes[action.meta.arg.id].lastActionError = action.payload ?? action.error;
                    state.scopes[action.meta.arg.id].actionStatus = ActionStatus.FAILED;
                }
            );
        },
    });

// Selector creators
export const createSelectEntity =
    <Model extends ViewModel, Store extends Record<string, EntityStore<Model>>>(storeName: string) =>
    (id: ModelPrimaryKey): ((rootStore: Store) => ReadonlyOptional<Model>) =>
    (rootStore): ReadonlyOptional<Model> =>
        new ReadonlyOptional(rootStore[storeName].scopes?.[id]?.model ?? null);

export const createSelectCreated =
    <Model extends ViewModel, Store extends Record<string, EntityStore<Model>>>(storeName: string) =>
    (): ((rootStore: Store) => ReadonlyOptional<Model>) =>
    (rootStore): ReadonlyOptional<Model> => {
        const { createdEntityId } = rootStore[storeName];
        if (createdEntityId === null) {
            return new ReadonlyOptional<Model>(null);
        }
        return new ReadonlyOptional(rootStore[storeName].scopes?.[createdEntityId]?.model);
    };

export const createSelectServiceEntity =
    <Model extends ViewModel, Store extends Record<string, EntityStore<Model>>>(storeName: string) =>
    (): ((rootStore: Store) => ReadonlyOptional<Model>) =>
    (rootStore): ReadonlyOptional<Model> => {
        const serviceEntityId = rootStore[storeName].resolvedServiceEntityId;
        if (serviceEntityId === null) {
            return new ReadonlyOptional<Model>(null);
        }
        return new ReadonlyOptional(rootStore[storeName].scopes?.[serviceEntityId]?.model);
    };
