import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterCriteria, MultiValueFilterComparator } from '@steelbuy/api-integration';
import { OrderBuyerModel, OrderStatus, isArray } from '@steelbuy/ts-shared';

import { Dropdown, SelectOption } from '@steelbuy/ui-primitive';
import { useMyOrdersContext } from './MyOrdersContext';

export const FILTER_CRITERIA_ID = 'filter';
export const FILTER_PROPERTY = 'status';

export const getFilterValue = (value: string[], filterOptions: SelectOption[]) => {
    let filterValue = value;
    let filter: FilterCriteria<OrderBuyerModel> | undefined;

    if (value.length === 0) {
        filterValue = filterOptions.map((option) => option.label);
    }

    if (value.length > 0) {
        filter = [
            {
                id: FILTER_CRITERIA_ID,
                criteria: [
                    {
                        property: FILTER_PROPERTY,
                        value: filterValue,
                        comparator: MultiValueFilterComparator.ONE_OF,
                    },
                ],
            },
        ];
    }
    return filter;
};

export const MyOrdersFilter = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { filter: ordersFilter, setFilter, reset } = useMyOrdersContext();

    const filterOptions: SelectOption[] = Object.values(OrderStatus).map((value) => ({
        label: t(`uiDomain:orderStatus.${value}`),
        value,
    }));

    const handleFilterChange = (value: string[]) => {
        setFilter(value);
    };

    useEffect(() => {
        if (isArray(ordersFilter) && ordersFilter.length === filterOptions.length) {
            reset();
        }
    }, [ordersFilter]);

    const selectedFilterOptionKeys = isArray(ordersFilter) ? ordersFilter.map((value) => value as OrderStatus) : [];

    return (
        <Dropdown
            multiselect
            title={t('translation:application.myOrdersFilter.selectLabel')}
            options={filterOptions}
            preselection={selectedFilterOptionKeys}
            onChange={handleFilterChange}
            placeholder={t('translation:application.myOrdersFilter.selectPlaceholder')}
        />
    );
};
