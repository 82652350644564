import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Definition, Product, getDefinitions, MaterialProperties } from '@steelbuy/ts-shared';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

export const MaterialProductDefinitionSelection = ({
    definitionSelected,
    material,
}: {
    material: MaterialProperties;
    definitionSelected: (definition?: Definition) => void;
}) => {
    const { t } = useTranslation('domainModel');
    const availableProductDefinitions = getDefinitions(material)?.slice().sort();
    const { definition } = material;

    useEffect(() => {
        if (definition !== undefined && availableProductDefinitions.length === 0) {
            definitionSelected(undefined);
        } else if (definition && !availableProductDefinitions.includes(definition)) {
            switch (material.product) {
                case Product.COIL:
                case Product.SHEET:
                    definitionSelected(Definition.HOT_ROLLED);
                    break;
                default:
                    definitionSelected(availableProductDefinitions[0]);
                    break;
            }
        } else if (!definition && availableProductDefinitions.length > 0) {
            definitionSelected(Definition.HOT_ROLLED);
        }
    }, [material.product, definition, definitionSelected, availableProductDefinitions]);

    if (!availableProductDefinitions.length) {
        return null;
    }

    const productDefinitionOptions = availableProductDefinitions.map((materialDefinition) => ({
        label: t(`material.definition.value.${materialDefinition}`),
        value: materialDefinition,
    }));

    return (
        <FormItem header={t('material.definition.name')} isMandatory>
            <InputSelect
                options={productDefinitionOptions}
                value={definition}
                onSelect={(value) => definitionSelected(value)}
            />
        </FormItem>
    );
};
