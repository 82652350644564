import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRFQDraftDetails } from '@steelbuy/data-access';
import { RFQDraftModel } from '@steelbuy/ts-shared';
import { CreateRFQ } from './CreateRFQ';
import { convertToRFQType } from './createRFQUtil';
import { useRfqFormContext } from './RfqFormContext';

export const CreateDraftRFQ = () => {
    const { draftId: paramsDraftId } = useParams();
    const location = useLocation();
    const draftRFQ = location.state?.draftRFQ;
    const { setDraft, draftId, updateFormData, setGoBack, goBack } = useRfqFormContext();
    const { data, isLoading } = useRFQDraftDetails({
        rfqId: paramsDraftId || '',
        queryOptions: { enabled: !draftRFQ && !draftId },
    });

    const setDraftData = (draft: RFQDraftModel) => {
        const rfqFormData = convertToRFQType(draft);
        if (rfqFormData) {
            setDraft(rfqFormData, draft.id);
            updateFormData(rfqFormData);
        }
    };

    useEffect(() => {
        if (goBack) {
            return;
        }
        setGoBack(
            location.state?.goBackLink
                ? {
                      link: location.state?.goBackLink,
                      label: location.state?.goBackText,
                  }
                : null
        );
    }, []);

    useEffect(() => {
        if (!draftId) {
            if (draftRFQ) {
                setDraftData(draftRFQ);
                return;
            }
            if (!isLoading && paramsDraftId && data) {
                setDraftData(data);
            }
        }
    }, [isLoading]);

    if (!draftId) {
        return null;
    }

    return <CreateRFQ />;
};
