import { CartOrderBuyerModel } from '@steelbuy/ts-shared';

import { ListingTeaser } from './ListingTeaser';
import { ListingTeaserCartContainer } from './ListingTeaserCartContainer';
import { ListingTeaserCartHeader } from './ListingTeaserCartHeader';
import { ListingTeaserCartWeightAndItems } from './ListingTeaserCartWeightAndItems';
import { ListingTeaserPrice } from './ListingTeaserPrice';

export type ListingBuyerCartOrderTeaserProps = {
    order: CartOrderBuyerModel;
};

export const ListingBuyerCartOrderTeaser = ({ order }: ListingBuyerCartOrderTeaserProps) => (
    <div className="cart-order-list">
        <ListingTeaser>
            <ListingTeaserCartHeader
                buyerOrderNumber={order.buyerOrderNumber}
                materialProductWithCount={order.materialProductWithCount}
            />

            <ListingTeaserCartContainer
                primeStatus={order.primeStatus}
                materialLocations={order.materialLocations}
                statusWithCount={order.statusWithCount}
            />
            <ListingTeaserCartWeightAndItems typeWithWeight={order.typeWithWeight} />

            <ListingTeaserPrice
                value={order.totalValue}
                currencyCode={order.currencyCode}
                showTotalExcludeVat
                showIncludingDelivery={false}
            />
        </ListingTeaser>
    </div>
);
