import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserSelfDetails } from '@steelbuy/data-access';
import { ActionStatus, useAddressCollectionData, useAddressEntityData } from '@steelbuy/data-provider';
import { NotFoundError } from '@steelbuy/error';
import { AddressModel, AddressType, UserRole, Mutable } from '@steelbuy/ts-shared';
import { AddressForm } from '@steelbuy/ui-domain';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { ROUTE_ACCOUNT_ORGANISATION } from '../account/Account.enum';
import { PageHeader } from '../page-header/PageHeader';

export const AccountEditAddress = () => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const { data: userModel } = useUserSelfDetails();
    const addressEntityData = useAddressEntityData();
    const { warehouseAddressCollectionData, invoiceAddressCollectionData } = useAddressCollectionData();

    const [error, setError] = useState<string>();

    const address = addressEntityData.query().get();
    const addressFromCollection = warehouseAddressCollectionData.queryOne(address?.id || '');

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setError(t('application.accountEditAddress.errorMessage'));
        },
        true
    );

    invoiceAddressCollectionData.useActionStatusEffect(
        [ActionStatus.FAILED],
        () => {
            setError(t('application.accountEditAddress.errorMessage'));
        },
        true
    );

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            if (address?.primary !== addressFromCollection?.get()?.primary) {
                warehouseAddressCollectionData.resolveFetchStatus();
            }
            addressEntityData.resolveFetchStatus();
            navigate(ROUTE_ACCOUNT_ORGANISATION);
        },
        false
    );

    warehouseAddressCollectionData.useActionStatusEffect(
        [ActionStatus.DELETE_SUCCESS],
        () => {
            navigate(ROUTE_ACCOUNT_ORGANISATION);
        },
        false
    );

    invoiceAddressCollectionData.useActionStatusEffect(
        [ActionStatus.MUTATE_SUCCESS],
        () => {
            addressEntityData.resolveFetchStatus();
            navigate(ROUTE_ACCOUNT_ORGANISATION);
        },
        false
    );

    if (!address) {
        throw new NotFoundError();
    }

    let pageTitle;
    if (address.addressType === AddressType.INVOICE) {
        pageTitle = t('application.accountEditAddress.pageTitleInvoiceAddress');
    } else {
        pageTitle = t('application.accountEditAddress.pageTitleWarehouseAddress');
    }

    // eslint-disable-next-line consistent-return
    const handleSave = (updatedAddress: Mutable<AddressModel>): boolean => {
        switch (address.addressType) {
            case AddressType.WAREHOUSE:
                warehouseAddressCollectionData.mutate(address.id, updatedAddress);
                return true;
            case AddressType.INVOICE:
                invoiceAddressCollectionData.mutate(address.id, updatedAddress);
                return true;
            default:
                return false;
        }

        // no default return value on purpose to force type error for missing cases
    };

    const handleDelete = () => {
        warehouseAddressCollectionData.delete(address.id);
    };

    return (
        <>
            <PageHeader
                pageTitle={pageTitle}
                previousPageTitle={t('application.accountEditAddress.previousPageTitle')}
                onBackClick={() => navigate(ROUTE_ACCOUNT_ORGANISATION)}
            />

            <FormLayout>
                <AddressForm
                    error={error}
                    address={address}
                    onCancel={() => navigate(ROUTE_ACCOUNT_ORGANISATION)}
                    onPrimary={handleSave}
                    onDelete={userModel?.roles.includes(UserRole.MANAGER) ? handleDelete : undefined}
                    isReadOnly={false}
                />
            </FormLayout>
        </>
    );
};
