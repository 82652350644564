import { PackageSellerDraftModel, PackageSellerDraftModelConverter, ApiModel } from '@steelbuy/ts-shared';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

export const MY_DRAFT_PACKAGE_KEY = 'packages-draft';

const modelConverter = new PackageSellerDraftModelConverter();

const searchPackageDraftsMapFn = (data: PackageSellerDraftModel) =>
    modelConverter.toViewModel(data as unknown as ApiModel<PackageSellerDraftModel>);

export const useMyDraftPackages = (queryOptions = {}) =>
    usePaginatedQuery<PackageSellerDraftModel>({
        url: '/api/universal/draft-packages',
        requestOptions: {},
        mapFn: searchPackageDraftsMapFn,
        queryKey: [MY_DRAFT_PACKAGE_KEY],
        queryOptions: {
            cacheTime: 0,
            ...queryOptions,
        },
    });
