import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetUrgentRequests, useUserSelfDetails } from '@steelbuy/data-access';

import { QnA } from '@steelbuy/ui-domain';
import {
    ButtonGhostSystemOnLightM,
    ButtonTertiaryOnLightM,
    EmptyState,
    IconIdentifier,
    LoadingSpinner,
    LoadingStatus,
    Separator,
    SeparatorIdentifier,
    ViewHeading,
} from '@steelbuy/ui-primitive';
import { UrgentRequestsList } from './UrgentRequestsList';
import { RoutePath } from '../../router/Routes';
import { FormLayout } from '../../views/layouts/form-layout/FormLayout';
import { TableLayout } from '../../views/layouts/table-layout/TableLayout';
import { PageHeader } from '../page-header/PageHeader';

import './UrgentRequestsOverview.scss';

export const URGENT_REQUESTS_PAGE_SIZE = 20;

const getQnAs = (translate: (literal: string) => string, items: string[]) =>
    items.map((item) => ({
        collapsedQuestion: translate(`application.urgentRequests.qna.items.${item}.label`),
        answer: translate(`application.urgentRequests.qna.items.${item}.content`),
    }));

export const UrgentRequestsOverview = () => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();
    const { data: userModel } = useUserSelfDetails();
    const {
        data: urgentRequests,
        isLoading,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useGetUrgentRequests(userModel?.roles, URGENT_REQUESTS_PAGE_SIZE);
    const newUrgentRequestsTotal = useMemo(() => {
        // TODO: this should come from backend
        if (urgentRequests === undefined) {
            return undefined;
        }
        const past24Hours = new Date().getTime() - 24 * 60 * 60 * 1000;
        const newItems = urgentRequests.filter((item) => new Date(`${item.createdAt}`).getTime() > past24Hours) || [];
        return newItems.length;
    }, [urgentRequests]);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const questionsAndAnswers = getQnAs(t, ['q1', 'q2', 'q3', 'q4']);

    return (
        <>
            <PageHeader pageTitle={t('application.urgentRequests.pageHeader')} />
            <TableLayout>
                <div className="sub-heading">
                    <ViewHeading
                        value={
                            newUrgentRequestsTotal !== undefined
                                ? t('application.urgentRequests.newResultsSeparatorHeader', {
                                      count: newUrgentRequestsTotal,
                                  })
                                : ''
                        }
                    />
                    <ButtonGhostSystemOnLightM
                        icon={IconIdentifier.SETTINGS}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            navigate(RoutePath.URGENT_REQUESTS_SETTING);
                        }}
                        title={t('application.urgentRequests.settingsButtonLabel')}
                    />
                </div>
                <Separator separatorType={SeparatorIdentifier.GROUP_ON_LIGHT} />
            </TableLayout>
            <div className="urgent-requests-wrapper">
                <TableLayout>
                    <div className="urgent-requests">
                        <div>
                            {urgentRequests && urgentRequests?.length > 0 ? (
                                <>
                                    <UrgentRequestsList
                                        urgentRequests={urgentRequests}
                                        origin={RoutePath.URGENT_REQUESTS}
                                        newUrgentRequestsTotal={newUrgentRequestsTotal}
                                    />
                                    {hasNextPage && (
                                        <div className="urgent-requests__load-more">
                                            <ButtonTertiaryOnLightM
                                                onClick={() => fetchNextPage()}
                                                label={t('uiDomain:commonList.loadMore')}
                                                loadingStatus={
                                                    isFetchingNextPage ? LoadingStatus.PENDING : LoadingStatus.IDLE
                                                }
                                            />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <FormLayout>
                                    <EmptyState
                                        imgSrc="/assets/lib-ui-primitive/images/EmptyStateData.svg"
                                        description={t('application.urgentRequests.emptyStateDescription')}
                                        button={{
                                            label: t('application.urgentRequests.createListing'),
                                            onClick: () => navigate(RoutePath.CREATE_LISTING_WIZARD),
                                        }}
                                    />
                                </FormLayout>
                            )}
                        </div>
                    </div>
                </TableLayout>
                <FormLayout>
                    <QnA
                        qnaTitle={t('application.urgentRequests.qna.header')}
                        questionsAndAnswers={questionsAndAnswers}
                    />
                </FormLayout>
            </div>
        </>
    );
};
