import { useTranslation } from 'react-i18next';
import {
    OrderBuyerModel,
    Product,
    getPricingUnit,
    isListingOrderModel,
    isPackageOrderModel,
} from '@steelbuy/ts-shared';

import { ListingTeaser } from './ListingTeaser';
import { ListingTeaserContainer } from './ListingTeaserContainer';
import { ListingTeaserHeader } from './ListingTeaserHeader';
import { ListingTeaserPrice } from './ListingTeaserPrice';
import { ListingTeaserWeightAndItems } from './ListingTeaserWeightAndItems';
import { useNumberOfItems } from '../number-of-items-label/NumberOfItemsLabel';
import { getBadgeVariant } from '../order-status-badge-variant/OrderStatusBadgeVariant';
import { PackageTeaserHeader } from '../package-teaser/PackageTeaserHeader';

export type OrderBuyerTeaserProps = {
    order: OrderBuyerModel;
};

export const ListingBuyerOrderTeaser = (props: OrderBuyerTeaserProps) => {
    const { order } = props;

    const { t } = useTranslation('uiDomain');
    const numberOfItems = useNumberOfItems(
        isListingOrderModel(order) ? order.listing.material.product : undefined,
        isListingOrderModel(order) ? order.listing.numberOfItems : undefined
    );

    const statusLabel = t(`orderStatus.${order.status}`);

    const orderListing = isListingOrderModel(order) ? order.listing : order.packaging;

    const pricingUnit = isListingOrderModel(order)
        ? getPricingUnit(order.listing.material)
        : getPricingUnit({ materialType: order.packaging.material, product: Product.PACKAGE });

    return (
        <ListingTeaser>
            {isListingOrderModel(order) && <ListingTeaserHeader listing={order.listing} />}
            {isPackageOrderModel(order) && <PackageTeaserHeader packages={order.packaging} />}
            <ListingTeaserContainer
                isPrime={orderListing.prime}
                steelBuyPurchaseOrderNumber={order.orderNumber}
                buyerPurchaseOrderNumber={order.buyerOrderNumber}
                statusLabel={statusLabel}
                statusBadgeVariant={getBadgeVariant(order.status)}
                countryCode={orderListing.country}
            />
            {orderListing.weight && orderListing.tradeUnit ? (
                <ListingTeaserWeightAndItems
                    weight={orderListing.weight}
                    unit={orderListing.tradeUnit}
                    numberOfItems={numberOfItems}
                />
            ) : null}
            {orderListing.pricing.priceIncludingDelivery !== undefined &&
                pricingUnit &&
                orderListing.pricing.currencyCode && (
                    <ListingTeaserPrice
                        value={orderListing.pricing.priceIncludingDelivery}
                        currencyCode={orderListing.pricing.currencyCode}
                        unit={pricingUnit}
                        showIncludingDelivery={false}
                    />
                )}
        </ListingTeaser>
    );
};
