import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CoatingType, getCoatingTypes, MaterialProperties } from '@steelbuy/ts-shared';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

export const MaterialCoatingTypeSelection = ({
    material,
    coatingTypeSelected,
    required,
    forceValidation,
}: {
    material: MaterialProperties;
    coatingTypeSelected: (coatingType?: CoatingType) => void;
} & AnyValidationProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();

    const coatingTypes = getCoatingTypes(material);

    const { coatingType } = material;

    useEffect(() => {
        if (coatingType !== undefined && !coatingTypes.includes(coatingType)) {
            coatingTypeSelected(undefined);
        }
    }, [coatingType, coatingTypeSelected, coatingTypes]);

    if (coatingTypes.length === 0) {
        return null;
    }

    const coatingTypeOptions = coatingTypes.map((type) => ({
        label: t(`domainModel:material.coatingType.value.${type}`),
        value: type,
    }));

    return (
        <FormItem header={t('domainModel:material.coatingType.name')} isMandatory={required}>
            <InputSelect
                options={coatingTypeOptions}
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={coatingType}
                onSelect={(value) => coatingTypeSelected(value)}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={forceValidation}
            />
        </FormItem>
    );
};
