import { FilterCriteria, ValueFilterCriteriaRule } from '@steelbuy/api-integration';
import { ListingBuyerSearchResultModel, SearchFormData, TradeUnit } from '@steelbuy/ts-shared';

export function composeFilterCriteria(
    data: SearchFormData & { tradeUnit?: TradeUnit }
): FilterCriteria<ListingBuyerSearchResultModel> {
    const criteria = Object.entries(data)
        .filter((entry) => entry[1])
        .map(
            (entry): ValueFilterCriteriaRule<ListingBuyerSearchResultModel, string> => ({
                property: entry[0],
                value: entry[1].toString(),
            })
        );

    return [
        {
            id: 'searchCriteria',
            criteria,
        },
    ];
}
