import { OrderBuyerCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { OrderBuyerModel, Nullable } from '@steelbuy/ts-shared';

import { createCrudCreateEntitySlice, createSelectEntity } from '../../util/store/crud/CreateEntitySlice';
import { CreateEntityStore } from '../../util/store/crud/CreateEntityStore';
import { CreateEntityStoreAccessor } from '../../util/store/crud/CreateEntityStoreAccessor';
import { createCreateThunk, createDeleteCreatedThunk, createMutateCreatedThunk } from '../../util/store/crud/Thunks';

// Basic definition
export const orderBuyerCreateEntityStoreName = 'order-buyer-create-entity';
export type OrderBuyerCreateEntityStore = {
    [orderBuyerCreateEntityStoreName]: CreateEntityStore<OrderBuyerModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new OrderBuyerCrudApiClient().init(apiBaseUrl, jsonWebToken);
const mutateCreatedEntity = createMutateCreatedThunk<
    OrderBuyerModel,
    OrderBuyerCreateEntityStore,
    OrderBuyerCrudApiClient
>(orderBuyerCreateEntityStoreName, createApiClient);
const createEntity = createCreateThunk<OrderBuyerModel, OrderBuyerCrudApiClient>(
    orderBuyerCreateEntityStoreName,
    createApiClient
);
const deleteCreatedEntity = createDeleteCreatedThunk<
    OrderBuyerModel,
    OrderBuyerCreateEntityStore,
    OrderBuyerCrudApiClient
>(orderBuyerCreateEntityStoreName, createApiClient);

// Slice definition
export const orderBuyerCreateEntitySlice = createCrudCreateEntitySlice<OrderBuyerModel>(
    orderBuyerCreateEntityStoreName,
    mutateCreatedEntity,
    createEntity,
    deleteCreatedEntity
);
const { resetActionStatus } = orderBuyerCreateEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<OrderBuyerModel, OrderBuyerCreateEntityStore>(orderBuyerCreateEntityStoreName);

// Export the store accessors to be used by the data provider value
export const orderBuyerCreateEntityStoreAccessors = {
    mutateCreatedEntity,
    createEntity,
    deleteCreatedEntity,
    resetActionStatus,
    select,
} as CreateEntityStoreAccessor<OrderBuyerModel, OrderBuyerCreateEntityStore>;
