import { useMutation } from '@tanstack/react-query';
import {
    ListingSellerDraftModel,
    ListingSellerDraftModelConverter,
    ListingSellerModel,
    PackageSellerDraftModel,
    PackageSellerDraftModelConverter,
    PackageSellerModel,
    Mutable,
} from '@steelbuy/ts-shared';
import axios from '../../axios';

type CreateDraftListingRequest =
    | {
          operation: 'UPDATE';
          draftId: string;
          isPackage?: boolean;
          listingDraft: Mutable<ListingSellerDraftModel | PackageSellerDraftModel>;
      }
    | {
          operation: 'SUBMIT';
          draftId: string;
          isPackage?: boolean;
      };

const createDraftListing = async (
    request: CreateDraftListingRequest
): Promise<ListingSellerModel | ListingSellerDraftModel> => {
    const { operation, draftId } = request;
    if (operation === 'UPDATE') {
        const modelConverter = new ListingSellerDraftModelConverter();
        const { data } = await axios.put<ListingSellerDraftModel>(
            `/api/universal/draft-listings/${draftId}`,
            modelConverter.toApiModel(request.listingDraft as ListingSellerDraftModel)
        );
        return data;
    }

    const { data } = await axios.put<ListingSellerModel>(`/api/seller/draft-listings/${draftId}/submission`);
    return data;
};

const createPackageDraftListing = async (
    request: CreateDraftListingRequest
): Promise<PackageSellerModel | PackageSellerDraftModel> => {
    const { operation, draftId } = request;
    if (operation === 'UPDATE') {
        const modelConverter = new PackageSellerDraftModelConverter();
        const { data } = await axios.put<PackageSellerDraftModel>(
            `/api/universal/draft-packages/${draftId}`,
            modelConverter.toApiModel(request.listingDraft as PackageSellerDraftModel)
        );
        return data;
    }

    const { data } = await axios.put<PackageSellerModel>(`/api/seller/draft-packages/${draftId}/submission`);
    return data;
};

const createDraft = async (
    request: CreateDraftListingRequest
): Promise<PackageSellerModel | PackageSellerDraftModel | ListingSellerDraftModel | ListingSellerModel> =>
    request.isPackage ? createPackageDraftListing(request) : createDraftListing(request);

export const useCreateDraftListing = () =>
    useMutation({
        mutationFn: (request: CreateDraftListingRequest) => createDraft(request),
    });
