import { useTranslation } from 'react-i18next';
import { WeightAndType } from '@steelbuy/ts-shared';

export type ListingTeaserCartWeightAndItemsProps = {
    typeWithWeight: WeightAndType[];
};

export const ListingTeaserCartWeightAndItems = ({ typeWithWeight }: ListingTeaserCartWeightAndItemsProps) => {
    const { t } = useTranslation('domainModel');
    return (
        <div className="listing-teaser__weight">
            {typeWithWeight.map(({ type, tradeUnit, weight }) => (
                <div key={type} className="listing-teaser__weight__wrapper">
                    <div className="listing-teaser__weight__type">{t(`material.materialType.value.${type}`)}</div>
                    <div className="listing-teaser__weight__value">
                        {t(`material.tradeUnit.value.${tradeUnit}.quantity`, {
                            count: weight,
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
};
