import { useMutation } from '@tanstack/react-query';
import { DocumentModel, DocumentType } from '@steelbuy/ts-shared';
import axios from '../../axios';

interface UploadDocumentMutationOptions {
    file: File;
    type: DocumentType;
}

const uploadDocument = async (file: File, type: DocumentType) => {
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await axios.post<DocumentModel>('/api/universal/documents/temporary', formData, {
        params: {
            type,
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return data;
};

type UseMutationParams = {
    onSuccess?: (data: DocumentModel, variables: UploadDocumentMutationOptions, context: unknown) => unknown;
    onError?: (error: unknown, variables: UploadDocumentMutationOptions, context: unknown) => unknown;
};

export const useUploadDocument = (params?: UseMutationParams) =>
    useMutation({
        mutationFn: ({ file, type }: UploadDocumentMutationOptions) => uploadDocument(file, type),
        ...(params || {}),
    });
