import { ListingSellerDraftModel, ListingSellerDraftModelConverter } from '@steelbuy/ts-shared';

import { AbstractCrudApiClient } from './api-client/AbstractCrudApiClient';

export class ListingSellerDraftCrudApiClient extends AbstractCrudApiClient<ListingSellerDraftModel> {
    protected collectionControllerUri = 'api/universal/draft-listings';

    protected paginationControllerUri = 'api/universal/draft-listings';

    protected entityCreateUri = 'api/universal/draft-listings/';

    protected entityFetchUri = 'api/universal/draft-listings/{id}/';

    protected entityMutateUri = 'api/universal/draft-listings/{id}/';

    protected entityDeleteUri = 'api/seller/draft-listings/{id}/';

    protected entityServiceCallUri = 'api/seller/draft-listings/{id}/';

    protected override paginationSize = 50;

    protected modelConverter = new ListingSellerDraftModelConverter();
}
