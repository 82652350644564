import { useNavigate } from 'react-router-dom';
import { useIsFeatureSupported, useUserSelfDetails, userHasRole } from '@steelbuy/data-access';
import { Feature, UserRole } from '@steelbuy/ts-shared';
import { SuccessErrorNotificationContextProvider } from '@steelbuy/ui-primitive';
import { MyRFQs } from '../../components/my-rfqs/MyRFQs';
import { RoutePath } from '../../router/Routes';

export const MyRFQsView = () => {
    const navigate = useNavigate();
    const { data: userModel } = useUserSelfDetails();
    const hasBuyerRole = userHasRole(userModel || null, [UserRole.BUYER]);
    const showRfq = useIsFeatureSupported(Feature.RFQ) && hasBuyerRole;

    if (!showRfq) {
        navigate(RoutePath.DASHBOARD);
    }
    return (
        <SuccessErrorNotificationContextProvider>
            <MyRFQs />
        </SuccessErrorNotificationContextProvider>
    );
};
