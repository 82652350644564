import { useMutation } from '@tanstack/react-query';
import { RFQBuyerDetailsModel } from '@steelbuy/ts-shared';
import axios from '../../axios';

type UpdateRFQRenewalPayload = {
    expiresAt: string;
    delivery: { month: number; year: number };
};

const updateRFQ = async (rfqId: string, payload: UpdateRFQRenewalPayload) => {
    const { data } = await axios.put<RFQBuyerDetailsModel>(`/api/buyer/quotes/${rfqId}/renewal`, payload);
    return data;
};

export const useUpdateRFQ = () =>
    useMutation({
        mutationFn: ({ rfqId, payload }: { rfqId: string; payload: UpdateRFQRenewalPayload }) =>
            updateRFQ(rfqId, payload),
    });
