import { Control, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Definition, getProductTypes, MaterialProperties, ProductType } from '@steelbuy/ts-shared';
import { FormItem, Icon, IconIdentifier } from '@steelbuy/ui-primitive';
import { CheckboxGroup } from '../CheckboxGroup';
import { Checkbox } from '../primitive/checkbox/Checkbox';

interface MaterialProductTypeSelectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
    name: TName;
    control: Control<TFieldValues>;
    material: MaterialProperties;
    isPrime: boolean;
    error?: string;
    addedIndexes: (ProductType | Definition)[];
    showConfirmSelection: (onConfirm: () => void) => void;
}

export const MaterialProductTypeSelection = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
    name,
    control,
    material,
    isPrime,
    error,
    addedIndexes,
    showConfirmSelection,
}: MaterialProductTypeSelectionProps<TFieldValues, TName>) => {
    const { t } = useTranslation(['domainModel', 'uiDomain']);
    const availableProductTypes = getProductTypes(material, isPrime)?.slice()?.sort();

    const productOptions = availableProductTypes.map((type) => ({
        label: t(`domainModel:material.plateType.value.${type}`),
        value: type,
    }));

    if (!availableProductTypes.length) {
        return null;
    }

    const deselectPlateType = (plateType: string, field: ControllerRenderProps<TFieldValues, TName>) =>
        field.onChange(field.value.filter((item: string) => item !== plateType));

    return (
        <FormItem
            header={t('domainModel:material.plateType.name')}
            description={t('uiDomain:rfqForm.plateType.description')}
            isMandatory
        >
            {!!error && <Icon name={IconIdentifier.ERROR} />}
            <CheckboxGroup>
                <Checkbox
                    options={productOptions}
                    name={name}
                    control={control}
                    rules={{
                        required: t('uiDomain:validation.requiredField'),
                    }}
                    onChange={({ target, field }) => {
                        if (target.checked) {
                            field.onChange([...field.value, target.value]);
                            return;
                        }
                        if (addedIndexes.includes(target.value as ProductType)) {
                            showConfirmSelection(() => deselectPlateType(target.value, field));
                            return;
                        }
                        field.onChange(field.value.filter((item: string) => item !== target.value));
                        deselectPlateType(target.value, field);
                    }}
                    id="plate-type-select"
                />
            </CheckboxGroup>
            {!!error && <span className="form-item__error-msg">{error}</span>}
        </FormItem>
    );
};
