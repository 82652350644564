import { Trans, useTranslation } from 'react-i18next';
import { OrderModel } from '@steelbuy/data-access';
import { OrderBuyerModel } from '@steelbuy/ts-shared';
import { CustomLink, Icon, IconIdentifier } from '@steelbuy/ui-primitive';

import './CheckoutIllustratedSuccess.scss';

type CheckoutIllustratedSuccessProps = {
    orderModel?: Partial<OrderBuyerModel>;
    orderModelCart?: Partial<OrderModel>;
    myOrdersUrl: string;
    isCart?: boolean;
};

export const CheckoutIllustratedSuccess = ({
    orderModel,
    orderModelCart,
    myOrdersUrl,
    isCart = false,
}: CheckoutIllustratedSuccessProps) => {
    const { t } = useTranslation('translation');
    return (
        <section className="checkout-illustration-success">
            <img alt="Success" src="/assets/lib-ui-primitive/images/Checkout-Success.svg" />

            <div className="checkout-illustration-success__header">{t('application.checkoutSuccess.successText')}</div>
            {isCart ? (
                <div className="checkout-illustration-success__help-text">
                    {t('application.checkoutSuccess.purchaseOrderNumber')}{' '}
                    <span className="checkout-illustration-success__purchase-order-number">
                        {orderModelCart?.purchaseOrderNumber}
                    </span>
                    <br />
                    {t('application.checkoutSuccess.steelbuyReferenceNumber')}{' '}
                    <span className="checkout-illustration-success__steelbuy-reference-number">
                        {orderModelCart?.steelbuyReferenceNumber}
                    </span>
                </div>
            ) : (
                <div className="checkout-illustration-success__help-text">
                    {t('application.checkoutSuccess.orderNumberText')}{' '}
                    <span className="checkout-illustration-success__order-number">{orderModel?.orderNumber}</span>
                </div>
            )}

            <section className="checkout-illustration-success__hint">
                <span className="checkout-illustration-success__hint__wrapper">
                    <Icon name={IconIdentifier.OK} />
                    <div className="checkout-illustration-success__hint__wrapper__text">
                        {t('application.checkoutSuccess.emailHint')}
                    </div>
                </span>
                <span className="checkout-illustration-success__hint__wrapper">
                    <Icon name={IconIdentifier.OK} />
                    <div className="checkout-illustration-success__hint__wrapper__text">
                        {t('application.checkoutSuccess.deliveryHint')}
                    </div>
                </span>
                <span className="checkout-illustration-success__hint__wrapper">
                    <Icon name={IconIdentifier.OK} />
                    <div className="checkout-illustration-success__hint__wrapper__text">
                        <Trans
                            i18nKey="application.checkoutSuccess.manageOrder"
                            components={{ link1: <CustomLink path={myOrdersUrl} isInlineLink /> }}
                            t={t}
                        />
                    </div>
                </span>
            </section>
        </section>
    );
};
