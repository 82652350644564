import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '@steelbuy/data-access';
import { Feature, NegotiationModel, OfferListingStatus, OfferView, PricingUnit } from '@steelbuy/ts-shared';
import { ListingTeaserOfferBadges } from '@steelbuy/ui-domain';
import {
    Accordion,
    AccordionGroup,
    Card,
    CardContent,
    CardLayout,
    Notification,
    NotificationLevel,
} from '@steelbuy/ui-primitive';
import { Offers } from './Offers';
import './Negotiations.scss';

type NegotiationsProps = {
    negotiations?: NegotiationModel[];
    view: OfferView;
    onStatusChange: (message?: string, error?: string) => void;
    pricingUnit: PricingUnit;
    weightOrItems: number;
    taxPercent?: number;
    numberOffers?: number;
    numberActiveOffers?: number;
    minimumPrice: number;
};

const Header = ({
    header,
    numberOffers,
    numberActiveOffers,
}: {
    header: string;
    numberActiveOffers?: number;
    numberOffers?: number;
}) => (
    <div className="negotiations__header">
        {header}
        <ListingTeaserOfferBadges numberOffers={numberOffers} numberActiveOffers={numberActiveOffers} />
    </div>
);

export const hasActiveOffer = (negotiations?: NegotiationModel[]) =>
    negotiations?.some(
        (negotiation) =>
            negotiation.status === OfferListingStatus.AWAITING_SELLER ||
            negotiation.status === OfferListingStatus.ACCEPTED ||
            negotiation.status === OfferListingStatus.AWAITING_BUYER
    );

export const Negotiations = ({
    negotiations,
    view,
    onStatusChange,
    pricingUnit,
    weightOrItems,
    taxPercent = 0,
    numberOffers,
    numberActiveOffers,
    minimumPrice,
}: NegotiationsProps) => {
    const { t } = useTranslation(['translation', 'uiDomain']);
    const { isFeatureSupported } = useFeatureFlag();

    if (!negotiations?.length || !isFeatureSupported(Feature.BID_OFFER)) {
        return null;
    }

    return (
        <div className="negotiations">
            <Card isClickable={false}>
                <CardLayout>
                    <CardContent
                        header={
                            <Header
                                header={t('application.offers.negotiationsHeader')}
                                numberActiveOffers={numberActiveOffers}
                                numberOffers={numberOffers}
                            />
                        }
                    >
                        {negotiations.map(({ referenceId, offers, status, originalPrice, id, expiresAt }, index) => (
                            <AccordionGroup key={referenceId}>
                                <Accordion
                                    initiallyExtended={
                                        index === 0 ||
                                        (view === OfferView.SELLER && status === OfferListingStatus.AWAITING_SELLER)
                                    }
                                    collapsedLabel={t('application.offers.accordionHeader', { referenceId })}
                                    className="negotiations__accordion-children"
                                >
                                    <Offers
                                        offers={offers}
                                        status={status}
                                        view={view}
                                        originalPrice={originalPrice}
                                        negotiationId={id}
                                        onStatusChange={onStatusChange}
                                        pricingUnit={pricingUnit}
                                        weightOrItems={weightOrItems}
                                        taxPercent={taxPercent}
                                        expiresAt={expiresAt}
                                        minimumPrice={minimumPrice}
                                    />
                                </Accordion>
                            </AccordionGroup>
                        ))}
                        <Notification
                            level={NotificationLevel.INFO}
                            message={
                                view === OfferView.BUYER
                                    ? t('application.offers.notificationMsgBuyer')
                                    : t('application.offers.notificationMsgSeller')
                            }
                            stayOpen
                        />
                    </CardContent>
                </CardLayout>
            </Card>
        </div>
    );
};
