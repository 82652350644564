import { PackageBuyerModel, PackageSellerModel, Product } from '@steelbuy/ts-shared';
import {
    ListingDetailsMaterial,
    ListingDetailsPackage,
    ListingDetailsPackageSheet,
    ListingDetailsQuality,
} from '@steelbuy/ui-domain';
import { constants } from '../../constants';

export const CommonPackageDetails = ({
    packageModel,
    showSku,
}: {
    packageModel: PackageBuyerModel | PackageSellerModel;
    showSku?: boolean;
}) => (
    <>
        <ListingDetailsMaterial
            materialType={packageModel.material}
            product={Product.PACKAGE}
            prime={packageModel.prime}
            shape={packageModel.shape}
            sku={showSku ? packageModel.internalSellerSku : undefined}
            origin={packageModel.origin}
            countryCode={(packageModel as PackageBuyerModel).country}
        />
        <ListingDetailsPackage
            packageTitle={packageModel.packageTitle}
            packageDescription={packageModel.packageDescription}
        />
        <ListingDetailsPackageSheet apiBaseUrl={constants.apiBaseUrl} files={[packageModel.packageDocument]} />
        {packageModel.prime ? (
            <ListingDetailsQuality
                prime={packageModel.prime}
                testCertificate={packageModel.certificates}
                description={packageModel.description}
                apiBaseUrl={constants.apiBaseUrl}
            />
        ) : (
            <ListingDetailsQuality
                prime={packageModel.prime}
                picture={packageModel.pictures}
                testCertificate={packageModel.certificates}
                description={packageModel.description ?? null}
                apiBaseUrl={constants.apiBaseUrl}
            />
        )}
    </>
);
