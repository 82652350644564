import { ListingStatus, OfferListingStatus, PricingUnit, Product, RFQStatus } from '@steelbuy/ts-shared';
import { AddressIdentifier } from '@steelbuy/ui-primitive';
import { Steps } from '../app/components/create-listing/CreateListingContextUtil';
import { RFQStep } from '../app/components/create-rfq/createRFQUtil';

export default {
    constants: {
        primaryAddress: {
            line1: "Friar's Gate",
            line2: '(Third Floor)',
            line3: '1011 Stratford Road',
            line4: 'Shirley',
            line5: 'Solihull',
            line6: 'West Midlands',
            country: 'England',
            postcode: 'B90 4BN',
        },
        companyName: 'SteelBuy Limited',
        companyNumber: '01914559',
        customerServiceTel: '+44 (0) 121 713 7280',
        emailSupport: 'support@steel-buy.com',
        registerLink: 'https://steel-buy.com/account-registration/',
        emailInfo: 'info@steel-buy.com',
        tkCompanyName: 'thyssenkrupp Materials (UK) Ltd',
        trademarkNumber: 'UK00003639459',
        vatNumber: 'GB 695 9678 44',
        website: 'steel-buy.com',
        marketplace: 'steelbuy.app',
    },
    application: {
        acceptabelUsePolicy: {
            headerTitle: 'Richtlinien zur akzeptablen Nutzung',
        },
        account: {
            headerTitle: 'Konto',
            headerButtonAddNewUser: 'Neuen Benutzer hinzufügen',
            tabMyAccount: 'Mein Konto',
            tabOrganisation: 'Organisation',
            tabUserManagement: 'Benutzerverwaltung',
        },
        accountAddAddress: {
            errorMessage: 'Adresse konnte nicht hinzugefügt werden. ',
            pageTitle: 'Lageradresse hinzufügen',
            previousPageTitle: 'Organisationskonto',
        },
        accountEditAddress: {
            errorMessage: 'Adresse konnte nicht bearbeitet werden. ',
            pageTitleInvoiceAddress: 'Rechnungsadresse bearbeiten',
            pageTitleWarehouseAddress: 'Lageradresse bearbeiten',
            previousPageTitle: 'Organisationskonto',
        },
        accountEditAddressNotFound: {
            notFoundMessage: 'Diese Adresse konnte nicht gefunden werden.',
            pageTitle: 'Adresse bearbeiten',
            previousPageTitle: 'Organisationskonto',
        },
        accountEditEmail: {
            pageTitle: 'E-Mail-Adresse bearbeiten',
            previousPageTitle: 'Kontoübersicht',
            successNotification:
                'Eine E-Mail wurde an gesendet {{userEmail}} mit Anweisungen zur Bestätigung dieser E-Mail-Adresse.',
        },
        accountChangePassword: {
            pageTitle: 'Kennwort ändern',
            incorrectPassword: 'Das aktuelle Passwort ist falsch.',
            rateLimitError:
                'Leider haben Sie die maximal zulässige Anzahl an Versuchen zur Änderung Ihres Passworts überschritten. ',
            genericErrorMessage:
                'Wir können Ihr Passwort derzeit nicht zurücksetzen. Bitte versuchen Sie es später noch einmal.',
        },
        accountMFA: {
            pageTitle: 'Zwei-Faktor-Authentifizierung',
            previousPageTitle: 'Kontoübersicht',
            info: 'Anstatt auf Textnachrichten zu warten, erhalten Sie Bestätigungscodes von einer Authentifizierungs-App. ',
            inputLabel: '6-stelliger Code',
            submit: 'Weitermachen',
        },
        accountEditPaymentTerms: {
            formItemHeader: 'Zahlungsbedingungen',
            inputLabel: '',
            pageTitle: 'Zahlungseinstellungen',
            previousPageTitle: 'Organisationskonto',
            saveButtonLabel: 'Änderungen speichern',
            saveErrorMessage: 'Das Speichern der Änderungen ist fehlgeschlagen. ',
        },
        accountValidateEmail: {
            emailUpdated: 'Ihre E-Mail-Adresse wurde aktualisiert.',
            unableToUpdateEmail:
                'Wir können Ihre E-Mail-Adresse derzeit nicht aktualisieren. Bitte versuchen Sie es später erneut.',
        },
        addressDetails: {
            additionalInformationLabel: 'Weitere Informationen',
            collectionInformationLabel: 'Adressnotizen für die Sammlung',
            deliveryTimeframeLabel: 'Lieferzeitraum',
            deliveryInformationLabel: 'Hinweise zur Lieferadresse (optional)',
            addressNotLoadable: 'Adresse konnte nicht geladen werden.',
            chooseAddressButtonLabel: 'Wählen Sie eine andere Adresse',
            deliveryInfoCollectionLabel: 'Abholadresse',
            sectionHeading: {
                [AddressIdentifier.BILLING_ADDRESS]: 'Rechnungsadresse',
                [AddressIdentifier.DELIVERY_ADDRESS]: 'Lieferadresse',
                [AddressIdentifier.PICKUP_ADDRESS]: 'Abholadresse',
                [AddressIdentifier.DELIVERY_INFO]: 'Lieferinformationen',
            },
        },
        addUser: {
            headerTitle: 'Neuen Benutzer hinzufügen',
            backLinkText: 'Benutzerverwaltung',
            addUserButtonLabel: 'Benutzer erstellen',
        },
        dashboard: {
            headerTitle: 'Willkommen {{name}}!',
            bannerSearch: {
                header: 'Starten Sie Ihre Suche',
                body: 'Suchen Sie nach Materialien, legen Sie eine Benachrichtigung fest und lassen Sie uns das passende Material finden, das Sie benötigen.',
                buttonLabel: 'Suche nach Material',
            },
            bannerSell: {
                header: 'Verkaufen Sie Ihre Produkte',
                body: 'Verkaufen Sie Ihre Produkte und verschaffen Sie sich einen Überblick über den aktuellen Verkaufsstatus.',
                buttonLabel1: 'Eintrag erstellen',
                buttonLabel2: 'Meine Einträge',
            },
            urgentRequests: {
                headerTitle: 'Sofortige Materialanfrage vom Käufer',
                helperText:
                    'Sie können auf Grundlage der unmittelbaren Materialanfrage des Käufers ein neues Angebot erstellen. ',
                noUrgentRequests: 'Derzeit liegen keine unmittelbaren Materialanfragen vor.',
                viewAll: 'Alle anzeigen',
            },
            bannerRequest: {
                header: 'Jetzt Material anfordern',
                body: 'Erreichen Sie unser gesamtes Verkäufernetzwerk und erhalten Sie innerhalb weniger Stunden eine Antwort.',
                buttonLabel: 'Material anfordern',
            },
        },
        dashboardRfq: {
            bannerNewAtSteelBuy: {
                header: 'Neu bei SteelBuy: Walzprodukte',
                body: 'Senden Sie Ihre Materialanfrage direkt an viele Werke und vergleichen Sie die Angebote, bevor Sie eine Bestellung aufgeben.',
                buttonLabel: 'RFQ erstellen',
            },
        },
        addToCartConfirmModal: {
            headline: 'Erfolgreich zum Warenkorb hinzugefügt',
            continueShopping: 'Weiter einkaufen',
            viewCart: 'Warenkorb ansehen',
            checkoutBtn_one: 'Kasse ({{count}} Artikel)',
            checkoutBtn_other: 'Kasse ({{count}} Artikel)',
            addToCartFailed:
                'Der Artikel ist möglicherweise abgelaufen oder verkauft und kann nicht in Ihren Warenkorb gelegt werden.',
            alreadyInCart: 'Dieses Angebot befindet sich bereits im Warenkorb.',
            actFast: 'Handeln Sie schnell!  <time>{{time}}</time>',
            actFastSmallScreenModal: 'Handeln Sie schnell!  <time>{{time}}</time>',
            itemsNotReserved: 'Artikel sind nicht reserviert und können von anderen gekauft werden.',
            itemsNotReservedSmallScreenModal: 'Artikel können von anderen gekauft werden.',
            insufficientCredit:
                'Das Guthaben reicht nicht aus, um diesen Artikel in den Warenkorb zu legen. Bitte fügen Sie Geld hinzu, um fortzufahren.',
        },
        cart: {
            entrySuccessMessage: 'Ihr Eintrag wurde aus dem Warenkorb entfernt.',
            entryErrorMessage: 'Das Angebot konnte nicht aus dem Warenkorb entfernt werden. ',
            expiredMessage: 'Der Warenkorb ist abgelaufen.',
            insufficientCreditMessage:
                'Der Kauf ist nicht möglich, da einige Artikel in Ihrem Warenkorb aufgrund unzureichenden Guthabens nicht gekauft werden können.',
        },
        cartReminderNotification: {
            message: 'Der Warenkorb wird in <time>{{time}} Minuten</time> automatisch geleert',
            checkoutButtonLabel_one: 'Zur Kasse gehen ({{count}} Artikel)',
            checkoutButtonLabel_other: 'Zur Kasse gehen ({{count}} Artikel)',
        },
        checkout: {
            cancel: {
                content: 'Ihre Bestellung wird gelöscht.',
                confirmButtonLabel: 'Habe es',
            },
            header: {
                title: 'Kasse',
            },
            checkbox1Label:
                'Durch Ankreuzen dieses Kästchens erkläre ich, dass ich berechtigt bin, diesen Vertrag für und im Namen von abzuschließen {{organisationName}}.',
            checkbox2Label:
                'Indem ich dieses Kästchen ankreuze, stimme ich für und im Namen von zu {{organisationName}} dass ich die folgenden Geschäftsbedingungen gelesen habe und ihnen zustimme: <websitetncs>Allgemeine Geschäftsbedingungen der Website,</websitetncs> <buyertncs>Allgemeine Geschäftsbedingungen für Käufer</buyertncs>.',
            negotiationExists: 'Ein anderes Mitglied Ihrer Organisation verhandelt derzeit über diesen Punkt.',
            creditLimitExceeded: 'Der Preis dieses Produkts übersteigt Ihr verbleibendes Kreditlimit. ',
            delivery: {
                title: 'Lieferung',
                haulierRequiredMessage: 'Muss der Spediteur diese Lieferung buchen?',
                checkboxYes: 'Ja',
                checkboxNo: 'NEIN',
                materialLocationLabel: 'Materialstandort',
            },
            purchaseOrderNumber: 'Ihre Bestellnummer',
            purchaseOrderPlaceholderText: 'PO3874387',
            purchaseOrderErrorMsg: 'Bitte geben Sie eine gültige Bestellnummer ein',
            deliveryText: 'Voraussichtliche Lieferzeit',
            payment: {
                creditInfoNotification: 'Der Betrag wird von Ihrem Kreditrahmen abgezogen.',
                sectionHeading: 'Zahlung',
                optionCreditLabel: 'Kredit',
                optionCreditExplanation: 'Ihre Bestellung wird direkt versendet.',
                optionUpfrontLabel: 'Vorauszahlung',
                optionUpfrontExplanation: 'Ihre Bestellung wird versendet, nachdem wir Ihre Zahlung erhalten haben.',
            },
            priceUpdatedError: 'Der Listenpreis wurde aktualisiert. Bitte überprüfen Sie den neuen Preis vor dem Kauf.',
            pricePerUnitInclDelivery: 'Preis pro {{tradeUnit}} (inkl. Lieferung)',
            shippingCostsLabel: 'Lieferung',
            incompleteInformationNotification:
                'Bitte geben Sie alle erforderlichen Informationen ein, um den Kauf abzuschließen.',
            submitCheckoutButtonLabel: 'Bestätigen und bezahlen',
            totalPriceSectionHeading: 'Gewicht und Preis',
            totalExclVat: 'Gesamt (exkl. MwSt.)',
            itemsTotalExclVat: 'Artikel Gesamt (exkl. MwSt.)',
            totalInclVat: 'Gesamt (inkl. MwSt.)',
            vatLabel: '+ {{value}}% MwSt',
            weight: 'Gewicht',
            theoreticalWeight: 'Theoretisches Gewicht',
            error: {
                pageTitle: 'Entschuldigung. ',
                retryMessage:
                    'Wenn die Bestellung nach einem erneuten Versuch nicht aufgegeben werden kann, wenden Sie sich bitte an <link1>support@steel-buy.com</link1> um Hilfe.',
                contactMessage:
                    'Die Bestellung konnte nicht aufgegeben werden.  <link1>support@steel-buy.com</link1> um Hilfe.',
                contactSupport: 'Kontaktieren Sie den Support',
                retry: 'Wiederholen',
                link: 'Zurück zur Checkout-Seite',
                unableToPurchaseCartWithRetry:
                    'Leider konnten wir Ihre Bestellung nicht aufgeben. Wenn die Bestellung nach einem erneuten Versuch nicht aufgegeben werden kann, wenden Sie sich bitte an <link1>support@steel-buy.com</link1>, um Hilfe zu erhalten.',
                unableToPurchaseCartAfterRetryExceeded:
                    'Leider konnten wir Ihre Bestellung nicht aufgeben. Bitte kontaktieren Sie <link1>support@steel-buy.com</link1> für Hilfe.',
                creditLimitExceedContactSupport:
                    'Sie haben Ihr aktuelles Kreditlimit überschritten. Bitte kontaktieren Sie <link1>support@steel-buy.com</link1> für Hilfe.',
            },
        },
        checkoutAddAddress: {
            errorMessage: 'Adresse konnte nicht hinzugefügt werden. ',
            pageTitle: 'Neue Adresse hinzufügen',
            previousPageTitle: 'Wählen Sie Adresse',
        },
        checkoutChooseAddress: {
            pageTitle: 'Kasse',
        },
        checkoutDeliveryOption: {
            headerLabel: 'Lieferoption',
            headerDescriptionLabel: 'Sollte SteelBuy alle Artikel an eine oder mehrere Adressen liefern?',
            singleAddressLabel: 'Einzeladresse',
            singleAddressDescriptionLabel: 'Ihre Bestellung wird an eine Adresse versendet.',
            multipleAddressLabel: 'Mehrere Adressen',
            multipleAddressDescriptionLabel: 'Ihre Bestellung wird an mehrere Adressen versendet.',
            orderSummary: 'Bestellübersicht',
            totalWeight: 'Gesamtgewicht',
        },
        checkoutSuccess: {
            header: {
                title: 'Kasse',
            },
            successText: 'SteelBuy hat Ihre Kaufanfrage erhalten!',
            orderNumberText: 'SteelBuy-Bestellnummer:',
            purchaseOrderNumber: 'Ihre Bestellnummer:',
            steelbuyReferenceNumber: 'Referenznummer von SteelBuy:',
            emailHint: 'Sie erhalten eine E-Mail mit Kaufbestätigung, Rechnung und Prüfzertifikat.',
            deliveryHint: 'SteelBuy übernimmt die Abholung und Lieferung des Materials.',
            banner: {
                headline: 'Haben Sie Fragen zu Ihrer Bestellung?',
                contactText: 'Kontaktieren Sie uns gerne hier:',
                emailAddress: 'support@steel-buy.com',
            },
            searchListingsButtonLabel: 'Einträge durchsuchen',
            manageOrder: 'Verwalten Sie Ihre Bestellung unter <link1>Meine Bestellungen</link1>',
        },
        contactDetails: {
            header: {
                title: 'Kontaktieren Sie uns',
            },
        },
        createAlert: {
            title: 'Wählen Sie "Sofortige Materialanfrage“, wenn Sie sofort Materialien kaufen müssen. Wir benachrichtigen dann unsere Verkäufer über Ihre Anfrage.',
            urgentRequest: 'Ja, sofortige Materialanfrage',
            nonUrgentRequest: 'Nicht jetzt, normale Alarmbereitschaft',
        },
        createListingAddPickupAddress: {
            errorMessage: 'Adresse konnte nicht hinzugefügt werden. ',
            pageTitle: 'Lageradresse hinzufügen',
            previousPageTitle: 'Wählen Sie Abholadresse',
        },
        createListingOverview: {
            notificationSuccess: 'Ihr Entwurf wurde erfolgreich gelöscht.',
            notificationError: 'Ihr Entwurf konnte nicht gelöscht werden.',
        },
        createListingListUpload: {
            packageTitleHeader: 'Pakettitel',
            packageTitleLabel: 'Der Pakettitel für Käufer',
            packageTitlePlaceholder: 'z.B. 20 Spulen, verzinkt, warmgewalzt...',
            packageDescriptionLabel: 'Die Paketbeschreibung für Käufer',
            packageDescriptionHeader: 'Paketbeschreibung',
            packageDescriptionPlaceholder:
                'Bitte beschreiben Sie Ihr Paket, damit Käufer leicht verstehen können, was darin enthalten ist.',
            packageListUploadHeader: 'Paketlisten-Upload (Excel-Datei)',
            packageListUploadLabel: 'Laden Sie eine Excel-Datei hoch, die alle Artikel im Paket enthält',
            packageFileInputHelperText: 'Excel-Datei ({{fileType}})',
        },
        createListingChoosePickupAddress: {
            pageTitle: 'Wählen Sie Abholadresse',
            previousPageTitle: 'Eintrag erstellen',
        },
        createListing: {
            draftSuccessfulNotification: 'Ihr Entwurf wurde erfolgreich gespeichert.',
            draftFailedNotification: 'Eintrag konnte nicht als Entwurf gespeichert werden.',
            packageDraftFailedNotification: 'Das Paket konnte nicht als Entwurf gespeichert werden.',
            headerCreateNew: 'Eintrag erstellen',
            headerEditDraft: 'Entwurfsliste bearbeiten',
            rejectedListingConvertedToDraft: 'Die abgelehnte Auflistung wurde in eine Entwurfsauflistung umgewandelt. ',
            targetPriceHeader: 'Zielpreis',
        },
        createListingDraftNotFound: {
            pageTitle: 'Angebotsentwurf nicht gefunden',
            previousPageTitle: 'Überblick',
            notificationMessage: 'Dieser Angebotsentwurf konnte nicht gefunden werden. ',
            notificationButtonLabel: 'Neuen Eintrag erstellen',
        },
        createListingSaveDraftButton: {
            label: 'Als Entwurf speichern',
        },
        createListingSteps: {
            labels: {
                [Steps.MATERIAL]: 'Materialien',
                [Steps.DIMENSIONS]: 'Abmessungen',
                [Steps.WEIGHT_PRICE]: 'Gewicht und Preis',
                [Steps.PICKUP_ADDRESS]: 'Abholadresse',
                [Steps.LIST_UPLOAD]: 'Listen-Upload',
                [Steps.DELIVERY_INFO]: 'Lieferinformationen',
            },
        },
        createListingButtons: {
            viewSummaryButtonLabel: 'Zusammenfassung anzeigen',
            wizardNextButtonLabel: 'Nächste',
        },
        createListingSuccess: {
            buttonLabelCreateNew: 'Neuen Eintrag erstellen',
            buttonLabelCreateSame: 'Erstellen Sie denselben Eintrag',
            buttonLabelMyListings: 'Meine Angebote',
            header: 'Eintrag erstellen',
            marketingBannerHeader: 'Haben Sie Fragen zu Ihrer Bestellung?',
            marketingBannerText: 'Kontaktieren Sie uns gerne hier:',
            marketingBannerEmail: 'support@steel-buy.com',
            nextStepsHint: 'Nächste Schritte',
            nextStepNotification: 'Sie erhalten eine Benachrichtigung, wenn es online ist.',
            nextStepReview:
                'Unser Team prüft den Eintrag, um sicherzustellen, dass er den Qualitätsstandards entspricht.',
            notificationMessage: 'Ihr Eintrag wird derzeit überprüft.',
        },
        createListingSummary: {
            errorNotificationMessage: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
            expirationNotificationMessage:
                'Sie verpflichten sich, Ihr Material für die vereinbarte Dauer der Listung ausschließlich auf SteelBuy zu verkaufen.',
            header: 'Zusammenfassung',
            submitButtonLabel: 'Eintrag einreichen',
            weightAndPriceHeader: 'Gewicht und Ihr Verkaufspreis',
            weightAndPriceNotification: 'Zu Ihrem Preis berechnen wir eine Liefer- und Servicegebühr.',
            weightAndPriceNotificationTheoretical: 'Zu Ihrem Preis berechnen wir eine Liefer- und Servicegebühr. ',
            checkboxLabel:
                'Indem ich dieses Kästchen ankreuze, stimme ich für und im Namen von zu {{organisationName}} dass ich die folgenden Geschäftsbedingungen gelesen habe und ihnen zustimme: <websitetncs>Allgemeine Geschäftsbedingungen der Website</websitetncs>, <sellertncs>Allgemeine Geschäftsbedingungen des Verkäufers</sellertncs>',
            websiteTCLinkText: 'Allgemeine Geschäftsbedingungen der Website',
            sellerTCLinkText: 'Allgemeine Geschäftsbedingungen des Verkäufers',
        },
        createListingUnsavedChangesModal: {
            discardButtonLabel: 'Änderungen verwerfen',
            headline: 'Dieser Eintrag weist nicht gespeicherte Änderungen auf.',
            savingFailedNotification: 'Das Speichern ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal.',
        },
        createRFQUnsavedChangesModal: {
            headline: 'Diese Angebotsanfrage weist nicht gespeicherte Änderungen auf.',
        },
        createRFQ: {
            headerCreateNew: 'RFQ erstellen',
            headerEditDraft: 'Bearbeiten Sie den RFQ-Entwurf',
            submitButtonLabel: 'Senden Sie eine Angebotsanfrage',
            chooseMaterial: 'Wählen Sie Ihr Wunschmaterial',
            addItems: 'Artikel zu Ihrer Angebotsanfrage (RFQ) hinzufügen',
            chooseMaterialOrigin: 'Wählen Sie die bevorzugte Materialherkunft und den gewünschten Liefermonat',
            wizardNextButtonLabel: 'Nächste',
            viewSummary: 'Zusammenfassung anzeigen',
            comments: 'Kommentare',
            selectPlaceholder: 'Wählen Sie eine Option',
            addSimilar: 'Ähnliches hinzufügen',
            actions: 'Aktionen',
            addToRFQ: 'Zur Angebotsanfrage hinzufügen',
            saveAsDraft: 'Als Entwurf speichern',
            deleteRFQItemConfirmation: 'Sind Sie sicher, dass Sie dieses Element löschen möchten?',
            materialOrigin: {
                header: 'Bevorzugte Materialherkunft(en)',
                description: 'Bitte wählen Sie einen oder mehrere Ursprungsorte aus. ',
            },
            preferredOrigin: 'Bevorzugte Herkunft(en)',
            deliveryMonth: {
                header: 'Erforderlicher Liefermonat',
                description: 'Bitte wählen Sie im Dropdown-Menü den gewünschten Liefermonat aus.',
                subHeader: 'Gewünschter Liefermonat',
            },
            fieldRequired: 'Dieses Feld ist erforderlich.',
            quoteDeadline: {
                header: 'Angebotsfrist',
                subHeader: 'Angebotsfrist',
                description:
                    'Bitte geben Sie das späteste Datum an, bis zu dem Sie Angebote von Lieferanten erhalten müssen. ',
            },
            additionalComments: {
                header: 'Zusätzliche Kommentare',
                description:
                    'Bitte geben Sie für die Angebotsanfrage zusätzliche Kommentare zur gewünschten Materialqualität ab.',
            },
            rfqDetails: 'RFQ-Details',
            downloadRfq: 'Angebotsanfrage herunterladen',
            rfqReferenceNumber: 'RFQ-Referenznummer',
        },
        myRFQ: {
            header: 'Meine Ausschreibungen',
            submitted: 'Eingereicht',
            drafts: 'Entwürfe',
            noResultsDescription: 'Erstellen Sie eine neue Anfrage für das Material, das Sie kaufen möchten.',
            noListingsCallToAction: 'RFQ erstellen',
            numberOfRFQListings_zero: 'Keine Ausschreibungen',
            numberOfRFQListings_one: '{{count}} Anfrage',
            numberOfRFQListings_other: '{{count}} Ausschreibungen',
            notificationMsg: 'Die Lieferanten können die Produktliste überprüfen und mit einem Angebot antworten. ',
            publishRfq: 'RFQ VERÖFFENTLICHEN',
            createSimilarRFQ: 'Erstellen Sie eine ähnliche Ausschreibung',
            statusText: {
                header: {
                    [RFQStatus.UNDER_REVIEW]: 'Zur Überprüfung eingereicht unter:',
                    [RFQStatus.REJECTED]: 'Abgelehnt bei:',
                    [RFQStatus.LIVE]: 'Veröffentlicht unter:',
                    [RFQStatus.WITHDRAWN]: 'Zurückgezogen am:',
                    [RFQStatus.EXPIRED]: 'Abgelaufen am:',
                },
            },
            emailSubject: 'Ablehnung der Angebotsanfrage {{quoteRef}}',
        },
        myRFQDetails: {
            withdrawRfq: 'Anfrage zurückziehen',
            withdrawSuccess: 'Ihre Angebotsanfrage wurde zurückgezogen.',
            withdrawFailure: 'Ihre Angebotsanfrage kann nicht zurückgezogen werden. ',
            withdrawModal: {
                header: 'Sind Sie sicher, dass Sie Ihre Angebotsanfrage zurückziehen möchten?',
                confirmLabel: 'Anfrage zurückziehen',
            },
            updateFailure: 'Ihre Angebotsanfrage konnte nicht veröffentlicht werden. ',
            success: 'Ihre Ausschreibung ist online.',
            notificationMsg:
                'Sie können dieses Angebot erneuern, indem Sie eine neue Angebotsfrist festlegen, die es Ihnen ermöglicht, die erneuerte Angebotsanfrage direkt an die Verkäufer zu veröffentlichen. ',
            restartRFQSuccess: '„Die abgelehnte RFQ wurde in einen RFQ-Entwurf umgewandelt. ',
            restartRFQFailure: 'Die Konvertierung in den RFQ-Entwurf ist fehlgeschlagen. ',
        },
        myRFQDraft: {
            numberOfRFQDraftListings_zero: 'Keine Entwürfe',
            numberOfRFQDraftListings_one: '{{count}} Entwurf',
            numberOfRFQDraftListings_other: '{{count}} Entwürfe',
        },
        myRFQFilter: {
            selectLabel: 'Filtern nach',
            selectPlaceholder: 'Wählen Sie Optionen',
            optionLabels: {
                [RFQStatus.REJECTED]: 'Abgelehnt',
                [RFQStatus.LIVE]: 'Live',
                [RFQStatus.WITHDRAWN]: 'Zurückgezogen',
                [RFQStatus.EXPIRED]: 'Abgelaufen',
                [RFQStatus.UNDER_REVIEW]: 'Wird überprüft',
            },
        },
        myCart: {
            header: 'Warenkorb',
            infoMsg: 'Artikel sind nicht reserviert und können von anderen gekauft werden.',
            noItemCallToAction: 'Einträge durchsuchen',
            noItemCaption: 'Der Warenkorb ist leer',
            noResultsDescription: 'Jetzt Angebote suchen und kaufen.',
            itemsExpiredOrSold: 'Einige Artikel sind möglicherweise abgelaufen oder wurden verkauft. ',
            itemsRenewed: '<property>{{property}}:</property> von {{oldPrice}} bis {{newPrice}} {{pricingUnit}}',
            itemsRenewedMessage:
                'Für {{count}} der Artikel in Ihrem Warenkorb gibt es eine neue Preisaktualisierung vom Verkäufer.',
        },
        createRFQSteps: {
            labels: {
                [RFQStep.CHOOSE_MATERIALS]: 'Wählen Sie Materialien',
                [RFQStep.ADD_ITEMS]: 'Elemente hinzufügen',
                [RFQStep.SHIPPING_DETAILS]: 'Versanddetails',
            },
        },
        createRFQSuccess: {
            buttonLabelCreateNew: 'RFQ erstellen',
            buttonLabelMyListings: 'Meine Ausschreibungen',
            header: 'RFQ erstellen',
            marketingBannerHeader: 'Haben Sie Fragen zu Ihrer Ausschreibung?',
            marketingBannerText: 'Kontaktieren Sie uns gerne hier:',
            marketingBannerEmail: 'support@steel-buy.com',
            nextStepsHint: 'Nächste Schritte',
            nextStepNotification: 'Sie erhalten eine Benachrichtigung, wenn es online ist.',
            nextStepReview:
                'Unser Team prüft die Angebotsanfrage, um sicherzustellen, dass sie den Qualitätsstandards entspricht.',
            notificationMessage: 'Ihre Angebotsanfrage wird derzeit überprüft.',
            referenceNumber: 'RFQ-Referenznummer: ',
        },
        createRFQSummary: {
            submitButtonLabel: 'Senden Sie eine Angebotsanfrage',
            header: 'Zusammenfassung',
            createRFQFailed: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
        },
        createDraftRFQ: {
            createDraftRFQFailed: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
            createDraftRFQSuccess: 'Ihr Entwurf wurde erfolgreich gespeichert.',
        },
        addRfq: {
            addTitle: 'Neues Element hinzufügen',
            addDescription: 'Um einen Artikel hinzuzufügen, füllen Sie bitte alle Felder in der Zeile aus. ',
            itemExists: 'Artikel existiert bereits. ',
            genericError: 'Ungültige Formularwerte.',
        },
        legalCheckboxes: {
            checkbox1Label:
                'Durch Ankreuzen dieses Kästchens erkläre ich, dass ich berechtigt bin, diesen Vertrag für und im Namen von abzuschließen {{organisationName}}.',
            checkbox2Label:
                'Indem ich dieses Kästchen ankreuze, stimme ich für und im Namen von zu {{organisationName}} dass ich die folgenden Geschäftsbedingungen gelesen habe und ihnen zustimme: <websitetncs>Allgemeine Geschäftsbedingungen der Website</websitetncs>, <sellertncs>Allgemeine Geschäftsbedingungen des Verkäufers</sellertncs>',
        },
        editUser: {
            headerTitle: 'Benutzer bearbeiten',
            backLinkText: 'Benutzerverwaltung',
            saveUserButtonLabel: 'Änderungen speichern',
            toggleUserSuccess: {
                enabled: 'Der Benutzer wurde erfolgreich reaktiviert',
                disabled: 'Der Benutzer wurde erfolgreich deaktiviert',
            },
            toggleUserError: {
                enabled: 'Benutzer konnte nicht deaktiviert werden. ',
                disabled: 'Benutzer konnte nicht erneut aktiviert werden. ',
            },
        },
        footer: {
            termsLinkText: 'Allgemeine Geschäftsbedingungen',
            contactDetailsLinkText: 'Kontaktieren Sie uns',
            privacyPolicyLinkText: 'Datenschutzrichtlinie',
            acceptableUseLinkText: 'Richtlinien zur akzeptablen Nutzung',
        },
        genericError: {
            dashboardButtonLabel: 'Gehen Sie zum Dashboard',
            helperText: 'Ups! ',
            reloadButtonLabel: 'Neu laden',
        },
        legal: {
            headerTitle: 'Rechtliche Hinweise',
        },
        listingDetails: {
            detailedDescription: 'Detaillierte Beschreibung',
            testCertificateTitle: 'Prüfzertifikat',
            deliveryTitle: 'Lieferung',
            deliveryText: 'Voraussichtliche Lieferzeit',
            checkoutButtonText: 'Jetzt kaufen',
            offerButtonText: 'Machen Sie ein Angebot',
            packageListSheetTitle: 'Paketlistentabelle',
        },
        listingNotFound: {
            notFoundMessage: 'Leider ist dieses Produkt nicht mehr verfügbar.',
            newSearchMessage: 'Vielleicht wartet bereits ein ähnliches Produkt auf Sie?',
            newSearchButton: 'Neue Suche starten',
        },
        listingSearchForm: {
            headerTitle: 'Einträge durchsuchen',
            theoreticalWeightNotification: 'Maximal mögliches theoretisches Gewicht.',
        },
        ListingSearchFormInputs: {
            refineSearch: 'Verfeinern Sie Ihre Suche',
            incompleteInformationNotification:
                'Bitte füllen Sie alle erforderlichen Informationen aus, um Ihre Materialanfrage einzureichen.',
            submitText: 'Suchen',
            weightLabel: 'Erforderliches Gewicht',
            theoreticalWeightLabel:
                'Sie werden nur über Material informiert, das auf dem theoretischen Gewicht basiert.',
            targetPriceHeader: 'Gewünschter Zielpreis',
            targetPriceDescription:
                'Bitte beachten Sie, dass Angebote, die weit unter dem Marktwert liegen, von Verkäufern möglicherweise übersehen werden.',
            targetPriceLabel: 'Gewünschter Richtpreis (optional)',
            minimumTargetPriceError: 'Bitte geben Sie einen höheren Betrag ein, der dem Marktwert entspricht',
            numberOfItems: {
                [Product.SHEET]: 'Erforderliche Blattanzahl',
                [Product.PLATE]: 'Erforderliche Anzahl an Tellern',
                [Product.TREAD]: 'Erforderliche Anzahl Trittplatten',
            },
        },
        listingAlertNotification: {
            explanation:
                'Erstellen Sie eine Benachrichtigung und wir benachrichtigen Sie per E-Mail über neue Übereinstimmungsergebnisse. ',
            createSuccess: 'Sie haben eine Warnung erstellt. ',
            deleteSuccess: 'Ihre Benachrichtigung wurde gelöscht.',
            link: 'Sie finden Ihre Benachrichtigungen unter „Meine Benachrichtigungen“.',
        },
        listingSearchResults: {
            addAlertButtonLabel: 'Alarm erstellen',
            deleteAlertButtonLabel: 'Warnung löschen',
            header: {
                title: 'Suchergebnisse',
                backLinkText: 'Suchkriterien',
            },
            editSearchButtonTitle: 'Suche bearbeiten',
            noResultsText: 'Derzeit gibt es keine Ergebnisse für Ihre Suche.',
            noResultsButtonLabel: 'Suchkriterien bearbeiten',
        },
        locationAwareGenericError: {
            pageTitle: 'Etwas ist schief gelaufen',
        },
        materialStep: {
            anonymityBannerHeader: 'Anonymität ist uns wichtig!',
            anonymityBannerText: 'Ihr Zertifikat wird von SteelBuy anonymisiert.',
            missingProductsNotification: 'Sie finden das gesuchte Produkt nicht?',
            missingProductsLinkLabel: 'Benachrichtigen Sie uns!',
            missingProductsEmailSubject: 'Fehlendes Produkt',
        },
        myAccount: {
            myAccountCardHeader: 'Mein Konto',
            myAccountCardEmailLabel: 'E-Mail',
            myAccountCardNameLabel: 'Name',
            editEmailAddress: 'E-Mail-Adresse bearbeiten',
            passwordCardButtonLabel: 'Passwort aktualisieren',
            passwordCardHeader: 'Passwort',
            permissionCardHeader: 'Meine Berechtigungen',
            mfa: {
                header: 'Zwei-Faktor-Authentifizierung',
                button: 'Einstellungen ändern',
                enabled: 'Ermöglicht',
                disabled: 'Deaktiviert',
            },
        },
        myAlertsOverview: {
            deleteSuccess: 'Ihre Benachrichtigung wurde gelöscht.',
            pageHeader: 'Meine Benachrichtigungen',
            pageHeaderButton: 'Neue Suche starten',
        },
        myOffers: {
            pageHeader: 'Meine Angebote',
            dataHeader: {
                numberOfOffers_zero: 'Keine Angebote',
                numberOfOffers_one: '{{count}} Angebot',
                numberOfOffers_other: '{{count}} Angebote',
            },
            noResultsText: 'Derzeit liegen keine Angebote vor.',
            infoMsg:
                'Der Verkäufer kann den vorgeschlagenen Preis überprüfen und entscheiden, ob er das Angebot annimmt, ablehnt oder widerspricht. ',
        },
        myAlertsSearchResults: {
            pageHeader: 'Ergebnisse',
            previousPageTitle: 'Meine Benachrichtigungen',
            notificationIllustratedHeader: 'Es ist ein Match',
            notificationIlulustratedHelperText: 'Schauen Sie sich die neuen Angebote an, die Ihrer Suche entsprechen',
            searchSummaryNewResults: '{{numListings}} neu',
        },
        myAlertsSettings: {
            changesSavedNotificationMessage: 'Ihre Änderungen wurden gespeichert!',
            deleteButtonLabel: 'Warnung löschen',
            header: 'Alarmeinstellungen',
            headerBackLinkLabel: 'Meine Benachrichtigungen',
            mailNotificationHeader: 'E-Mail-Benachrichtigungen',
            mailNotificationLabel:
                'Sie erhalten eine E-Mail-Benachrichtigung an {{email}} sobald ein Produkt Ihrer Benachrichtigung entspricht.',
            receiveNotifications: 'Erhalten Sie Benachrichtigungen',
            saveButtonLabel: 'Änderungen speichern',
        },
        myListings: {
            header: 'Meine Einträge',
            noResultsDescription: 'Erstellen Sie ein neues Angebot für das Material, das Sie verkaufen möchten.',
            noListingsCallToAction: 'Eintrag erstellen',
            numberOfListings_zero: 'Keine Einträge',
            numberOfListings_one: '{{count}} Auflistung',
            numberOfListings_other: '{{count}} Einträge',
            numberOfDrafts_zero: 'Keine Entwürfe',
            numberOfDrafts_one: '{{count}} Entwurf',
            numberOfDrafts_other: '{{count}} Entwürfe',
            listings: 'Einträge',
            drafts: 'Entwürfe',
            subTabListingLabel: 'Haupteintrag',
            subTabDraftsLabel: 'Hauptentwürfe',
        },
        myPackages: {
            numberOfPackageListings_zero: 'Keine Paketlisten',
            numberOfPackageListings_one: '{{count}} Paketliste',
            numberOfPackageListings_other: '{{count}} Paketlisten',
            numberOfPackageDrafts_zero: 'Keine Entwürfe',
            numberOfPackageDrafts_one: '{{count}} Paketentwurf',
            numberOfPackageDrafts_other: '{{count}} Paketentwürfe',
            subTabPackageLabel: 'Paketlisten',
            subTabDraftsLabel: 'Paketentwürfe',
        },
        myListingsFilter: {
            selectLabel: 'Filtern nach',
            selectPlaceholder: 'Wählen Sie Optionen',
            optionLabels: {
                [ListingStatus.DRAFT]: 'Entwurf',
                [ListingStatus.REVIEW_REQUIRED]: 'Wird überprüft',
                [ListingStatus.REVIEW_REJECTED]: 'Abgelehnt',
                [ListingStatus.PUBLISHED]: 'Live',
                [ListingStatus.PURCHASED]: 'Verkauft',
                [ListingStatus.WITHDRAWN]: 'Zurückgezogen',
                [ListingStatus.EXPIRED]: 'Abgelaufen',
            },
        },
        manageOffers: {
            pageHeader: 'Angebote verwalten',
            dataHeader: {
                numberOfOffers_zero: 'Keine Angebote',
                numberOfOffers_one: '{{count}} Angebot',
                numberOfOffers_other: '{{count}} Angebote',
            },
            noResultsText: 'Derzeit liegen keine Angebote vor.',
            infoMsg:
                'Der Käufer kann den vorgeschlagenen Preis überprüfen und entscheiden, ob er das Angebot annimmt, ablehnt oder widerspricht. ',
        },
        offerListingsFilter: {
            selectLabel: 'Filtern nach',
            selectPlaceholder: 'Wählen Sie Optionen',
            awaitingYourResponse: 'Warten auf Ihre Antwort',
            optionLabels: {
                [OfferListingStatus.AWAITING_BUYER]: 'Warten auf die Antwort des Käufers',
                [OfferListingStatus.AWAITING_SELLER]: 'Warten auf die Antwort des Verkäufers',
                [OfferListingStatus.ACCEPTED]: 'Akzeptiert',
                [OfferListingStatus.REJECTED]: 'Abgelehnt',
                [OfferListingStatus.WITHDRAWN]: 'Zurückgezogen',
                [OfferListingStatus.EXPIRED]: 'Abgelaufen',
                [OfferListingStatus.CANCELLED]: 'Abgesagt',
                [OfferListingStatus.PURCHASED]: 'Verkauft',
            },
        },
        offers: {
            negotiationsHeader: 'Sie verhandeln über dieses Angebot',
            accordionHeader: 'Referenznummer der Verhandlung: {{referenceId}}',
            timeLeft: 'Es bleibt noch Zeit, Maßnahmen zu ergreifen',
            tableHeadings: {
                offersAndCounterOffers: 'Angebot(e) und Gegenangebot(e)',
                date: 'Datum',
                requestBy: 'Anfrage per',
                action: 'Aktion',
            },
            reject: 'Ablehnen',
            counter: 'Schalter',
            accept: 'Akzeptieren',
            withdraw: 'Angebot zurückziehen',
            hours: 'Std.',
            minutes: 'Minuten',
            acceptModelHeader: 'Sind Sie sicher, dass Sie dieses Angebot annehmen möchten?',
            buyNowModalheader: 'Bestehendes Angebot in Bearbeitung. ',
            addToCartModalHeader:
                'Bestehendes Angebot in Bearbeitung. Sind Sie sicher, dass Sie das Angebot zum Originalpreis in den Warenkorb legen möchten?',
            buynow: 'Jetzt kaufen',
            submitCounterOffer: 'Gegenangebot unterbreiten',
            review: 'Rezension',
            priceExceed: 'Der von Ihnen eingegebene Betrag ist höher als der Originalpreis.',
            minimumPriceError: 'Bitte geben Sie einen höheren Betrag ein, der dem Marktwert entspricht.',
            limitPriceExceed: 'Der von Ihnen eingegebene Betrag überschreitet Ihr Kreditlimit.',
            buyerCounterInfoMsg:
                'Wir werden Ihnen innerhalb von 24 Stunden eine Antwort des Käufers senden {{timeLeft}}',
            sellerCounterInfoMsg:
                'Wir werden Ihnen innerhalb von 24 Stunden eine Antwort des Verkäufers senden {{timeLeft}}',
            counterOffer: 'Ihr Gegenangebot',
            reviewCounterOffer: 'Überprüfen Sie das Gegenangebot',
            pricelabel: 'Originalpreis pro {{pricingUnit}}',
            buyerOfferedPriceLabel: 'Angebot des Käufers pro {{pricingUnit}}',
            sellerOfferedPriceLabel: 'Angebot des Verkäufers pro {{pricingUnit}}',
            proposedPrice: 'Ihr vorgeschlagener Preis pro {{pricingUnit}}',
            total: 'Gesamt',
            reviewPricingUnit: {
                [PricingUnit.TON]: {
                    label: 'Gewicht',
                },
                [PricingUnit.KILOGRAM]: {
                    label: 'Gewicht',
                },
                [PricingUnit.SHEET]: {
                    label: 'Artikel',
                },
                [PricingUnit.PLATE]: {
                    label: 'Artikel',
                },
                [PricingUnit.TREAD]: {
                    label: 'Artikel',
                },
            },
            rejectModelHeader: 'Möchten Sie dieses Angebot wirklich ablehnen?',
            withdrawModalHeader: 'Sind Sie sicher, dass Sie Ihr Angebot zurückziehen möchten?',
            showAllText: 'Alle Gegenangebote-Datensätze anzeigen',
            counterMsgSeller: 'Ihr Gegenangebot ist eingegangen. ',
            counterMsgBuyer: 'Ihr Angebot ist eingegangen. ',
            counterErrorMSg: 'Derzeit kann dem Angebot nicht widersprochen werden. Bitte versuchen Sie es erneut.',
            acceptErrorMsg: 'Das Angebot kann derzeit nicht angenommen werden. Bitte versuchen Sie es erneut.',
            rejectErrorMsg: 'Das Angebot kann derzeit nicht abgelehnt werden. Bitte versuchen Sie es erneut.',
            withdrawErrorMsg: 'Das Angebot kann derzeit nicht zurückgezogen werden. Bitte versuchen Sie es erneut.',
            acceptMsgBuyer: 'Sie haben das Angebot des Verkäufers angenommen. ',
            rejectMsgBuyer: 'Die Ablehnung Ihres Angebots wird bestätigt.',
            withdrawMsg: 'Ihr Angebot wurde zurückgezogen.',
            acceptMsgSeller:
                'Der Verkäufer hat das Angebot angenommen, das bis zum Ablauf der Anfragefrist verfügbar ist.',
            rejectMsgSeller: 'Der Verkäufer hat das Angebot abgelehnt.',
            notificationMsgBuyer:
                'Der Verkäufer kann den vorgeschlagenen Preis überprüfen und entscheiden, ob er das Angebot annimmt, ablehnt oder widerspricht. ',
            notificationMsgSeller:
                'Der Käufer kann den vorgeschlagenen Preis überprüfen und entscheiden, ob er das Angebot annimmt, ablehnt oder widerspricht. ',
        },
        myOrders: {
            header: 'Meine Bestellungen',
            numberOfOrders_zero: 'Keine Bestellungen',
            numberOfOrders_one: '1 Bestellung',
            numberOfOrders_other: '{{count}} Bestellungen',
            noResultsDescription: 'Keine Ergebnisse entsprechen diesen Filteroptionen.',
            noOrdersCallToAction: 'Filter löschen',
            bannerBuy: {
                header: 'Kaufen Sie Ihr erstes Produkt',
                subHeader: 'Kaufen Sie Material und profitieren Sie von unserem SteelBuy-Versprechen:',
                description: [
                    'Sofortiger Zugriff auf Materialien, um Ihre Zeit zu maximieren.',
                    'Der schnellste Weg, Stahl zu kaufen, bei dem die Technologie die Arbeit erledigt.',
                    'Vollständig verwalteter Bestellvorgang.',
                ],
                buttonLabel: 'Suche nach Material',
            },
            searchPlaceHolder: 'Ihre Bestellnummer',
            noOrdersCallToActionClearSearch: 'Suche löschen',
            noOrdersCallToActionClearBoth: 'Klare Suche und Filter',
            noResultsDescriptionForSearch: 'Keine Ergebnisse für "{{number}}"',
            noResultsDescriptionForBoth:
                'Für "{{number}}" oder passend zu den ausgewählten Filteroptionen wurden keine Ergebnisse gefunden.',
        },
        myOrdersFilter: {
            selectLabel: 'Filtern nach',
            selectPlaceholder: 'Wählen Sie eine Option',
        },
        myOrderDetails: {
            marketingBannerHeader: 'Haben Sie Fragen zu Ihrer Bestellung?',
            marketingBannerText: 'Kontaktieren Sie uns gerne hier:',
            purchaseOrderNumbersHeader: 'Bestellnummern',
            purchaseOrderNumberHeader: 'Bestellnummer',
            steelbuyPurchaseOrderNumberLabel: 'SteelBuy Bestellnr',
            buyerPurchaseOrderNumberLabel: 'Ihre Bestell-Nr',
            backLinkText: 'Meine Bestellungen',
            steelBuyReferenceNoLabel: 'SteelBuy-Referenznr',
        },
        myOrderDetailsNotFound: {
            pageTitle: 'Bestellung nicht gefunden',
            previousPageTitle: 'Meine Bestellungen',
            notFoundMessage: 'Diese Bestellung konnte nicht gefunden werden.',
        },
        myOrderDetailsStatus: {
            cardHeader: 'Bestellstatus',
            delivered: 'Geliefert',
            delivery: 'Lieferung',
            freight_booked: 'Fracht gebucht',
            processing: 'Bearbeitungsauftrag',
            processed: 'Bestellung bearbeitet',
            shipping: 'Versand',
            shippingConfirmed: 'Versand bestätigt',
        },
        offerSuccess: {
            title: 'Ihr Angebot',
            successText: 'SteelBuy hat Ihr Angebot erhalten!',
            referenceNumberText: 'SteelBuy-Referenznummer:',
            responseHint: 'Sie erhalten in Kürze eine Antwort des Verkäufers auf Ihr Angebot.',
            manageOffers: 'Verwalten Sie Ihre Angebote unter <link1>Meine Angebote</link1>.',
        },
        pickupAddressStep: {
            anonymityBannerHeader: 'Anonymität ist uns wichtig!',
            anonymityBannerText: 'Ihre Adresse wird für die Abholung benötigt und ist nur für SteelBuy sichtbar.',
            incompleteInformationNotification:
                'Bitte geben Sie vollständige Informationen ein, um mit der Zusammenfassung fortzufahren.',
            filesUploadingNotification: 'Bitte warten Sie, bis der Upload der Dateien abgeschlossen ist.',
            additionalInformationLabel: 'Adressnotizen für die Sammlung (optional)',
            additionalInfoPlaceholder: 'z.B. ',
            deliveryTimeframeHeader: 'Lieferzeitraum',
            deliveryTimeframeLabel: 'Ihr Lieferzeitraum für das aktuelle Paket.',
        },
        privacyPolicy: {
            headerTitle: 'Datenschutzrichtlinie',
            cookieLinks: {
                edge: 'https://enablecookies.info/#en-edge',
                safari: 'https://enablecookies.info/#en-safari',
                chrome: 'https://enablecookies.info/#en-chrome',
                firefox: 'https://enablecookies.info/#en-firefox',
                opera: 'https://enablecookies.info/#en-opera',
            },
        },
        purchaseContactEdit: {
            backLinkText: 'Organisationskonto',
            emailFieldLabel: 'E-Mail-Adresse',
            headerTitle: 'Kaufkontakt',
            nameFieldLabel: 'Name',
            notificationErrorMessage: 'Das Speichern ist fehlgeschlagen. Bitte versuchen Sie es später noch einmal',
            saveButtonLabel: 'Änderungen speichern',
        },
        renewListingSuccess: {
            notificationMessage: 'Ihr Eintrag ist online!',
            notificationHelperText: 'SteelBuy-Eintrags-ID: {{steelbuyListingID}}',
            bannerHeader: 'Haben Sie Fragen zu Ihrem Eintrag?',
            bannerText: 'Kontaktieren Sie uns gerne hier:',
            bannerEmailAddress: 'support@steel-buy.com',
            myListingsButtonLabel: 'Meine Angebote',
        },
        rfqHeadingLabels: {
            grade: 'Grad',
            specification: 'Spezifikation',
            finish: 'Beenden',
            width: 'Breite',
            thickness: 'Dicke',
            length: 'Länge',
            weight: 'Gewicht',
            tolerance: 'Toleranz',
            surface: 'Oberfläche',
            coating: 'Beschichtung',
            coatingThicknessValue: 'Beschichtungsdicke',
        },
        sellerListingDetails: {
            rejectionReason: {
                title: 'Ablehnungsgrund',
                description: 'Wenden Sie sich an den Support, wenn Sie Fragen zum Überprüfungsprozess haben.',
                buttonLabel: 'Kontaktieren Sie den Support',
                emailSubject: 'Ablehnung der Listung {{sellerSku}}',
            },
            weightAndSellingPrice: {
                title: 'Gewicht und Preis',
                offerTitle: 'Gewicht und Ihr Verkaufspreis',
                weight: 'Originalgewicht und Preis',
                notification: 'Zu Ihrem Preis berechnen wir eine Liefer- und Servicegebühr.',
                offerNotification:
                    'Zum Gesamtpreis des Käufers berechnen wir eine Liefergebühr und eine Servicegebühr.',
                newSellingPriceHeader: 'Ihr neuer Verkaufspreis pro {{tradeUnit}}',
            },
            checkboxLabel:
                'Indem ich dieses Kästchen ankreuze, stimme ich für und im Namen von zu {{organisationName}} dass ich die folgenden Geschäftsbedingungen gelesen habe und ihnen zustimme: <websitetncs>Allgemeine Geschäftsbedingungen der Website</websitetncs>, <sellertncs>Allgemeine Geschäftsbedingungen des Verkäufers</sellertncs>.',
            createSameButtonLabel: 'Erstellen Sie einen ähnlichen Eintrag',
            renewButtonLabel: 'Auf dem Marktplatz veröffentlichen',
            save: 'Speichern',
            listingHasUnsavedChanges: 'Dieser Eintrag weist nicht gespeicherte Änderungen auf.',
            updateError: 'Änderungen am Eintrag konnten nicht gespeichert werden.',
            updateSuccess:
                'Ihre Aktualisierungen wurden erfolgreich gespeichert und werden auf dem Marktplatz angezeigt, sobald Ihr aktuelles Angebot abläuft und automatisch erneuert wird.',
        },
        sellerListingDetailsNotFound: {
            pageTitle: 'Eintrag nicht gefunden',
            previousPageTitle: 'Meine Einträge',
            notFoundMessage: 'Dieser Eintrag konnte nicht gefunden werden. ',
        },
        termsAndConditions: {
            pageHeader: 'Geschäftsbedingungen',
        },
        navigation: {
            itemLabels: {
                account: 'Konto',
                alerts: 'Meine Benachrichtigungen',
                createListing: 'Eintrag erstellen',
                dashboard: 'Armaturenbrett',
                manageOffers: 'Angebote verwalten',
                myListings: 'Meine Einträge',
                myOffers: 'Meine Angebote',
                myOrders: 'Meine Bestellungen',
                searchListing: 'Einträge durchsuchen',
                requestMaterial: 'Material anfordern',
                activeRequests: 'Aktive Anfragen',
                createRfq: 'RFQ erstellen',
                myRfqs: 'Meine Ausschreibungen',
            },
            menuButtonLabel: 'Speisekarte',
        },
        navigationItemUser: {
            logoutButtonLabel: 'Abmelden',
        },
        navigationFooter: {
            loginButtonLabel: 'Login',
        },
        warehouseAddressSelection: {
            chooseAddressButtonLabel: 'Adresse wählen',
            addNewAddress: 'Neue Adresse hinzufügen',
        },
        organisation: {
            addressAddedNotificationText: 'Vielen Dank, dass Sie eine neue Lageradresse hinzugefügt haben. ',
            addressDeletedNotificationText: 'Adresse wurde gelöscht.',
            addressUpdatedNotificationText: 'Adresse wurde aktualisiert.',
            createNewButtonLabel: 'Neue Adresse hinzufügen',
            creditCardHeader: 'Kredit',
            creditLineBalanceLabel: 'Restguthaben',
            creditLineTotalLabel: 'Gesamtguthaben',
            invoiceAddressCardHeader: 'Rechnungsadresse',
            organisationCardHeader: 'Organisation',
            organisationCardNameLabel: 'Name',
            organisationSavedNotificationText: 'Änderungen wurden erfolgreich gespeichert!',
            paymentSectionHeading: 'Zahlung',
            paymentTermsHeader: 'Zahlungsbedingungen für den Einkauf',
            primaryAddressTag: 'Primär',
            purchaseContactCardHeader: 'Kaufkontakt',
            purchaseContactNameLabel: 'Name',
            purchaseContactEmailLabel: 'E-Mail',
            warehouseAddressHeader: 'Lageradresse {{number}}',
            warehouseAddressesSectionHeading: 'Lageradressen',
        },
        pageTitle: 'SteelBuy-Marktplatz',
        urgentRequests: {
            pageHeader: 'Sofortige Materialanfrage',
            settingsButtonLabel: 'Einstellungen',
            newResultsSeparatorHeader_zero: 'Keine neuen Ergebnisse (letzte 24 Stunden)',
            newResultsSeparatorHeader_one: '1 Neues Ergebnis (letzte 24 Stunden)',
            newResultsSeparatorHeader_other: '{{count}} Neue Ergebnisse (letzte 24 Stunden)',
            emptyStateDescription: 'Derzeit liegen keine unmittelbaren Materialanfragen vor.',
            createListing: 'Eintrag erstellen',
            qna: {
                header: 'Fragen und Antworten',
                items: {
                    q1: {
                        label: 'Wie funktionieren sofortige Materialanfragen?',
                        content:
                            'Wenn Sie Verkäufer sind, können Sie auf der Grundlage der unmittelbaren Materialanfrage des Käufers ein neues Angebot erstellen.  ',
                    },
                    q2: {
                        label: 'Wie werde ich benachrichtigt?',
                        content:
                            'Wenn bei SteelBuy sofortige Materialanfragen auftauchen, senden wir Ihnen eine Benachrichtigung per E-Mail an die E-Mail-Adresse Ihres Kontos.',
                    },
                    q3: {
                        label: 'Wie oft werde ich benachrichtigt',
                        content:
                            'Derzeit benachrichtigen wir Sie per E-Mail jedes Mal, wenn es ein neues Suchergebnis für Ihre Kriterien gibt. ',
                    },
                    q4: {
                        label: 'Wie kann ich sofortige Materialanfragen stummschalten?',
                        content:
                            'Sie können Ihre Benachrichtigung über sofortige Materialanfragen in den Einstellungen für sofortige Materialanfragen stummschalten oder löschen.',
                    },
                },
            },
            new: 'neu',
        },
        urgentRequestsSettings: {
            pageHeader: 'Sofortige Materialanforderungseinstellungen',
            headerBackLinkLabel: 'Sofortige Materialanfrage',
            changesSavedNotificationMessage: 'Ihre Änderungen wurden gespeichert!',
            mailNotificationHeader: 'E-Mail-Benachrichtigungen',
            mailNotificationLabel:
                'Sie erhalten eine E-Mail-Benachrichtigung an {{email}} sobald eine sofortige Materialanfrage erstellt wurde (abhängig von Ihrer Benachrichtigungshäufigkeit).',
            receiveNotifications: 'Erhalten Sie Benachrichtigungen',
            saveButtonLabel: 'Änderungen speichern',
            notificationFrequencyHeader: 'Benachrichtigungshäufigkeit',
            notificationFrequencyLabel: 'Sie erhalten eine Benachrichtigung basierend auf Ihrer Frequenzauswahl',
        },
        urgentRequestForm: {
            pageHeader: 'Ihre sofortige Materialanfrage',
            warningMessage:
                'Bitte wählen Sie die detaillierten Kriterien aus und geben Sie sie ein, um eine sofortige Materialanfrage zu erstellen.',
            infoMessage:
                'Bitte füllen Sie die erforderlichen Felder aus, um eine sofortige Materialanfrage zu erstellen.',
            submitButtonLabel: 'Anfrage abschicken',
            createdAlertMessageNoMatch: 'Sie haben eine sofortige Materialanfrage erstellt.',
            createdAlertMessageWithMatch:
                'Sie haben eine sofortige Materialanfrage erstellt und eine Übereinstimmung gefunden.',
            failedToCreate:
                'Wir können Ihre Benachrichtigung derzeit nicht erstellen. Bitte versuchen Sie es später erneut.',
        },
        userForm: {
            nameFieldLabel: 'Name',
            permissionsFieldLabel: 'Berechtigungen',
            emailFieldLabel: 'E-Mail-Adresse',
            activateToggleLabel: 'Aktiver Benutzer',
            deactivateUserModal: {
                modalTitle: 'Möchten Sie diesen Benutzer wirklich deaktivieren?',
                confirmButtonLabel: 'Ja, deaktiviere sie!',
            },
            activateUserModal: {
                modalTitle: 'Möchten Sie diesen Benutzer wirklich reaktivieren?',
                confirmButtonLabel: 'Ja, reaktivieren Sie sie!',
            },
            errors: {
                invalidDomain: 'Die E-Mail muss von derselben Domäne wie die Organisation stammen',
                other: 'Fehler',
            },
        },
        userManagement: {
            emailHeader: 'E-Mail',
            permissionsHeader: 'Berechtigungen',
            createUserSuccess: 'Der Benutzer wurde erstellt!',
            updateUserSuccess: 'Der Benutzer wurde aktualisiert!',
        },
        time: {
            day_one: '1 Tag',
            day_other: '{{count}} Tage',
        },
    },
    error: {
        noUserError: 'Kein Benutzer geladen',
    },
};
