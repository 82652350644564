import { createContext } from 'react';
import { Optional } from '@steelbuy/ts-shared';
import { AccessToken, JsonWebToken, RefreshToken } from '../jwt/JsonWebToken';

export interface AuthContextValue {
    authenticate(accessToken: AccessToken, refreshToken: RefreshToken): void;

    update(accessToken: AccessToken): void;

    reauthenticate(): void;

    unauthenticate(queryParam?: string): void;

    getToken(): Optional<JsonWebToken>;

    isAuthenticated(): boolean;

    needsUpdate(): boolean;

    hasPermission(permission: string): boolean;
}

// noinspection JSUnusedLocalSymbols
export const AuthContext = createContext<AuthContextValue>({
    authenticate: (accessToken: AccessToken, refreshToken: RefreshToken): void => {},
    update: (accessToken: AccessToken): void => {},
    reauthenticate: (): void => {},
    unauthenticate: (queryParam: string): void => {},
    getToken: (): Optional<JsonWebToken> => new Optional<JsonWebToken>(null),
    isAuthenticated: (): boolean => false,
    needsUpdate: (): boolean => false,
    hasPermission: (permission: string): boolean => false,
});
