import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { ListingStatus } from '@steelbuy/ts-shared';

export interface MyPackagesContextValue {
    scrollPosition: number;
    setScrollPosition: (position: number) => void;
    packageFilter: ListingStatus[];
    setPackageFilter: (filter: ListingStatus[]) => void;
}

const defaultValue: MyPackagesContextValue = {
    scrollPosition: 0,
    setScrollPosition: () => null,
    packageFilter: [],
    setPackageFilter: () => null,
};

const MyPackagesContext = createContext(defaultValue);

export const MyPackagesContextProvider = ({ children }: { children: ReactNode }) => {
    const [scrollPosition, setScrollPosition] = useState(defaultValue.scrollPosition);
    const [packageFilter, setPackageFilter] = useState<ListingStatus[]>([]);

    const value = useMemo(
        () => ({
            scrollPosition,
            setScrollPosition,
            packageFilter,
            setPackageFilter,
        }),
        [scrollPosition, setScrollPosition, packageFilter, setPackageFilter]
    );

    return <MyPackagesContext.Provider value={value}>{children}</MyPackagesContext.Provider>;
};

export const useMyPackagesContext = () => useContext(MyPackagesContext);
