import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Specification, getSpecifications, MaterialProperties } from '@steelbuy/ts-shared';
import { FormItem, InputSelect } from '@steelbuy/ui-primitive';

import { AnyValidationProps } from '../validation/Validation';
import { useRequiredFieldValidator } from '../validation/ValidationHooks';

export const MaterialSpecificationSelection = ({
    forceValidation,
    material,
    required,
    specificationSelected,
}: {
    material: MaterialProperties;
    specificationSelected: (specification?: Specification) => void;
} & AnyValidationProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();

    const specifications = getSpecifications(material)?.slice()?.sort();

    const { specification } = material;

    useEffect(() => {
        if (specification !== undefined && !specifications.includes(specification)) {
            specificationSelected(undefined);
        }
    }, [specification, specificationSelected, specifications]);

    if (specifications.length === 0) {
        return null;
    }

    const gradeSpecificationOptions = specifications.map((gradeSpecification) => ({
        label: t(`domainModel:material.specification.value.${gradeSpecification}`),
        value: gradeSpecification,
    }));

    return (
        <FormItem header={t('domainModel:material.specification.name')} isMandatory={required}>
            <InputSelect
                options={gradeSpecificationOptions}
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={specification}
                onSelect={(value) => specificationSelected(value)}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={forceValidation}
            />
        </FormItem>
    );
};
