import { useMutation } from '@tanstack/react-query';
import {
    PackageSellerDraftModel,
    PackageSellerCreateDraftModel,
    PackageSellerCreateDraftModelConverter,
    PackageSellerDraftModelConverter,
    ApiModel,
} from '@steelbuy/ts-shared';
import axios from '../../axios';

const createModelConverter = new PackageSellerCreateDraftModelConverter();
const draftModelConverter = new PackageSellerDraftModelConverter();

const createPackageDraft = async (createPackageDraftRequest: PackageSellerCreateDraftModel) => {
    const { data } = await axios.post<PackageSellerDraftModel>(
        '/api/universal/draft-packages',
        createModelConverter.toApiModel(createPackageDraftRequest)
    );
    return draftModelConverter.toViewModel(data as unknown as ApiModel<PackageSellerDraftModel>);
};

export const useCreatePackageDraft = () =>
    useMutation({
        mutationFn: (createPackageDraftRequest: PackageSellerCreateDraftModel) =>
            createPackageDraft(createPackageDraftRequest),
    });
