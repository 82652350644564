import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CoatingColourType, getCoatingColours, MaterialProperties } from '@steelbuy/ts-shared';
import { FormItem, InputSelect, InputTextfield } from '@steelbuy/ui-primitive';

import { useOtherDropDown } from '../hooks/useOtherDropDown';
import { AnyValidationProps } from '../validation/Validation';
import { useLettersOnlyValidator, useRequiredFieldValidator } from '../validation/ValidationHooks';

const OTHER = 'OTHER';

export const MaterialCoatingColourSelection = ({
    material,
    coatingColourSelected,
    required,
    forceValidation,
    allowOtherInput = true,
}: {
    material: MaterialProperties;
    coatingColourSelected: (color?: CoatingColourType) => void;
    allowOtherInput?: boolean;
} & AnyValidationProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const requiredFieldValidator = useRequiredFieldValidator();
    const letterFieldValidator = useLettersOnlyValidator();

    const colors = getCoatingColours(material).filter((colour) => allowOtherInput || colour !== OTHER);

    const { coatingColour } = material;

    const { dropDownValue, setDropDownValue, textValue, setTextValue } = useOtherDropDown<CoatingColourType>(
        coatingColour,
        colors
    );

    useEffect(() => {
        if (coatingColour !== undefined && !colors.length) {
            coatingColourSelected(undefined);
        }
        if (dropDownValue !== OTHER) {
            setTextValue('');
        }
    }, [coatingColour, coatingColourSelected, colors]);

    if (colors.length === 0) {
        return null;
    }

    const coatingColourOptions = colors.map((color) => ({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        label: t(`domainModel:material.coatingColour.value.${color}`),
        value: color,
    }));

    return (
        <FormItem header={t('domainModel:material.coatingColour.name')} isMandatory={required}>
            <InputSelect
                options={coatingColourOptions}
                testId="coating-colour-select"
                placeholder={t('uiDomain:materialSelection.placeholder')}
                value={dropDownValue}
                onSelect={(value) => {
                    setDropDownValue(value);
                    if (value !== OTHER) {
                        coatingColourSelected(value);
                    } else {
                        coatingColourSelected(undefined);
                    }
                }}
                validators={required ? [requiredFieldValidator] : undefined}
                forceValidation={forceValidation}
            />
            {allowOtherInput && dropDownValue === OTHER && (
                <InputTextfield
                    label={t('uiDomain:materialSelection.otherLabel')}
                    name="coatingColourText"
                    value={textValue}
                    onChange={(value) => {
                        setTextValue(value);
                        coatingColourSelected(value);
                    }}
                    validators={required ? [requiredFieldValidator, letterFieldValidator] : undefined}
                    forceValidation={forceValidation}
                />
            )}
        </FormItem>
    );
};
