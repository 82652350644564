import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    OfferListingStatus,
    createDateFromTimestamp,
    formatCurrency,
    OfferModel,
    OfferOriginalPriceModel,
    OfferView,
    PricingUnit,
} from '@steelbuy/ts-shared';
import { OfferStatusBadge } from '@steelbuy/ui-domain';
import { formatDateTime } from '@steelbuy/util';
import { ModalView, OfferModals } from './OfferModals';
import { OffersActionButtons } from './OffersActionButtons';
import { getTimeLeftText } from './OffersUtil';
import './Offers.scss';

const REMAINING_TIME_EXPIRED_TEXT = '-';

type OffersProps = {
    offers: OfferModel[];
    status: OfferListingStatus;
    view: OfferView;
    originalPrice: OfferOriginalPriceModel;
    negotiationId: string;
    onStatusChange: (message?: string, errorMessage?: string) => void;
    pricingUnit: PricingUnit;
    expiresAt: string;
    weightOrItems: number;
    taxPercent: number;
    minimumPrice: number;
};

const OfferAndCounterCell = ({
    isFirstRow,
    requestedBy,
    createdAt,
    price,
}: {
    isFirstRow: boolean;
    requestedBy: string;
    createdAt: string;
    price: string;
}) => (
    <div
        className={classNames('offers__grid__body offers__grid__body__col1', {
            offers__grid__body__row1: isFirstRow,
        })}
    >
        {/* this div is only displayed on md and lower screen size */}
        <div className="offers__grid__body__col1__requested-by">
            <span className="offers__grid__body__col1__requested-by__created-at">{createdAt}</span>
            <span>{requestedBy}</span>
        </div>
        <span
            className={classNames({
                offers__grid__body__col1__circleIcon: isFirstRow,
            })}
        >
            {price}
        </span>
    </div>
);

export const Offers = ({
    offers,
    status,
    view,
    originalPrice,
    negotiationId,
    onStatusChange,
    pricingUnit,
    weightOrItems,
    taxPercent,
    expiresAt,
    minimumPrice,
}: OffersProps) => {
    const { t } = useTranslation(['translation', 'domainModel']);
    const [activeModal, setActiveModal] = useState<ModalView | null>(null);

    const [timeLeft, setTimeLeft] = useState<string>(
        offers.length > 0 ? getTimeLeftText(expiresAt, t) : REMAINING_TIME_EXPIRED_TEXT
    );

    const showWarningIcon =
        (view === OfferView.SELLER && status === OfferListingStatus.AWAITING_SELLER) ||
        (view === OfferView.BUYER && status === OfferListingStatus.AWAITING_BUYER);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeLeft(getTimeLeftText(expiresAt, t));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const originalPriceDate = formatDateTime(navigator.language, createDateFromTimestamp(originalPrice.date));

    const originalPriceText = t(`domainModel:offers.originalPrice`);

    const showRemainsTime =
        status === OfferListingStatus.AWAITING_SELLER ||
        status === OfferListingStatus.ACCEPTED ||
        status === OfferListingStatus.AWAITING_BUYER;

    return (
        <div className="offers">
            <div className="offers__header">
                <div className="offers__header__time-left">
                    <span className="offers__header__time-left__label">
                        {t('translation:application.offers.timeLeft')}
                    </span>
                    <span className="offers__header__time-left__value">
                        {showRemainsTime ? timeLeft : REMAINING_TIME_EXPIRED_TEXT}
                    </span>
                </div>
                <div className="offers__header__status">
                    <span className="offers__header__status__label">{t('domainModel:offers.statusLabel')}</span>
                    <OfferStatusBadge status={status} showWarningIcon={showWarningIcon} offerView={view} />
                </div>
            </div>
            <div className="offers__grid">
                <div className="offers__grid__header offers__grid__header__col1">
                    {t('translation:application.offers.tableHeadings.offersAndCounterOffers')}
                </div>
                <div className="offers__grid__header offers__grid__header__col2">
                    {t('translation:application.offers.tableHeadings.date')}
                </div>
                <div className="offers__grid__header offers__grid__header__col3">
                    {t('translation:application.offers.tableHeadings.requestBy')}
                </div>
                <div className="offers__grid__header offers__grid__header__col4">
                    {t('translation:application.offers.tableHeadings.action')}
                </div>
                {offers
                    .slice()
                    .reverse()
                    .map(({ price, createdAt, requestedBy }, index) => {
                        const formattedCreatedAt = formatDateTime(
                            navigator.language,
                            createDateFromTimestamp(createdAt)
                        );
                        const requestedByText = t(`domainModel:offers.requestedBy.${requestedBy}`);
                        return (
                            <Fragment key={createdAt.value}>
                                <OfferAndCounterCell
                                    isFirstRow={index === 0}
                                    requestedBy={requestedByText}
                                    createdAt={formattedCreatedAt}
                                    price={formatCurrency(
                                        navigator.language,
                                        price.priceIncludingDelivery.value,
                                        price.currencyCode
                                    )}
                                />
                                <div
                                    className={classNames('offers__grid__body offers__grid__body__col2', {
                                        offers__grid__body__row1: index === 0,
                                    })}
                                >
                                    {formattedCreatedAt}
                                </div>
                                <div
                                    className={classNames('offers__grid__body offers__grid__body__col3', {
                                        offers__grid__body__row1: index === 0,
                                    })}
                                >
                                    {requestedByText}
                                </div>
                                <div
                                    className={classNames('offers__grid__body offers__grid__body__col4', {
                                        offers__grid__body__row1: index === 0,
                                    })}
                                >
                                    {index === 0 ? (
                                        <OffersActionButtons
                                            displayInGrid
                                            setActiveModal={setActiveModal}
                                            view={view}
                                            status={status}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </Fragment>
                        );
                    })}
                <OfferAndCounterCell
                    isFirstRow={false}
                    requestedBy={originalPriceText}
                    createdAt={originalPriceDate}
                    price={formatCurrency(
                        navigator.language,
                        originalPrice.price.priceIncludingDelivery.value,
                        originalPrice.price.currencyCode
                    )}
                />
                <div className={classNames('offers__grid__body offers__grid__body__col2')}>{originalPriceDate}</div>
                <div className={classNames('offers__grid__body offers__grid__body__col3')}>{originalPriceText}</div>
            </div>
            {offers.length > 4 && (
                <div className="offers__show-all">{t('translation:application.offers.showAllText')}</div>
            )}
            <OffersActionButtons setActiveModal={setActiveModal} view={view} status={status} />

            <OfferModals
                activeModal={activeModal}
                onModalClose={() => setActiveModal(null)}
                negotiationId={negotiationId}
                onStatusChange={onStatusChange}
                originalPrice={originalPrice.price.priceIncludingDelivery.value}
                latestOfferPrice={offers[offers.length - 1].price.priceIncludingDelivery.value}
                currencyCode={originalPrice.price.currencyCode}
                pricingUnit={pricingUnit}
                weightOrItems={weightOrItems}
                expiresAt={expiresAt}
                view={view}
                taxPercent={taxPercent}
                minimumPrice={minimumPrice}
            />
        </div>
    );
};
