import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '@steelbuy/data-access';
import { Feature, getMillFinishes, getPolishes, getProducts, MillFinish, Polish, Product } from '@steelbuy/ts-shared';
import {
    ListingSkuInput,
    MaterialAgeSelection,
    MaterialCoatingSelection,
    MaterialFinishSelection,
    MaterialGenericSelection,
    MaterialGradeSelection,
    MaterialOriginSelection,
    MaterialProductDefinitionSelection,
    MaterialProductSelection,
    MaterialProductTypeSelection,
    MaterialQualitySelection,
    MaterialShapeSelection,
    MaterialSpecificationSelection,
    MaterialSurfaceSelection,
    MaterialTemperSelection,
    MaterialTypeSelection,
} from '@steelbuy/ui-domain';
import { MarketingBannerAdvantages, Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { StepProps } from './StepProps';
import { constants } from '../../../constants';
import { FormLayout } from '../../../views/layouts/form-layout/FormLayout';
import { useCreateListingContext } from '../CreateListingContext';
import { StepVisitation, useCreateListingStep } from '../CreateListingHooks';

import './MaterialStep.scss';

export const MaterialStep = ({ stepId }: StepProps) => {
    const { t } = useTranslation('translation');
    const createListingContext = useCreateListingContext();
    const { materialStepData } = createListingContext;
    const { updateMaterialStepData, updateFileStatus, picturesStatus, certificatesStatus, addFile } =
        createListingContext;
    const { isFeatureSupported } = useFeatureFlag();

    const { formRef, stepVisitation } = useCreateListingStep(stepId);

    const handleNotifyUs = () => {
        const subject = t('application.materialStep.missingProductsEmailSubject');
        window.location.href = `mailto:support@steel-buy.com?subject=${encodeURIComponent(subject)}`;
    };

    const stepVisited = stepVisitation === StepVisitation.VISITED;

    const availableProducts = getProducts(materialStepData, isFeatureSupported(Feature.PACKAGES));

    let disabledProducts;

    if (createListingContext?.draftId) {
        disabledProducts =
            materialStepData.product === Product.PACKAGE
                ? availableProducts.filter((product) => product !== materialStepData.product)
                : [Product.PACKAGE];
    }

    return (
        <div className="material-step">
            <FormLayout>
                <form ref={formRef}>
                    <ListingSkuInput
                        sku={materialStepData.sku}
                        skuSelected={(sku) => updateMaterialStepData({ sku })}
                        required
                        forceValidation={stepVisited}
                    />
                    <MaterialTypeSelection
                        materialType={materialStepData.materialType}
                        materialTypeSelected={(materialType) => updateMaterialStepData({ materialType })}
                        isStainlessFeatureEnabled
                    />
                    <MaterialShapeSelection
                        material={materialStepData}
                        shapeSelected={(shape) => updateMaterialStepData({ shape })}
                    />

                    <div className="material-step__material-product-selection">
                        <MaterialProductSelection
                            material={materialStepData}
                            productSelected={(product) => updateMaterialStepData({ product })}
                            isPackagesSupported={isFeatureSupported(Feature.PACKAGES)}
                            disabledProducts={disabledProducts}
                        />
                        <Notification
                            level={NotificationLevel.INFO}
                            message={t('application.materialStep.missingProductsNotification')}
                            labelActionButton={t('application.materialStep.missingProductsLinkLabel')}
                            onClickActionButton={handleNotifyUs}
                        />
                    </div>

                    <MaterialProductDefinitionSelection
                        material={materialStepData}
                        definitionSelected={(definition) => updateMaterialStepData({ definition })}
                    />

                    <MaterialProductTypeSelection
                        material={materialStepData}
                        typeSelected={(plateType) => updateMaterialStepData({ plateType })}
                        isPrime={materialStepData.prime}
                    />

                    <MaterialGradeSelection
                        material={materialStepData}
                        gradeSelected={(grade) => updateMaterialStepData({ grade })}
                        required
                        forceValidation={stepVisited}
                    />

                    <MaterialTemperSelection
                        material={materialStepData}
                        temperSelected={(temper) => updateMaterialStepData({ temper })}
                        required
                        forceValidation={stepVisited}
                    />

                    <MaterialSpecificationSelection
                        material={materialStepData}
                        specificationSelected={(specification) => updateMaterialStepData({ specification })}
                        required
                        forceValidation={stepVisited}
                    />

                    <MaterialSurfaceSelection
                        material={materialStepData}
                        surfaceSelected={(surface) => updateMaterialStepData({ surface })}
                        required
                        forceValidation={stepVisited}
                    />

                    <MaterialFinishSelection
                        material={materialStepData}
                        finishSelected={(finish) => updateMaterialStepData({ finish })}
                        required
                        forceValidation={stepVisited}
                    />

                    <MaterialGenericSelection<MillFinish>
                        material={materialStepData}
                        onSelected={(millFinish) => updateMaterialStepData({ millFinish })}
                        getOptions={getMillFinishes}
                        field="millFinish"
                        required
                    />

                    <MaterialGenericSelection<Polish>
                        material={materialStepData}
                        onSelected={(polish) => updateMaterialStepData({ polish })}
                        getOptions={getPolishes}
                        field="polish"
                        required
                    />

                    <MaterialCoatingSelection
                        material={materialStepData}
                        coatingSelected={(coating) => updateMaterialStepData({ coating })}
                        coatingThicknessSelected={(coatingThicknessValue) => {
                            updateMaterialStepData({ coatingThicknessValue });
                        }}
                        coatingColourSelected={(coatingColour) => {
                            updateMaterialStepData({ coatingColour });
                        }}
                        coatingTypeSelected={(coatingType) => {
                            updateMaterialStepData({ coatingType });
                        }}
                        coatingCoverageSelected={(coatingCoverage) => {
                            updateMaterialStepData({ coatingCoverage });
                        }}
                        required
                        forceValidation={stepVisited}
                    />

                    <MaterialAgeSelection
                        age={materialStepData.age}
                        ageSelected={(age) => updateMaterialStepData({ age })}
                        required
                        forceValidation={stepVisited}
                        material={materialStepData}
                    />

                    <MaterialOriginSelection
                        origins={materialStepData.origins}
                        originsSelected={(origins) => updateMaterialStepData({ origins })}
                        required
                        forceValidation={stepVisited}
                        material={materialStepData}
                    />
                    <div className="material-step__material-quality-selection">
                        <MaterialQualitySelection
                            prime={materialStepData.prime}
                            primeStatusSelected={(prime) => updateMaterialStepData({ prime })}
                            description={materialStepData.description}
                            descriptionChanged={(description) => updateMaterialStepData({ description })}
                            testCertificates={materialStepData.testCertificate}
                            addFile={addFile}
                            pictures={materialStepData.picture}
                            required
                            forceValidation={stepVisited}
                            materialType={materialStepData.materialType}
                            plateType={materialStepData.plateType}
                            multiple={materialStepData.product === Product.PACKAGE}
                            picturesStatus={picturesStatus}
                            certificatesStatus={certificatesStatus}
                            updateFileStatus={updateFileStatus}
                            apiBaseUrl={constants.apiBaseUrl}
                        />
                        <MarketingBannerAdvantages
                            header={t('application.materialStep.anonymityBannerHeader')}
                            text={t('application.materialStep.anonymityBannerText')}
                        />
                    </div>
                </form>
            </FormLayout>
        </div>
    );
};
