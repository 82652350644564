import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingBuyerModel, ListingSellerDraftModel, ListingSellerModel, SearchFormData } from '@steelbuy/ts-shared';
import { useMaterialProperties } from '../material/Material';
import { TeaserHeader } from '../teaser-header/TeaserHeader';
import { isDefinedAndDiffers } from '../teaser-header/TeaserHeader.util';

type MaterialAttribute = {
    property: string;
    label: string;
    differsFromSearch: boolean;
};

export function calculatePropertyHighlight(
    listing: ListingBuyerModel | ListingSellerModel | ListingSellerDraftModel,
    searchData?: SearchFormData
) {
    let propertyHighlight: Record<string, boolean> = {};

    if (listing.material) {
        propertyHighlight = {
            ...propertyHighlight,
            surface:
                'surface' in listing.material && isDefinedAndDiffers(searchData?.surface, listing.material.surface),
            finish: 'finish' in listing.material && isDefinedAndDiffers(searchData?.finish, listing.material.finish),
            specification:
                'specification' in listing.material &&
                isDefinedAndDiffers(searchData?.specification, listing.material.specification),
            coating:
                'coating' in listing.material && isDefinedAndDiffers(searchData?.coating, listing.material.coating),
            grade: 'grade' in listing.material && isDefinedAndDiffers(searchData?.grade, listing.material.grade),
            definition:
                'definition' in listing.material &&
                isDefinedAndDiffers(searchData?.definition, listing.material.definition),
            plateType:
                'plateType' in listing.material &&
                isDefinedAndDiffers(searchData?.plateType, listing.material.plateType),
            product:
                'product' in listing.material && isDefinedAndDiffers(searchData?.product, listing.material.product),
            materialType:
                'materialType' in listing.material &&
                isDefinedAndDiffers(searchData?.materialType, listing.material.materialType),
        };
    }

    if (listing.materialDimensions) {
        propertyHighlight = {
            ...propertyHighlight,
            thickness:
                'thickness' in listing.materialDimensions &&
                isDefinedAndDiffers(searchData?.thickness, listing.materialDimensions.thickness?.toString()),
            width: isDefinedAndDiffers(searchData?.width, listing.materialDimensions.width?.toString()),
            length:
                'length' in listing.materialDimensions &&
                isDefinedAndDiffers(searchData?.length, listing.materialDimensions.length?.toString()),
        };
    }

    return propertyHighlight;
}

const ListingProperty = ({
    propertyDisplayValue,
    highlighted,
}: {
    propertyDisplayValue: string | number;
    highlighted?: boolean;
}) => {
    const className = highlighted ? 'listing-teaser__header__highlighted' : '';
    return <span className={className}>{propertyDisplayValue}</span>;
};

export type ListingTeaserHeaderProps = {
    listing: ListingBuyerModel | ListingSellerModel | ListingSellerDraftModel;
    searchData?: SearchFormData;
    isNew?: boolean;
};

export const ListingTeaserHeader = ({ isNew, listing, searchData }: ListingTeaserHeaderProps) => {
    const { t } = useTranslation('domainModel');
    const headerProperties = useMaterialProperties(listing.material, ['materialType']);
    const { material } = listing;
    const dimensions = listing.materialDimensions;

    const highlights = calculatePropertyHighlight(listing, searchData);

    const dimensionAttributes: Array<MaterialAttribute> = [];

    if (dimensions) {
        if (dimensions.thickness) {
            dimensionAttributes.push({
                property: 'thickness',
                label: t('material.dimensions.units.millimetersWithValue', { value: dimensions.thickness }),
                differsFromSearch: highlights['thickness'] === true,
            });
        }

        if (dimensions.width) {
            dimensionAttributes.push({
                property: 'width',
                label: t('material.dimensions.units.millimetersWithValue', { value: dimensions.width }),
                differsFromSearch: highlights['width'] === true,
            });
        }

        if ('length' in dimensions && dimensions.length) {
            dimensionAttributes.push({
                property: 'length',
                label: t('material.dimensions.units.millimetersWithValue', { value: dimensions.length }),
                differsFromSearch: highlights['length'] === true,
            });
        }
    }

    const renderThicknessNorm = () => {
        if (!(material && 'tolerance' in material && material.tolerance)) {
            return null;
        }

        return (
            <>
                {dimensionAttributes.length > 0 && ', '}
                <ListingProperty
                    propertyDisplayValue={t(`material.tolerance.value.${material.tolerance}`)}
                    highlighted={false}
                />
            </>
        );
    };

    return (
        <TeaserHeader headerProperties={headerProperties} highlights={highlights} isNew={isNew} listing={listing}>
            <div className="listing-teaser__header__text__attributes__dimensions">
                {dimensionAttributes.map((attribute, index) => (
                    <Fragment key={`dimension-attribute-${attribute.property}`}>
                        {index > 0 && ' x '}

                        <ListingProperty
                            propertyDisplayValue={attribute.label}
                            highlighted={attribute.differsFromSearch}
                        />
                    </Fragment>
                ))}

                {renderThicknessNorm()}
            </div>
        </TeaserHeader>
    );
};
