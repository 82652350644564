import { MaterialType, PackageBuyerModel, PackageBuyerModelConverter, ApiModel } from '@steelbuy/ts-shared';
import cacheConfig from '../../utils/cacheOptions';
import { usePaginatedQuery } from '../../utils/usePaginatedQuery';

interface SearchPackageOptions {
    searchData: { materialType: MaterialType };
}
export const SEARCH_PACKAGE_KEY = 'packaging/search';

const modelConverter = new PackageBuyerModelConverter();
const searchListingMapFn = (data: PackageBuyerModel) =>
    modelConverter.toViewModel(data as unknown as ApiModel<PackageBuyerModel>);

export const useSearchPackages = (options: SearchPackageOptions) =>
    usePaginatedQuery<PackageBuyerModel>({
        url: '/api/universal/packaging/search',
        requestOptions: {
            filter: options.searchData,
        },
        mapFn: searchListingMapFn,
        queryKey: [SEARCH_PACKAGE_KEY, JSON.stringify(options)],
        queryOptions: {
            staleTime: cacheConfig['5_MINUTES'],
        },
    });
