import { useTranslation } from 'react-i18next';
import {
    checkListingAlreadyInCart,
    useIsFeatureSupported,
    useCartDetails,
    useUserSelfDetails,
    userHasRole,
} from '@steelbuy/data-access';
import {
    Feature,
    ListingBuyerModel,
    OfferListingStatus,
    OfferView,
    OrderStatus,
    PackageBuyerModel,
    SearchFormData,
    UserRole,
    getPricingUnit,
    isStackableProduct,
    MonetaryAmount,
    createDateFromTimestamp,
} from '@steelbuy/ts-shared';

import {
    BadgeSecondary,
    BadgeVariant,
    ButtonSize,
    ButtonTertiary,
    ButtonTertiaryOnDarkS,
    ButtonTertiaryOnLightM,
    IconIdentifier,
    LoadingStatus,
} from '@steelbuy/ui-primitive';
import { DaynameFormat, formatDayname, formatTime } from '@steelbuy/util';
import { ListingTeaser } from './ListingTeaser';

import { ListingTeaserContainer } from './ListingTeaserContainer';
import { ListingTeaserHeader } from './ListingTeaserHeader';
import { ListingTeaserPrice } from './ListingTeaserPrice';
import { ListingTeaserWeightAndItems } from './ListingTeaserWeightAndItems';
import { useNumberOfItems } from '../number-of-items-label/NumberOfItemsLabel';
import { getBadgeVariant } from '../order-status-badge-variant/OrderStatusBadgeVariant';
import { PackageTeaserHeader } from '../package-teaser/PackageTeaserHeader';
import './ListingTeaser.scss';

type ListingProps = { isPackage?: false; listing: ListingBuyerModel } | { isPackage: true; listing: PackageBuyerModel };

export type ListingBuyerTeaserProps = ListingProps & {
    searchData?: SearchFormData;
    isNew?: boolean;
    offerStatus?: OfferListingStatus;
    numberOffers?: number;
    numberActiveOffers?: number;
    offerPrice?: MonetaryAmount;
    referenceId?: string;
    offerValue?: number;
    minActiveOffersForBadge?: number;
    onBuyClick?: () => void;
    onClickCartButton?: () => void;
    isLoading?: boolean;
    isCart?: boolean;
    onRemoveClick?: () => void;
    isCartCheckout?: boolean;
    isBuying?: boolean;
    orderStatus?: OrderStatus;
};

export const ListingBuyerTeaser = ({
    isNew,
    listing,
    searchData,
    offerStatus,
    numberOffers,
    numberActiveOffers,
    offerPrice,
    referenceId,
    isPackage,
    offerValue,
    minActiveOffersForBadge,
    onBuyClick,
    isCart = false,
    onClickCartButton,
    isLoading = false,
    onRemoveClick,
    isCartCheckout = false,
    isBuying,
    orderStatus,
}: ListingBuyerTeaserProps) => {
    const { t } = useTranslation(['uiDomain', 'domainModel']);
    const numberOfItems = useNumberOfItems(
        (listing as ListingBuyerModel).material.product,
        (listing as ListingBuyerModel).numberOfItems
    );
    const { data } = useCartDetails({ queryOptions: { enabled: false } });

    const { data: userModel } = useUserSelfDetails();
    const hasBuyerRole = userHasRole(userModel || null, [UserRole.BUYER]);
    const showCart = useIsFeatureSupported(Feature.CART) && hasBuyerRole;

    const listingInCart = data && checkListingAlreadyInCart(data, listing.id);

    const noOfItemsText =
        !isPackage && isStackableProduct(listing.material.product)
            ? { label: listing.material.product, value: listing.numberOfItems ?? 0 }
            : undefined;

    const expirationDate = createDateFromTimestamp(listing.expiresAt);

    const pricingUnit = getPricingUnit(!isPackage ? listing.material : { materialType: listing.material });

    const originTranslations = Array.isArray(listing.origin)
        ? listing.origin.map((text) => t(`domainModel:material.origin.value.${text}`)).join(', ')
        : t(`domainModel:material.origin.value.${listing.origin}`);

    const ageTranslation = isPackage ? undefined : t(`domainModel:material.age.value.${listing.age}`);

    const deliveryPromise = t(
        `domainModel:deliveryPromise.${!isPackage ? listing.deliveryPromise : listing.deliveryTimeframe}`
    );

    const isOfferInProgress =
        isCart &&
        !isPackage &&
        listing.negotiations?.some(
            (negotiation) =>
                negotiation.status === OfferListingStatus.AWAITING_BUYER ||
                negotiation.status === OfferListingStatus.AWAITING_SELLER
        );

    const listingOfferTotal =
        (!isPackage && listing.offerPricing?.totalExcludingVat) || listing.pricing.totalExcludingVat;

    const cartItemTotalExclVat = isPackage ? listing.pricing.totalExcludingVat : listingOfferTotal;

    return (
        <ListingTeaser threeColumns={isCart} isCartCheckout={isCartCheckout}>
            {isPackage ? (
                <PackageTeaserHeader packages={listing} isNew={isNew} />
            ) : (
                <ListingTeaserHeader listing={listing} searchData={searchData} isNew={isNew} />
            )}
            <ListingTeaserContainer
                isPrime={listing.prime}
                expiryTime={
                    !orderStatus
                        ? `Exp: ${formatDayname(
                              navigator.language,
                              expirationDate,
                              undefined,
                              DaynameFormat.SHORT
                          )} ${formatTime(navigator.language, expirationDate)}`
                        : undefined
                }
                deliveryTime={!orderStatus ? deliveryPromise : undefined}
                offerStatus={offerStatus}
                statusLabel={orderStatus ? t(`orderStatus.${orderStatus}`) : undefined}
                statusBadgeVariant={orderStatus ? getBadgeVariant(orderStatus) : undefined}
                numberOffers={numberOffers}
                numberActiveOffers={numberActiveOffers}
                referenceId={referenceId}
                offerView={OfferView.BUYER}
                minActiveOffersForBadge={minActiveOffersForBadge}
                countryCode={listing.country}
                cartValues={
                    isCart
                        ? {
                              weight: { value: listing.weight, tradeUnit: listing.tradeUnit },
                              noOfItems: noOfItemsText,
                              origin: originTranslations,
                              age: ageTranslation,
                          }
                        : undefined
                }
            />
            {!isCart && (
                <ListingTeaserWeightAndItems
                    weight={listing.weight}
                    unit={listing.tradeUnit}
                    numberOfItems={numberOfItems}
                />
            )}
            {!isCartCheckout && (
                <ListingTeaserPrice
                    value={offerPrice || listing.pricing.priceIncludingDelivery}
                    currencyCode={listing.pricing.currencyCode}
                    unit={pricingUnit}
                    isNew={isNew}
                    isMyOffer={!!offerStatus}
                    offerValue={offerValue}
                    showTotal={isCart ? cartItemTotalExclVat : undefined}
                    oldPricingTotal={listing.oldPricing?.totalExcludingVat}
                    isCart
                >
                    {isOfferInProgress && (
                        <div className="cart-offer-badge">
                            <BadgeSecondary
                                icon={IconIdentifier.OFFER}
                                label={t('uiDomain:listingTeaserOfferBadge.offerInProgress')}
                                variant={BadgeVariant.WARNING}
                            />
                        </div>
                    )}
                    {isCart && !orderStatus ? (
                        <div className="cart-remove-button">
                            <ButtonTertiary
                                label={t('uiDomain:common.remove')}
                                size={ButtonSize.S}
                                onClick={(e) => {
                                    e.preventDefault();
                                    onRemoveClick?.();
                                }}
                                loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                            />
                        </div>
                    ) : (
                        <div className="listing-teaser__buttons">
                            {showCart && !isCart && (!!onClickCartButton || !!onBuyClick) && (
                                <ButtonTertiaryOnLightM
                                    label={
                                        listingInCart
                                            ? t('uiDomain:commonList.addedToCart')
                                            : t(`uiDomain:commonList.addToCart`)
                                    }
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onClickCartButton?.();
                                    }}
                                    disabled={listingInCart}
                                    loadingStatus={isLoading ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                                />
                            )}
                            {onBuyClick && hasBuyerRole && (
                                <ButtonTertiaryOnDarkS
                                    label={t(`uiDomain:commonList.buyNow`)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onBuyClick?.();
                                    }}
                                    disabled={isBuying}
                                    loadingStatus={isBuying ? LoadingStatus.PENDING : LoadingStatus.IDLE}
                                />
                            )}
                        </div>
                    )}
                </ListingTeaserPrice>
            )}
        </ListingTeaser>
    );
};
