import { ReactNode } from 'react';
import { AnyMaterialModel } from '@steelbuy/ts-shared';
import { useMaterialProperties } from '@steelbuy/ui-domain';
import { PageHeader, PageHeaderProps } from '../page-header/PageHeader';

type PackageProps = {
    packageTitle: string;
};

type MaterialPageHeaderProps = {
    material?: AnyMaterialModel;
    children?: ReactNode;
    packageModel?: PackageProps;
} & Omit<PageHeaderProps, 'pageTitle'>;

export const MaterialPageHeader = (props: MaterialPageHeaderProps) => {
    const { material, children, packageModel, previousPageTitle, onBackClick } = props;

    const materialProperties = useMaterialProperties(material);
    const pageTitle = material
        ? materialProperties.map((property) => property.label).join(', ')
        : packageModel?.packageTitle;

    if (previousPageTitle && onBackClick) {
        return (
            <PageHeader pageTitle={pageTitle ?? ''} previousPageTitle={previousPageTitle} onBackClick={onBackClick}>
                {children}
            </PageHeader>
        );
    }
    return <PageHeader pageTitle={pageTitle ?? ''}>{children}</PageHeader>;
};
