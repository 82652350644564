import { CartEntryModel } from '@steelbuy/ts-shared';
import { Notification, NotificationLevel } from '@steelbuy/ui-primitive';
import { ItemsRenewedList } from './ItemsRenewedList';

export const ItemsRenewedNotification = ({
    cartEntries,
    handleClose,
}: {
    cartEntries?: CartEntryModel[];
    handleClose: () => void;
}) => {
    const renewedListings = cartEntries?.filter((entry) => entry.product.oldPricing);

    if (!renewedListings?.length) return null;

    return (
        <Notification
            level={NotificationLevel.WARNING}
            message=""
            className="my-cart-list__updated-notification"
            closeButton
            onClose={handleClose}
            Component={<ItemsRenewedList cartEntries={renewedListings} />}
        />
    );
};
