import { useTranslation } from 'react-i18next';
import { useCreatePackageDraft, useUpdatePackageDraft } from '@steelbuy/data-access';
import { PackageSellerDraftModel } from '@steelbuy/ts-shared';
import { ButtonCallToAction, ButtonPrimary, ButtonTertiaryOnLightM, LoadingStatus } from '@steelbuy/ui-primitive';
import { useCreateListingContext } from './CreateListingContext';
import { contextToPackageDraft } from './CreateListingContextMapper';

type SavePackageDraftButtonProps = {
    onSaveSuccess: (draft: PackageSellerDraftModel, newEntityCreated: boolean, isPackage?: boolean) => void;
    onSaveFail?: () => void;
    ButtonComponent: typeof ButtonTertiaryOnLightM | typeof ButtonPrimary | typeof ButtonCallToAction;
};

export const SavePackageDraftButton = ({ ButtonComponent, onSaveFail, onSaveSuccess }: SavePackageDraftButtonProps) => {
    const { t } = useTranslation('translation');
    const createListingContext = useCreateListingContext();
    const createPackageDraftMutation = useCreatePackageDraft();
    const updatePackageDraftMutation = useUpdatePackageDraft();

    const handleSaveDraft = () => {
        const draftData = contextToPackageDraft(createListingContext);

        const mutateOptions = {
            onSuccess: (packageDraftResponse: PackageSellerDraftModel) => {
                createListingContext.savedDraft(packageDraftResponse);

                if (createListingContext.draftId) {
                    onSaveSuccess(packageDraftResponse, false);
                } else {
                    onSaveSuccess({ ...packageDraftResponse, id: packageDraftResponse.id }, true, true);
                }
            },
            onError: () => {
                onSaveFail?.();
            },
        };
        if (createListingContext.draftId) {
            updatePackageDraftMutation.mutate({ ...draftData, id: createListingContext.draftId }, mutateOptions);
        } else {
            createPackageDraftMutation.mutate(draftData, mutateOptions);
        }
    };

    return (
        <ButtonComponent
            label={t('application.createListingSaveDraftButton.label')}
            onClick={handleSaveDraft}
            loadingStatus={
                createPackageDraftMutation.isLoading || updatePackageDraftMutation.isLoading
                    ? LoadingStatus.PENDING
                    : LoadingStatus.IDLE
            }
        />
    );
};
