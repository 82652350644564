import { useTranslation } from 'react-i18next';
import { MaterialProductWithCount } from '@steelbuy/ts-shared';

export type ListingTeaserCartHeaderProps = {
    buyerOrderNumber: string;
    materialProductWithCount: MaterialProductWithCount[];
};

const PRODUCT_ENTRY_COUNT = 3;
export const ListingTeaserCartHeader = ({
    buyerOrderNumber,
    materialProductWithCount,
}: ListingTeaserCartHeaderProps) => {
    const { t } = useTranslation('domainModel');

    return (
        <div className="listing-teaser__header">
            <div className="listing-teaser__header__text">
                <div className="listing-teaser__header__text__head">{buyerOrderNumber}</div>
                <div className="listing-teaser__header__text__attributes__wrapper">
                    {materialProductWithCount.slice(0, PRODUCT_ENTRY_COUNT).map((item, index, array) => {
                        const productLabel = t(`material.product.value.${item.product}`);
                        const materialTypeLabel = t(`material.materialType.value.${item.materialType}`);
                        const countLabel = `(${item.count})`;

                        const showEllipsis =
                            index === array.length - 1 && materialProductWithCount.length > PRODUCT_ENTRY_COUNT;

                        return (
                            <span
                                className="listing-teaser__header__text__attributes"
                                key={`${item.product}-${item.materialType}`}
                            >
                                {productLabel}, {materialTypeLabel} {countLabel}
                                {showEllipsis && '...'}
                            </span>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
