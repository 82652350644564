import { ListingSellerCrudApiClient } from '@steelbuy/api-integration';
import { JsonWebToken } from '@steelbuy/auth';
import { ListingSellerModel, Nullable } from '@steelbuy/ts-shared';
import {
    createCrudEntitySlice,
    createSelectCreated,
    createSelectEntity,
    createSelectServiceEntity,
} from '../../util/store/crud/EntitySlice';
import { EntityStore } from '../../util/store/crud/EntityStore';
import { EntityStoreAccessor } from '../../util/store/crud/EntityStoreAccessor';

import {
    createCreateThunk,
    createDeleteThunk,
    createEntityServiceThunk,
    createFetchEntityThunk,
    createMutateThunk,
} from '../../util/store/crud/Thunks';

// Basic definition
export const listingSellerEntityStoreName = 'listing-seller-entity';
export type ListingSellerEntityStore = {
    [listingSellerEntityStoreName]: EntityStore<ListingSellerModel>;
};

// Implementation of the async actions
// It is required to declare them before declaring the slice because the block constant has to be defined before using it as the
const createApiClient = (apiBaseUrl: string, jsonWebToken: Nullable<JsonWebToken>) =>
    new ListingSellerCrudApiClient().init(apiBaseUrl, jsonWebToken);
const fetch = createFetchEntityThunk<ListingSellerModel, ListingSellerEntityStore, ListingSellerCrudApiClient>(
    listingSellerEntityStoreName,
    createApiClient
);
const mutateEntity = createMutateThunk<ListingSellerModel, ListingSellerCrudApiClient>(
    listingSellerEntityStoreName,
    createApiClient
);
const createEntity = createCreateThunk<ListingSellerModel, ListingSellerCrudApiClient>(
    listingSellerEntityStoreName,
    createApiClient
);
const deleteEntity = createDeleteThunk<ListingSellerModel, ListingSellerCrudApiClient>(
    listingSellerEntityStoreName,
    createApiClient
);
const entityService = createEntityServiceThunk<ListingSellerModel, ListingSellerCrudApiClient>(
    listingSellerEntityStoreName,
    createApiClient
);

// Slice definition
export const listingSellerEntitySlice = createCrudEntitySlice<ListingSellerModel>(
    listingSellerEntityStoreName,
    fetch,
    mutateEntity,
    createEntity,
    deleteEntity,
    entityService
);
const { resetActionStatus, resetFetchStatus } = listingSellerEntitySlice.actions;

// Selector functions to be used with useSelector or useTypedSelector to read from the state
const select = createSelectEntity<ListingSellerModel, ListingSellerEntityStore>(listingSellerEntityStoreName);
const selectCreated = createSelectCreated<ListingSellerModel, ListingSellerEntityStore>(listingSellerEntityStoreName);
const selectServiceEntity = createSelectServiceEntity<ListingSellerModel, ListingSellerEntityStore>(
    listingSellerEntityStoreName
);

// Export the store accessors to be used by the data provider value
export const listingSellerEntityStoreAccessors = {
    fetch,
    resetFetchStatus,
    mutateEntity,
    createEntity,
    deleteEntity,
    resetActionStatus,
    select,
    selectCreated,
    entityService,
    selectServiceEntity,
} as EntityStoreAccessor<ListingSellerModel, ListingSellerEntityStore>;
