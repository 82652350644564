import { JsonWebToken } from '@steelbuy/auth';
import { DownloadFile, Url, Potentially } from '@steelbuy/ts-shared';
import { JsonRestConnector } from './network/JsonRestConnector';

export async function exchangeDocumentIdForAccessibleLink(
    baseUrl: string,
    documentId: string,
    jsonWebToken: Potentially<JsonWebToken>
) {
    const connector = new JsonRestConnector(jsonWebToken);
    const response = await connector.get(`${baseUrl}api/universal/documents/${documentId}/exchange`);
    return response.body as DownloadFile;
}

export const exchangeUrlForAccessibleLink = async (link: Url, jsonWebToken: Potentially<JsonWebToken>) =>
    (await new JsonRestConnector(jsonWebToken).get(link.value)).body as DownloadFile;
