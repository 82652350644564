import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useCreateRFQ } from '@steelbuy/data-access';
import {
    MaterialWithDimensions,
    getRFQFields,
    RFQField,
    rfqHeadings,
    RFQDetailsHeading,
    QuoteEntryDetail,
    RFQQuoteEntryMaterial,
} from '@steelbuy/ts-shared';
import { MarketingBannerSteelbuyPromise } from '@steelbuy/ui-domain';
import {
    ButtonCallToAction,
    ButtonTertiaryOnLightM,
    LoadingSpinnerOverlay,
    Notification,
    NotificationLevel,
    WizardBar,
    WizardBarItemStatus,
} from '@steelbuy/ui-primitive';
import { RoutePath } from '../../../router/Routes';
import { TableLayout } from '../../../views/layouts/table-layout/TableLayout';
import { CreateRFQUnsavedChangesModal } from '../../create-rfq-unsaved-changes/CreateRFQUnsavedChangesModal';
import LegalCheckboxes from '../../legal-checkboxes/LegalCheckboxes';
import { PageHeader } from '../../page-header/PageHeader';
import { CardContentRFQDetails } from '../../rfqs/card-content-rfq-details/CardContentRFQDetails';
import { CardContentAdditionalComments } from '../../rfqs/CardContentAdditionalComments';
import { CardContentDeliveryMonth } from '../../rfqs/CardContentDeliveryMonth';
import { CardContentOrigins } from '../../rfqs/CardContentOrigins';
import { CardContentQuoteDeadline } from '../../rfqs/CardContentQuoteDeadline';
import { RFQ_STEPS, getCreateRFQModel, getStepRoute, hasFormData } from '../createRFQUtil';
import { useRfqFormContext } from '../RfqFormContext';
import { Step2Type, isStep1WithDefinition, isStep1WithPlateType, isStep3 } from '../Schema';
import './CreateRFQSummary.scss';

const CURRENT_STEP_INDEX = 2;

const getRFQHeadings = (fields: RFQField[], materialsArray: MaterialWithDimensions[]) =>
    fields.reduce(
        (acc, curr) =>
            rfqHeadings[curr.field] !== undefined &&
            materialsArray.some((materials) => materials[curr.field] !== undefined)
                ? {
                      ...acc,
                      [curr.field]: { ...rfqHeadings[curr.field] },
                  }
                : acc,
        {} as RFQDetailsHeading
    );

export const CreateRFQSummary = () => {
    const { formData, draftId } = useRfqFormContext();
    const { t } = useTranslation(['translation', 'uiDomain']);
    const navigate = useNavigate();
    const [validity, setValidity] = useState(false);
    const [createError, setCreateError] = useState('');
    const createRFQMutation = useCreateRFQ();

    useEffect(() => {
        if (!hasFormData(formData)) {
            navigate(getStepRoute(1, draftId));
        }
    }, []);

    if (!isStep3(formData)) {
        return null;
    }

    const handleSubmit = () => {
        if (!formData) {
            setCreateError(t('application.createRFQSummary.createRFQFailed'));
            return;
        }
        let payload = getCreateRFQModel(formData);
        if (!payload) {
            setCreateError(t('application.createRFQSummary.createRFQFailed'));
            return;
        }
        if (draftId) {
            payload = { ...payload, draftId };
        }
        setCreateError('');
        createRFQMutation.mutate(payload, {
            onSuccess: ({ quoteReferenceNumber }) => {
                navigate(RoutePath.CREATE_RFQ_SUCCESS, { state: { quoteReferenceNumber } });
            },
            onError: () => setCreateError(t('application.createRFQSummary.createRFQFailed')),
        });
    };

    const { origin, delivery, deadline, comments } = formData;

    let quoteEntries: QuoteEntryDetail[] = [];
    if (isStep1WithPlateType(formData)) {
        quoteEntries = formData.plateType.map((plateType) => ({
            materialType: formData.materialType,
            product: formData.product,
            group: {
                label: 'plateType',
                value: plateType,
            },
            fieldHeadings: getRFQHeadings(
                getRFQFields({ ...formData, plateType }, t),
                (formData as Step2Type).materials[plateType]
            ),
            materials: formData.materials[plateType] as RFQQuoteEntryMaterial[],
        }));
    } else if (isStep1WithDefinition(formData)) {
        quoteEntries = formData.definition.map((definition) => ({
            materialType: formData.materialType,
            product: formData.product,
            group: {
                label: 'definition',
                value: definition,
            },
            fieldHeadings: getRFQHeadings(
                getRFQFields({ ...formData, definition }, t),
                (formData as Step2Type).materials[definition]
            ),
            materials: formData.materials[definition] as RFQQuoteEntryMaterial[],
        }));
    }

    return (
        <div className="create-rfq-summary">
            <PageHeader pageTitle={t('application.createRFQSummary.header')} />
            {createRFQMutation.isLoading && <LoadingSpinnerOverlay />}
            <div className="create-rfq-summary__wizard">
                <TableLayout>
                    <WizardBar
                        items={RFQ_STEPS.map((step, index) => ({
                            label: t(`application.createRFQSteps.labels.${step}`),
                            status: WizardBarItemStatus.SUCCESS,
                            selected: false,
                            onClick: () => {
                                navigate(getStepRoute(index + 1, draftId));
                            },
                        }))}
                        currentStepIndex={CURRENT_STEP_INDEX}
                    />
                </TableLayout>
            </div>
            <div className="create-rfq-summary__layout">
                {createError && (
                    <Notification
                        level={NotificationLevel.ERROR}
                        message={createError}
                        onClose={() => setCreateError('')}
                        closeButton
                        stayOpen
                    />
                )}
                <CardContentRFQDetails quoteEntries={quoteEntries} />
                <CardContentOrigins origin={origin} />
                <CardContentDeliveryMonth delivery={delivery} />
                <CardContentQuoteDeadline deadline={deadline} />
                {comments && (
                    <div className="create-rfq-summary__comments">
                        <CardContentAdditionalComments comments={comments} />
                    </div>
                )}
                <MarketingBannerSteelbuyPromise />

                <LegalCheckboxes setValidity={setValidity} />
                <div className="create-rfq-summary__layout__actionbar">
                    <ButtonTertiaryOnLightM
                        label={t('uiDomain:common.edit')}
                        onClick={() => navigate(getStepRoute(1, draftId))}
                    />
                    <ButtonCallToAction
                        disabled={!validity}
                        label={t('application.createRFQ.submitButtonLabel')}
                        onClick={handleSubmit}
                    />
                </div>
            </div>
            <CreateRFQUnsavedChangesModal
                onError={() => setCreateError(t('application.createDraftRFQ.createDraftRFQFailed'))}
            />
        </div>
    );
};
