import { ReactNode } from 'react';

import './FormItem.scss';

export type FormItemLayoutProps = {
    header?: string;
    unit?: string;
    description?: string;
    children: ReactNode;
    hideTextWrapper?: boolean;
    isMandatory?: boolean;
};

export const FormItem = (props: FormItemLayoutProps) => {
    const { header, description, hideTextWrapper, children, unit, isMandatory = false } = props;

    const renderHeader = () => {
        if (header === undefined) {
            return null;
        }
        return (
            <span className="form-item__text-wrapper__header">
                {header}
                {unit && <span className="form-item__text-wrapper__header__unit">{unit}</span>}
                {isMandatory && <span className="required-field"> *</span>}
            </span>
        );
    };

    const renderDescription = () => {
        if (description === undefined) {
            return null;
        }
        return <span className="form-item__text-wrapper__description">{description}</span>;
    };

    return (
        <section className="form-item">
            {hideTextWrapper !== true && (
                <article className="form-item__text-wrapper">
                    {renderHeader()}
                    {renderDescription()}
                </article>
            )}
            {children}
        </section>
    );
};
