import { useMutation } from '@tanstack/react-query';
import {
    PackageSellerDraftModel,
    PackageSellerCreateDraftModel,
    PackageSellerCreateDraftModelConverter,
    PackageSellerDraftModelConverter,
    ApiModel,
} from '@steelbuy/ts-shared';
import axios from '../../axios';

const updateModelConverter = new PackageSellerCreateDraftModelConverter();
const draftModelConverter = new PackageSellerDraftModelConverter();

const updatePackageDraft = async (updatePackageDraftRequest: PackageSellerCreateDraftModel) => {
    const { data } = await axios.put<PackageSellerDraftModel>(
        `/api/universal/draft-packages/${updatePackageDraftRequest.id}`,
        updateModelConverter.toApiModel(updatePackageDraftRequest)
    );
    return draftModelConverter.toViewModel(data as unknown as ApiModel<PackageSellerDraftModel>);
};

export const useUpdatePackageDraft = () =>
    useMutation({
        mutationFn: (updatePackageDraftRequest: PackageSellerCreateDraftModel) =>
            updatePackageDraft(updatePackageDraftRequest),
    });
