import { useTranslation } from 'react-i18next';
import { useUserSelfDetails } from '@steelbuy/data-access';
import { OrganisationEntityDataProvider } from '@steelbuy/data-provider';
import { CustomError } from '@steelbuy/error';

import { EditPurchaseContact } from '../../components/edit-purchase-contact/EditPurchaseContact';

export const EditPurchaseContactView = () => {
    const { t } = useTranslation('translation');

    const { data: userModel } = useUserSelfDetails();

    if (!userModel) {
        throw new CustomError(t('error.noUserError'));
    }

    return (
        <OrganisationEntityDataProvider entityId={userModel.organisation.id}>
            <EditPurchaseContact />
        </OrganisationEntityDataProvider>
    );
};
