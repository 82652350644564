import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PackageSellerModel, PackageSellerModelConverter, ApiModel } from '@steelbuy/ts-shared';
import axios from '../../axios';

interface PackageViewsOptions<Model> {
    packageId: string;
    queryOptions?: Omit<UseQueryOptions<Model, unknown, Model, string[]>, 'queryFn' | 'queryKey'>;
}

const modelConverter = new PackageSellerModelConverter();

const getSellerPackageDetails = async (packageId: string) => {
    const { data } = await axios.get<PackageSellerModel>(`/api/universal/packaging/packages/${packageId}`);
    const packageModel = modelConverter.toViewModel(data as unknown as ApiModel<PackageSellerModel>);
    return packageModel;
};

export const useSellerPackageDetails = ({ packageId, queryOptions }: PackageViewsOptions<PackageSellerModel>) =>
    useQuery({
        queryKey: ['packages', packageId],
        queryFn: () => getSellerPackageDetails(packageId),
        ...queryOptions,
        cacheTime: 0,
    });
